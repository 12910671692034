import { Component, Input } from '@angular/core';

import { GlobalInfoMessage } from '@app/shared/interfaces/info/global-info.interface';

@Component({
  selector: 'app-global-info',
  templateUrl: './global-info.component.html',
  styleUrls: ['./global-info.component.scss']
})
export class GlobalInfoComponent {
  @Input() message: GlobalInfoMessage = {
    title: '',
    description: '',
    url_image: ''
  };
}

<div class="row">
  <div class="col" size="12" *ngIf="type === SECTIONS.model; else modelPlaceholder">
    <div class="placeholder-model" *ngFor="let number of items; trackBy: trackByItems">
      <div class="placeholder-model__title"></div>
      <div class="placeholder-model__content"></div>
      <div class="placeholder-model__content"></div>
    </div>
  </div>
  <ng-template #modelPlaceholder>
    <div class="col" size-md="6" size-lg="4" size="12" *ngFor="let number of items; trackBy: trackByItems">
      <app-placeholder-card></app-placeholder-card>
    </div>
  </ng-template>
</div>

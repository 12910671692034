import { Injectable } from '@angular/core';
import { from } from 'rxjs';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { isNullOrUndefined } from '@app/shared/utils/utils';
import { Logger } from '@services/logger/logger.service';
import { STORAGE_CONSTANTS, StorageService } from '@services/storage/';
import { ENV } from 'src/environments/environment';

import { SessionToken, SessionTokenTypes } from './auth.token.service';

export interface TokenCollection {
  token: string;
  refreshToken: string;
  expiration: number;
  refreshTokenExpiration: number;
  sessionRefresh: SessionToken;
}

@Injectable({
  providedIn: 'root'
})
export class TokenHelperService {
  private logger = new Logger('apiTokenProvider');
  constructor(private storage: StorageService) {}

  async get() {
    return this.getMockValue('Token') || this.storage.get(STORAGE_CONSTANTS.TOKEN);
  }

  async getRefreshToken() {
    return this.getMockValue('RefreshToken') || this.storage.get(STORAGE_CONSTANTS.REFRESH_TOKEN);
  }

  async hasAdfsSession(): Promise<boolean> {
    const token = await this.get();
    return !isNullOrUndefined(token);
  }

  async getSessionRefresh() {
    const [refreshToken, refreshTokenExpiration, sessionRefreshData] = await Promise.all([
      this.getRefreshToken(),
      this.getRefreshTokenExpiration(),
      this.storage.get(STORAGE_CONSTANTS.SESSION_REFRESH)
    ]);

    const sessionRefresh: SessionToken = {
      token: refreshToken,
      expiration: refreshTokenExpiration,
      type: SessionTokenTypes.REFRESH_TOKEN
    };

    if (sessionRefreshData) {
      sessionRefresh.token = sessionRefreshData;
      sessionRefresh.type = SessionTokenTypes.SESSION_REFRESH;
    }

    return sessionRefresh;
  }

  async getExpiration() {
    return this.getMockValue('Expiration') || this.storage.get(STORAGE_CONSTANTS.EXPIRATION);
  }

  async getRefreshTokenExpiration() {
    return this.getMockValue('Refresh') || this.storage.get(STORAGE_CONSTANTS.REFRESH_TOKEN_EXPIRATION);
  }

  isTokenExpired(date: number) {
    return date < this.now();
  }

  async getCollection() {
    const [token, refreshToken, expiration, refreshTokenExpiration, sessionRefresh] = await Promise.all([
      this.get(),
      this.getRefreshToken(),
      this.getExpiration(),
      this.getRefreshTokenExpiration(),
      this.getSessionRefresh()
    ]);

    this.logger.info('get collection', {
      token,
      refreshToken,
      expiration,
      refreshTokenExpiration,
      sessionRefresh
    });

    return {
      token,
      refreshToken,
      expiration,
      refreshTokenExpiration,
      sessionRefresh
    };
  }

  getCollectionPromise() {
    return from(this.getCollection());
  }

  set(token: MSafeAny) {
    this.storage.set(STORAGE_CONSTANTS.TOKEN, token);
  }

  remove() {
    this.storage.remove(STORAGE_CONSTANTS.TOKEN);
  }

  now() {
    return Math.round(new Date().getTime() / 1000.0);
  }

  private getMockValue(value: string): string | false {
    const strToEncode = `{
      "upn": "${value}@"
    }`;
    return ENV.isMocksMode && `mock.${btoa(strToEncode)}`;
  }
}

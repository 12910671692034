<app-content-modal [buttons]="buttons" [hideClose]="true" [class.darkmode]="darkMode" [darkMode]="darkMode">
  <h1>{{ currentSection.title | translate }}</h1>
  <div class="row wizard__image">
    <div [ngClass]="welcomeOrSectionClass(currentSection)"></div>
  </div>
  <div class="row wizard__progress" *ngIf="currentSection.active !== null">
    <div *ngFor="let section of progressSection; trackBy: trackByItems">
      <span [class.active]="section" class="dot" *ngIf="section !== null"></span>
    </div>
  </div>
  <div class="row wizard__text">
    <p>{{ currentSection.text | translate }}</p>
  </div>
</app-content-modal>

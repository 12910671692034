import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ProfileMenuLink, STATUS } from '@app/shared/models/profile-menu/profile-menu-link.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-menu-section',
  templateUrl: './menu-section.component.html',
  styleUrls: ['./menu-section.component.scss']
})
export class MenuSectionComponent {
  readonly STATUS = STATUS;
  @Input() list!: ProfileMenuLink[];
  @Output() clickLink = new EventEmitter<ProfileMenuLink>();

  trackByItems(item: MSafeAny) {
    return item;
  }
}

export enum ModelSectionNames {
  INITIAL = 'INITIAL',
  MISSION_AND_VISION = 'MISSION_AND_VISION',
  MODEL_APPLICATION = 'MODEL_APPLICATION',
  MODEL_IMPORTANCE = 'MODEL_IMPORTANCE',
  PARADIGM = 'PARADIGM',
  PURPOSE = 'PURPOSE',
  THE_BOSS = 'THE_BOSS',
  THE_CAPITAL = 'THE_CAPITAL',
  THE_SLIDER = 'THE_SLIDER',
  THE_PROVIDER = 'THE_PROVIDER',
  THE_SOCIETY = 'THE_SOCIETY',
  THE_WORKER = 'THE_WORKER',
  UNIVERSAL_TRUTHS = 'UNIVERSAL_TRUTHS',
  UNIVERSAL_TRUTHS_APPLY = 'UNIVERSAL_TRUTHS_APPLY',
  UNIVERSAL_TRUTHS_CAUSE = 'UNIVERSAL_TRUTHS_CAUSE',
  UNIVERSAL_TRUTHS_CHAIN = 'UNIVERSAL_TRUTHS_CHAIN',
  UNIVERSAL_TRUTHS_CONTRAST = 'UNIVERSAL_TRUTHS_CONTRAST',
  UNIVERSAL_TRUTHS_DPE = 'UNIVERSAL_TRUTHS_DPE',
  UNIVERSAL_TRUTHS_FACTS = 'UNIVERSAL_TRUTHS_FACTS',
  UNIVERSAL_TRUTHS_IMPROVE = 'UNIVERSAL_TRUTHS_IMPROVE',
  UNIVERSAL_TRUTHS_NOBODY = 'UNIVERSAL_TRUTHS_NOBODY',
  UNIVERSAL_TRUTHS_TIME = 'UNIVERSAL_TRUTHS_TIME',
  UNIVERSAL_TRUTHS_WALK = 'UNIVERSAL_TRUTHS_WALK',
  UNIVERSAL_TRUTHS_WHAT = 'UNIVERSAL_TRUTHS_WHAT',
  FINAL = 'FINAL'
}

<div class="container">
  <app-avatar-comment *ngIf="!isReply" [avatarUser]="user.photo"></app-avatar-comment>

  <app-avatar-comment-selector
    *ngIf="isReply"
    [user]="user"
    [userList]="asUsers"
    (selectUser)="emitUserSelected($event)"
  ></app-avatar-comment-selector>

  <div class="editor" [class.reply-with-selector]="isReply && asUsers?.length > 1">
    <app-richtext-editor
      [maxLength]="MAX_COMMENT_LENGTH"
      [placeholder]="commentPlaceholder | translate"
      [minHeight]="MIN_EDITOR_HEIGHT"
      [(ngModel)]="comment"
      *ngIf="langReady"
      (urlDeleted)="hasUrl($event)"
    >
      <button class="c-button link" [disabled]="!this.comment || urlDeleted" (click)="emitComment(publishLabel)">
        {{ publishLabel | translate }}
      </button>
    </app-richtext-editor>
  </div>
</div>

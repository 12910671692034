<div class="row">
  <div class="col" size="10">
    <app-input-wrapper [label]="label">
      <app-input
        placeholder="PROFILE.PERSONAL_DATA.LANGUAGES.SEARCH_DEGREE"
        [model]="searchTerm"
        (modelChange)="onModelChange($event)"
        [disabled]="disabled"
        [maxlength]="maxLength"
        (focusInput)="showDropdown()"></app-input>
    </app-input-wrapper>
    <div #dropdown_item_list class="list dropdown animated fadeIn faster" [hidden]="!toggle">
      <app-item
        class="dropdown-item"
        id="item-{{ item.value }}"
        *ngFor="let item of arrayData; trackBy: trackByItems"
        [class.item-disabled]="!item.selectable"
        (click)="selectItem(item)">
        <div class="content" *ngIf="item.selectable; else notSelectable">
          <h6 class="u-dark-grey" [innerHTML]="item.label"></h6>
          <img *ngIf="item.selected" item-end src="assets/imgs/employee-portal/green-check.svg" alt="Check" />
        </div>
        <ng-template #notSelectable>
          <p>{{ item.label | translate }}</p>
        </ng-template>
      </app-item>

      <app-item class="dropdown-item item-disabled" *ngIf="!arrayData.length">
        <h6 class="u-dark-grey">{{ 'SEARCH_NO_RESULTS' | translate }}</h6>
      </app-item>
    </div>
  </div>
  <div class="col" size="2">
    <button class="c-button primary" [disabled]="disabledButton" (click)="searchItems()">
      {{ 'SEARCH' | translate }}
    </button>
  </div>
</div>

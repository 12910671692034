<app-content-modal [buttons]="buttons">
  <h1 class="base-modal__title">{{ 'PUBLICATIONS_LIST.FILTER_LOCALS' | translate }}</h1>
  <app-input-wrapper label="PUBLICATIONS_LIST.SEARCH_SEGMENT" class="form-item">
    <app-input placeholder="SEARCH" [model]="searchTerm" (modelChange)="onModelChange($event)"></app-input>
  </app-input-wrapper>
  <div class="list list-wrapper">
    <div class="list-item" *ngFor="let segment of segmentsFiltered; trackBy: trackByItems; let i = index">
      <app-checkbox [segment]="segment" (checkboxChange)="toggleSegment(segment)"></app-checkbox>
    </div>
  </div>
</app-content-modal>

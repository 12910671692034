import { Permissions } from '@app/shared/enums/permissions/permissions.enum';
import { flatten } from '@app/shared/utils/utils';

export const MOMO_SECTIONS_PERMISSIONS: { [key: string]: Permissions[] } = {
  MOMO_VACATIONS: [
    Permissions.MOMO_VACATIONS
    // Permissions.MOT_VACATIONS_CALENDAR,
    // Permissions.MOT_VACATIONS_PDF,
  ],
  MOMO_MANAGE_VACATIONS: [Permissions.MOMO_VACATIONS_PROPOSAL_LIST, Permissions.MOMO_VACATIONS_PROPOSAL_EDIT]
};

export const MOMO_PERMISSIONS = flatten(Object.values(MOMO_SECTIONS_PERMISSIONS));

import { Directive, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { CategoriesAnalytics, PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { StorageService } from '@app/services/storage/storage.service';
import { Platform } from '@ionic/angular';

import { Percentages, PERCENTAGES_LIST, ScrollHelper } from '../helpers/scroll.helper';
import { MSafeAny } from '../models/safe-any/safe-any.model';
import { WebViewService } from '../services/webview.service';
import { ContentComponent } from '@app/components/content/content.component';
import { domChanges } from '../utils/utils';
@Directive()
export abstract class BasePageDirective implements OnInit, OnDestroy {
  @ViewChild(ContentComponent) mainContent!: ContentComponent;

  protected readonly analyticsService: AnalyticsService = this.injector.get(AnalyticsService);
  protected readonly webViewService: WebViewService = this.injector.get(WebViewService);
  protected readonly activatedRoute: ActivatedRoute = this.injector.get(ActivatedRoute);
  protected readonly platform: Platform = this.injector.get(Platform);
  protected readonly router: Router = this.injector.get(Router);

  pageAnalytics!: string;
  isWebview: boolean;
  valueDataTags!: string;
  darkMode: boolean;
  valueSearchWebview!: string;

  protected navParams!: MSafeAny;
  protected scrollHelper = new ScrollHelper();

  private percentagesSent: Percentages[] = [];
  private subscription!: Subscription;

  constructor(protected injector: Injector) {
    const urlParams = new URLSearchParams(window.location.search);
    const searchValue = urlParams.get('searchTerm');
    const darkMode = urlParams.get('darkmode');
    const dataTags = urlParams.get('dataTags');

    this.isWebview = this.webViewService.isWebView();

    this.darkMode = this.isWebview && (StorageService.isDarkMode() || this.navParams?.darkmode || darkMode === 'true');

    if (this.isWebview && searchValue) this.valueSearchWebview = searchValue;
    if (this.isWebview && dataTags) this.valueDataTags = dataTags;
  }

  ngOnInit() {
    this.navParams = history.state;
    this.subscribeScroll();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  protected setAnalyticsPage(
    category: CategoriesAnalytics,
    subcategory: CategoriesAnalytics,
    page?: PagesAnalytics,
    errorCode?: string,
    errorDescription?: string
  ) {
    this.analyticsService.setPage(category, subcategory, page, errorCode, errorDescription);
  }

  protected setAnalyticsPageFromRoute() {
    this.activatedRoute.data.subscribe((data: MSafeAny) => {
      this.pageAnalytics = data.pageAnalytics;
      this.analyticsService.setPage(data.categoryAnalytics, data.subCategoryAnalytics, data.pageAnalytics);
    });
  }

  protected resetScrollAnalytics() {
    this.percentagesSent = [];
  }

  protected onScroll?(): MSafeAny;

  private async subscribeScroll() {
    await domChanges();
    this.subscription = this.mainContent?.scrolled.subscribe((scrollEvent: CustomEvent) => {
      this.scrollHelper.updateNative(scrollEvent.target as HTMLElement);

      if (this.onScroll) {
        this.onScroll();
      }

      const scrollPercentage = this.scrollHelper.getScrollPercentageNative();
      const percentagesToSend = this.getPercentagesToSend(scrollPercentage as Percentages);

      percentagesToSend.forEach((percentageToSend) => {
        this.percentagesSent.push(percentageToSend);
      });
    });
  }

  private getPercentagesToSend(percentage: Percentages): Percentages[] {
    return percentage
      ? PERCENTAGES_LIST.slice(1).filter((per) => per <= percentage && !this.percentagesSent.includes(per))
      : [];
  }
}

export enum ActionsAnalytics {
  DIRECT_DEBIT_CHANGE_CERT_DOWNLOAD = 'descarga_certificado_cambio_domiciliacion',
  HEADER = 'navegacion_click_header',
  LOGIN = 'login',
  LOGOUT = 'cerrar_sesion',
  PIT_DATA_CHANGE = 'cambio_datos_irpf',
  VOLUNTARY_WITHHOLDING_EDITION = 'solicitud_retencion_voluntaria',
  SEARCHER_OPEN = 'buscador_apertura',
  SEARCHER_CLICK_RECENT = 'buscador_click_reciente',
  SEARCHER_CLICK_DELETE = 'buscador_click_borrarhist',
  SEARCHER_CLICK_INTRO = 'buscador_click_intro',
  SEARCHER_CLICK_RECOMMENDED = 'buscador_click_recomendado',
  SEARCHER_CLICK_ALL = 'buscador_click_todassecc',
  NAVIGATION_PUBLICATION = 'navegacion_click_publicacion',
  NAVIGATION_FILTER = 'navegacion_click_filtro',
  NAVIGATION_BACK_PUBLICATIONS = 'navegacion_click_backpublicaciones',
  NAVIGATION_BACK_EXAMPLES = 'navegacion_click_backejemplos',
  NAVIGATION_EXAMPLE = 'navegacion_click_ejemplo',
  NAVIGATION_FILTER_MODEL = 'navegacion_click_filtromodelo',
  NAVIGATION_FILTER_ARROW = 'navegacion_click_filtroflecha',
  NAVIGATION_CAROUSEL = 'navegacion_click_carrusel',
  INTERACTION_SHARE = 'interaccion_click_compartir',
  INTERACTION_SHARE_FINISH = 'interaccion_click_finalizarcompartir',
  INTERACTION_SHARE_APP = 'interaccion_click_compartir_app',
  INTERACTION_SHARE_APP_FINISH = 'interaccion_click_finalizarcompartir_app',
  INTERACTION_WATCH_COMMENTS = 'interaccion_click_vercomentarios',
  INTERACTION_COMMENT = 'interaccion_click_comentar',
  INTERACTION_PUBLISH_COMMENT = 'interaccion_click_publicarcomentario',
  INTERACTION_VIDEO_START = 'interaccion_start_video',
  INTERACTION_VIDEO_PERCENT_VIEW = 'interaccion_%visualizacion_video',
  INTERACTION_CLICK_MODIFY = 'interaccion_click_modificar',
  INTERACTION_CLICK_IRPF = 'interaccion_click_verirpf',
  INTERACTION_CLICK_CERTIFICATE = 'interaccion_click_vercertificado',
  INTERACTION_CLICK_REQUEST = 'interaccion_click_solicitar',
  INTERACTION_CREATE_EXAMPLE = 'interaccion_click_crearejemplo',
  INTERACTION_UPLOAD_EXAMPLE = 'interaccion_click_subirejemplo',
  INTERACTION_CLICK_DOWNLOAD = 'interaccion_click_descargar',
  INTERACTION_PDF_DOWNLOAD = 'interaccion_click_descargapdf',
  INTERACTION_EDIT_START = 'interaccion_click_modificar_inicio',
  INTERACTION_EDIT_END = 'interaccion_click_modificar_fin',
  NAVIGATION_MODEL = 'navegacion_click_modelo',
  NAVIGATION_FOOTER = 'navegacion_click_footer',
  NAVIGATION_BACK_MODEL = 'navegacion_click_backmodelo',
  NAVIGATION_SEE_ALL = 'navegacion_click_vertodo',
  NAVIGATION_MENU_AVATAR = 'navegacion_click_perfil',
  NAVIGATION_NOTIFICATION = 'navegacion_click_notificacion',
  INTERACTION_NOTIFICATION = 'interaccion_notificaciones',
  EDIT_AVATAR_IMAGE = 'interaccion_click_editarimg',

  SCROLL = 'scroll',

  INTERACTION_CALENDAR = 'interaccion_calendario',
  INTERACTION_CLICK_LIKE = 'interaccion_click_like',
  INTERACTION_CLICK_OBSERVATIONS = 'interaccion_click_observaciones',
  INTERACTION_CLICK_MORE_INFO = 'interaccion_click_masinformacion',

  CLICK = 'click',
  TEXT = 'text',
  TYPE = 'type',
  CLICKACTION = 'clickAction',
  CLICKLABEL = 'clickLabel',
  DOWNLOAD = 'download',
  MORE_INFO = 'mas_informacion',
  IBAN = 'iban',
  IBAN_MODIFY = 'modificar_iban',
  DIRECT_DEBIT = 'domiciliacion_bancaria',
  DIRECT_DEBIT_CHANGE = 'cambio_domiciliacion_bancaria',
  WITHHOLDING_CERTIFICATE = 'certificado_retenciones',
  WITHHOLDING_VOLUNTARY_REQUEST = 'retencion_voluntaria',
  WITHHOLDING_VOLUNTARY_REQUESTED = 'nueva_retencion',
  PLAY_VIDEO_IRPF = 'play_video_irpf',
  EMPLOYMENT_CERTIFICATE_CONTRACT = 'contrato',
  EMPLOYMENT_CERTIFICATE_LABORAL_SITUATION = 'situacion_laboral',
  EMPLOYMENT_CERTIFICATE_LABORAL_DAY = 'jornada_laboral',
  EMPLOYMENT_CERTIFICATE_DAY_REDUCTION = 'reduccion_jornada',
  REMUNERATION_CERTIFICATE_STUDY_AIDS = 'ayudas_estudios',
  PERMISSION_LEAVES_QUOTES = 'documentacion_cotizaciones',
  PERMISSION_LEAVES_PREGNANCY_RISKY = 'documentacion_embarazo',
  REMUNERATION_INCOMES = 'ingresos_haberes',
  REMUNERATION_INCOMES_TYPE_MONTHLY = 'mensual',
  REMUNERATION_INCOMES_TYPE_REDUCE_WORKING_HOUR = 'mensual_para_reduccion_de_jornada',
  REMUNERATION_INCOMES_TYPE_YEARLY = 'anual',
  REMUNERATION_INCOMES_TYPE_YEARLY_FORECAST = 'anual_previsto',
  REMUNERATION_INCOMES_TYPE_PREVIOUS_ANNUAL = 'anual_de_ejercicios_anteriores',
  REMUNERATION_INCOMES_TYPE_YEARLY_RANGE = 'periodo_concreto',
  YEARS = 'years',
  PERSONAL_DATA_SECTION_PHONE1 = 'telefono_principal',
  PERSONAL_DATA_SECTION_PHONE2 = 'telefono_secundario',
  PERSONAL_DATA_SECTION_MOBILE = 'otro_telefono',
  PERSONAL_DATA_SECTION_EMAIL_EDITION = 'correo_electronico',
  PERSONAL_DATA_SECTION_ADDRESS_EDITION = 'domicilio',
  PERSONAL_DATA_SECTION_SOSTENIBILITY_TRUE = 'sostenible_si',
  PERSONAL_DATA_SECTION_SOSTENIBILITY_FALSE = 'sostenible_no',
  MY_DATA = 'mis_datos_personales',
  MY_HOLIDAYS = 'mis_vacaciones',
  HOLIDAYS_REQUEST = 'solicitud_vacaciones',
  MY_CALENDAR = 'calendario_anual',
  HOLIDAYS = 'vacaciones',
  PORTUGAL_WORKER_HOME = 'portal_trabajador_portugal',
  USER_PIC_EDITED = 'foto_perfil',
  HOME = 'inicio',
  MORE = 'mas',
  LANGUAGE_ES = 'español',
  LANGUAGE_EN = 'ingles',
  LANGUAGE_PT = 'portugues',
  LANGUAGE_CA = 'catalan',
  LANGUAGE_EU = 'euskera',
  LANGUAGE_GL = 'gallego',
  LANGUAGE_VA = 'valenciano',
  FILTER_PT = 'publicaciones_exclusivas_portugal',
  ADDRESS_VIA_TYPE = 'tipo_de_via',
  ADDRESS_VIA_NAME = 'nombre_de_via',
  ADDRESS_VIA_NUMBER = 'numero',
  ADDRESS_VIA_FLOOR = 'piso',
  ADDRESS_VIA_DOOR = 'puerta',
  ADDRESS_VIA_PROVINCE = 'provincia',
  ADDRESS_VIA_TOWN = 'poblacion',
  ADDRESS_VIA_CP = 'codigo_postal',
  CONTINUE = 'continuar',
  VIEWS = 'visualizaciones',
  LIKES = 'Me_gustas',
  LIKED = 'liked',
  COMMENTS = 'Comentarios',
  PAYER = 'pagador',
  OFFICE = 'oficina',
  LOGISTIC_CALENDAR = 'calendario_logistica',
  HOURS_BAG = 'bolsa_horas',
  HOURS_BAG_DETAILS = 'detalle_bolsa_logistica',
  WORKER_EDIT = 'editar_cambios_trabajador',
  SPOUSE_EDIT = 'editar_cambios_conyuge',
  YOUNG_WITHHOLDING_EDIT = 'editar_irs_joven',
  ROOM_BENEFIT_EDIT = 'editar_beneficio_habitacion',
  OFFICESERVICES = 'servicios_oficina',
  START_ON_BOARDING = 'start_onboarding',
  NEXT_ON_BOARDING = 'next'
}

export enum CategoriesAnalytics {
  AUTHENTICATION = 'autenticacion',
  CLICK_NOTIFICATION = 'click_notification',
  EDIT = 'edicion',
  ERROR = 'error',
  EXAMPLES = 'ejemplos',
  GENERAL = 'general',
  HOME = 'home',
  IRPF = 'irpf',
  MODEL = 'modelo',
  NAVIGATION = 'navegacion',
  PAYSLIPS = 'nominas',
  DIRECT_DEBIT = 'domiciliacion',
  PUBLICATIONS = 'publicaciones',
  SEARCH = 'busqueda',
  SHARE_APP = 'compartir_app',
  SHOW_LOCAL_PUBLICATION = 'ver_publicacion_local',
  SEND_COMMENT = 'send_comment',
  IRPF_INFO = 'perfil_irpf_informacion',
  IRPF_MY_DATA = 'perfil_irpf_misdatos',
  IRPF_CERTIFICATE = 'perfil_irpf_certificado',
  IRPF_WITHHOLDING = 'perfil_irpf_retencion',
  PROFILE_HOME = 'perfil_home',
  NOTIFICATIONS = 'notificaciones',
  CERTIFICATES = 'perfil_certificados',
  VACATIONS = 'perfil_vacaciones',
  LOCAL_PUBLICATION = 'local_publication',
  LIST = 'list',
  LOCAL = 'local',
  SETTING = 'mas',
  DELETE = 'eliminar',
  MODIFY = 'modificar',
  CLICK = 'click',
  DOWNLOAD = 'download',
  COMMENT = 'comentar',
  SHARE = 'compartir',
  LIKE = 'me_gusta',
  LIKE_EVENT = 'like',
  SEE = 'ver',
  SCHEDULE = 'mi_horario',
  SCHEDULE_OBSERVATIONS = 'observaciones_horario',
  SEND_PUBLICATION = 'send_publication',
  USER_MENU = 'menu_desplegable',
  SEE_PUBLICATION = 'ver_publicacion',
  SEE_EXAMPLE = 'ver_ejemplo',
  MENU_INFERIOR = 'menu_inferior',
  RESET_PASSWORD = 'reset_contrasena',
  EDIT_LANGUAGE = 'seleccion_idioma',
  MAIN_HOME = 'pagina_principal',
  MOT = 'mot',
  VACATIONS_PAGE = 'vacaciones',
  PROFILE_COMMON = 'generales',
  PROFILE_AVATAR = 'menu_avatar',
  CONTENT = 'contenido',
  finder = 'buscador',
  GLOBAL = 'global',
  PTRAB = 'ptrab',
  PTRAB_nominas = 'mis_nominas',
  PTRAB_irpf = 'datos_irpf',
  PTRAB_certificados = 'certificados',
  PTRAB_datos_personales = 'datos_personales',
  MOT_mi_horario = 'mi_horario',
  SERVICES = 'servicios',
  CONFIGURATION_SELECTED = 'seleccion_configuracion',
  WATCH_VIDEO = 'reproducir_video',
  VACATIONS_PROPOSAL = 'propuesta_vacaciones',
  WORKER = 'trabajador',
  SPOUSE = 'conyuge',
  DEPENDANTS = 'dependientes',
  YOUNG_WITHHOLDING = 'irs_joven',
  ROOM_BENEFIT = 'beneficio_habitacion'
}

export enum PagesAnalytics {
  ANCESTORS = 'modificar_ascendiente',
  BANNED = 'baneado',
  BEHAVIOUR_CODE = 'codigo conducta',
  CERTIFICATES = 'certificados',
  CEUTA_MELILLA_RESIDENCE = 'modificar_residencia',
  CLOSE_APP = 'cierre sesion',
  CONTACT = 'contacto',
  COOKIES = 'cookies',
  LANGUAGE = 'idioma',
  DATA_TREATMENT = 'tratamiento_de_datos',
  DESCENDANTS = 'modificar_descendiente',
  DEVICE_INFO = 'informacion_del_dispositivo',
  DIRECT_DEBIT_EDITION = 'modificar_cuentas',
  ERROR = 'error generico',
  EXAMPLE = 'ejemplo',
  EXAMPLES = 'ejemplos',
  MORE = 'más',
  FULLSCREEN_IMAGES_VIEWER = 'imagen pantalla completa',
  FULLSCREEN_VIDEO = 'video pantalla completa',
  HOME = 'home',
  HOME_PUBLIC = 'home publica',
  INCOME_AND_ASSETS = 'ingresos_haberes',
  IOS_CODES = 'codigos instalacion ios',
  IOS_CODES_HELP = 'ayuda codigos ios',
  LOGIN = 'login',
  LOGOUT = 'logout',
  MAINTENANCE = 'mantenimiento',
  MANAGE_MODEL = 'modelo de gestion',
  MOBILE_LANDING = 'landing movil',
  MODAL_CREATE_EXAMPLE = 'modal crear ejemplo',
  MODAL_CREATED_EXAMPLE = 'modal ejemplo creado',
  MODAL_EDIT_COOKIES = 'modal editar cookies',
  MODAL_EDIT_EXAMPLE = 'modal editar ejemplo',
  MODAL_PUBLISH_EXAMPLE = 'modal publicar ejemplo',
  MODAL_DELETE_EXAMPLE = 'modal eliminar ejemplo',
  MODAL_SHARE_APP = 'modal compartir app',
  MODAL_SHARE_CONTENT = 'modal compartir contenido',
  MODAL_LANGUAGE = 'modal cambiar idioma',
  MODAL_CHANGE_AVATAR_IMAGE = 'modal cambiar imagen avatar',
  MODAL_FILTER_EXAMPLES_UT = 'modal filtro ejemplos vu',
  MODAL_2FA_PHONE_SELECTION = '2fa - modal seleccion telefono',
  MODAL_2FA_CODE_ACCOUNT_VALIDATION = '2fa - modal confirmacion cuenta',
  MODAL_2FA_CODE_VALIDATION = '2fa - modal confirmacion clave',
  MODAL_2FA_PHONE_REGISTRATION = '2fa - modal sin telefonos',
  MODAL_2FA_PHONE_MANAGEMENT = '2fa - modal gestion telefonos',
  MODAL_DIRECT_DEBIT_SUCCESS = 'nominas - modal domiciliacion cambiada',
  MODAL_GARNISHMENT_DETAIL = 'nominas embargos - modal detalle embargo',
  MODAL_CREATE_DESCENDANT = 'irpf datos - modal crear descendiente',
  MODAL_EDIT_DESCENDANT = 'irpf datos - modal editar descendiente',
  MODAL_CREATE_ANCESTOR = 'irpf datos - modal crear ascendiente',
  MODAL_EDIT_ANCESTOR = 'irpf datos - modal editar ascendiente',
  MODAL_IRPF_EDIT_SUCCESS = 'irpf datos - modal datos cambiados',
  MODAL_WITHHOLDING_SUCCESS = 'irpf retencion - modal retencion cambiada',
  MODAL_UNCOMMITTED_CHANGES = 'ptrab - modal cambios sin enviar',
  MODAL_INACTIVE_CHANGES_LOST = 'ptrab - modal inactividad cambios perdidos',
  MODAL_PERSONAL_DATA_ADDRESS_SUCCESS = 'datos personales - modal domicilio modificado',
  MODAL_PERSONAL_DATA_EMAIL_SUCCESS = 'datos personales - modal email modificado',
  MODAL_PERSONAL_DATA_DELETE_EMAIL = 'datos personales - modal eliminar email',
  MODAL_PERSONAL_DATA_EMAIL_DELETED = 'datos personales - modal email eliminado',
  MODAL_PERSONAL_DATA_PHONE_SUCCESS = 'datos personales - modal telefono modificado',
  MODAL_PERSONAL_DATA_DELETE_PHONE = 'datos personales - modal eliminar telefono',
  MODAL_PERSONAL_DATA_PHONE_DELETED = 'datos personales - modal telefono eliminado',
  MODAL_DAY_SCHEDULE = 'mi horario - modal tareas de un dia',
  MODEL = 'modelo',
  MORTGAGE_REDUCTION = 'modificar_hipoteca',
  MY_EXAMPLES = 'mis_ejemplos',
  NOT_FOUND = 'error no encontrado',
  NOTIFICATIONS = 'notificaciones',
  OFFLINE = 'sin conexion',
  OUTDATED_VERSION = 'nueva version disponible',
  PAYSLIPS = 'nominas',
  PENSION_PAYMENT = 'modificar_pension',
  PERSONAL_DATA = 'datos personales',
  PERSONAL_DATA_EDIT_ADDRESS = 'datos personales - modificar domicilio',
  PERSONAL_DATA_ADD_EMAIL = 'datos personales - agregar email',
  PERSONAL_DATA_EDIT_EMAIL = 'datos personales - modificar email',
  PERSONAL_DATA_ADD_PHONE = 'datos personales - agregar telefono',
  PERSONAL_DATA_EDIT_PHONE = 'datos personales - modificar telefono',
  PERSONAL_DATA_ADD_LANGUAGE = 'datos personales - agregar idioma',
  PERSONAL_DATA_EDIT_LANGUAGE = 'datos personales - modificar idioma',
  PIT = 'irpf',
  PIT_INFO = 'irpf info',
  PIT_PT = 'irs',
  PIT_INFO_PT = 'irs info',
  PRIVACY = 'politica_de_privacidad',
  PROFILE = 'perfil',
  PTRAB_LOGIN = 'login ptrab',
  PUBLICATION = 'publicacion',
  PUBLICATIONS = 'publicaciones',
  RISKY_PREGNANCY = 'riesgo_embarazo',
  SEARCH_RESULTS = 'busqueda',
  TERMS_AND_CONDITIONS = 'terminos y condiciones',
  UNKNOWN = 'pagina desconocida',
  PUBLIC_CONTENT = 'publico',
  WITHHOLDING_EDITION = 'modificar_retencion',
  WITHHOLDING_REQUEST_CONFIRMATION = 'irpf retencion voluntaria - confirmar solicitud',
  WITHHOLDING_DELETE_CONFIRMATION = 'irpf retencion voluntaria - confirmar eliminacion',
  WORKER = 'modificar_trabajador',
  SCHEDULE = 'mi_horario',
  VACATIONS = 'mis_vacaciones',
  PDF_VACATIONS = 'vacaciones',
  MOT_PAGES = 'MOT_PAGES',
  MONTHLY_OBSERVATIONS = 'observaciones_mensuales',
  WEEKLY_OBSERVATIONS = 'observaciones_semanales',
  YEARLY_OBSERVATIONS = 'observaciones_anual',
  MORE_INFO = 'mas_informacion',
  PUB_CONTENT = 'publico',
  PRIVATE_CONTENT = 'privado',
  SEARCH_HOME = 'busqueda_publicaciones',
  SEARCH_MODEL = 'busqueda_modelo',
  SEARCH_EXAMPLES = 'busqueda_ejemplo',
  PTRAB_nominas_PAYSLIPS = 'nominas',
  PTRAB_nominas_DIRECT_DEBIT = 'domiciliacion',
  PTRAB_nominas_WAGE_GARNISHMENT = 'embargos',
  PTRAB_nominas_CAR_TRANSFER = 'cesion_coche',
  PTRAB_nominas_GARNISHMENT_ACTIVE = 'embargo_activo',
  PTRAB_nominas_GARNISHMENT_PENDING = 'embargo_pendiente',
  PTRAB_nominas_GARNISHMENT_FINISHED = 'embargo_finalizado',
  PTRAB_irpf_IRPF_DATA = 'consulta_datos',
  PTRAB_irpf_WITHHOLDING_CERTIFICATE = 'certificados_retenciones',
  PTRAB_irpf_VOLUNTARY_WITHHOLDING = 'consultar_retencion',
  PTRAB_irpf_IRPF_INFO = 'que_es_irpf',
  PTRAB_irs_IRPF_DATA = 'os_meus_dados',
  PTRAB_irs_WITHHOLDING_CERTIFICATE = 'declaracao_retencoes',
  PTRAB_irs_VOLUNTARY_WITHHOLDING = 'retençao_voluntaria',
  PTRAB_irs_IRPF_INFO = 'o_que_e_o_irs',
  PTRAB_certificados_EMPLOYMENT = 'contratacion',
  PTRAB_certificados_PERMISSIONS_AND_LEAVES = 'permisos_y_bajas',
  PTRAB_certificados_REMUNERATION = 'vista_retributivos',
  PTRAB_datos_personales_PROFILE = 'trabajador',
  PTRAB_datos_personales_PROFILE_phone1 = 'num_telefono_principal',
  PTRAB_datos_personales_PROFILE_phone2 = 'num_telefono_secundario',
  PTRAB_datos_personales_PROFILE_mobile = 'num_telefono_otro',
  PTRAB_datos_personales_PROFILE_EMAIL_EDITION = 'correo_electronico',
  PTRAB_datos_personales_PROFILE_ADDRESS_EDITION = 'domicilio',
  PTRAB_datos_personales_PROFILE_LANGUAGES = 'mis_idiomas',
  PTRAB_datos_personales_PROFILE_STUDIES = 'mis_estudios',
  PTRAB_datos_personales_LANGUAGES = 'idiomas',
  PTRAB_datos_personales_MY_LANGUAGES = 'idiomas_comunicados',
  PTRAB_datos_personales_MY_STUDIES = 'estudios_comunicados',
  PTRAB_datos_personales_ADD_LANGUAGE = 'añadir_idioma',
  PTRAB_datos_personales_ADD_STUDY = 'anadir_estudio',
  PTRAB_datos_personales_EDIT_LANGUAGE = 'editar_idioma',
  PTRAB_datos_personales_EDIT_STUDY = 'editar_estudio',
  PTRAB_datos_personales_DELETE_LANGUAGE = 'eliminar_idioma',
  PTRAB_datos_personales_DELETE_STUDY = 'eliminar_estudio',
  MOT_mi_horario_MONTHVIEW = 'mes',
  MOT_mi_horario_WEEKVIEW = 'semana',
  MOT_mi_horario_CARDSVIEW = 'fichas',
  FILTER = 'filtrar',
  FILTER_PUBLICATIONS = 'filtrar_publicaciones',
  CANCEL_FILTER = 'cancelar_filtro',
  DELETE_FILTERS = 'borrar_filtros',
  SEE_RESULTS = 'ver_resultados',
  FILTER_SELECTED = 'filtro',
  FILTER_ON = 'activado',
  LOGISTIC_INFO = 'calendario_anual',
  CONFIGURATION = 'configuracion',
  OFFICESERVICES = 'servicios_oficina',
  MY_PUBLICATIONS = 'mis_publicaciones',
  CREATE_PUBLICATION = 'crear_publicacion',
  MANAGE_VACATIONS = 'gestionar_vacaciones',
  OFFICE_FLOOR_PLAN = 'plano_oficinas'
}

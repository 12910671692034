import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ShareContentModule } from '@app/components/share-content/share-content.component.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { CardButtonsComponent } from './components/card-buttons/card-buttons.component';
import { CardInfoComponent } from './components/card-info/card-info.component';
import { CardTooltipComponent } from './components/card-tooltip/card-tooltip.component';
import { ImageCardComponent } from './image-card/image-card.component';
import { InternalLinkCardComponent } from './internal-link-card/internal-link-card.component';
import { MultimediaCardComponent } from './multimedia-card/multimedia-card.component';
import { NewsCardComponent } from './news-card/news-card.component';
import { TextCardComponent } from './text-card/text-card.component';
import { VideoCardComponent } from './video-card/video-card.component';

@NgModule({
  declarations: [
    MultimediaCardComponent,
    ImageCardComponent,
    InternalLinkCardComponent,
    TextCardComponent,
    VideoCardComponent,
    NewsCardComponent,
    CardInfoComponent,
    CardButtonsComponent,
    CardTooltipComponent
  ],
  imports: [CommonModule, IonicModule, TranslateModule, ShareContentModule, PipesModule],
  exports: [
    MultimediaCardComponent,
    ImageCardComponent,
    InternalLinkCardComponent,
    TextCardComponent,
    VideoCardComponent,
    NewsCardComponent,
    CardInfoComponent,
    CardButtonsComponent,
    CardTooltipComponent
  ]
})
export class MultimediaCardModule {}

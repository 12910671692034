import { HttpHeaders } from '@angular/common/http';

import { UserCommentPermission } from '@app/shared/enums/comment/comment.enum';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { AbstractMultimediaItem } from './multimedia.abstract.model';

export class MultimediaMetadata<T extends AbstractMultimediaItem = AbstractMultimediaItem> {
  item: T;
  userCommentPermission!: UserCommentPermission;
  userIsOwner!: boolean;
  userIsPresident!: boolean;

  constructor(item: T, headers?: HttpHeaders) {
    this.item = item;

    if (headers) {
      this.userCommentPermission = headers.get('x-user-comment-permission') as MSafeAny;
      this.userIsOwner = JSON.parse(headers.get('x-user-owner') as string);
      const userPresidentHeader = headers.get('x-user-president');
      this.userIsPresident = userPresidentHeader !== null && (JSON.parse(userPresidentHeader.toLowerCase()) as boolean);
    }
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { IShareItem, IShareSlide, ShareVias } from '../modals/share-modal/share-item.model';

@Component({
  selector: 'app-share-modal-template',
  templateUrl: './share-modal-template.component.html',
  styleUrls: ['./share-modal-template.component.scss']
})
export class ShareModalTemplateComponent {
  @Input() shareItems: IShareItem[] = [];
  @Input() shareSlides: IShareSlide[] = [];
  @Input() linkToShare = '';

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() shareVia: EventEmitter<ShareVias> = new EventEmitter<ShareVias>();

  readonly shareVias = ShareVias;

  close(): void {
    this.closeModal.emit();
  }

  onShareVia(type: ShareVias): void {
    this.shareVia.emit(type);
  }

  trackByItems(item: MSafeAny) {
    return item.title;
  }
}

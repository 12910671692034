import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { Logger, ModalManager } from '@app/services';
import { UserService } from '@app/services/user/user.service';
import { Segment } from '@app/shared/interfaces/segments/segment.interface';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { isValidString, normalizeString } from '@app/shared/utils/utils';
import { MSafeAny } from '@shared/models/safe-any/safe-any.model';
import { InputComponent } from '../input/input.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

const TWO_MILLISECONDS = 200;

@Component({
  selector: 'app-segment-filter',
  templateUrl: './segment-filter.component.html',
  styleUrls: ['./segment-filter.component.scss']
})
export class SegmentFilterComponent implements OnInit {
  private readonly logger: Logger = new Logger('SegmentFilterComponent');

  @ViewChild(InputComponent) searchInput: InputComponent | undefined;
  searchInputEvent: EventEmitter<MSafeAny> | undefined;

  selectedFilterIds!: string[];
  segments: Segment[] | undefined;
  initialSegments: Segment[] | undefined;
  segmentsFiltered: Segment[] | undefined;
  segmentsFilteredSelected: string[] = [];

  buttons: Buttons[] = [];

  searchTerm: string | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny,
    private userService: UserService,
    private modalManager: ModalManager
  ) {
    this.buttons = [
      {
        text: 'PUBLICATIONS_LIST.DELETE_FILTERS',
        type: 'secondary',
        enabled: true,
        onClick: () => this.deleteFilters()
      },
      {
        text: 'PUBLICATIONS_LIST.SEE_ALL_RESULTS',
        type: 'primary',
        enabled: true,
        onClick: () => this.saveFilters()
      }
    ];
  }

  ngOnInit(): void {
    this.selectedFilterIds = this.data.selectedFilterIds || [];

    this.segmentsFilteredSelected = this.selectedFilterIds;
    this.userService.getUserSegments(true).subscribe((segments) => {
      this.initialSegments = segments;
      this.segmentsFiltered = this.initialSegments;
      this.searchInputEvent = this.searchInput?.modelChange;
      this.mapCheckboxes();
      this.loadPredictiveResults();
    });
  }

  /* istanbul ignore next */
  private loadPredictiveResults() {
    let value: string;
    this.searchInputEvent
      ?.pipe(
        debounceTime(TWO_MILLISECONDS),
        switchMap((inputValue: string) => {
          this.segments = JSON.parse(JSON.stringify(this.initialSegments));
          value = inputValue;
          this.search(value);
          if (value) {
            return of(this.segmentsFiltered);
          }
          return of(null);
        })
      )
      .subscribe(
        (searchPredictive) => {
          if (searchPredictive) {
            searchPredictive.forEach((record) => {
              const found = normalizeString(record.description)
                .toLowerCase()
                .indexOf(normalizeString(value).toLowerCase() || normalizeString(record.description).toLowerCase());

              if (found >= 0) {
                record.description = [
                  record.description.slice(0, found),
                  '<b>',
                  record.description.slice(found, found + value.length),
                  '</b>',
                  record.description.slice(found + value.length)
                ].join('');
              }
            });
          }
        },
        (error) => this.logger.error(error)
      );
  }

  search(event?: MSafeAny) {
    /* istanbul ignore next */
    const termToSearch = event ? event.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '').trim() : this.searchTerm;

    if (isValidString(termToSearch)) {
      this.segmentsFiltered = this.segments?.filter((segment) =>
        normalizeString(segment.description).toLowerCase().includes(normalizeString(termToSearch).toLowerCase())
      );
    } else {
      this.segmentsFiltered = this.segments;
    }
  }

  toggleSegment(filter: Segment) {
    const filterIndex = this.segmentsFilteredSelected.findIndex((filterId) => filter.id === filterId);

    if (filterIndex !== -1) {
      this.segmentsFilteredSelected.splice(filterIndex, 1);
    } else {
      this.segmentsFilteredSelected.push(filter.id);
    }
  }

  mapCheckboxes() {
    this.segmentsFiltered?.map((segment) => {
      segment.selected = Boolean(this.segmentsFilteredSelected.find((filterId) => segment.id === filterId));
    });
  }

  onModelChange(term: string) {
    this.searchTerm = term;
  }

  private saveFilters() {
    this.modalManager.dismissMatModal(this.segmentsFilteredSelected);
  }

  private deleteFilters() {
    this.segmentsFilteredSelected = [];
    this.mapCheckboxes();
  }

  trackByItems(item: Segment) {
    return item;
  }
}

import { Injectable, Injector } from '@angular/core';

import { PtrabSessionManager } from '@app/ptrab/services/session/ptrab-session.manager.service';
import { PAGES, PTRAB_SECTIONS, ROOT_PAGES } from '@app/shared/enums/pages/pages.enum';
import { Permissions } from '@app/shared/enums/permissions/permissions.enum';
import { InternalRoute, RouteHelper } from '@app/shared/helpers/route.helper';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { User } from '@app/shared/models/user/user.model';
import { Logger } from '@services/logger/logger.service';
import { NavigationHelperService } from '@services/navigation/navigation.helper.service';
import { NavigationEvent, NavigationEvents } from '@services/navigation/navigation.service';
import { NetworkService } from '@services/network/network.service';

import { EventEmitter } from 'events';

import { AUTHENTICATION_STATUS, AuthService } from '../auth/auth.service';
import { AppError, ErrorTypes } from '../error/error.model';
import { ErrorService } from '../error/error.service';
import { LoadingService } from '../loading/loading.service';
import { StorageService } from '../storage';

/* eslint-disable @typescript-eslint/naming-convention */

export enum AppStatus {
  INIT = 'INIT',
  ERROR = 'ERROR',
  OFFLINE = 'OFFLINE',
  MAINTENANCE = 'MAINTENANCE',
  OK = 'OK',
  BANNED = 'BANNED'
}

const CHECK_HEALTH_INTERVAL = 5000;

@Injectable({
  providedIn: 'root'
})
export class AppManagerService {
  private readonly logger = new Logger('AppManager');
  private readonly LOGOUT_TIMEOUT = 30000;
  private healthCheckInterval: MSafeAny;

  readonly BLOCKING_APP_STATUSES = [AppStatus.ERROR, AppStatus.OFFLINE, AppStatus.MAINTENANCE, AppStatus.BANNED];

  networkService!: NetworkService;
  ptrabSessionManager!: PtrabSessionManager;
  status = AppStatus.INIT;
  statusEmitter = new EventEmitter();

  private authService: AuthService;
  private isLoading = false;

  constructor(
    private errorProvider: ErrorService,
    private navigationHelperService: NavigationHelperService,
    private injector: Injector,
    private loadingService: LoadingService
  ) {
    this.authService = this.injector.get(AuthService);
    this.statusEmitter.setMaxListeners(2);
  }

  init(networkService: NetworkService, ptrabSessionManager: PtrabSessionManager) {
    this.logger.debug('App manager initialized');
    this.networkService = networkService;
    this.ptrabSessionManager = ptrabSessionManager;
  }

  isBlock() {
    return this.BLOCKING_APP_STATUSES.includes(this.status);
  }

  async ready(): Promise<MSafeAny> {
    if (this.status === AppStatus.OK) {
      return Promise.resolve();
    }

    return this.timeoutPromise(
      3000,
      new Promise((resolve) => {
        this.statusEmitter.once('AppStatus', (data: MSafeAny) => {
          if (data === AppStatus.OK) {
            resolve(true);
          }
        });
      })
    );
  }

  async deepLinkRedirect(internalRoute: InternalRoute): Promise<void> {
    if (this.status !== AppStatus.OK) {
      return this.navigationHelperService.storeRedirect(internalRoute);
    }

    return this.navigationHelperService.deepLinkRedirect(internalRoute);
  }

  async rootPageStablished(internalRoute: InternalRoute): Promise<void> {
    this.logger.debug(`rootPageStablished: additional actions on internalRoute "${JSON.stringify(internalRoute)}"`);
    const rootPage = internalRoute.path;

    // eslint-disable-next-line
    if (internalRoute.navParams?.iswebview) {
      StorageService.setWebView();
    }

    // eslint-disable-next-line
    if (internalRoute.navParams?.darkmode) {
      StorageService.setDarkMode();
    }

    // eslint-disable-next-line
    if (internalRoute.navParams?.languageCode) {
      StorageService.setLanguageCode(internalRoute.navParams?.languageCode);
    }

    if (rootPage === ROOT_PAGES.AUTHENTICATION && internalRoute.redirectAfterLogin) {
      return this.navigationHelperService.storeRedirect(internalRoute.redirectAfterLogin);
    }

    if (RouteHelper.isPublic(internalRoute)) {
      this.changeAppStatus(AppStatus.OK);
    }

    const storedRoute = await this.navigationHelperService.getRedirect();
    this.logger.debug(
      `rootPageStablished: RootPage is "${rootPage}" and stored redirect route is: "${JSON.stringify(storedRoute)}"`
    );
    // eslint-disable-next-line
    if (storedRoute && storedRoute.navParams?.iswebview) {
      StorageService.setWebView();
    }

    // eslint-disable-next-line
    if (storedRoute && storedRoute.navParams?.darkmode) {
      StorageService.setDarkMode();
    }

    // eslint-disable-next-line
    if (storedRoute && storedRoute.navParams?.languageCode) {
      StorageService.setLanguageCode(storedRoute.navParams?.languageCode);
    }

    this.navigationHelperService
      .redirect(NavigationEvents.SetRoot, storedRoute, false)
      .then(() => this.navigationHelperService.cleanRedirect())
      .catch(() => {});
  }

  userInfoCompleted() {
    this.changeAppStatus(AppStatus.OK);
  }

  setMaintenanceMode(): Promise<void> {
    if (!this.healthCheckInterval) {
      this.healthCheckInterval = setInterval(() => this.checkMaintenanceMode(), CHECK_HEALTH_INTERVAL);
      this.changeAppStatus(AppStatus.MAINTENANCE);
    }

    return this.navigationHelperService.doRedirect(NavigationEvents.SetRoot, { path: PAGES.MAINTENANCE });
  }

  setBannedStatus() {
    this.navigationHelperService.doRedirect(NavigationEvents.SetRoot, { path: PAGES.BANNED });

    setTimeout(() => {
      this.authService.sendStatus(AUTHENTICATION_STATUS.LOGOUT);
      this.navigationHelperService.doRedirect(NavigationEvents.SetRoot, { path: PAGES.LOGOUT });
    }, this.LOGOUT_TIMEOUT);
  }

  setMaintenanceModeOff() {
    this.errorProvider.removeErrors();

    if (this.healthCheckInterval) {
      clearInterval(this.healthCheckInterval);
      this.healthCheckInterval = null;
    }

    return this.resetToOkStatus()
      .then(() => {
        this.logger.info('Leaving maintenance mode successfully');
      })
      .catch((error) => {
        this.logger.error('Failure leaving maintenance mode: error: ', error);
        this.errorProvider.add(new AppError(ErrorTypes.MAINTENANCE_MODE, error));
      });
  }

  setOffline(error: AppError): Promise<void> {
    this.changeAppStatus(AppStatus.OFFLINE);
    const pageWhenOnline = error.params && error.params.pageWhenOnline;

    return this.navigationHelperService
      .doRedirect(NavigationEvents.Push, { path: PAGES.OFFLINE, navParams: { pageWhenOnline } })
      .catch((err) => {
        this.logger.debug(err);
      });
  }

  setOnline(pageWhenOnline?: MSafeAny): Promise<void> {
    return this.resetToOkStatus(pageWhenOnline);
  }

  setErrorStatus(redirect = false): Promise<void> | undefined {
    this.changeAppStatus(AppStatus.ERROR);
    if (redirect) {
      return this.navigationHelperService.doRedirect(NavigationEvents.Push, { path: PAGES.ERROR });
    }
  }

  goBackOnError(): Promise<void> {
    return this.resetToOkStatus();
  }

  async resetToOkStatus(pageWhenOnline?: MSafeAny): Promise<void> {
    this.changeAppStatus(AppStatus.OK);

    if (!pageWhenOnline && this.navigationHelperService.canTryPop()) {
      return this.navigationHelperService.redirect(NavigationEvents.Pop, undefined);
    }

    const redirectTo = await this.navigationHelperService.getOnlineRedirectPage(pageWhenOnline);
    return this.navigationHelperService.redirect(NavigationEvents.SetRoot, redirectTo);
  }

  async retryOnError(): Promise<void> {
    this.errorProvider.addRetry();
    this.errorProvider.removeErrors();
    this.changeAppStatus(AppStatus.OK);
    this.logger.info(`retry times ${this.errorProvider.retryTimes}`);
    return this.navigationHelperService.retryOnError();
  }

  redirect(
    navigationType: NavigationEvents,
    internalRoute: InternalRoute,
    cleanStorageRedirect = false
  ): Promise<void> {
    if (this.status !== AppStatus.OK) {
      this.logger.debug(`App is not ready to redirect, storing internalRoute: ${JSON.stringify(internalRoute)}`);
      return this.navigationHelperService.storeRedirect(internalRoute);
    }

    return this.navigationHelperService.redirect(navigationType, internalRoute, cleanStorageRedirect);
  }

  async redirectAfterSuccessUserInfoInit(userInfo: User): Promise<void> {
    if (!userInfo.acceptLegal) {
      this.logger.info('userInfoLegal is not Accept');
      return this.errorProvider.add(new AppError(ErrorTypes.TERMS_NOT_ACCEPTED));
    }

    this.logger.info('userInfoLegal is Accept');

    if (userInfo.permissions.includes(Permissions.ONBOARDING)) {
      this.logger.info('user must watch on boarding');
      return this.errorProvider.add(new AppError(ErrorTypes.ON_BOARDING_REQUIRED));
    }

    const redirect = await this.navigationHelperService.getRedirect();
    if (redirect && !RouteHelper.isPtrabPage(redirect.path)) {
      this.logger.info(`detected redirect stored, redirecting to: ${redirect.path}`);
      try {
        return await this.navigationHelperService.redirect(NavigationEvents.SetRoot, redirect, true);
      } catch (error) {
        return this.navigationHelperService.redirect(NavigationEvents.SetRoot, { path: PAGES.HOME }).then(() => {
          return this.navigationHelperService.cleanRedirect();
        });
      }
    }

    return this.navigationHelperService.redirect(NavigationEvents.SetRoot, { path: PAGES.HOME });
  }

  async handleNavigationEvent(navigationEvent: NavigationEvent): Promise<void> {
    try {
      const hasInternalRoute = navigationEvent && navigationEvent.internalRoute;

      if (hasInternalRoute && navigationEvent.internalRoute?.path === PAGES.AUTHENTICATION) {
        await this.navigationHelperService.storeRedirect(navigationEvent.internalRoute.redirectAfterLogin);
        await this.navigationHelperService.doRedirect(NavigationEvents.SetRoot, { path: PAGES.AUTHENTICATION });
        return;
      }

      if (this.status === AppStatus.INIT) {
        await this.navigationHelperService.storeRedirect(navigationEvent.internalRoute);
        return;
      }

      if (hasInternalRoute && !RouteHelper.isPublic(navigationEvent.internalRoute) && !this.authService.isLoggedIn()) {
        await this.navigationHelperService.storeRedirect(navigationEvent.internalRoute);
        return;
      }

      if (hasInternalRoute) {
        await this.navigationHelperService.storeRedirect(navigationEvent.internalRoute?.redirectAfterLogin);
      }

      if (
        hasInternalRoute &&
        RouteHelper.isPtrabPage(navigationEvent.internalRoute?.path) &&
        !PTRAB_SECTIONS.find((page) => page === navigationEvent.internalRoute?.path)
      ) {
        this.showLoader();
        if (!(await this.ptrabSessionManager.checkSession())) {
          return;
        }
      }

      await this.navigationHelperService.redirect(navigationEvent.eventType, navigationEvent.internalRoute);
    } catch (error) {
      this.logger.error(`Unable to perform redirect from Navigation message: ${error}`);
    } finally {
      this.hideLoader();
    }
  }

  private showLoader() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.loadingService.show();
    }
  }

  private hideLoader() {
    this.loadingService.hide();
    this.isLoading = false;
  }

  private checkMaintenanceMode(): Promise<void> {
    return this.networkService
      .hasServerConnection()
      .then((isOnline) => {
        if (isOnline) {
          this.setMaintenanceModeOff();
        }
      })
      .catch((error) => this.logger.error(error));
  }

  private changeAppStatus(newStatus: AppStatus) {
    this.status = newStatus;
    this.statusEmitter.emit('AppStatus', this.status);
  }

  private timeoutPromise(ms: number, promise: Promise<MSafeAny>) {
    const timeout = new Promise((_resolve, reject) => {
      const id = setTimeout(() => {
        clearTimeout(id);
        reject('Timed out in ' + ms + 'ms.');
      }, ms);
    });

    return Promise.race([promise, timeout]);
  }
}

import { HttpClient } from '@angular/common/http';

import { ApiUrls } from '@app/services';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { User } from './user.model';

export enum UserUpdatableTypes {
  IMAGE,
  LANGUAGE
}

export abstract class UserUpdatableProp {
  data: MSafeAny;

  constructor(private http: HttpClient, private url: string) {}

  hasDataAndUser(user: User) {
    return this.data && user;
  }

  async initialize() {
    this.data = await this.http.get(this.url).toPromise();
  }

  abstract hasChanges(user: User): boolean;
  abstract getUserUpdated(user: User): User;
}

export class UserUpdatableImage extends UserUpdatableProp {
  constructor(http: HttpClient, urls: ApiUrls) {
    super(http, urls.user.image_database);
  }

  hasChanges(user: User): boolean {
    return this.hasDataAndUser(user) && user.photo !== this.data.photo;
  }

  getUserUpdated(userStorage: User): User {
    userStorage.photo = this.data.photo;
    return userStorage;
  }
}

export class UserUpdatableLanguage extends UserUpdatableProp {
  constructor(http: HttpClient, urls: ApiUrls) {
    super(http, urls.user.language_database);
  }

  hasChanges(user: User): boolean {
    return this.hasDataAndUser(user) && user.language_code !== this.data.language_code;
  }

  getUserUpdated(userStorage: User): User {
    userStorage.language_code = this.data.language_code;
    userStorage.language_country = this.data.language_country;
    return userStorage;
  }
}

export class UserUpdatablePropsFactory {
  static getUserUpdatableProp(type: UserUpdatableTypes, http: HttpClient, url: ApiUrls): UserUpdatableProp | null {
    switch (type) {
      case UserUpdatableTypes.IMAGE:
        return new UserUpdatableImage(http, url);
      case UserUpdatableTypes.LANGUAGE:
        return new UserUpdatableLanguage(http, url);
      default:
        return null;
    }
  }
}

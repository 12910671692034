<div class="rh__container" *ngIf="display" [ngStyle]="highlighterStyles()">
  <div class="rh__close-holder">
    <ion-icon (click)="close()" class="rh__close" name="close"></ion-icon>
  </div>
  <span *ngIf="!isEmbedded" class="rh__text">{{ 'HIGHLIGHTER.RESULTS' | translate }}</span>
  <strong class="rh__term">{{ searchTerm }}</strong>
  <span class="rh__counter">
    <span *ngIf="!singleResults" class="previous" (click)="previousResult()"><</span>
    {{ currentPosition }}/{{ resultsPositions.length }}
    <span *ngIf="!singleResults" class="next" (click)="nextResult()">></span>
  </span>
</div>

<div *ngIf="loading" class="rh__container" [ngStyle]="highlighterStyles()">
  <span class="rh__text">{{ 'HIGHLIGHTER.LOADING' | translate }}...</span>
  <app-spinner [autoSize]="true" [horizontal]="true"></app-spinner>
</div>

import { Component, Injector, Input } from '@angular/core';

import { Logger } from '@app/services';
import { ItemCardType } from '@app/shared/enums/cards/item-card-type.enum';
import { Multimedia } from '@app/shared/models/multimedia/multimedia-detail.model';
import { MultimediaCardDirective } from '@components/multimedia/multimedia-cards/multimedia-card';

import { MultimediaCarouselComponent } from '../../multimedia-details';

@Component({
  selector: 'app-multimedia-card',
  templateUrl: './multimedia-card.component.html',
  styleUrls: ['../multimedia-card.scss', './multimedia-card.component.scss']
})
export class MultimediaCardComponent extends MultimediaCardDirective {
  @Input() multimedia!: Multimedia;
  @Input() type!: ItemCardType;

  logger: Logger = new Logger(' Multimedia Card Component');

  show = false;

  constructor(injector: Injector) {
    super(MultimediaCarouselComponent, injector);
  }

  protected onModalDetailDismiss(multimedia: Multimedia) {
    if (multimedia) {
      this.multimedia.views = multimedia.views;
      this.multimedia.likes = multimedia.likes;
      this.multimedia.liked = multimedia.liked;
    }
  }

  protected getItem(): Multimedia {
    return this.multimedia;
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ptrab-edit-info',
  templateUrl: './edit-info.component.html',
  styleUrls: ['./edit-info.component.scss']
})
export class EditInfoComponent {
  @Input() description!: string;
  @Input() label!: string;
  @Input() infoText!: string;
}

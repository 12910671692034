import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FormImageService, Logger } from '@app/services';
import { ElementType } from '@app/shared/enums/elements-type/element-type.enum';
import { ContentType } from '@app/shared/enums/multimedia/content-status-types.enum';
import { Example } from '@app/shared/models/multimedia/example.model';
import { ImageExample } from '@app/shared/models/multimedia/image.model';
import { blobToFile, cleanFormat, cleanHTMLAllowingFormat } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';

import { ExampleItemForm } from '../../models/example-item-form.model';
import { MultimediaService } from '../../services/multimedia/multimedia.service';
import { MultimediaManagerEvent } from '../multimedia-manager/multimedia-manager.component';

/* eslint-disable @typescript-eslint/naming-convention */

export interface MultimediaItem {
  file: Blob | undefined;
  preview: string;
}

export interface ImageItem extends MultimediaItem {
  isThumbnail?: boolean;
  base64File?: string;
  caption?: string;
}

@Component({
  selector: 'app-example-form',
  templateUrl: './example-form.component.html',
  styleUrls: ['./example-form.component.scss'],
  providers: [MultimediaService]
})
export class ExampleFormComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  @Input() example!: Example;
  @Output() submitExample: EventEmitter<FormData> = new EventEmitter<FormData>();
  @Output() formChanges: EventEmitter<void> = new EventEmitter<void>();

  elementType = ElementType;
  form!: FormGroup;
  type: ContentType = ContentType.TEXT;
  images: ImageItem[] = [];
  multimedia!: MultimediaItem | null;

  MAX_FILES = 5;

  private logger = new Logger('ExampleFormComponent');
  private modifiedMultimedia = false;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private formImageService: FormImageService
  ) {}

  get thumbnailIndex(): number {
    return this.images.length ? this.images.findIndex((img) => img.isThumbnail) : 0;
  }

  get isMultimediaModified(): boolean {
    return this.modifiedMultimedia;
  }

  get valid(): boolean {
    return this.form && this.form.valid;
  }

  get title(): string {
    return this.form ? this.form.get('title')?.value : '';
  }

  get description(): string {
    return this.form ? this.form.get('description')?.value : '';
  }

  get descriptionControl(): AbstractControl {
    return this.form.get('description') as AbstractControl;
  }

  get titleControl(): AbstractControl {
    return this.form.get('title') as AbstractControl;
  }

  ngOnInit() {
    this.initMultimedia();
    this.form = this.initForm();
    this.form.valueChanges.subscribe(() => {
      this.formChanges.emit();
    });
  }

  addMultimedia({ type, multimedia }: MultimediaManagerEvent) {
    this.type = type;

    if (type === ContentType.IMAGE) {
      this.images = [...(multimedia as ImageItem[])];
    } else {
      this.multimedia = multimedia as MultimediaItem;
    }

    this.descriptionControl.clearValidators();
    this.descriptionControl.updateValueAndValidity();
    this.modifiedMultimedia = true;
    this.formChanges.emit();
  }

  updateImages(images: ImageItem[]) {
    this.images = [...images];
    if (!this.images.length) {
      this.type = ContentType.TEXT;
      this.descriptionControl.setValidators(Validators.required);
      this.descriptionControl.updateValueAndValidity();
    }
    this.modifiedMultimedia = true;
    this.formChanges.emit();
  }

  removeMultimedia() {
    this.multimedia = null;
    this.type = ContentType.TEXT;
    this.modifiedMultimedia = true;
    this.descriptionControl.setValidators(Validators.required);
    this.descriptionControl.updateValueAndValidity();
    this.formChanges.emit();
  }

  async submitForm() {
    const actions = {
      [ContentType.IMAGE]: () => this.formImageService.optimizeImages(this.images),
      [ContentType.VIDEO]: () => [this.multimedia?.file],
      [ContentType.TEXT]: () => []
    };

    const files = await actions[this.type]();

    const fd: FormData = new ExampleItemForm({
      language: this.translate.currentLang,
      content_type: this.type,
      description: cleanHTMLAllowingFormat(this.description),
      title: cleanFormat(this.title),
      files,
      thumbnail: this.thumbnailIndex,
      isEdition: false
    }).buildFormData();

    this.submitExample.emit(fd);
  }

  private initMultimedia() {
    if (!this.example) {
      return;
    }

    this.type = this.example.content_type;
    const actions = {
      [ContentType.IMAGE]: () => this.initImages(this.example as ImageExample),
      [ContentType.VIDEO]: () => (this.multimedia = { file: undefined, preview: this.example.url as string })
    };
    const action: () => void = actions[this.type];

    if (action) {
      action();
    }
  }

  private initImages(example: ImageExample) {
    const { images, url } = example;
    const imageUrls = images.length ? images.map((img) => img.url) : [url];

    this.images = imageUrls.map<ImageItem>((src, index) => ({
      file: blobToFile(new Blob([src as string]), src as string),
      preview: src as string,
      isThumbnail: index === example.images_thumbnail_index
    }));
  }

  private initForm(): FormGroup {
    const title = this.example ? this.example.title : '';
    const description = this.example ? this.example.text.replace(/\n/g, '<br>') : '';
    const validator = this.images.length || this.multimedia ? [] : [Validators.required];
    return this.fb.group({
      title: [title, [Validators.required]],
      description: [description, validator]
    });
  }
}

<app-share-modal-template
  *ngIf="multimediaItem"
  [shareItems]="shareItems"
  [shareSlides]="shareSlides"
  [linkToShare]="linkToShare"
  (closeModal)="close()"
  (shareVia)="shareVia($event)"
>
  <h4 title class="u-h4 u-anthracite">{{ 'SHARE.TITLE' | translate }}</h4>
  <ng-container warning>
    <ion-icon class="u-medium-grey" [name]="isPublic ? 'globe' : 'lock-closed'"></ion-icon>
    <h6 class="u-h6 u-medium-grey u-text-left">
      {{ (isPublic ? 'SHARE.PUBLIC' : 'SHARE.PRIVATE') | translate }}
    </h6>
  </ng-container>
</app-share-modal-template>

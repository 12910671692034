<app-content-modal [buttons]="buttons">
  <div class="grid-fixed">
    <div class="row">
      <h1>{{ 'PROFILE.LANGUAGE' | translate }}</h1>
    </div>
    <div class="row">
      <app-language
        class="container-language"
        [languageCode]="languageCode"
        (sendChoice)="updateLanguage($event)"></app-language>
    </div>
  </div>
</app-content-modal>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent {
  @Input() showText = false;
  @Input() autoSize = false;
  @Input() crescent = false;
  @Input() horizontal = false;
  @Input() spaceBetween = false;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { TwoFactorAuthorization } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { Readjustment, ReadjustmentRequest } from '@app/ptrab/shared/models/readjustment';
import { IDataResponse } from '@app/shared/interfaces/data/data.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { ApiPtrabUrls } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class IrpfReadjustmentService {
  constructor(private apiUrls: ApiPtrabUrls, private http: HttpClient) {}

  getReadjustment(): Observable<Readjustment> {
    return this.http
      .get<IDataResponse<Readjustment>>(this.apiUrls.readjustment.regularization)
      .pipe(map((d) => d.data));
  }

  setRegularization(params: ReadjustmentRequest, authorization: TwoFactorAuthorization): Observable<MSafeAny> {
    const request = { params, authorization };
    return this.http.post(this.apiUrls.readjustment.regularization, request);
  }
}

import { Component, Input, OnChanges, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { AVATAR_STYLE, AVATAR_STYLE_URLS } from '@app/shared/enums/comment/avatar-style.enum';

/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-avatar-comment',
  templateUrl: './avatar-comment.component.html',
  styleUrls: ['./avatar-comment.component.scss']
})
export class AvatarCommentComponent implements OnChanges {
  readonly GENERIC_AVATAR_URL = 'assets/imgs/comments/first-user-avatar.svg';
  sanitizedAvatar: string | SafeUrl = 'assets/imgs/comments/first-user-avatar.svg';

  @Input() avatarUser!: string;

  avatarImage!: string;
  allowedAvatars: string[] = [AVATAR_STYLE.EMPTY_AVATAR, AVATAR_STYLE.FILLED_AVATAR];

  constructor(private sanitizer: DomSanitizer) {
    this.getSanitizedAvatar();
  }

  ngOnChanges() {
    if (!this.checkDefaultInitialization()) {
      this.setImage();
      this.getSanitizedAvatar();
    }
  }

  checkDefaultInitialization(): boolean {
    if (!this.avatarUser) {
      this.setDefaultStyle();
    }
    return !this.avatarUser;
  }

  setDefaultStyle() {
    this.avatarImage = AVATAR_STYLE_URLS.FIRST;
  }

  setImage() {
    this.avatarImage = this.allowedAvatars.some((avatar: string) => {
      return avatar === this.avatarUser;
    })
      ? `assets/imgs/comments/${this.avatarUser}-user-avatar.svg`
      : this.avatarUser;
  }

  getSanitizedAvatar() {
    this.sanitizedAvatar = this.isInternalAvatar()
      ? this.avatarImage
      : (this.sanitizer.sanitize(SecurityContext.URL, this.avatarImage) as string);
  }

  isInternalAvatar() {
    return this.allowedAvatars.includes(this.avatarUser);
  }

  handleAvatarError(event) {
    event.target.src = this.GENERIC_AVATAR_URL;
    this.avatarImage = this.GENERIC_AVATAR_URL;
  }
}

import { Injectable } from '@angular/core';

import { AlertService, Logger } from '@app/services';
import { ContentType } from '@app/shared/enums/multimedia/content-status-types.enum';
import { ExtensionsManager } from '@app/shared/extensions/extensions-manager';
import { FileFormatsHelper } from '@app/shared/helpers/file-formats/file-formats.helper';
import { TranslateService } from '@ngx-translate/core';

import { MultimediaItem } from '../../components/example-form/example-form.component';
import { SIZE_LIMIT } from '../../models/example-form-radio.model';

@Injectable()
export class MultimediaService {
  private extensionsManager = new ExtensionsManager<ContentType>();
  private logger = new Logger('MultimediaService');
  private fileFormatsHelper = new FileFormatsHelper();

  constructor(private translate: TranslateService, private alertService: AlertService) {}

  selectDesktopFiles(selectedFiles: FileList): MultimediaItem[] | undefined {
    if (selectedFiles.length === 0) {
      return;
    }

    const files = Array.from(selectedFiles);
    const firstFile = files[0];

    const validExtensions = files.every((file: File) => this.extensionsManager.isValidExtension(file.name));

    if (!validExtensions) {
      const messages = {
        title: this.getMessage('CUSTOM_FILE_CHOOSER.ERROR'),
        description: this.getMessage('CUSTOM_FILE_CHOOSER.MISMATCH_EXTENSION')
      };

      if (this.fileFormatsHelper.isVideo(firstFile.type)) {
        this.alertService.showWarning(messages.title, messages.description);
      } else {
        this.alertService.showError(messages.title, messages.description);
      }
    }

    const content_type: ContentType = this.extensionsManager.getContentType(firstFile);
    const sizeLimit = SIZE_LIMIT[content_type];
    const validSizes = files.every((file: File) => this.isValidSize(file.size, sizeLimit));

    if (!validSizes) {
      this.showInvalidSizeError(sizeLimit);
      return;
    }

    const multimedia: MultimediaItem[] = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file)
    }));

    this.showSuccessOnChoose();
    return multimedia;
  }

  private isValidSize(rawSize: number, limit: number) {
    const normalizedSize = this.normalizeSize(rawSize);
    return normalizedSize < limit;
  }

  private normalizeSize(rawSize: number): number {
    return parseFloat((rawSize / 1024 / 1024).toFixed(4));
  }

  private showInvalidSizeError(size) {
    this.alertService.showError(
      this.getMessage('CUSTOM_FILE_CHOOSER.ERROR'),
      `${this.getMessage('CUSTOM_FILE_CHOOSER.SIZE_LIMIT')} ${size}MB`
    );
  }

  private showSuccessOnChoose() {
    this.alertService.showSuccess(
      this.getMessage('CUSTOM_FILE_CHOOSER.SUCCESS_SELECT'),
      this.getMessage('CUSTOM_FILE_CHOOSER.SUCCESS_DESC')
    );
  }

  private getMessage(key: string): string {
    return this.translate.instant(key);
  }
}

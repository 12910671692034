import { Component, Input } from '@angular/core';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-card-tooltip',
  templateUrl: './card-tooltip.component.html',
  styleUrls: ['./card-tooltip.component.scss']
})
export class CardTooltipComponent {
  @Input() is_liked!: false;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { ApiUrls } from '../api/api.urls.service';
import { AppError, ErrorTypes } from '../error/error.model';
import { ErrorService } from '../error/error.service';
import { Logger } from '../logger';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private logger = new Logger('NetworkProvider');
  constructor(private urls: ApiUrls, private errorService: ErrorService, private http: HttpClient) {}

  hasConnection() {
    return navigator.onLine;
  }

  doIfConnection(callback: () => void, pageWhenOnline = null, callbackOffline?: () => void) {
    if (this.hasConnection()) {
      return callback();
    }
    if (callbackOffline) {
      callbackOffline();
    }
    this.redirectIfOffline(pageWhenOnline);
  }

  redirectIfOffline(pageWhenOnline = null) {
    if (this.hasConnection()) {
      return;
    }
    return this.errorService.add(new AppError(ErrorTypes.OFFLINE, undefined, { pageWhenOnline }));
  }

  async isOnline() {
    if (!this.hasConnection()) {
      this.logger.error('No connection available');
      return false;
    }

    return this.hasServerConnection(5, 5000, true);
  }

  async hasServerConnection(
    maxRetries: number = 2,
    requestTimeout: number = 2000,
    noRetry: boolean = false
  ): Promise<boolean> {
    const httpObserver: Observable<MSafeAny> = this.http.get(this.urls.healthCheck.get);
    const httpPromise: Promise<MSafeAny> = noRetry
      ? httpObserver.toPromise()
      : httpObserver.pipe(timeout(requestTimeout), retry(maxRetries)).toPromise();
    return httpPromise
      .then(() => {
        return true;
      })
      .catch(() => {
        this.logger.error('HealthCheck failed - no connection');
        return false;
      });
  }
}

export enum SECTIONS {
  model = 'model',
  publications = 'publications',
  examples = 'examples'
}

export enum STATUSES {
  empty = 'empty',
  loading = 'loading',
  loaded = 'loaded',
  error = 'error'
}

export const PAGE_SIZE = {
  all: 48,
  examples: 48,
  publications: 48,
  model: 48
};

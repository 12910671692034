import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IDataResponse } from '@app/shared/interfaces/data/data.interface';

import { ApiPtrabUrls } from '../api/api.service';
import { IDocument, IDocumentUrl } from '@app/ptrab/shared/interfaces/document.interface';
import { Document } from '@app/ptrab/shared/models/document';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  constructor(private apiUrls: ApiPtrabUrls, private http: HttpClient) {}

  getDocuments(): Observable<Document[]> {
    return this.http
      .get<IDataResponse<IDocument[]>>(this.apiUrls.documents.data)
      .pipe(map((rawData) => rawData.data.map((data) => new Document(data))));
  }

  getDocumentUrlById(documentId: string): Observable<string> {
    return this.http.get<IDocumentUrl>(this.apiUrls.documents.url(documentId)).pipe(map((rawData) => rawData.url));
  }

  getOnBoardingDocument(): Observable<Document | null> {
    return this.http
      .get<IDataResponse<IDocument | null>>(this.apiUrls.documents.onboarding)
      .pipe(map((rawData) => (rawData.data ? new Document(rawData.data) : null)));
  }
}

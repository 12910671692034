<div class="c-feedback-msg c-feedback-msg--{{ type }}" [class.reverse]="reverseContent">
  <img
    class="c-feedback-msg__img"
    [class.d-none]="imageHiddenOnMobile"
    [class.d-md-block]="imageHiddenOnMobile"
    [src]="messageData.image"
  />
  <p *ngIf="!wrapper.innerHTML.trim()" class="c-feedback-msg__text">{{ messageData.textLabel | translate }}</p>
  <div #wrapper class="c-feedback-msg__content"><ng-content></ng-content></div>
</div>

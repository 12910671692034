import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { IrsWithholdingModel } from '@app/ptrab/shared/models/irs.model';
import { Withholding } from '@app/ptrab/shared/models/withholding';

@Directive()
export abstract class BaseWithholdingInfoComponent {
  @Input() withholding!: Withholding | IrsWithholdingModel;
  @Input() singleCompany!: boolean;

  @Output() editClick = new EventEmitter<Withholding | IrsWithholdingModel>();
  @Output() requestClick = new EventEmitter();
  @Output() removeClick = new EventEmitter();

  onEditClick() {
    this.editClick.emit(this.withholding);
  }

  onRequestClick() {
    this.requestClick.emit();
  }

  onRemoveClick() {
    this.removeClick.emit();
  }
}

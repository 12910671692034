<div *ngIf="profileInfo">
  <app-menu-header [userInfo]="profileInfo" (closeEvent)="close()"></app-menu-header>
  <app-menu-section
    *ngFor="let personalList of personalSections; trackBy: trackByItems"
    [list]="personalList"
    (clickLink)="goToPage($event)"></app-menu-section>
  <div class="logout" (click)="logout()">
    <a class="segment u-lmenu">
      <i role="img" [ngClass]="'app-icon-logout'" class="segment-icon"></i>
    </a>
    {{ 'LOGOUT' | translate }}
  </div>
</div>

export enum NotificationTypes {
  ACCEPTED = 'accepted',
  OPENED = 'opened',
  REJECTED = 'rejected',
  NEWS = 'news'
}

export enum NotificationStatus {
  NEW = 'new',
  OPENED = 'opened',
  DELETED = 'deleted'
}

export enum NotificationActions {
  SCROLL_TO_CONTENT = 'scrollToContent',
  GO_TO_CONTENT = 'goToContent',
  PUBLICATION_ACCEPTED = 'publicationAccepted',
  PUBLICATION_REJECTED = 'publicationRejected',
  PUBLICATION_RETURNED = 'publicationReturned',
  GO_TO_LOCAL_PUBLICATION = 'goToLocalPublication'
}

export enum NotificationsTabs {
  PUBLICATIONS = 'PUBLICATIONS',
  INDIVIDUAL = 'INDIVIDUAL'
}

export enum NotificationPages {
  GARNISHMENT = 'payslipsGarnishment',
  DOCUMENTS = 'documentsSign'
}

export const NOTIFICATION_CATEGORIES = [NotificationsTabs.PUBLICATIONS, NotificationsTabs.INDIVIDUAL];

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CookiesConfig } from '@app/shared/models/cookies/cookies.config';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { UserAnalytics } from '@app/shared/models/user/analytics-user.model';
import { ENV } from 'src/environments/environment';

import { IAnalyticsEngine } from './models/analytics-engine.interface';
import { CategoriesAnalytics, PagesAnalytics } from './models/analytics.enum';
import { WebAnalyticsEngine } from './web-engine';
import { Logger } from '../logger';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  logger = new Logger('AnalyticsService');
  private analyticsEngine!: IAnalyticsEngine;
  private isTrackingEnabled: MSafeAny;
  private environment!: string;

  private overrideAnalyticsPage = new BehaviorSubject<string>('');
  overrideAnalyticsPage$: Observable<string> = this.overrideAnalyticsPage.asObservable();

  constructor(private cookieConfig: CookiesConfig) {
    this.setEnv();
    this.initAnalytics();
  }

  sendEvent(category: CategoriesAnalytics, eventParameters: MSafeAny) {
    const normalizedEventParameterValues = this.normalizeEventParameters(eventParameters);
    this.analyticsEngine.sendEvent(category, normalizedEventParameterValues);
    this.logDebug(`Track event ${category}, parameters ${JSON.stringify(eventParameters)}`);
  }

  setPage(
    category: CategoriesAnalytics,
    subcategory: CategoriesAnalytics,
    page: PagesAnalytics = '' as PagesAnalytics,
    params?: MSafeAny,
    errorCode: string = '',
    errorDescription: string = ''
  ) {
    this.analyticsEngine.setPage(category, subcategory, page, params, errorCode, errorDescription);
  }

  setUserProperties(user: UserAnalytics) {
    this.analyticsEngine.setUserProperties(user);
  }

  setModelLevel(modelLevel: number) {
    this.analyticsEngine.setModelLevel(modelLevel.toString());
  }

  toggleTracking(isTrackingEnabled: boolean) {
    if (this.isTrackingEnabled !== isTrackingEnabled) {
      this.isTrackingEnabled = isTrackingEnabled;
      this.analyticsEngine.toggleTracking(isTrackingEnabled);
      this.logger.debug(`Is tracking enabled: "${isTrackingEnabled}"`);
    }
  }

  overridePage(page: string) {
    this.overrideAnalyticsPage.next(page);
  }

  private async initAnalytics() {
    this.analyticsEngine = new WebAnalyticsEngine();

    const cookiesData = await this.cookieConfig.getCookiesService();
    if (cookiesData) {
      this.toggleTracking(cookiesData.notEssentialsCookies);
    } else {
      this.isTrackingEnabled = undefined;
      this.toggleTracking(false);
    }
  }

  private normalizeEventParameters(eventParameters: MSafeAny) {
    const normalized = {};
    Object.keys(eventParameters).forEach((key) => (normalized[key] = this.normalizeString(eventParameters[key])));
    return normalized;
  }

  private normalizeString(value: string): string {
    if (this.isString(value)) {
      // use of normalize & replace to remove accents as it is specified in
      // stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript#answer-37511463
      return value
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .split(' ')
        .join('_');
    }

    return value;
  }

  private isString(value: MSafeAny): boolean {
    return typeof value === 'string' || value instanceof String;
  }

  private logDebug(log: string) {
    if (this.isTrackingEnabled) {
      this.logger.debug(log);
    }
  }

  private setEnv() {
    const env: string = ENV.name ?? '';
    const platform = 'web';

    if (env?.includes('local')) {
      this.environment = `local_${platform}`;
      return;
    }

    this.environment = `${env}_${platform}`;
  }
}

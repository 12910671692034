import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TwoFactorOperationCode } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { UserPhone } from '@app/ptrab/shared/models/user-phone';
import { ModalManager } from '@app/services';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ptrab-phone-selection',
  templateUrl: './phone-selection.component.html',
  styleUrls: ['./phone-selection.component.scss']
})
export class PhoneSelectionComponent implements OnInit {
  phones!: UserPhone[];
  isPortugueseUser!: boolean;

  selectedPhoneId!: string;
  description!: string;
  reminderMessage!: string;
  isSinglePhone!: boolean;

  buttons: Buttons[] = [
    {
      text: 'EMPLOYEE_PORTAL.NOT_HAVE_NUMBER',
      type: 'link',
      enabled: true,
      onClick: () => this.modalManager.dismissMatModal(TwoFactorOperationCode.REQUEST_OTHER_PHONE)
    },
    {
      text: 'EMPLOYEE_PORTAL.CONTINUE',
      type: 'primary',
      enabled: true,
      onClick: () => this.close()
    }
  ];

  private messages!: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny,
    private translateService: TranslateService,
    private modalManager: ModalManager
  ) {
    this.getTranslations();
  }

  ngOnInit() {
    this.phones = this.data.phones;
    this.isPortugueseUser = this.data.isPortugueseUser;

    this.isSinglePhone = this.phones && this.phones.length === 1;
    this.checkPhones();
  }

  close() {
    this.modalManager.dismissMatModal(this.selectedPhoneId);
  }

  setSelectedPhone(phoneId: string) {
    this.selectedPhoneId = phoneId;
  }

  trackByItems(item: UserPhone) {
    return item;
  }

  private checkPhones() {
    this.reminderMessage = this.messages[`EMPLOYEE_PORTAL.ONLY_MOBILE`];

    if (this.isSinglePhone) {
      this.description = this.messages['EMPLOYEE_PORTAL.SINGLE_PHONE.MESSAGE'];
      this.setSelectedPhone(this.phones[0].id);
      return;
    }
    this.description = this.messages['EMPLOYEE_PORTAL.SELECT_NUMBER'];
  }

  private getTranslations() {
    this.translateService
      .get([
        'EMPLOYEE_PORTAL.CLOSE_SESSION_TITLE',
        'EMPLOYEE_PORTAL.ACCEPT',
        'EMPLOYEE_PORTAL.CANCEL',
        'EMPLOYEE_PORTAL.CLOSE_SESSION_DETAIL',
        'EMPLOYEE_PORTAL.SEND',
        'EMPLOYEE_PORTAL.MANAGE_PHONES.TITLE',
        'EMPLOYEE_PORTAL.MANAGE_PHONES.DETAIL',
        'EMPLOYEE_PORTAL.PHONE_ENDED',
        'EMPLOYEE_PORTAL.SELECT_NUMBER',
        'EMPLOYEE_PORTAL.ONLY_MOBILE',
        'EMPLOYEE_PORTAL.SINGLE_PHONE.MESSAGE',
        'EMPLOYEE_PORTAL.CONTINUE'
      ])
      .subscribe((messages) => (this.messages = messages));
  }
}

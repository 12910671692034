<div class="popover__content" [class.tablet]="isTablet">
  <img class="close" src="assets/imgs/comments/x-tooltip.svg" (click)="close.emit()" />
  <div class="user-info">
    <h3 class="user-info__name">{{ comment.name | nounUpperCase }} {{ comment.lastname | nounUpperCase }}</h3>
    <div class="user-info__store-info">
      <p>{{ comment.department | nounUpperCase }}</p>
      <p *ngIf="comment.cod_store">
        {{ (comment.store | nounUpperCase) + ' - ' + (comment.cod_store | nounUpperCase) }}
      </p>
    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';

import { BaseIrpfInfoDirective } from '../irpf-info-base/irpf-info-base.component';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-ptrab-irs-info',
  templateUrl: './irs-info.component.html',
  styleUrls: ['./irs-info.component.scss']
})
export class IrsInfoComponent extends BaseIrpfInfoDirective implements OnInit, OnDestroy {
  protected readonly VIDEO_ID = 'irs';
}

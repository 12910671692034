import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { SECTIONS } from '@shared/constants/search/search-results.const';
import { PAGES } from '@shared/enums/pages/pages.enum';

import { ModelResult } from './model-result.model';
import {
  AbstractMultimediaItem,
  AbstractPaginatedMultimediaItemResponse
} from '../multimedia/multimedia.abstract.model';

export interface SearchHistory {
  section: string;
  phrase: SECTIONS;
}

export class SearchParameters {
  term!: string;
  section!: SECTIONS;
  page!: PAGES;
}

export class ModelCardItem {
  title!: string;
  result!: {
    type: string;
    phrase?: string;
    dataTags?: string;
  };
  highlights!: string[];
  page!: string;
  section!: string;
  url!: string;
}

export interface UnifiedSearchPagination {
  count: number;
  last_viewed: number;
  limit: number;
  page: number;
}

export interface UnifiedSearchResponse {
  examples?: {
    results: AbstractMultimediaItem[];
    pagination: UnifiedSearchPagination;
  };
  model?: ModelResult[];
  publications?: {
    results: AbstractMultimediaItem[];
    pagination: UnifiedSearchPagination;
  };
}

export class SearchResult {
  examples: AbstractPaginatedMultimediaItemResponse;
  model: ModelResult[];
  publications: AbstractPaginatedMultimediaItemResponse;

  constructor(item: MSafeAny) {
    this.examples = item.examples;
    this.model = item.model;
    this.publications = item.publications;
  }
}

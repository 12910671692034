import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { FilterTypes } from '../enums/filter-types.enum';

export interface IDropdownOption {
  option: string | null;
  filter: FilterTypes;
}

export interface DropdownItemOptions<T = MSafeAny> {
  value: T;
  label: string;
  selected: boolean;
  selectable?: boolean;
}

export class DropdownItem<T = MSafeAny> implements DropdownItemOptions<T> {
  value: T;
  label: string;
  selected: boolean;
  selectable?: boolean;

  constructor(item: DropdownItemOptions<T>) {
    this.value = item.value;
    this.label = item.label;
    this.selected = item.selected;
    this.selectable = item.selectable;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ActionSheetController, ModalController, MenuController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AutoCloseOverlaysService {
  private unsubscribeBackButtonSubject = new BehaviorSubject<string>('subscribe');
  unsubscribeBackButtonSubject$ = this.unsubscribeBackButtonSubject.asObservable();

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private modalCtrl: ModalController,
    private menu: MenuController
  ) {}

  async trigger() {
    const actionSheet = await this.actionSheetCtrl.getTop();
    const modal = await this.modalCtrl.getTop();
    if (!actionSheet && !modal) {
      this.getMenuStatus();
    } else if (actionSheet) {
      actionSheet.dismiss();
    } else if (modal) {
      modal.dismiss();
    }
  }

  async getMenuStatus() {
    const menu = await this.menu.getOpen();
    if (!menu) {
      this.exitApp();
    } else {
      this.menu.close();
    }
  }

  subscribeToBackButton() {
    this.unsubscribeBackButtonSubject.next('subscribe');
  }

  exitApp() {
    this.unsubscribeBackButtonSubject.next('exitApp');
  }
}

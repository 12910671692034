<app-content-modal [buttons]="buttons" [hideClose]="true">
  <div class="grid">
    <div class="row">
      <h1>{{ title | translate }}</h1>
    </div>
    <div class="row" *ngFor="let content of contents; trackBy: trackByItems">
      <p>{{ content | translate : { example: example } }}</p>
    </div>
  </div>
</app-content-modal>

import { Ancestor, Descendant } from './worker-relative';
import { Disabilities, MaritalStatus, Territories } from '../enums/irpf.enum';
import { IrpfDataResponse } from '../interfaces/irpf-data-response.interface';

export interface Worker {
  maritalStatus: MaritalStatus;
  spouseNif?: string | null;
  reducedMobility: boolean;
  disability: Disabilities;
  geographicMobilityDate: string;
}

export interface PensionPayment {
  childAllowance: number;
  alimony: number;
  courtRulingDate?: string;
}

export interface IrpfTexts {
  ancestorsText: string;
  descendantsText: string;
}

export class IrpfModel {
  worker: Worker;
  i18nTexts: IrpfTexts;
  descendants: Descendant[];
  ancestors: Ancestor[];
  pensionPayment: PensionPayment;
  mortgageReduction: boolean;
  ceutaMelillaResidence: boolean;
  territory: Territories;
  minimumChildrenAge?: number;
  childrenIncomeCondition?: number;

  constructor(irpfResponse: IrpfDataResponse) {
    const workerInfo: Worker = {
      disability: irpfResponse.disability,
      maritalStatus: irpfResponse.maritalStatus,
      spouseNif: irpfResponse.spouseNif,
      reducedMobility: Boolean(irpfResponse.reducedMobility),
      geographicMobilityDate: irpfResponse.geographicMobilityDate
    };
    const pensionPaymentInfo: PensionPayment = {
      alimony: irpfResponse.alimony,
      childAllowance: irpfResponse.childAllowance,
      courtRulingDate: irpfResponse.courtRulingDate
    };

    this.worker = workerInfo;
    this.i18nTexts = irpfResponse.i18nTexts;
    this.descendants = irpfResponse.descendants;
    this.ancestors = irpfResponse.ancestors ?? [];
    this.pensionPayment = pensionPaymentInfo;
    this.mortgageReduction = Boolean(irpfResponse.mortgageReduction);
    this.ceutaMelillaResidence = Boolean(irpfResponse.ceutaMelillaResidence);
    this.territory = irpfResponse.territory;
    this.minimumChildrenAge = irpfResponse.minimumChildrenAge;
    this.childrenIncomeCondition = irpfResponse.childrenIncomeCondition;
  }

  isCommonTerritory() {
    return this.territory && this.territory === Territories.COMMON_TERRITORY;
  }

  isNavarraTerritory() {
    return this.territory && this.territory === Territories.NAVARRA_TERRITORY;
  }

  isEuskalTerritory() {
    return [Territories.ARABA_TERRITORY, Territories.BIZKAIA_TERRITORY, Territories.GIPUZKOA_TERRITORY].includes(
      this.territory
    );
  }
}

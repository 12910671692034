import { LocalPublicationStatus } from '@app/activo2/publications/local-publications.constants';
import { PageExtras } from '@app/services/analytics/models/analytics-engine.interface';
import { ContentType } from '@app/shared/enums/multimedia/content-status-types.enum';
import { ENV } from 'src/environments/environment';

import { AbstractMultimediaItem } from '../multimedia/multimedia.abstract.model';
import { MSafeAny } from '../safe-any/safe-any.model';
/* eslint-disable @typescript-eslint/naming-convention */
export class LocalPublication extends AbstractMultimediaItem {
  content_type!: ContentType;
  images!: {
    image: string;
    caption: string;
    mime_type: string;
    url?: string;
  }[];
  video!: {
    url: string;
    video_image: string;
    url_video_upload: string;
    vimeo_status: string;
    video_id: string;
  };

  constructor(multimediaItem: MSafeAny) {
    super(multimediaItem);
    this.link = `${ENV.APP_UNIVERSAL_LINKS_URL}/p/${this.slug}`;
  }

  getAnalyticsPageExtras(): PageExtras {
    return {
      pageType: 'publicaciones',
      tipologiaDeContenido: this.getAnalyticsPageContentType()
    };
  }
}

export interface LocalPublicationListItem {
  id: number;
  content_type: ContentType;
  title: string;
  status: LocalPublicationStatus;
  publication_date: string;
  thumbnail: string;
}

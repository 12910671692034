import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

import { Comment } from '@app/shared/models/comment/comment';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-user-info-popover',
  templateUrl: './user-info-popover.component.html',
  styleUrls: ['./user-info-popover.component.scss']
})
export class UserInfoPopoverComponent {
  @Input() comment!: Comment;
  // eslint-disable-next-line
  @Output() close = new EventEmitter();
  @Output() closePopover = new EventEmitter<number>();

  isTablet = this.platform.is('tablet');
  private clicks = 0;

  @HostBinding('class.desktop') isDesktop = true;
  @HostBinding('class.tablet') tablet = this.isTablet;

  constructor(private element: ElementRef, private platform: Platform) {}

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchend', ['$event'])
  clickOutside(event) {
    if (!this.element.nativeElement.contains(event.target)) {
      this.closePopover.emit(this.clicks);
      this.clicks++;
    }
  }
}

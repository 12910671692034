import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

type SanitizerType = 'html' | 'style' | 'script' | 'url' | 'resourceUrl';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    value: MSafeAny,
    type: SanitizerType
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl | null {
    const actions = {
      html: () => this.sanitizer.sanitize(SecurityContext.HTML, value),
      style: () => this.sanitizer.sanitize(SecurityContext.STYLE, value),
      script: () => this.sanitizer.sanitize(SecurityContext.SCRIPT, value),
      url: () => this.sanitizer.bypassSecurityTrustUrl(value),
      resourceUrl: () => this.sanitizer.bypassSecurityTrustResourceUrl(value)
    };

    if (!type || !actions[type]) {
      throw new Error(`Invalid safe type specified: ${type}`);
    }

    return actions[type]();
  }
}

import { NotificationsTabs } from '@app/shared/enums/notification/notification.enum';
import { Permissions } from '@shared/enums/permissions/permissions.enum';
import { NavTab } from '@shared/interfaces/nav-tab/nav-tab.interface';

export const NOTIFICATIONS_NAV_TABS: NavTab[] = [
  {
    tabID: NotificationsTabs.PUBLICATIONS,
    label: 'NOTIFICATIONS_PAGE.PUBLICATIONS',
    tabIcon: '',
    permissions: [Permissions.NOTIFICATIONS_PUBLICATIONS]
  },
  {
    tabID: NotificationsTabs.INDIVIDUAL,
    label: 'NOTIFICATIONS_PAGE.INDIVIDUALS',
    tabIcon: '',
    permissions: [Permissions.NOTIFICATIONS_INDIVIDUAL]
  }
];

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { Logger } from '@app/services';
import { ItemChangeNotifier } from '@app/services/multimedia/item-change-notifier.service';
import { AbstractMultimediaItem } from '@app/shared/models/multimedia/multimedia.abstract.model';

enum IIconsType {
  like = 'LIKE',
  comment = 'COMMENT'
}
@Component({
  selector: 'app-multimedia-actions',
  templateUrl: './multimedia-actions.component.html',
  styleUrls: [
    '../multimedia-details/multimedia-detail.component.scss',
    '../multimedia-actions/multimedia-actions.component.scss'
  ]
})
export class MultimediaActionsComponent implements OnInit, OnDestroy {
  @Input() item!: AbstractMultimediaItem;
  @Input() analyticsCategory!: string;
  @Output() commentsClickedEvent = new EventEmitter();
  @Output() sendLikeEvent = new EventEmitter();
  logger = new Logger('Multimedia actions');
  hasActions = false;
  actionsCounter = 0;
  actionsSpacing = {
    'one-action-available': false,
    'two-actions-available': false
  };
  isLessThanThree = false;
  isShareable = false;
  likeImagePath = '';
  commentImagePath = '';
  iconsType = IIconsType;
  subscriptions = new Subscription();
  isWebView!: boolean;

  constructor(private itemChangeNotifier: ItemChangeNotifier) {}
  ngOnDestroy() {
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.isShareable = this.item.isShareable();
    this.getToolbarActions();
    this.initIcons();
    this.isLessThanThree = this.actionsCounter < 3;
    this.subscribeToItemChange();
  }

  private subscribeToItemChange() {
    this.subscriptions.add(
      this.itemChangeNotifier.updatedItem$.subscribe(() => {
        this.initIcons();
      })
    );
  }

  like() {
    this.sendLikeEvent.emit();
  }

  goToComments() {
    this.commentsClickedEvent.emit();
  }

  getToolbarActions() {
    if (this.item?.hasLikesAllowed()) {
      this.actionsCounter++;
    }

    if (this.item?.hasCommentsAllowed()) {
      this.actionsCounter++;
    }

    if (this.item?.isShareable()) {
      this.actionsCounter++;
    }

    switch (this.actionsCounter) {
      case 1: {
        this.actionsSpacing = {
          'one-action-available': true,
          'two-actions-available': false
        };
        this.hasActions = true;
        break;
      }
      case 2: {
        this.actionsSpacing = {
          'one-action-available': false,
          'two-actions-available': true
        };
        this.hasActions = true;
        break;
      }
      case 3: {
        this.hasActions = true;
        this.actionsSpacing = {
          'one-action-available': false,
          'two-actions-available': false
        };
        break;
      }
      default: {
        this.hasActions = false;
        break;
      }
    }
  }

  over(isOver: boolean, iconsType: IIconsType) {
    switch (iconsType) {
      case IIconsType.like:
        this.likeImagePath = `assets/imgs/likes/like${this.item.liked ? '_filled' : '_empty'}${
          isOver ? '_hover' : ''
        }.svg`;
        break;
      case IIconsType.comment:
        this.commentImagePath = `assets/imgs/comments/comment${
          (this.item?.user_comments as number) > 0 ? '_filled' : '_empty'
        }${isOver ? '_hover' : ''}.svg`;
        break;
    }
  }

  trackByItems(item: number) {
    return item;
  }

  private initIcons() {
    this.likeImagePath = `assets/imgs/likes/like${this.item.liked ? '_filled' : '_empty'}.svg`;
    this.commentImagePath = `assets/imgs/comments/comment${
      (this.item?.user_comments as number) > 0 ? '_filled' : '_empty'
    }.svg`;
  }
}

<div *ngIf="!isPublication" class="overview" [class.spacing]="!item?.likes_allowed || !item?.comments_allowed">
  <div class="views-container">
    <p [ngPlural]="item.views" class="overview-views">
      {{ item.views | number }}
      <ng-template ngPluralCase="=1">{{ 'VIEW' | translate | ucfirst }}</ng-template>
      <ng-template ngPluralCase="other">{{ 'VIEWS' | translate | ucfirst }}</ng-template>
    </p>
  </div>
  <div class="comments-container">
    <p *ngIf="item?.comments_allowed" class="overview-comments">
      {{ item.n_comments || 0 | number }}
      <img class="comment-icon" src="assets/imgs/comments/comment-grey.svg" />
    </p>
  </div>
</div>

<div *ngIf="isPublication" [class.spacing]="!item?.likes_allowed || !item?.comments_allowed">
  <div class="overview" *ngIf="!isSegmentedPublication; else local">
    <div class="views-container">
      <p [ngPlural]="item.views" class="overview-views">
        {{ item.views | number }}
        <ng-template ngPluralCase="=1">{{ 'VIEW' | translate | ucfirst }}</ng-template>
        <ng-template ngPluralCase="other">{{ 'VIEWS' | translate | ucfirst }}</ng-template>
      </p>
    </div>
    <div class="likes-container">
      <p *ngIf="item?.likes_allowed" class="overview-likes">
        {{ item.likes || 0 | number }} <img class="image" src="assets/imgs/likes/likes.svg" />
      </p>
    </div>
    <div class="comments-container">
      <p *ngIf="item?.comments_allowed" class="overview-comments">
        {{ item.n_comments || 0 | number }}
        <img class="comment-icon" src="assets/imgs/comments/comment-grey.svg" />
      </p>
    </div>
  </div>
  <ng-template #local>
    <div class="local-publication">
      <div class="row">
        <div size="8" class="col pv-0">
          <div class="row">
            <span [ngPlural]="item.views" class="overview-views">
              {{ item.views | number }}
              <ng-template ngPluralCase="=1">{{ 'VIEW' | translate | ucfirst }}</ng-template>
              <ng-template ngPluralCase="other">{{ 'VIEWS' | translate | ucfirst }}</ng-template>
            </span>
          </div>
          <div class="row second">
            <span *ngIf="item?.likes_allowed" class="overview-likes">
              {{ item.likes || 0 | number }} <img class="image" src="assets/imgs/likes/likes.svg" />
            </span>
            <span *ngIf="item?.comments_allowed" class="overview-comments">
              {{ item.n_comments || 0 | number }}
              <img class="comment-icon" src="assets/imgs/comments/comment-grey.svg" />
            </span>
          </div>
        </div>
        <div size="4" class="col local-publication-col">
          <div class="local-publication-container">
            {{ item.label.description }}
            <img [src]="item.label.image" />
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="isExpandedCard" class="expanded-info">
  <span class="author" [innerHTML]="item.author | nounUpperCase | textOrBlankSpace"></span>
  <span [innerHTML]="item.department | nounUpperCase | textOrBlankSpace"></span>
  <span [innerHTML]="item.division_zone | nounUpperCase | textOrBlankSpace"></span>
  <span [innerHTML]="item.getStoreFullName() | nounUpperCase | textOrBlankSpace"></span>
</div>

<header class="c-data-section__header" [class.d-none]="hideHeader">
  <ng-content select=".header-left-item"></ng-content>
  <h6 class="c-data-section__title u-h6 u-medium-grey">{{ title }}</h6>
  <span class="notification-dot" *ngIf="showDot"> </span>
  <img
    *ngIf="popover"
    src="assets/imgs/profile/information-circle-filled.svg"
    class="tooltip"
    #mPopover="m-popover"
    popoverPosition="bottomRight"
    [m-popover]="popoverTemplate"
    customPanelClass="custom" />
  <span class="c-data-section__action u-ls" (click)="actionClick.emit()">{{ actionText }}</span>
  <ng-content select=".header-right-item"></ng-content>
</header>
<div class="c-data-section__content" [class.pt-0]="hideHeader">
  <ng-content></ng-content>
</div>

<ng-template #popoverTemplate>
  <div class="info-popover__content">
    <p>{{ popoverText }}</p>
  </div>
</ng-template>

export enum UserCommentPermission {
  ALLOWED = 'ALLOWED',
  BLOCKED = 'BLOCKED',
  BANNED = 'BANNED',
  DISABLED = 'DISABLED'
}

export enum UserCommentRole {
  ADMIN = 'adminActivo2'
}

export enum CommentStatus {
  ACCEPTED = 'accepted',
  WARNING = 'warning',
  FORBIDDEN = 'forbidden'
}

import { ApiPtrabUrls } from '@app/ptrab/services/api/api.service';
import { TwoFactorOperationCode } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { ActionsAnalytics } from '@app/services/analytics/models/analytics.enum';

const apiUrls = new ApiPtrabUrls();

export const DIRECT_DEBIT_CERTIFICATES = {
  PAYSLIP_DIRECT_DEBIT: {
    title: 'EMPLOYEE_PORTAL.PAYSLIP_DIRECT_DEBIT',
    description: 'EMPLOYEE_PORTAL.PAYSLIP_DIRECT_DEBIT_DESCRIPTION',
    url: apiUrls.certificate.wageCharge,
    operationCode: TwoFactorOperationCode.WAGE_CHARGE_CERTIFICATE,
    analyticsLabel: ActionsAnalytics.DIRECT_DEBIT,
    onErrorMessage: ''
  },
  CHANGE_DIRECT_DEBIT: {
    title: 'EMPLOYEE_PORTAL.CHANGE_DIRECT_DEBIT',
    description: 'EMPLOYEE_PORTAL.BANK_ACCOUNT_CERTIFICATE_DETAIL',
    url: apiUrls.directDebit.getFile,
    operationCode: TwoFactorOperationCode.DIRECT_DEBIT_CERTIFICATE,
    analyticsLabel: ActionsAnalytics.DIRECT_DEBIT_CHANGE,
    onErrorMessage: 'EMPLOYEE_PORTAL.NO_CHANGE_IN_BANK_ACCOUNT'
  }
};

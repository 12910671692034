import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { MediaPlayerComponent } from '@app/components/media-player/media-player.component';
import { Logger } from '@app/services';
import { VimeoStatus } from '@app/shared/constants/multimedia/vimeo.constants';
import { VideoProviderTypes } from '@app/shared/enums/media-player/media-player.enum';
import { Video } from '@app/shared/models/multimedia/video.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { MultimediaDetailDirective } from '../multimedia-detail.component';

/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-video-detail',
  templateUrl: './video-detail.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./video-detail.component.scss', '../multimedia-detail.component.scss']
})
export class VideoDetailComponent extends MultimediaDetailDirective implements OnInit {
  @ViewChild('player') mediaplayer!: MediaPlayerComponent;

  item!: Video;
  logger = new Logger('Video Detail');
  isTranscodingVideo = false;
  timeout: MSafeAny;

  readonly timeoutDelay = 180000; // 3 minutes

  readonly CSS_CLASSES = {
    MODAL: 'media-content-modal--fullscreen',
    PAGE: 'video-detail--fullscreen',
    TITLE: 'plyr__title-overlay'
  };
  isWebView!: boolean;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getVideo();
    this.isWebView = this.webViewService.isWebView();
  }

  beforeDismiss = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.mediaplayer && !this.isLoadingItem) {
      this.mediaplayer.stopPlayer();
    }
  };

  onPlay() {
    if (!this.item.text) {
      this.network.doIfConnection(() => this.incrementViews());
    }
  }

  onEnterFullscreen() {
    document.body.classList.add(this.CSS_CLASSES.PAGE);
    this.playerService.enterFullscreenDevice(this.mediaplayer);
  }

  onExitFullscreen() {
    document.body.classList.remove(this.CSS_CLASSES.PAGE);
    this.playerService.exitFullscreenDevice(this.mediaplayer);
  }

  onPlayerReady(evt) {
    this.logger.debug(`Player is ready at ${new Date().toLocaleTimeString('es')}`);

    try {
      const container = evt.detail.plyr.elements.container;
      const titleEl = document.createElement('div');
      titleEl.className = this.CSS_CLASSES.TITLE;
      titleEl.innerText = this.item.title;
      container.appendChild(titleEl);
    } catch (err) {
      this.logger.error(err);
    }

    if (this.mediaplayer.isYoutubePlayer) {
      this.isLoadingFile = false;
    }
  }

  onLoadStart() {
    this.logger.debug(`Video load has been started at ${new Date().toLocaleTimeString('es')}`);
  }

  onLoadedData() {
    this.isLoadingFile = false;
    this.logger.debug(`Video data has been loaded at ${new Date().toLocaleTimeString('es')}`);
  }

  onLoadedMetadata() {
    this.isLoadingFile = false;
    this.logger.debug(`Video metadata has been loaded at ${new Date().toLocaleTimeString('es')}`);
  }

  onCanPlay() {
    this.logger.debug(`Video can be played at ${new Date().toLocaleTimeString('es')}`);
  }

  onStalled() {
    this.logger.debug(`Video stalled at ${new Date().toLocaleTimeString('es')}`);
    this.network.redirectIfOffline();
  }

  onMultimediaError(event) {
    const error = event.detail.plyr.media.error || {};
    this.logger.debug('Media player has errors: ', error);

    if (error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
      let msg = this.messages['ERRORS_TOASTS.GENERIC_MSG'];
      if (!this.item.url) {
        msg = this.messages['ERRORS_TOASTS.INVALID_CONTENT_URL'];
      }

      this.isLoadingFile = false;
      this.alertService.showError(this.messages['ERRORS_TOASTS.ERROR_TITLE'], msg);
    }
  }

  getVideo() {
    this.getItem().subscribe(
      () => {
        if (this.item.video_provider === VideoProviderTypes.VIMEO && this.vimeoStatusError) {
          this.close();
          this.alertService.showError(
            this.messages['ERRORS_TOASTS.ERROR_TITLE'],
            this.messages['ERRORS_TOASTS.' + this.vimeoStatusError]
          );
          return;
        }
        this.isTranscodingVideo =
          'isTranscoding' in this.item && typeof this.item.isTranscoding === 'function'
            ? this.item.isTranscoding()
            : false;
        this.isLoadingFile = !this.isTranscodingVideo;

        if (this.item.text) {
          this.incrementViews();
        }

        if (this.isTranscodingVideo) {
          this.timeout = setTimeout(() => this.getVideo(), this.timeoutDelay);
        }
      },
      (err) => {
        this.logger.error(err);
        this.close();
      }
    );
  }

  get vimeoStatusError(): string | null {
    switch (this.item.vimeo_status) {
      case VimeoStatus.AVAILABLE:
      case VimeoStatus.TRANSCODING:
      case VimeoStatus.TRANSCODE_STARTING:
      case VimeoStatus.UPLOADING:
        return null;
      case VimeoStatus.TRANSCODING_ERROR:
        return 'INVALID_FORMAT';
      default:
        return 'INCOMPLETE_VIDEO';
    }
  }

  like() {
    this.sendLike(this.item);
  }
}

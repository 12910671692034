import { Component } from '@angular/core';

import { SECTION_MAINTENANCE } from './section-maintenance.const';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-section-maintenance',
  templateUrl: './section-maintenance.component.html',
  styleUrls: ['./section-maintenance.component.scss']
})
export class SectionMaintenanceComponent {
  readonly section_maintenance = SECTION_MAINTENANCE;
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { FilterService, ModalManager } from '@app/services';
import { NavigationHelperService } from '@app/services/navigation/navigation.helper.service';
import { Filter, FilterTypes } from '@app/shared/models/filters/filters';

import { FilterPopoverComponent } from './filter-popover/filter-popover.component';

@Component({
  selector: 'app-filter-tag-list',
  templateUrl: './filter-tag-list.component.html',
  styleUrls: ['./filter-tag-list.component.scss']
})
export class FilterTagListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() filterType!: FilterTypes;
  @Input() updateData!: boolean;
  @Input() filterChanges = false;

  @Output() filter = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() scrollArrow = new EventEmitter<void>();
  @Output() hasFilters = new EventEmitter<boolean>();

  filterTypeSection = FilterTypes;

  list!: Filter[];
  selected!: Filter | null;

  constructor(
    private filterService: FilterService,
    private modalManager: ModalManager,
    private navigationHelperService: NavigationHelperService
  ) {}

  ngOnChanges() {
    if (this.filterType === FilterTypes.PUBLICATION) {
      this.getFiltersLocal();
      const previousUrl = this.navigationHelperService.getPreviousUrl();
      if (this.filterChanges && !previousUrl.includes('/publication/')) {
        this.clearFilter();
        this.filterChanges = false;
      }
    }
  }

  ngOnInit() {
    if (this.filterType !== FilterTypes.PUBLICATION) {
      this.getFiltersLocal();
    } else {
      this.selected = null;
      this.filterService.removeFilterSelected(this.filterType);
    }
  }

  async getFiltersLocal() {
    this.list = await this.filterService.getFilters(this.filterType);
    this.selected = await this.filterService.getFilterSelected(this.filterType);
    if (this.filterType === FilterTypes.PUBLICATION) {
      const hasFilters = this.list?.length > 0;
      this.hasFilters.emit(hasFilters);
    }
  }

  selectFilter(filter: Filter) {
    if (filter.subSections) {
      this.openSubsectionsFilterModal(filter);
      return;
    }
    this.setFilter(filter);
  }

  async clearFilter() {
    this.selected = null;
    await this.filterService.removeFilterSelected(this.filterType);
    this.cancel.emit();
  }

  ngOnDestroy() {
    this.modalManager.dismissAllMatModal();
    if (this.filterType === FilterTypes.PUBLICATION) this.clearFilter();
  }

  trackByItems(item: Filter) {
    return item;
  }

  private async openSubsectionsFilterModal(filter: Filter) {
    const popoverTitle = this.filterService.getModalTitle(this.filterType);
    const modalOptions: MatDialogConfig = {
      data: {
        filterList: filter.subSections,
        title: popoverTitle
      },
      panelClass: 'full-width-modal'
    };
    const filterPopover = await this.modalManager.createMatModal(FilterPopoverComponent, modalOptions);

    /* istanbul ignore next */
    // eslint-disable-next-line
    filterPopover?.afterClosed().subscribe((filterSelected: Filter) => {
      if (!filterSelected) {
        return;
      }
      this.setFilter(filterSelected);
    });
  }

  private async setFilter(filterSelected: Filter) {
    this.selected = filterSelected;
    await this.filterService.setFilterSelected(filterSelected, this.filterType);
    this.filter.emit(filterSelected.value ? filterSelected.value : filterSelected);
  }
}

/* eslint-disable id-denylist */
/* eslint-disable @typescript-eslint/naming-convention */

import { Component, Input, OnInit } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { SECTIONS } from '@shared/constants/search/search-results.const';

@Component({
  selector: 'app-placeholder-list',
  templateUrl: './placeholder-list.component.html',
  styleUrls: ['./placeholder-list.component.scss']
})
export class PlaceholderListComponent implements OnInit {
  @Input() number!: number;
  @Input() type!: SECTIONS;

  items!: MSafeAny[];
  SECTIONS = SECTIONS;

  ngOnInit() {
    this.items = Array(this.number).fill(0);
  }

  trackByItems(item: number) {
    return item;
  }
}

import { HttpHeaders } from '@angular/common/http';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

export class Pagination {
  count!: number;
  limit!: number;
  page!: number;

  constructor(headers?: HttpHeaders) {
    if (headers) {
      this.count = Number(headers.get('x-pagination-count'));
      this.limit = Number(headers.get('x-pagination-limit'));
      this.page = Number(headers.get('x-pagination-page'));
    }
  }
}

export class ServerPagination {
  count: number;
  limit: number;
  page: number;
  lastViewed: number;

  constructor(pagination: MSafeAny) {
    this.count = pagination.count;
    this.limit = pagination.limit;
    this.page = pagination.page;
    this.lastViewed = pagination.last_viewed;
  }
}

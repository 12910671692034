import { Directive, Injector } from '@angular/core';

import { Platform } from '@ionic/angular';

import { isTablet } from '../utils/is-tablet.util';

@Directive()
export abstract class BaseComponentDirective {
  protected readonly platform: Platform = this.injector.get(Platform);

  isTablet = isTablet();

  constructor(protected injector: Injector) {}
}

import { Component, Input } from '@angular/core';

import { IrsWithholdingModel } from '@app/ptrab/shared/models/irs.model';

import { BaseWithholdingInfoComponent } from '../../base-voluntary-withholding/base-withholding-info/withholding-info.component';

@Component({
  selector: 'app-ptrab-withholding-info-pt',
  templateUrl: './withholding-info-pt.component.html',
  styleUrls: ['./withholding-info-pt.component.scss']
})
export class WithholdingInfoPtComponent extends BaseWithholdingInfoComponent {
  @Input() withholding!: IrsWithholdingModel;
}

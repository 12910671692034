/* eslint-disable @typescript-eslint/naming-convention */

export enum ContactTypes {
  ISSUE = 'issue',
  SUGGESTION = 'suggestion',
  QUESTION = 'question'
}

export interface IMessage {
  id?: string;
  message_type: ContactTypes;
  subject: string;
  creation_date?: string;
  text: string;
}
export class Message implements IMessage {
  id: string;
  message_type: ContactTypes;
  subject: string;
  creation_date: string;
  text: string;

  constructor(data: IMessage) {
    this.id = data.id as string;
    this.message_type = data.message_type;
    this.subject = data.subject;
    this.creation_date = data.creation_date as string;
    this.text = data.text;
  }
}

export const isTablet = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const smallest = Math.min(width, height);
  const largest = Math.max(width, height);
  const hasTabletBounds = smallest > 460 && smallest < 820 && largest > 780 && largest < 1400;

  const userAgent = navigator.userAgent.toLowerCase();
  const jsTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );

  return (isMobileWeb() && hasTabletBounds) || jsTablet;
};

export const isIOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /iPad|iPhone|iPod/.test(userAgent);
};

export const isAndroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /android/i.test(userAgent);
};

export const isMobileWeb = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  const isAndroidDevice = /android/i.test(userAgent);
  const isIosDevice = /iPad|iPhone|iPod/.test(userAgent);

  return isAndroidDevice || isIosDevice;
};

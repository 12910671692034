import { FilterTypes } from '@app/shared/models/filters/filters';
import { ENV } from 'src/environments/environment';

export const STORAGE_CONSTANTS = {
  TOKEN: `${ENV.name}-idtoken`,
  REFRESH_TOKEN: `${ENV.name}-refreshToken`,
  SESSION_REFRESH: `${ENV.name}-sessionRefresh`,
  REFRESH_TOKEN_EXPIRATION: `${ENV.name}-refreshTokenExpiration`,
  USER_ID: `${ENV.name}-userLoginId`,
  USERNAME: `${ENV.name}-username`,
  EXPIRATION: `${ENV.name}-tokenExpiration`,
  CONDITIONS: `${ENV.name}-conditions`,
  USER: `${ENV.name}-userInfo`,
  FORCE_LOGIN: `${ENV.name}-forceLogin`,
  AUDIT_MESSAGES: `${ENV.name}-auditMessages`,
  SEARCH_PARAMETERS: `${ENV.name}-searchParameters`,
  MYEXAMPLES_TAB: `${ENV.name}-myExamplesTab`,
  EXAMPLES_FILTER: `${ENV.name}-ExampleFilters`,
  REDIRECT_INTERNAL_ROUTE: `${ENV.name}-redirectInternalRoute`,
  RESPONSE_AS_OTHER_USER: `${ENV.name}-responseAsOtherUser`,
  PAYSLIPS_CURRENT_TAB: `${ENV.name}-payslipsCurrentTab`,
  IRPF_CURRENT_TAB: `${ENV.name}-irpfCurrentTab`,
  CERTIFICATES_CURRENT_TAB: `${ENV.name}-irpfCurrentTab`,
  APP_VERSION: `${ENV.name}-appVersion`,
  SCHEDULE_CURRENT_TAB: `${ENV.name}-scheduleCurrentTab`,
  SIGNED_TIMETABLES_FILTER: `${ENV.name}-signedTimetablesFilter`,
  NOTIFICATIONS_CURRENT_TAB: `${ENV.name}-notificationsCurrentTab`,
  PERSONAL_DATA_CURRENT_TAB: `${ENV.name}-personalDataCurrentTab`,
  VACATIONS_CURRENT_TAB: `${ENV.name}-vacationsCurrentTab`,
  COOKIES: `${ENV.name}-cookie`,
  IS_WEB_VIEW: `${ENV.name}-isWebView`,
  DARK_MODE: `${ENV.name}-darkMode`,
  LANGUAGE_CODE: `${ENV.name}-languageCode`,
  PUBLICATIONS_SEGMENTS_TAGS: `${ENV.name}-publication-segments`,
  PUBLICATIONS_SEGMENT_TAG_SELECT: `${ENV.name}-${FilterTypes.PUBLICATION}`,
  WEB_VIEW_PATH: `${ENV.name}-WebViewPath`,
  FIRST_MOT_SCHEDULE: `${ENV.name}-firtsMotSchedule`,
  VACATIONS_YEAR: `${ENV.name}-vacationsYear`,
  LOGISTIC_CALENDAR_YEAR: `${ENV.name}-logisticCalendarYear`,
  LOGISTIC_CALENDAR_TAB: `${ENV.name}-logisticCalendarTab`,
  CHECK_OFFICE_SERVICE: `${ENV.name}-checkOfficeService`,
  CHECK_OFFICE_SERVICE_SELECTED: `${ENV.name}-checkOfficeService_selected`,
  REDIRECT_PAGE_AFTER_PTRAB_LOGIN: `${ENV.name}-setPageToRedirectAfterPtrabLogin`,
  REDIRECT_PARAMS_AFTER_PTRAB_LOGIN: `${ENV.name}-setParamsToRedirectAfterPtrabLogin`,
  OFFICE_FLOOR: `${ENV.name}-officeFloor`
};

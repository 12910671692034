<app-data-section>
  <app-data-item
    [label]="'EMPLOYEE_PORTAL.VOLUNTARY_WITHHOLDING.MAX_CURRENT_TAX' | translate"
    value="{{ withholding.maxRetention | number : '.0' }}%"></app-data-item>
  <ng-container *ngIf="!withholding.voluntaryRetention">
    <app-data-item
      [label]="'EMPLOYEE_PORTAL.VOLUNTARY_WITHHOLDING.CURRENT_TAX' | translate"
      [value]="'EMPLOYEE_PORTAL.VOLUNTARY_WITHHOLDING.NO_TAX_INFO' | translate"
      [actionText]="'EMPLOYEE_PORTAL.REQUEST' | translate"
      (actionClick)="onRequestClick()"></app-data-item>
    <p class="applicable-withholding-message u-p3 u-medium-grey">
      {{ 'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.REQUEST.APPLICABLE_MESSAGE' | translate }}
    </p>
  </ng-container>
  <ng-container *ngIf="withholding.voluntaryRetention">
    <app-data-item
      [label]="'EMPLOYEE_PORTAL.VOLUNTARY_WITHHOLDING.CURRENT_TAX' | translate"
      value="{{ withholding.voluntaryRetention | number : '.0' }}%"
      [actionText]="'EMPLOYEE_PORTAL.MODIFY' | translate"
      (actionClick)="onEditClick()"></app-data-item>
  </ng-container>
</app-data-section>

/* eslint-disable id-denylist */
import { PersonalDataAddress } from './personal-data.model';

/* eslint-disable @typescript-eslint/naming-convention */

export interface HierarchyItem {
  order?: number;
  id: string;
  alternative_code: string;
  name?: string;
}
export class Address {
  id: string;
  principal: boolean;
  streetName: string;
  streetNumber: string;
  floorNumber: string;
  stairway: string;
  doorNumber: string;
  streetTypeId: string;
  streetTypeName: string;
  countryIsoNumericCode: string;
  postalCode: string;
  hierarchyCode?: string;
  hierarchy: HierarchyItem[];
  province: HierarchyItem;
  city: HierarchyItem;

  constructor(data: PersonalDataAddress) {
    this.id = data.address_id;
    this.principal = data.is_principal;
    this.streetName = data.street_name;
    this.streetNumber = data.street_number;
    this.floorNumber = data.floor_number;
    this.stairway = data.stairway;
    this.doorNumber = data.door_number;
    this.streetTypeId = data.street_type_id;
    this.streetTypeName = data.street_type_name;
    this.countryIsoNumericCode = data.country_iso_numeric_code;
    this.postalCode = data.postal_code;
    this.hierarchyCode = data.hierarchy_code;
    this.hierarchy = data.hierarchy;
    this.province = data.hierarchy.find((place) => place.order === 1)!;
    this.city = data.hierarchy.find((place) => place.order === 2)!;
  }
}

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ptrab-toggle-option',
  templateUrl: './toggle-option.component.html',
  styleUrls: ['./toggle-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleOptionComponent),
      multi: true
    }
  ]
})
export class ToggleOptionComponent implements ControlValueAccessor {
  @Input() label!: string;
  @Input() description!: string;
  @Input() disabled = false;
  @Input() cssClass!: string;

  value!: boolean;

  writeValue(value?: boolean) {
    this.value = value || false;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  registerOnChange(fn: (_: boolean) => void) {
    this.onChange = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleModelChange(state: boolean) {
    this.value = state;
    this.onTouch();
    this.onChange(this.value);
  }

  getCssClass() {
    return this.cssClass ? this.cssClass : 'label';
  }

  private onTouch = () => {};
  private onChange = (_: boolean) => {};
}

import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { CookiesModalCommentComponent } from '@app/components/modals/cookies-modal-comment/cookies-modal-comment.component';
import { AlertService, Logger, ModalManager } from '@app/services';
import { CommentsService } from '@app/services/comments/comments.service';
import { LanguageService } from '@app/services/language/language.service';
import { STORAGE_CONSTANTS, StorageService } from '@app/services/storage';
import { UserService } from '@app/services/user/user.service';
import { LANGUAGES } from '@app/shared/constants/language/language.const';
import { AVATAR_STYLE, AVATAR_STYLE_URLS } from '@app/shared/enums/comment/avatar-style.enum';
import { CommentStatus, UserCommentPermission, UserCommentRole } from '@app/shared/enums/comment/comment.enum';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { RepresentedUser } from '@app/shared/models/user/represented-user.model';
import { User } from '@app/shared/models/user/user.model';
import { cleanFormat, cleanHTMLPartially, newLineToBr } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { Comment } from '@shared/models/comment/comment';
import { ENV } from 'src/environments/environment';

import { CommentHelper } from './helpers/comment.helper';
import { ActionComments } from '../../action-comments.enum';
import { URL_REGEX } from '@ptrab/shared/constants/constants';
import { ContentComponent } from '@app/components/content/content.component';
import { MatDialogConfig } from '@angular/material/dialog';

/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements AfterContentInit, OnInit, OnChanges, OnDestroy {
  @Input() comment!: Comment;
  @Input() commentIndex!: number;
  @Input() parentItemId!: number;
  @Input() contentIdPublication!: number;
  @Input() commentParent!: Comment;
  @Input() userPermission!: UserCommentPermission;
  @Input() avatarPhoto!: string;
  @Input() isReplyDisabled = false;
  @Input() userCanReply = false;
  @Input() userIsOwner!: boolean;
  @Input() userIsPresident!: boolean;
  @Input() asUsers!: RepresentedUser[];
  @Input() showLoadingOnComment: MSafeAny;
  @Input() commentInfo!: Comment;
  @Input() highlightedCommentId!: number;
  @Input() linksOnWebview!: boolean;
  @Input() mainContent!: ContentComponent;

  @Output() action = new EventEmitter();
  @Output() enableEdit = new EventEmitter();
  @Output() clickAvatar = new EventEmitter();
  @Output() enterAvatar = new EventEmitter();
  @Output() closeInfo = new EventEmitter();

  @ViewChild('editModeContent') contentEdited!: ElementRef;

  readonly MAX_COMMENT_LENGTH = 500;

  currentDate = new Date();
  relativeDate!: string;
  fullName!: string;
  editMode = false;
  commentEdit!: string;
  isSaveCommentEditDisabled = true;
  messages!: string[];
  currentLang!: string;
  replyMode = false;
  isSendingReply = false;
  commentText = '';
  isTranslated = false;
  userSelected!: User;
  isIconHovered = false;
  animatedCommentId!: number;
  isFromUser!: boolean;
  isFromAsUser!: boolean;
  isFromUserAdmin!: boolean;
  hasAsUser!: boolean;
  isAddMoreCommentsDisabled!: boolean;
  isBanned!: boolean;
  hasToShowTranslationButton!: boolean;
  hasInfoInAvatar!: boolean;
  showPopover = false;
  popoverOpen = false;
  showLoadingControl = false;
  loadUser = false;
  user!: User;
  lastScrollY!: number;

  domainPath =
    `${window.location.protocol}//${window.location.hostname}` +
    (window.location.port ? `:${window.location.port}` : '');
  enviromentSiteUrl = ENV.APP_UNIVERSAL_LINKS_URL;

  animatedComment = false;

  private logger = new Logger('Comment');
  private scrollSubscription!: Subscription;

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private commentsService: CommentsService,
    private alertService: AlertService,
    private storageService: StorageService,
    private modalManager: ModalManager,
    private userService: UserService,
    private el: ElementRef
  ) {
    this.getTranslations();
    this.subscribeToLanguage();
  }

  async ngOnInit() {
    this.user = await this.userService.getStoredUser();
    this.loadUser = true;
    this.checkUserPermissions();
  }

  ngOnChanges() {
    if (!this.loadUser) return;
    this.checkUserPermissions();
  }

  private checkUserPermissions() {
    this.isFromUser = this.checkIsFromUser();
    this.isFromUserAdmin = this.checkIsFromUserAdmin();
    this.hasAsUser = this.checkHasAsUser() as boolean;
    this.isFromAsUser = this.checkIsFromAsUser() as boolean;
    this.isAddMoreCommentsDisabled = this.checkAddMoreCommentsDisabled();
    this.isBanned = this.checkIsBanned();
    this.hasToShowTranslationButton = this.checkTheNeedOfTranslation();
    this.hasInfoInAvatar = this.checkHasInfoInAvatar();
    this.showLoadingControl = this.showLoading();
  }

  ngAfterContentInit() {
    this.commentText = newLineToBr(this.comment.comment);
    this.commentEdit = this.commentText;
    CommentHelper.convertDateToRelative(this.comment.creation_date, this.translateService).subscribe((date: string) => {
      this.relativeDate = date;
    });
    this.fullName = this.getFullName(this.comment);
    this.checkLinks();
  }

  deleteComment(): void {
    this.action.emit({ comment: this.comment, commentIndex: this.commentIndex, action: ActionComments.delete });
  }

  editComment(): void {
    if (!this.commentEdit.length) {
      return;
    }

    const resultMatchRegExp = (this.commentEdit.match(URL_REGEX) || []).length > 0;

    if (resultMatchRegExp && !this.userIsOwner) {
      CommentHelper.confirmDeleteComment(this.modalManager);
      return;
    }

    if (!this.parentItemId) {
      // Clean HTML comment allowing links (href) if is editing a reply of a comment
      this.comment.comment = cleanHTMLPartially(this.commentEdit).normalize('NFKC');
    } else {
      // Clean all HTML from comment if is editing a comment publication
      this.comment.comment = cleanFormat(this.commentEdit);
    }

    this.commentText = newLineToBr(this.comment.comment);

    this.action.emit({ comment: this.comment, action: ActionComments.edit });
    this.disableEditMode();
  }

  canEditMiddleware(): boolean {
    if (this.userIsPresident && this.isReplyDisabled) {
      this.logger.debug('Cannot Edit: userIsPresident && this.isReplyDisabled');
      return false;
    }

    return !this.editMode && !this.isAddMoreCommentsDisabled && (this.canEditAsUser() || this.canEditAsAsUser());
  }

  canEditAsUser(): boolean {
    return this.isFromUser && !this.isFromUserAdmin;
  }

  canEditAsAsUser(): boolean {
    return this.isFromAsUser && !this.comment.response_comments?.length;
  }

  enableEditMode() {
    this.enableEdit.emit();
    this.editMode = true;
  }

  disableEditMode() {
    this.commentEdit = this.commentText;
    this.editMode = false;
    this.isSaveCommentEditDisabled = true;
  }

  checkIfCanUserReply(): boolean {
    if (this.userPermission !== UserCommentPermission.ALLOWED) {
      return false;
    }

    if (this.comment.parent_id) {
      const latestReply = this.commentParent.response_comments[this.commentParent.response_comments.length - 1];
      const isMyComment = this.user && this.user.userid === this.commentParent.user_id;
      const currentLastComment = latestReply && latestReply.id === this.comment.id;

      if (this.isReplyableParentComment(isMyComment)) {
        const indexComment = this.commentParent.response_comments.indexOf(this.comment);
        const newResponses = this.commentParent.response_comments.filter((_comments, index) => {
          if (index >= indexComment) return true;
        });

        return !this.userHasCommented(newResponses);
      }

      return this.isNotLastResponseFromMe(isMyComment, currentLastComment, latestReply);
    } else if (this.canUserReply()) {
      return this.canReplyToComment();
    }

    return false;
  }

  private isReplyableParentComment(isMyComment: boolean): boolean {
    return (
      !isMyComment && this.canUserReply() && this.commentHasAlreadyReply(this.commentParent)?.id === this.comment.id
    );
  }

  private isNotLastResponseFromMe(isMyComment: boolean, currentLastComment: boolean, latestReply: Comment): boolean {
    return isMyComment && currentLastComment && latestReply.user_id !== this.user.userid;
  }

  private canReplyToComment(): boolean {
    return (
      this.comment.response_comments?.length === 0 ||
      (!this.userHasCommented(this.comment.response_comments) && !this.commentHasAlreadyReply(this.comment))
    );
  }

  commentHasAlreadyReply(commentParent): MSafeAny {
    let replyToLastComment = null;
    const replies = commentParent.response_comments.filter((comment) => {
      if (comment.user_id === commentParent.user_id) {
        return comment;
      }
    });
    if (replies && replies.length > 0) {
      replyToLastComment = replies[replies.length - 1];
    }
    return replyToLastComment;
  }

  userHasCommented(commentParent): boolean {
    const replies = commentParent.find((comment) => {
      if (
        (this.asUsers && this.asUsers.length > 0 && this.asUsers[0].userid === comment.user_id) ||
        comment.user_id === this.user?.userid
      ) {
        return comment;
      }
    });
    return Boolean(replies);
  }

  canUserReply(): boolean {
    return !this.isReplyDisabled && !this.isFromUser && (this.userIsOwner || this.hasAsUserAndCommentNotFromAsUser());
  }

  replyToOwner(): boolean {
    return !this.isReplyDisabled && !this.parentItemId && !this.isFromUser;
  }

  hasAsUserAndCommentNotFromAsUser(): boolean {
    return this.hasAsUser && !this.isFromAsUser;
  }

  enableReply(): void {
    this.replyMode = true;
  }

  replyToComment(reply: string): void {
    const userId = this.userSelected && !this.isMyParentComment() ? this.userSelected.userid : undefined;
    const parentId = !this.commentParent ? this.comment.id : this.comment.parent_id;
    const parentContentItemId = !this.commentParent ? this.parentItemId : this.contentIdPublication;

    this.isSendingReply = true;
    this.commentsService
      .createComment(parentContentItemId, reply, parentId, userId)
      .pipe(
        finalize(() => {
          this.isSendingReply = false;
        })
      )
      .subscribe(
        (postedReply: Comment) => {
          if (postedReply.status === CommentStatus.ACCEPTED) {
            if (this.commentParent) {
              this.commentParent.response_comments.push(postedReply);
            } else {
              this.comment.response_comments.push(postedReply);
            }
          }

          this.action.emit({ comment: postedReply, action: ActionComments.reply });
          this.replyMode = false;
        },
        (error) => {
          this.logger.error(error);
          this.alertService.showError(this.messages['ERROR_MESSAGES.ERROR'], this.messages['COMMENTS.ERROR_SEND']);
        }
      );
  }

  onEnterAvatar($event: MSafeAny, comment: Comment | null = null) {
    const isCommentReply = !(comment === null || comment === undefined);
    if (isCommentReply) {
      $event.reply = comment;
    }

    this.isIconHovered = true;
    this.enterAvatar.emit($event);
    this.checkHasPopupOpened();
  }

  onLeaveAvatar() {
    this.isIconHovered = false;
    this.checkHasPopupOpened();
  }

  closePopover() {
    this.showPopover = false;
    this.isIconHovered = false;
    this.closeInfo.emit();
    this.checkHasPopupOpened();
  }

  checkHasPopupOpened() {
    this.popoverOpen = this.isIconHovered && this.hasInfoInAvatar;
  }

  getAvatarDefault(isOdd: boolean): AVATAR_STYLE {
    return isOdd ? AVATAR_STYLE.EMPTY_AVATAR : AVATAR_STYLE.FILLED_AVATAR;
  }

  getAvatarPhoto(comment: Comment, isOdd: boolean) {
    if (this.checkIsFromUserAdmin(comment)) {
      return AVATAR_STYLE_URLS.ACTIVO2_AVATAR;
    }

    return comment.photo || this.getAvatarDefault(isOdd);
  }

  toggleTranslate(text) {
    this.isTranslated = !this.isTranslated;
    this.commentText = text;
  }

  getDontShowResponseAsOtherUserAgainStatus(): Promise<MSafeAny> {
    return this.storageService.get(STORAGE_CONSTANTS.RESPONSE_AS_OTHER_USER);
  }

  setUserSelected(user: User): void {
    this.userSelected = user;
  }

  async replyMiddleware(reply: string) {
    const mustNotShowResponseModal = await this.getDontShowResponseAsOtherUserAgainStatus();

    if (mustNotShowResponseModal || !this.hasAsUser || !this.userSelected) {
      this.replyToComment(reply);
      return;
    }

    if (this.userSelected && (this.isMyParentComment() || this.user.userid === this.userSelected.userid)) {
      this.replyToComment(reply);
      return;
    }

    this.confirmReplyAsOtherUser(reply);
  }

  confirmReplyAsOtherUser(reply: string): void {
    const title = 'COMMENTS.ANSWER_AS_OTHER_USER.TITLE';
    const advise = 'COMMENTS.ANSWER_AS_OTHER_USER.ADVISE';
    const checkboxLabel = 'COMMENTS.ANSWER_AS_OTHER_USER.CHECKBOX_LABEL';
    const cookieReference = STORAGE_CONSTANTS.RESPONSE_AS_OTHER_USER;

    const buttons: Buttons[] = [
      {
        text: 'CANCEL',
        type: 'secondary',
        enabled: true,
        onClick: () => {
          this.modalManager.dismissMatModal();
        }
      },
      {
        text: 'COMMENTS.ANSWER_AS_OTHER_USER.PUBLISH_BUTTON',
        type: 'primary',
        enabled: true,
        onClick: () => {
          this.modalManager.dismissMatModal();
          this.replyToComment(reply);
        }
      }
    ];

    const modalOpts: MatDialogConfig = {
      data: { title, advise, checkboxLabel, buttons, cookieReference },
      disableClose: true
    };

    this.modalManager.openMatModal(CookiesModalCommentComponent, modalOpts);
  }

  isMyParentComment(): boolean {
    return this.commentParent && this.commentParent.user_id === this.user.userid;
  }

  showLoading() {
    return this.showLoadingOnComment.commentId === this.comment.id && this.showLoadingOnComment.showLoading;
  }

  trackComment(_: number, comment: Comment) {
    return comment.id;
  }

  onCommentEditChanged() {
    this.isSaveCommentEditDisabled = false;
  }

  private subscribeToLanguage() {
    this.languageService.getCurrentLanguage().subscribe((value) => (this.currentLang = value));
  }

  private getTranslations() {
    this.translateService
      .get(['COMMENTS.PLACEHOLDER', 'ERROR_MESSAGES.ERROR', 'COMMENTS.ERROR_SEND', 'COMMENTS.TEAM'])
      .subscribe((messages) => (this.messages = messages));
  }

  private checkIsFromUser(): boolean {
    return this.user.userid === this.comment.user_id;
  }

  private checkIsFromUserAdmin(comment = this.comment) {
    return comment.role === UserCommentRole.ADMIN;
  }

  private checkHasAsUser(): boolean | undefined {
    if (!this.asUsers) {
      return;
    }
    return Boolean(this.asUsers.length);
  }

  private checkIsFromAsUser(): boolean | undefined {
    if (!this.asUsers) {
      return;
    }
    return this.asUsers.some((asUser) => asUser.userid === this.comment.user_id);
  }

  private checkAddMoreCommentsDisabled() {
    return this.userPermission === UserCommentPermission.DISABLED;
  }

  private checkIsBanned(): boolean {
    return this.userPermission === UserCommentPermission.BANNED;
  }

  private checkTheNeedOfTranslation(): boolean {
    return !this.isFromUser && this.checkSpanishOrPortugueseLang();
  }

  private checkSpanishOrPortugueseLang(): boolean {
    return (
      (this.comment.language === LANGUAGES.PT.code && this.currentLang === LANGUAGES.ES.code) ||
      (this.comment.language === LANGUAGES.ES.code && this.currentLang === LANGUAGES.PT.code)
    );
  }

  private checkHasInfoInAvatar(): boolean {
    return Boolean(
      this.comment.show_info && this.comment.user_id !== this.user.userid && this.comment.role !== UserCommentRole.ADMIN
    );
  }

  private getFullName(comment: Comment) {
    if (this.isFromUserAdmin) {
      return this.messages['COMMENTS.TEAM'];
    }
    return `${comment.name} ${comment.lastname}`;
  }

  ngOnDestroy(): void {
    if (this.scrollSubscription) this.scrollSubscription.unsubscribe();
  }

  setAnimate(currentComment: Comment) {
    this.animatedCommentId = currentComment.id;

    const content = this.el.nativeElement.closest('app-content')! as HTMLElement;
    const closestHeaderHeight = content.parentElement!.querySelector('app-header')?.clientHeight ?? 0;
    const closestFooterHeight = content.parentElement!.querySelector('footer')?.clientHeight ?? 0;
    const commentsSectionElement = content.parentElement!.querySelector('app-comments-section');
    const parentComment: HTMLElement = document.getElementById(String(currentComment.parent_id)) as HTMLElement;
    this.scrollSubscription = this.mainContent.scrolled.subscribe((scrollEvent: CustomEvent) => {
      const parentCommentBounds = parentComment.getBoundingClientRect();

      if (
        this.isScrolling('up', scrollEvent.target as HTMLElement) &&
        parentCommentBounds.top > content.offsetHeight + closestHeaderHeight + closestFooterHeight
      ) {
        content.scrollTo(0, commentsSectionElement!.scrollTop);
        this.action.emit({ action: ActionComments.reset });
        this.scrollSubscription.unsubscribe();
      }

      this.lastScrollY = (scrollEvent.target as HTMLElement).scrollTop;
    });
  }

  private isScrolling(directionToCheck: 'up' | 'down', element: HTMLElement): boolean {
    const y = element.scrollTop;
    const direction = y <= this.lastScrollY ? 'up' : 'down';
    return direction === directionToCheck;
  }

  private checkLinks() {
    const links = this.commentText?.match(URL_REGEX);
    const mappedLinks = [];

    if (!links) {
      return;
    }

    // Create link tag dynamically and store values in array
    links.forEach((link) => {
      const aTag = document.createElement('a');
      let linkText = link;

      // Add http protocol before link if not exists to be able to open a new page in absolute path
      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        linkText = 'http://' + link;
      }
      if (!linkText.includes(this.domainPath) && !linkText.includes(this.enviromentSiteUrl)) {
        aTag.target = '_blank';
      }

      aTag.href = linkText;
      aTag.innerText = link;

      mappedLinks.push(aTag.outerHTML as never);
    });

    links.forEach((match, index) => {
      // Replace first link
      if (index === 0 && this.commentText.indexOf('</a>') === -1) {
        this.commentText = this.comment.comment.replace(match, mappedLinks[index]);
        this.comment.comment = this.commentText;
      } else {
        // For the rest of links split text by html tags
        const splitText = this.commentText.split('</a>');
        let auxText = '';

        for (let i = 0; i < splitText.length; i++) {
          // Cpncat links already mapped adding closing tag removed via split. Keep iterating
          if (i < splitText.length - 1) {
            auxText = auxText.concat(splitText[i].concat('</a>'));
          }
          // Always replace last link tag with mapped link adding closing link tag if it is not unique
          else if (i > 0) {
            this.commentText = auxText.concat('</a>', splitText[i].replace(match, mappedLinks[index]));
          } else {
            this.commentText = splitText[i].replace(match, mappedLinks[index]);
          }
        }
      }
    });
  }
}

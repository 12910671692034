import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-final-list',
  templateUrl: './message-final-list.component.html',
  styleUrls: ['./message-final-list.component.scss']
})
export class MessageFinalListComponent {
  @Input() title!: string;
}

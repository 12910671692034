import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '@services/guard/auth.guard.service';
import { OnBoardingGuardService } from '@services/guard/on-boarding.guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./activo2/activo2-routing.module').then((m) => m.Activo2RoutingModule)
  },
  {
    path: 'ptrab',
    loadChildren: () => import('./ptrab/ptrab.module').then((m) => m.PtrabModule),
    canLoad: [AuthGuardService],
    canActivate: [OnBoardingGuardService]
  },
  {
    path: 'mot',
    loadChildren: () => import('./mot/mot.module').then((m) => m.MotModule),
    canActivate: [OnBoardingGuardService]
    // canLoad: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export const DEFINITION = [
  {
    question: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.DEFINITION.WHO_PAYS.QUESTION',
    answer: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.DEFINITION.WHO_PAYS.ANSWER',
    note: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.DEFINITION.WHO_PAYS.NOTE'
  },
  {
    question: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.DEFINITION.WHEN_IS_PAID.QUESTION',
    answer: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.DEFINITION.WHEN_IS_PAID.ANSWER',
    note: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.DEFINITION.WHEN_IS_PAID.NOTE'
  }
];

export const HOW_IS_IT_CALCULATED_LIST = [
  {
    title: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.HOW_IS_IT_CALCULATED.ANNUAL_INCOME.TITLE',
    description: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.HOW_IS_IT_CALCULATED.ANNUAL_INCOME.DESCRIPTION',
    quote: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.HOW_IS_IT_CALCULATED.ANNUAL_INCOME.QUOTE'
  },
  {
    title: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.HOW_IS_IT_CALCULATED.PERSONAL_SITUATION.TITLE',
    description: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.HOW_IS_IT_CALCULATED.PERSONAL_SITUATION.DESCRIPTION',
    quote: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.HOW_IS_IT_CALCULATED.PERSONAL_SITUATION.QUOTE'
  }
];

export const WHEN_IS_IT_CALCULATED_LIST = [
  {
    title: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.WHEN_IS_IT_CALCULATED.IF_NOTHING_CHANGES.TITLE',
    description: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.WHEN_IS_IT_CALCULATED.IF_NOTHING_CHANGES.DESCRIPTION'
  },
  {
    title: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.WHEN_IS_IT_CALCULATED.IF_CHANGES.TITLE',
    description: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.WHEN_IS_IT_CALCULATED.IF_CHANGES.DESCRIPTION'
  }
];

export const ANY_EXCEPTION_LIST = [
  {
    title: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.ANY_EXCEPTION.NAVARRA_EUSKADI.TITLE',
    description: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.ANY_EXCEPTION.NAVARRA_EUSKADI.DESCRIPTION'
  },
  {
    title: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.ANY_EXCEPTION.CEUTA_MELILLA.TITLE',
    description: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.ANY_EXCEPTION.CEUTA_MELILLA.DESCRIPTION'
  }
];

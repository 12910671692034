import { appVersion } from '@shared/utils/utils';

/* eslint-disable @typescript-eslint/naming-convention */

export enum AuditLevels {
  Debug = 'DEBUG',
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR',
  Critical = 'CRITICAL'
}

export enum AuditCodes {
  Success = '200',
  Error = '500',
  Maintenance = '503'
}

export class AuditMessage {
  app_version? = appVersion;
  level: AuditLevels;
  message: string;
  status_code?: AuditCodes;
  date?: string;
  app_type? = 'WEBAPP';
  app_component?: string;
  extra?: string;

  constructor(auditMessage: AuditMessage) {
    this.level = auditMessage.level;
    this.message = auditMessage.message;
    this.status_code = auditMessage.status_code;
    this.date = new Date().toLocaleString();
    this.extra = navigator.userAgent;
    this.app_component = auditMessage.app_component || 'UNDEFINED';
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { FileFetchHelper } from '@app/shared/helpers/file-fetch/file-fetch.helper';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import {
  UserUpdatableProp,
  UserUpdatablePropsFactory,
  UserUpdatableTypes
} from '@app/shared/models/user/user-updatable.model';
import { User } from '@app/shared/models/user/user.model';

import { UserService } from './user.service';
import { ApiUrls, Logger } from '..';

@Injectable({ providedIn: 'root' })
export class UserSyncPropsService {
  private readonly logger: Logger = new Logger('UserSyncPropsService');
  private userService: UserService;

  constructor(private apiUrls: ApiUrls, private http: HttpClient, private injector: Injector) {
    this.userService = this.injector.get(UserService);
  }

  syncAllProperties() {
    const promises = Object.keys(UserUpdatableTypes)
      .filter((type) => isNaN(type as MSafeAny))
      .map((key) => this.syncProperty(UserUpdatableTypes[key]));
    return Promise.all(promises);
  }

  async syncProperty(dbProperty: UserUpdatableTypes): Promise<MSafeAny> {
    try {
      let user: User = await this.userService.getStoredUser();
      const updatableProperty: UserUpdatableProp = UserUpdatablePropsFactory.getUserUpdatableProp(
        dbProperty,
        this.http,
        this.apiUrls
      ) as UserUpdatableProp;

      await updatableProperty.initialize();

      if (updatableProperty.hasChanges(user)) {
        user = updatableProperty.getUserUpdated(user);

        if (dbProperty === UserUpdatableTypes.LANGUAGE) {
          await this.userService.storeLanguage(user.language_code, user.language_country);
        } else if (dbProperty === UserUpdatableTypes.IMAGE) {
          try {
            user.photo = await FileFetchHelper.getB64FromUrl(user.photo);
          } catch (err) {
            this.logger.error(err);
            user.photo = '';
          }
        }
        await this.userService.storeAvatar(user.photo);
      }
    } catch (err) {
      this.logger.error(err);
      return Promise.reject(err);
    }
  }
}

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

/* eslint-disable @typescript-eslint/naming-convention */
export class RepresentedUser {
  cod_store: string | null;
  department: string;
  division_zone: string;
  lastname: string;
  name: string;
  photo: string;
  store: string;
  userid: string;

  constructor(representedUser: MSafeAny) {
    this.cod_store = representedUser.cod_store;
    this.department = representedUser.department;
    this.division_zone = representedUser.division_zone;
    this.lastname = representedUser.lastname;
    this.name = representedUser.name;
    this.photo = representedUser.photo;
    this.store = representedUser.store;
    this.userid = representedUser.userid;
  }
}

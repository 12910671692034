import { Injectable } from '@angular/core';

import { Logger } from '@app/services/logger';
import imageCompression from 'browser-image-compression';

@Injectable({
  providedIn: 'root'
})
export class ImageCompressionService {
  private readonly logger = new Logger('ImageCompresionService');

  imageCompression;

  constructor() {
    this.imageCompression = imageCompression;
  }

  async compress(file: File, options = {}): Promise<File> {
    const defaultOptions = {
      // XXX Do not activate this, it will cause obfuscation not work
      // https://github.com/javascript-obfuscator/javascript-obfuscator/issues/566
      useWebWorker: false
    };

    const fileOptions = Object.assign({}, defaultOptions, options);

    try {
      // We can cast to file safely since we always pass a file object
      const compressedImage = (await this.imageCompression(file, fileOptions)) as File;
      this.logger.debug('Compressed image:', compressedImage.name);
      return compressedImage;
    } catch (error) {
      this.logger.warn('Compression error:', error);
      return file;
    }
  }
}

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Logger } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { CategoriesAnalytics, ActionsAnalytics } from '@app/services/analytics/models/analytics.enum';
import { ErrorCodes } from '@app/services/error/error.model';
import { STORAGE_CONSTANTS, StorageService } from '@app/services/storage';
import { IDataResponse } from '@app/shared/interfaces/data/data.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ErrorMsgEnum } from '@app/components/error-msg/error-msg-enum';
import { ApiMotUrls } from '@mot/services/api/api.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class VacationsService {
  private selectedYear!: string;
  private logger = new Logger('MotService');
  private messages!: string[];

  private onError = new Subject<ErrorMsgEnum>();
  onError$ = this.onError.asObservable();

  constructor(
    protected injector: Injector,
    private apiUrls: ApiMotUrls,
    private http: HttpClient,
    private translateService: TranslateService,
    private storage: StorageService,
    private analyticsService: AnalyticsService
  ) {
    this.getTranslations();
  }

  getVacations(isMomoUser: boolean): Observable<MSafeAny> {
    let params = new HttpParams();
    params = params.set('lang', this.translateService.currentLang);
    return this.http
      .get(isMomoUser ? this.apiUrls.momo_vacations.list : this.apiUrls.vacations.list, { params })
      .pipe(catchError(this.handleError));
  }

  getExtraInfo(year?: string): Observable<MSafeAny> {
    let params = new HttpParams();
    params = params.set('lang', this.translateService.currentLang);

    if (year) {
      params = params.set('year', year);
    }

    return this.http.get(this.apiUrls.vacations.info, { params }).pipe(catchError(this.handleError));
  }

  getSignedVacations(): Observable<MSafeAny> {
    let params = new HttpParams();
    params = params.set('lang', this.translateService.currentLang);
    return this.http.get<IDataResponse<MSafeAny>>(this.apiUrls.vacations.signed, { params }).pipe(
      map((d) => d.data),
      catchError(this.handleError)
    );
  }

  getSelectedYear(): string {
    return this.selectedYear;
  }

  setSelectedYear(year: string) {
    this.selectedYear = year;
    this.storage.setItems([{ key: STORAGE_CONSTANTS.VACATIONS_YEAR, value: this.selectedYear }]);
    this.analyticsService.sendEvent(CategoriesAnalytics.CLICK, {
      [ActionsAnalytics.CLICKACTION]: ActionsAnalytics.HOLIDAYS,
      [ActionsAnalytics.TEXT]: `calendario_${this.selectedYear}`
    });
  }

  private handleError = (error: HttpErrorResponse): Observable<MSafeAny[]> => {
    let errorType;

    switch (error.status) {
      case ErrorCodes.LOCKED:
        errorType = ErrorMsgEnum.SERVICE_LOCKED;
        break;
      case ErrorCodes.SECTION_MAINTENANCE:
        errorType = ErrorMsgEnum.SECTION_MAINTENANCE;
        break;
      default:
        errorType = ErrorMsgEnum.SERVER_ERROR;
        break;
    }

    this.onError.next(errorType);
    this.logger.error(error);

    return of([]);
  };

  private getTranslations() {
    this.translateService.stream(['ERRORS_TOASTS.GENERIC_MSG']).subscribe((messages) => {
      this.messages = messages;
    });
  }
}

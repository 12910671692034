/* eslint-disable @typescript-eslint/naming-convention */

export enum AVATAR_STYLE {
  EMPTY_AVATAR = 'first',
  FILLED_AVATAR = 'second'
}

export enum AVATAR_STYLE_URLS {
  FIRST = 'assets/imgs/comments/first-user-avatar.svg',
  ACTIVO2_AVATAR = 'assets/imgs/comments/activo2-avatar.svg'
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { ShareContentComponent } from './share-content.component';

@NgModule({
  declarations: [ShareContentComponent],
  imports: [CommonModule, TranslateModule],
  exports: [ShareContentComponent]
})
export class ShareContentModule {}

import { Injectable } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { checkCircle1Svg, informationCircleSvg, alertTriangleSvg } from '@mercadona/icons/svg';
import { MIconService } from '@mercadona/icons';

enum AlertTypes {
  success = 'success',
  info = 'info',
  error = 'alert',
  warning = 'warning'
}
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private static readonly alertDefaultDuration = 5000;

  showAlertSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showAlert$: Observable<boolean> = this.showAlertSubject.asObservable();
  titleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  title$: Observable<string> = this.titleSubject.asObservable();
  descriptionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  description$: Observable<string> = this.descriptionSubject.asObservable();
  colorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  color$: Observable<string> = this.colorSubject.asObservable();
  iconSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  icon$: Observable<string> = this.iconSubject.asObservable();

  constructor(private mIconService: MIconService) {
    this.mIconService.registerIcon(checkCircle1Svg, informationCircleSvg, alertTriangleSvg);
  }

  showSuccess(title: MSafeAny, description: MSafeAny, duration = AlertService.alertDefaultDuration) {
    this.show(title, description, AlertTypes.success, duration, 'checkCircle1Svg');
  }

  showInfo(title: MSafeAny, description: MSafeAny, duration = AlertService.alertDefaultDuration) {
    this.show(title, description, AlertTypes.info, duration, 'informationCircleSvg');
  }

  showWarning(title: MSafeAny, description: MSafeAny, duration = AlertService.alertDefaultDuration) {
    this.show(title, description, AlertTypes.warning, duration, 'alertTriangleSvg');
  }

  showError(title: MSafeAny, description: MSafeAny, duration = AlertService.alertDefaultDuration) {
    this.show(title, description, AlertTypes.error, duration, 'alertTriangleSvg');
  }

  dismiss(): void {
    if (!this.showAlertSubject.value) {
      return;
    }

    this.showAlertSubject.next(false);
  }

  private show(
    title: MSafeAny,
    description: MSafeAny,
    alertType: AlertTypes,
    duration = AlertService.alertDefaultDuration,
    icon: string
  ) {
    const formatedMessage = this.formatMessage(description);
    this.generateAlert(title, formatedMessage, alertType, duration, icon);
  }

  private formatMessage(description: MSafeAny) {
    const lineJump = '\n';
    const message = `${lineJump}${description}`;
    return message;
  }

  private generateAlert(title: string, message: string, alertType: AlertTypes, duration: number, icon: string) {
    this.titleSubject.next(title);
    this.descriptionSubject.next(message);
    this.colorSubject.next(alertType);
    this.showAlertSubject.next(true);
    this.iconSubject.next(icon);

    setTimeout(() => this.dismiss(), duration);
  }
}

<div [class.darkMode]="darkMode">
  <ng-template [ngIf]="!isSlideMode" [ngIfElse]="imageSlides">
    <div class="image-container">
      <img
        tappable
        [src]="image.images[0] ? image.images[0].url : image.url"
        class="image-detail"
        (click)="openImage()" />
    </div>
    <div class="fullscreen">
      <i tappable class="app-icon-ic-full-screen" (click)="openImage()"></i>
    </div>
  </ng-template>

  <ng-template #imageSlides>
    <app-slider
      class="image-detail-slides"
      [image]="image?.images"
      (slideIndexChange)="slideChanged($event)"
      (setFullscreen)="openImage(slider)"
      #slider>
    </app-slider>
  </ng-template>
</div>

<mat-radio-group class="example-form-images" [value]="selectedImage" (change)="setSelected($event.value)">
  <div
    class="example-form-images__items"
    [dragula]="BAG_NAME"
    [dragulaModel]="images"
    (dragulaModelChange)="setOrder($event)"
  >
    <div class="example-form-images__item" *ngFor="let image of images; trackBy: trackByItems">
      <div class="example-form-images__bin" (click)="onRemove(image)">
        <i class="app-icon-bin"></i>
      </div>
      <img [src]="image.preview | safe: 'url'" />
      <mat-radio-button [value]="image" disableRipple>{{ 'MULTIMEDIA_FORM.COVER' | translate }}</mat-radio-button>
    </div>
  </div>
  <ng-container *ngIf="images.length < maxFiles">
    <div class="example-form-images__btn" (click)="addImages()">
      <i class="app-icon-add"></i>
    </div>
  </ng-container>
</mat-radio-group>

<input
  #fileInput

  hidden
  multiple
  type="file"
  data-cy-id="example-file"
  [attr.accept]="extensions"
  (change)="onChangeInputFile($event.target.files)"
/>

<!-- Slider main container -->
<div
  class="swiper"
  [ngClass]="{
    'is-webview': isWebview,
    'adjust-swiper': noBackground,
    'hidden-swiper-wrapper': isVideoEnterFullscreen,
    'hidden-swipe-actions': noBackground && multimediaCarousel && slider && image[slider.activeIndex].type === 'video'
  }"
  #container>
  <!-- Additional required wrapper -->
  <div
    class="swiper-wrapper"
    [ngClass]="{
      'no-slide': isSingleImage || isVideoEnterFullscreen,
      'slider-multimedia': multimediaCarousel,
      'reduced-height': !isWebview
    }">
    <!-- Slides -->
    <ng-container *ngFor="let img of image; trackBy: trackByItems; let i = index">
      <div class="swiper-slide animated animated-fix-fullscreen fadeIn" #slides>
        <div
          *ngIf="!multimediaCarousel || (multimediaCarousel && img.type !== 'video')"
          class="swiper-img-container"
          [ngClass]="{
            transparent: noBackground,
            'resolution-landscape': noBackground && positionLandscape
          }">
          <div class="swiper-zoom-container" data-swiper-zoom="3" *ngIf="allowZoom">
            <img (load)="show = true" [src]="!multimediaCarousel ? img.url : img.src" draggable="false" />
          </div>
          <img
            tappable
            (load)="show = true"
            [src]="!multimediaCarousel ? img.url : img.src"
            class="swiper-img-container__pointer"
            draggable="false"
            *ngIf="!allowZoom"
            (click)="emitFullScreen($event)" />
          <p class="image-caption" *ngIf="img.caption && slider?.zoom.scale <= 1">
            {{ img.caption }}
          </p>
        </div>
        <div
          class="detail__player"
          *ngIf="multimediaCarousel && img.type === 'video'"
          app-scroll-to-print
          [doScrollToPoint]="playerService.doScrollToPoint"
          [player]="currentPlayer">
          <app-media-player
            #player
            class="container-multimedia is-browser"
            [ngClass]="{
              'slider-multimedia': allowZoom,
              'video-loaded': !isLoadingFile,
              'resolution-landscape': noBackground && positionLandscape
            }"
            [video]="img.detailVideo"
            [preloadType]="'metadata'"
            [noBackgroundCarousel]="noBackground"
            (ready)="onPlayerReady()"
            (loadedData)="onLoadedData()"
            (loadedMetadata)="onLoadedMetadata()"
            (enterFullscreen)="onEnterFullscreen()"
            (exitFullscreen)="onExitFullscreen()"
            (multimediaError)="onMultimediaError($event, img)">
          </app-media-player>
        </div>
        <app-spinner
          [autoSize]="true"
          *ngIf="multimediaCarousel && img.type === 'video' && isLoadingFile"></app-spinner>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="image.length > 1">
    <div class="swiper-pagination" #pagination></div>
    <div class="swiper-button-prev" (click)="slidePrev()" #prevBtn></div>
    <div class="swiper-button-next" (click)="slideNext()" #nextBtn></div>
  </ng-container>

  <div *ngIf="!isWebview" class="swiper-actions" [class.single-img]="isSingleImage">
    <ng-container *ngIf="allowZoom">
      <div
        *ngIf="slider?.zoom.scale > 1"
        class="zoom-button out"
        [class.hidden-swipe-actions]="multimediaCarousel && slider && image[slider.activeIndex].type === 'video'"
        role="img"
        alt="zoom-out"
        (click)="zoomOut()"></div>
      <div
        *ngIf="slider?.zoom.scale <= 1"
        class="zoom-button in"
        [class.hidden-swipe-actions]="multimediaCarousel && slider && image[slider.activeIndex].type === 'video'"
        role="img"
        alt="zoom-in"
        (click)="zoomIn()"></div>
    </ng-container>

    <ng-container *ngIf="setFullscreen.observers.length || setFullscreenCarousel.observers.length">
      <div *ngIf="!hiddenSwipeActions" class="fullscreen-button" (click)="emitFullScreen($event)">
        <i class="app-icon-ic-full-screen"></i>
      </div>
    </ng-container>
  </div>
</div>

import { DropdownItem } from '@app/ptrab/shared/models/dropdown';

export function buildDropdownYearsItems(years: number): DropdownItem[] {
  const dropdownItems: DropdownItem[] = [];

  const currentYear = getCurrentYear();

  for (let index = 1; index < years; index++) {
    const year = currentYear - index;
    const dropdownItem = mapDropdownItem(year);
    dropdownItems.push(dropdownItem);
  }

  return dropdownItems;
}

function getCurrentYear(): number {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date.getFullYear();
}

export function mapDropdownItem(year: number): DropdownItem {
  return {
    value: year,
    label: year.toString(),
    selected: false
  };
}

export enum LEGAL {
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  TERMS_AND_CONDITIONS_INTER = 'terms-and-conditions-inter'
}

export enum UserTypes {
  INTERNAL_PTRAB = 'INTERNAL_PTRAB',
  INTERNAL_NO_PTRAB = 'INTERNAL_NO_PTRAB',
  EXTERNAL = 'EXTERNAL'
}

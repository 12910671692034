<app-spinner [showText]="true" *ngIf="isListLoading"></app-spinner>
<div *ngIf="!isListLoading && !hasProviderError">
  <div *ngIf="isPortugueseUser">
    <p class="u-p2 u-medium-grey" [innerHtml]="ptText"></p>
    <div *ngIf="dropdownItems.length; else noItems">
      <app-ptrab-dropdown
        *ngIf="dropdownItems.length"
        [label]="'EMPLOYEE_PORTAL.WITHHOLDING_YEAR' | translate"
        [items]="dropdownItems"
        (select)="setYear($event)"
        (actionClick)="dropdownOpenAction($event)"></app-ptrab-dropdown>
      <app-ptrab-pt-withholding-certificate-list
        [items]="portugueseItemsByYear"
        [selectedYear]="year"
        (downloadItemClicked)="downloadCertificate($event)"
        *ngIf="portugueseItemsByYear"></app-ptrab-pt-withholding-certificate-list>
    </div>
    <ng-template #noItems>
      <div class="no-items">
        <img class="no-items-image" src="assets/imgs/no-results/empty.svg" alt="no-items" />
        <h2>{{ 'EMPLOYEE_PORTAL.WITHHOLDING_CERTIFICATE_EMPTY_RESULTS' | translate }}</h2>
      </div>
    </ng-template>
  </div>
  <div *ngIf="!isPortugueseUser">
    <p class="u-p2 u-medium-grey">
      {{ 'EMPLOYEE_PORTAL.WITHHOLDING_CERTIFICATE_DESC' | translate }}
    </p>
    <p class="u-p2 u-medium-grey">{{ 'EMPLOYEE_PORTAL.WITHHOLDING_CERTIFICATE_DESC_2' | translate }}</p>
    <app-ptrab-dropdown
      *ngIf="renderDropdown"
      [label]="'EMPLOYEE_PORTAL.WITHHOLDING_YEAR' | translate"
      [items]="dropdownItems"
      (select)="setYear($event)"
      (actionClick)="dropdownOpenAction($event)"></app-ptrab-dropdown>
    <button
      class="c-button primary certificate-button"
      [class.dropdown-opened]="dropdownOpened"
      (click)="downloadCertificate()">
      {{ 'EMPLOYEE_PORTAL.WITHHOLDING_CERTIFICATE_ACTION' | translate }}
    </button>
  </div>
</div>

<div class="row" *ngIf="hasProviderError">
  <div class="col">
    <app-ptrab-feedback-msg [type]="errorOcurred"></app-ptrab-feedback-msg>
  </div>
</div>

import { DocumentStatus } from '../enums/document-status.enum';
import { IDocument } from '../interfaces/document.interface';

export class Document {
  id: string;
  url: string;
  date: string;
  subclass: string;
  status: DocumentStatus;

  constructor(data: IDocument) {
    this.id = data.document_id;
    this.date = data.date;
    this.subclass = data.subclass;
    this.url = data.url || '';
    this.status = data.status;
  }

  setDocumentUrl(url: string) {
    this.url = url;
  }
}

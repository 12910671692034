<div class="pop-over-notifications">
  <div class="pop-over-title">
    <h4 class="u-h4">{{ 'NOTIFICATIONS_PAGE.TITLE' | translate }}</h4>
  </div>
  <app-notification-filter [notificationsList]="notificationsList" (tabSelected)="onSelectTab($event)">
  </app-notification-filter>
  <div class="info-container u-p2 u-medium-grey" *ngIf="unreadNotifications > 0">
    {{ unreadNotifications }} {{ 'NOTIFICATIONS_PAGE.NO_READ' | translate }}
  </div>
  <div class="info-container u-p2 u-medium-grey" *ngIf="unreadNotifications < 1 && existNotifications()">
    {{ 'NOTIFICATIONS_PAGE.NO_NEWS' | translate }}
  </div>

  <app-notification-list
    *ngIf="existNotifications()"
    [filterBy]="this.selectedTab"
    (performAction)="notificationAction($event)"
    (removeItem)="removeNotification($event)"
    [notificationsList]="notificationsList"></app-notification-list>

  <div class="no-notifications" *ngIf="!existNotifications() && !sectionMaintenance">
    <img class="no-notifications-image" src="assets/imgs/no-results/empty.svg" alt="no-notifications" />
    <div class="warning-container">
      <img src="assets/imgs/notifications/info.svg" alt="info" />
      <h4 class="u-h4 u-medium-grey">{{ 'NOTIFICATIONS_PAGE.NO_NOTIFICATIONS' | translate }}</h4>
    </div>
    <p class="u-p2 u-medium-grey u-text-book">{{ 'NOTIFICATIONS_PAGE.DESCRIPTION' | translate }}</p>
  </div>

  <div class="row" *ngIf="sectionMaintenance">
    <div class="col" size="12">
      <app-section-maintenance></app-section-maintenance>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

import { formatOutputIban } from '@app/shared/utils/utils';

@Pipe({ name: 'iban' })
export class IbanPipe implements PipeTransform {
  transform(iban: string): string {
    return formatOutputIban(iban);
  }
}

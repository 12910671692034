<app-input-wrapper [label]="title">
  <div class="radio-list">
    <app-item
      *ngFor="let option of options; trackBy: trackByItems"
      class="radio-input item-md"
      [class.radio-disabled]="option.disabled">
      <label (click)="handleModelChange(option.value)">
        <input
          type="radio"
          [name]="title"
          [value]="option.value"
          [disabled]="option.disabled"
          [checked]="selectedValue === option.value" />
        {{ option.label | translate }}
        <img
          *ngIf="option.extraInfo"
          src="assets/imgs/profile/information-circle-filled.svg"
          class="tooltip"
          #mPopover="m-popover"
          popoverPosition="bottomLeft"
          [m-popover]="popoverTemplate"
          customPanelClass="custom" />
      </label>
    </app-item>
  </div>
</app-input-wrapper>

<ng-template #popoverTemplate>
  <div class="info-popover__content">
    <p>{{ popoverText }}</p>
  </div>
</ng-template>

export type Percentages = 0 | 25 | 50 | 75 | 100;
export const PERCENTAGES_LIST: Percentages[] = [0, 25, 50, 75, 100];

export class ScrollHelper {
  isAtTop = false;
  isAtBottom = false;
  isScrollingToTop = false;
  isScrollingDownFromTop = false;

  lastScrollY!: number;
  private scrollContent!: HTMLElement;
  private currentScrollPosition!: number;
  private maxScrollPosition!: number;

  // Call this method inside scroll events to update data
  updateNative(scrollElement: HTMLElement, offsetBottom = 0) {
    this.scrollContent = scrollElement;

    const currentY = this.scrollContent.scrollTop;

    if (!this.lastScrollY && this.lastScrollY !== 0) {
      this.lastScrollY = currentY;
    }

    if (this.lastScrollY === currentY) {
      return;
    }

    this.setScrollPropsNative(currentY, offsetBottom);

    this.lastScrollY = currentY;
  }

  getScrollPercentageNative(): Percentages | undefined {
    const percentage = Math.ceil((this.currentScrollPosition / this.maxScrollPosition) * 100);

    const percentageBlock = PERCENTAGES_LIST.find((value, index) => {
      const nextIndex = index + 1;
      const nextPercentage = PERCENTAGES_LIST.length > nextIndex && PERCENTAGES_LIST[nextIndex];

      return value < percentage && typeof nextPercentage === 'number' && percentage < nextPercentage;
    });

    if (percentageBlock) {
      return percentageBlock;
    }

    if (this.isAtBottom) {
      return 100;
    }

    return;
  }

  private setScrollPropsNative(currentY: number, offsetBottom: number) {
    this.currentScrollPosition =
      (this.scrollContent.scrollTop || document.body.scrollTop) + this.scrollContent.offsetHeight;
    this.maxScrollPosition = this.scrollContent.scrollHeight - (100 + offsetBottom);

    this.isAtTop = currentY === 0;
    this.isAtBottom = this.currentScrollPosition >= this.maxScrollPosition;
    this.isScrollingToTop = this.isScrollingNative('up');
    this.isScrollingDownFromTop = this.isScrollingNative('down') && currentY <= 0;
  }

  private isScrollingNative(directionToCheck: 'up' | 'down'): boolean {
    const y = this.scrollContent.scrollTop;
    const direction = y <= this.lastScrollY ? 'up' : 'down';
    return direction === directionToCheck;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Pipe({
  name: 'striphtml'
})
export class StripHtmlPipe implements PipeTransform {
  private static readonly blankRe = /&nbsp;/gim;
  private static readonly htmlTagRe = /(<([^>]+)>)/gim;
  private static readonly newLineRe = /[\r\n]+/gim;
  private static readonly spacesRe = /\s+/gim;

  private static readonly empty = '';
  private static readonly space = ' ';

  transform(value: MSafeAny) {
    if (typeof value === 'string') {
      const lines = value.split(StripHtmlPipe.newLineRe);
      return lines.map(this.transformLine).join(StripHtmlPipe.space);
    }

    return value;
  }

  private transformLine = (str: string): string => {
    return this.simplifyWhiteSpaces(this.transformBlankSpaces(this.removeHtmlTags(str)));
  };

  private removeHtmlTags(str: string): string {
    return str.replace(StripHtmlPipe.htmlTagRe, StripHtmlPipe.empty);
  }

  private simplifyWhiteSpaces(str: string): string {
    return str.replace(StripHtmlPipe.spacesRe, StripHtmlPipe.space);
  }

  private transformBlankSpaces(str: string): string {
    return str.replace(StripHtmlPipe.blankRe, StripHtmlPipe.space);
  }
}

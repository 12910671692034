import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalManager } from '@app/services';

import { Filter } from '@app/shared/models/filters/filters';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-filter-popover',
  templateUrl: './filter-popover.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./filter-popover.component.scss']
})
export class FilterPopoverComponent implements OnInit {
  filterList!: Filter[];
  title!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MSafeAny, private modalManager: ModalManager) {}

  ngOnInit() {
    this.filterList = this.data.filterList;
    this.title = this.data.title;
  }

  sendFilter(filter: Filter) {
    this.modalManager.dismissMatModal(filter);
  }

  trackByItems(item: Filter) {
    return item;
  }
}

import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss']
})
export class InputWrapperComponent {
  @Input() label!: string;
  @Input() errorMsg = '';
  @Input() errorTranslateParam = {};
  @Input() @HostBinding('class.has-error') hasError!: boolean;
  @Input() showErrorMsg = true;
  @Input() showIcon = true;

  @HostBinding('class.c-form-item') cssClasses = true;
}

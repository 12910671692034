import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ErrorMsgEnum } from '@app/components/error-msg/error-msg-enum';
import { TwoFactorAuthorization } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { Company } from '@app/ptrab/shared/models/company';
import { PortugueseWithholding, Withholding } from '@app/ptrab/shared/models/withholding';
import { Logger } from '@app/services';
import { ErrorCodes, ErrorMessages } from '@app/services/error/error.model';
import { IDataResponse } from '@app/shared/interfaces/data/data.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { ApiPtrabUrls } from '../api/api.service';

@Injectable()
export class WithholdingService {
  private logger = new Logger('WithholdingService');
  private onError = new Subject<ErrorMsgEnum>();
  onError$ = this.onError.asObservable();

  constructor(private apiUrls: ApiPtrabUrls, private http: HttpClient) {}

  getWithholdingList(): Observable<Withholding[]> {
    return this.http.get<IDataResponse<Withholding[]>>(this.apiUrls.withholding.list).pipe(
      map((rawData) => rawData.data.map((rawItem) => new Withholding(rawItem))),
      catchError(this.handleError)
    );
  }

  editWithholding(withholding: Withholding, twoFactorAuthorization: TwoFactorAuthorization): Observable<MSafeAny> {
    const company: Company = withholding.company;

    return this.http.post(this.apiUrls.withholding.list, {
      params: {
        company_code: company.code,
        employee_number: company.employee_number,
        voluntary: withholding.voluntary
      },
      authorization: twoFactorAuthorization
    });
  }

  getPortugueseWithholdingList(): Observable<PortugueseWithholding> {
    return this.http.get<IDataResponse<Withholding[]>>(this.apiUrls.withholding.portugalList).pipe(
      map((rawData) => new PortugueseWithholding(rawData.data)),
      catchError((error: HttpErrorResponse) => {
        const errorType =
          error && error.status === ErrorCodes.LOCKED ? ErrorMsgEnum.SERVICE_LOCKED : ErrorMsgEnum.SERVER_ERROR;
        this.onError.next(errorType);

        return of([] as MSafeAny);
      })
    );
  }

  private handleError = (error: HttpErrorResponse): Observable<MSafeAny[]> => {
    let errorType;

    switch (error.status) {
      case ErrorCodes.LOCKED:
        errorType = ErrorMsgEnum.SERVICE_LOCKED;
        break;
      case ErrorCodes.SECTION_MAINTENANCE:
        if (error.error?.code === ErrorMessages.PERM_DEACTIVATED) {
          errorType = ErrorMsgEnum.SECTION_MAINTENANCE;
        } else {
          errorType = ErrorMsgEnum.SERVER_ERROR;
        }

        break;
      default:
        errorType = ErrorMsgEnum.SERVER_ERROR;
        break;
    }

    this.onError.next(errorType);
    this.logger.error(error);

    return of([]);
  };
}

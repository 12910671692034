import { Component, HostBinding, Input } from '@angular/core';

import { IrpfDataSectionError } from '@app/ptrab/shared/interfaces/irpf-section.interface';

@Component({
  selector: 'app-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss']
})
export class ErrorDisplayComponent {
  @Input() title!: string;
  @Input() error!: IrpfDataSectionError;
  @Input() close = false;

  @HostBinding('hidden') isHidden = false;

  closeAlert() {
    this.isHidden = true;
  }
}

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

export class ModelResult {
  id: number;
  titleLiteralId: string;
  result: MSafeAny[];
  url: string;
  page: string;
  section: string;
  highlights: string[];

  constructor(item: MSafeAny) {
    this.id = item.id;
    this.titleLiteralId = item.title_id;
    this.url = item.url;
    this.page = item.page;
    this.section = item.section;
    this.highlights = item.highlighted_content;
    this.result = item.content;
  }
}

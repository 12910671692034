import { DatePipe } from '@angular/common';

import { CarTransfer } from '@app/ptrab/shared/models/car-transfer';
import { Payslip } from '@app/ptrab/shared/models/payslip';
import { subMonths } from 'date-fns';

/* istanbul ignore next */
// eslint-disable-next-line
export class PayslipsFilterManager {
  constructor(private payslips: (Payslip | CarTransfer)[], private datePipe: DatePipe) {}

  filterByCompany(companyCode: string): (Payslip | CarTransfer)[] {
    return this.payslips.filter((payslip: Payslip | CarTransfer) => {
      return payslip.company.code === companyCode;
    });
  }

  filterByYear(year: number) {
    return this.payslips.filter((payslip: Payslip | CarTransfer) => {
      const payslipDate = parseInt(this.datePipe.transform(payslip.end_date.substring(0, 10), 'yyyy') as string, 10);
      return payslipDate === year;
    });
  }

  filterByLastMonths(monthAmount: number): (Payslip | CarTransfer)[] {
    const firstDayOfMonth = new Date();
    firstDayOfMonth.setDate(1);
    firstDayOfMonth.setHours(0, 0, 0, 0);

    const minDateToShow = subMonths(firstDayOfMonth, monthAmount);
    return this.payslips.filter((data: Payslip | CarTransfer) => new Date(data.end_date) >= minDateToShow);
  }

  filterByRange(startDate: Date, endDate: Date) {
    return this.payslips.filter((payslip: Payslip | CarTransfer) => {
      const datePayslip = new Date(payslip.end_date);
      return datePayslip >= startDate && datePayslip <= endDate;
    });
  }

  mapYears() {
    return this.payslips.map((payslip: Payslip | CarTransfer) =>
      parseInt(this.datePipe.transform(payslip.end_date.substring(0, 10), 'yyyy') as string, 10)
    );
  }
}

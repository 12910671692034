import { ContentType } from '@app/shared/enums/multimedia/content-status-types.enum';

const sizeLimit = {};
sizeLimit[ContentType.TEXT] = null;
sizeLimit[ContentType.IMAGE] = 5; // MB
sizeLimit[ContentType.VIDEO] = 100; // MB
export const SIZE_LIMIT = sizeLimit;

export interface ExampleItemRadioButton {
  label: string;
  value: ContentType;
}

import { Injectable } from '@angular/core';

import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component';
import { ModalManager } from '@app/services';

export interface IConfirmationMessageConfig {
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText?: string | null;
}

@Injectable({ providedIn: 'root' })
export class ConfirmationController {
  private confirmPromise!: Promise<boolean>;
  private openConfirmation = false;

  constructor(private modalManager: ModalManager) {}

  confirmAction(messageConfig: IConfirmationMessageConfig): Promise<boolean> {
    if (this.openConfirmation && this.modalManager.isAnyModalOpened()) return this.confirmPromise;

    let resolveFn: (confirmed: boolean) => void = () => undefined;
    this.confirmPromise = new Promise<boolean>((resolve) => {
      resolveFn = (confirmed: boolean) => {
        this.openConfirmation = false;
        return resolve(confirmed);
      };
    });

    this.openConfirmation = true;
    this.confirmByModal(messageConfig, resolveFn);

    return this.confirmPromise;
  }

  private confirmByModal(messageConfig: IConfirmationMessageConfig, resolveFn: (confirmed: boolean) => void) {
    const buttons = [
      {
        text: messageConfig.confirmButtonText,
        type: 'primary',
        enabled: true,
        onClick: () => {
          this.modalManager.dismissMatModal();
          resolveFn(true);
        }
      }
    ];

    if (messageConfig.cancelButtonText) {
      buttons.unshift({
        text: messageConfig.cancelButtonText,
        type: 'secondary',
        enabled: true,
        onClick: () => {
          this.modalManager.dismissMatModal();
          resolveFn(false);
        }
      });
    }

    this.modalManager.openMatModal(ConfirmationModalComponent, {
      data: {
        title: messageConfig.title,
        contents: [messageConfig.message],
        buttons
      },
      disableClose: true,
      panelClass: ['media-content-modal', 'confirmation-modal']
    });
  }
}

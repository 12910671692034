import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { Logger } from '@app/services';
import { RepresentedUser } from '@app/shared/models/user/represented-user.model';
import { User } from '@app/shared/models/user/user.model';

@Component({
  selector: 'app-avatar-comment-selector',
  templateUrl: './avatar-comment-selector.component.html',
  styleUrls: ['./avatar-comment-selector.component.scss'],
  animations: [
    trigger('visibilitySelector', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('* => *', animate('300ms ease'))
    ])
  ]
})
export class AvatarCommentSelectorComponent implements OnInit {
  @Input() user!: User;
  @Input() userList!: RepresentedUser[];
  @Output() selectUser = new EventEmitter<{ userid: string }>();

  private logger = new Logger('avatar-comment-selector');

  // TODO [Manu]: tech debt modelos User y RepresentedUser
  userSelected!: RepresentedUser;
  showAvatarSelector = false;
  visibility = 'hidden';
  hasBeenOpened!: boolean;

  @HostListener('document:click')
  clickout() {
    if (!this.hasBeenOpened) {
      this.showAvatarSelector = false;
    }
    this.hasBeenOpened = false;
  }

  ngOnInit() {
    this.initSelectedUser();
  }

  canChooseUser() {
    return this.userList && this.userList.length > 1;
  }

  toggleAvatarSelector() {
    this.hasBeenOpened = true;
    this.showAvatarSelector = !this.showAvatarSelector;
  }

  isCurrentUser(user: User) {
    return this.user.userid === user.userid;
  }

  onSelectUser(user: RepresentedUser) {
    this.logger.debug('selected user', user);
    this.userSelected = user;
    this.showAvatarSelector = false;
    this.selectUser.emit({ userid: user.userid });
  }

  getSelectorVisibility() {
    return this.showAvatarSelector ? 'shown' : 'hidden';
  }

  isUserSelected(user: User) {
    return this.userSelected.userid === user.userid;
  }

  trackByItems(item: RepresentedUser) {
    return item;
  }

  private initSelectedUser() {
    if (!this.user) {
      this.logger.error('User is empty');
      throw new Error('Cannot init Avatar Selector');
    }

    if (!this.userList || this.userList.length < 1) {
      this.logger.error('User list is empty');
      throw new Error('Cannot show Avatar Selector');
    }

    this.userSelected = this.userList[0];
  }
}

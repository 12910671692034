import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalManager } from '@app/services';
import { MediaPlayerService } from '@app/services/media-player/media-player.service';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { domChanges } from '@app/shared/utils/utils';

import { SliderComponent } from '../../slider/slider.component';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PhotoViewerComponent implements AfterViewInit, OnInit {
  @ViewChild(SliderComponent) slides!: SliderComponent;

  images!: MSafeAny[];
  initialSlideIndex: number | null = null;
  isWebview = false;
  multimediaCarousel: MSafeAny = null;
  auxUpdateCarrousel: MSafeAny[] = [];

  readonly spaceBetweenImages = 100;

  isSliderWorking = true;
  setFullScreen!: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny,
    private modalManager: ModalManager,
    private mediaPlayerService: MediaPlayerService
  ) {}

  ngOnInit() {
    this.images = this.data.images;
    this.initialSlideIndex = this.data.initialSlideIndex;
    this.isWebview = this.data.isWebview;
    this.multimediaCarousel = this.data.multimediaCarousel;
    this.auxUpdateCarrousel = this.data.auxUpdateCarrousel;
  }

  async ngAfterViewInit() {
    await domChanges();
    this.slides.isReady$.subscribe(async () => {
      this.slides.slideTo(this.initialSlideIndex as number, 0);
      // 15/04/2021 bug reported in ion-slide ionic web component https://github.com/ionic-team/ionic-framework/issues/19486
      await domChanges();
      this.isSliderWorking = false;
      //
    });
  }

  enterFullScreenVideo(element: MSafeAny) {
    this.setFullScreen = element;
  }

  async onSlideChange(isVideoEnterFullscreen: boolean) {
    if (isVideoEnterFullscreen !== undefined && !isVideoEnterFullscreen) {
      this.slides.pauseCurrentPlayerPlaying();
    }
  }

  closeGallery() {
    this.mediaPlayerService.isMultimediaCarrouselFullScreen = false;
    let dataDismiss: MSafeAny = this.slides.getActiveIndex();
    if (this.multimediaCarousel) {
      this.multimediaCarousel = this.slides.updateDataVideoPlayer().actual;
      this.auxUpdateCarrousel = this.slides.updateDataVideoPlayer().allVideos;
      dataDismiss = {
        position: this.slides.getActiveIndex(),
        multimediaCarousel: this.multimediaCarousel,
        auxUpdateCarrousel: this.auxUpdateCarrousel
      };
    }
    this.modalManager.dismissMatModal(dataDismiss);
  }
}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import {
  PortugueseWithholdingCertificate,
  PortugueseWithholdingDropdownItem
} from '@app/ptrab/shared/models/withholding';

@Component({
  selector: 'app-ptrab-pt-withholding-certificate-list',
  templateUrl: './pt-withholding-certificate-list.component.html',
  styleUrls: ['./pt-withholding-certificate-list.component.scss']
})
export class PtWithholdingCertificateListComponent implements OnChanges {
  @Input() items!: PortugueseWithholdingDropdownItem[];
  @Input() selectedYear!: number;
  @Output() downloadItemClicked = new EventEmitter<PortugueseWithholdingCertificate>();

  certificatesByYear!: PortugueseWithholdingCertificate[] | undefined;

  ngOnChanges() {
    this.certificatesByYear = this.items.find((item) => item.year === this.selectedYear.toString())?.certificates;
  }

  downloadCertificate(item: PortugueseWithholdingCertificate) {
    this.downloadItemClicked.emit(item);
  }

  trackByItems(item: PortugueseWithholdingCertificate) {
    return item;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalManager, NetworkService, AlertService } from '@app/services';
import { VimeoStatus } from '@app/shared/constants/multimedia/vimeo.constants';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { Example } from '@app/shared/models/multimedia/example.model';
import { VideoExample } from '@app/shared/models/multimedia/video.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-example-publish',
  templateUrl: './example-publish.component.html',
  styleUrls: ['./example-publish.component.scss']
})
export class ExamplePublishComponent implements OnInit {
  exampleItem!: Example;

  readonly confirmationButtons: Buttons[] = [
    {
      text: 'EXAMPLES_CREATION.DRAFT',
      type: 'secondary',
      enabled: true,
      onClick: () => this.close()
    },
    {
      text: 'EXAMPLES_CREATION.PUBLISH',
      type: 'primary',
      enabled: true,
      onClick: () => this.sendToReviewIfConnection()
    }
  ];

  constructor(
    private network: NetworkService,
    private translate: TranslateService,
    private alertService: AlertService,
    private modalManager: ModalManager,
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny
  ) {}

  ngOnInit() {
    this.exampleItem = this.data.exampleItem;
  }

  close(confirmReview = false) {
    const event = { exampleItem: this.exampleItem, confirmReview };
    this.modalManager.dismissMatModal(event);
  }

  sendToReviewIfConnection() {
    if (this.isVideoUploadingError()) {
      this.alertService.showWarning(
        this.translate.instant('ERRORS_TOASTS.GENERIC_MSG'),
        this.translate.instant('ERRORS_TOASTS.CANNOT_SEND')
      );
      return;
    }

    this.network.doIfConnection(() => this.close(true));
  }

  isVideoUploadingError(): boolean {
    return (this.exampleItem as VideoExample).vimeo_status === VimeoStatus.UPLOADING_ERROR;
  }
}

import { Component, Injector, Input, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ItemCardType } from '@shared/enums/cards/item-card-type.enum';
import { Video } from '@shared/models/multimedia/video.model';

import { VideoDetailComponent } from '../../multimedia-details';
import { MultimediaCardDirective } from '../multimedia-card';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['../multimedia-card.scss', './video-card.component.scss']
})
export class VideoCardComponent extends MultimediaCardDirective implements OnInit {
  @Input() video!: Video;
  @Input() type!: ItemCardType;

  isTranscoding = false;
  thumbnailTranscoding!: string;
  show = false;

  constructor(private translateService: TranslateService, injector: Injector) {
    super(VideoDetailComponent, injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isTranscoding = this.checkIfIsTranscoding();
    this.thumbnailTranscoding = this.thumbnailTranslate();
  }

  protected onModalDetailDismiss(video: Video) {
    if (!video) {
      return;
    }

    this.video.thumbnail = this.video.thumbnail || video.thumbnail;
    this.video.vimeo_status = video.vimeo_status;
    this.video.url = video.url;
    this.video.views = video.views;
    this.video.likes = video.likes;
    this.video.liked = video.liked;
    this.isTranscoding = this.checkIfIsTranscoding();
    this.thumbnailTranscoding = this.thumbnailTranslate();
  }

  protected getItem(): Video {
    return this.video;
  }

  private checkIfIsTranscoding(): boolean {
    return !this.video.isOfficialContent() && !this.video.isAvailable();
  }

  private thumbnailTranslate(): string {
    return this.video.thumbnail + this.translateService.currentLang + '.svg';
  }
}

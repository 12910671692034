/* eslint-disable @typescript-eslint/naming-convention */

type genericError = {
  [key: string]: string;
};

const errorHandlerFiscal: genericError = {
  'PTRAB-MC-RQ-OM-FIS-1023': 'EMPLOYEE_PORTAL.PTRAB-MC-RQ-OM-FIS-1023',
  'PTRAB-MC-RQ-OM-FIS-1030': 'EMPLOYEE_PORTAL.PTRAB-MC-RQ-OM-FIS-1030'
};

const errorHandlerPerso: genericError = {
  'PTRAB-MC-RQ-PER-ERR-1001': 'EMPLOYEE_PORTAL.PTRAB-MC-RQ-PER-ERR-1001',
  'PTRAB-MC-RQ-PER-ERR-1002': 'EMPLOYEE_PORTAL.PTRAB-MC-RQ-PER-ERR-1002',
  'PTRAB-MC-RQ-PER-ERR-1003': 'EMPLOYEE_PORTAL.PTRAB-MC-RQ-PER-ERR-1003',
  'PTRAB-MC-RQ-PER-ERR-1004': 'EMPLOYEE_PORTAL.PTRAB-MC-RQ-PER-ERR-1004',
  'PTRAB-MC-RQ-PER-ERR-1005': 'EMPLOYEE_PORTAL.PTRAB-MC-RQ-PER-ERR-1005',
  'PTRAB-MC-RQ-PER-ERR-1006': 'EMPLOYEE_PORTAL.PTRAB-MC-RQ-PER-ERR-1006'
};

const errorRhnom: genericError = {
  'PTRAB-MC-RQ-OM-ERR-1008': 'EMPLOYEE_PORTAL.PTRAB-MC-RQ-OM-ERR-1008'
};

const errorComments: genericError = {
  'Activo2-error-comment-not-found': 'COMMENTS.NOT_FOUND'
};

export const genericErrors = {
  ...errorHandlerFiscal,
  ...errorHandlerPerso,
  ...errorRhnom,
  ...errorComments
};

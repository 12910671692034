import { PageExtras } from '@app/services/analytics/models/analytics-engine.interface';
import { ExampleRequestStatusEnum } from '@app/shared/interfaces/multimedia/example.request.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ContentSourceType, ContentType, ExampleStatusType } from '@shared/enums/multimedia/content-status-types.enum';
import { ENV } from 'src/environments/environment';

import { AbstractMultimediaItem } from './multimedia.abstract.model';
/* eslint-disable @typescript-eslint/naming-convention */

export abstract class Example extends AbstractMultimediaItem {
  private static readonly status_map = {
    [ExampleStatusType.APPROVED]: ExampleRequestStatusEnum.PUBLISH,
    [ExampleStatusType.DENIED]: ExampleRequestStatusEnum.DENIED,
    [ExampleStatusType.DRAFT]: ExampleRequestStatusEnum.DRAFT,
    [ExampleStatusType.PENDING]: ExampleRequestStatusEnum.PENDING
  };

  author: string;
  request_date: string;
  creation_date: string;
  modification_date!: string;
  content_type!: ContentType;
  department: string;
  division_zone: string;
  store: string;
  status: ExampleStatusType;
  status_request: ExampleRequestStatusEnum;
  is_public: boolean;
  cod_store: string;
  url_video_upload?: string;
  denied_reason?: string;
  status_info: string;
  returned: boolean;

  constructor(exampleItem: MSafeAny) {
    super(exampleItem);
    this.author = exampleItem.author;
    this.request_date = exampleItem.request_date;
    this.creation_date = exampleItem.creation_date;
    if (exampleItem.modification_date) {
      this.modification_date = exampleItem.modification_date.split(' ')[0];
    }
    this.department = exampleItem.department;
    this.division_zone = exampleItem.division_zone;
    this.store = exampleItem.store;
    this.status = exampleItem.status;
    this.status_request = Example.status_map[exampleItem.status as keyof typeof Example.status_map];
    this.is_public = exampleItem.status === ExampleStatusType.APPROVED;
    this.likes = exampleItem.likes || 0;
    this.cod_store = exampleItem.cod_store;
    this.url_video_upload = exampleItem.url_video_upload;
    this.denied_reason = exampleItem.denied_reason;
    this.status_info = exampleItem.status_info;
    this.returned = exampleItem.returned;
    this.content_source = ContentSourceType.EXAMPLE;
    this.link = `${ENV.APP_UNIVERSAL_LINKS_URL}/e/${this.slug}`;
  }

  isDraft() {
    return this.status === ExampleStatusType.DRAFT;
  }

  getStoreFullName(): string {
    return this.cod_store ? `${this.cod_store} - ${this.store}` : this.store;
  }

  isDenied() {
    return this.status === ExampleStatusType.DENIED;
  }

  getLastDate(): string {
    return this.status_request === ExampleRequestStatusEnum.PUBLISH ? this.publication_date : this.modification_date;
  }

  getAnalyticsPageExtras(): PageExtras {
    return {
      pageType: 'ejemplos',
      tipologiaDeContenido: this.getAnalyticsPageContentType(),
      autor: this.author
    };
  }
}

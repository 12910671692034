import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { Logger, ModalManager } from '@app/services';
import { ProfileAvatarService } from '@app/services/profile/profile.avatar.service';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { User } from '@app/shared/models/user/user.model';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { EditAvatarActions, EditAvatarModalComponent } from '../modals/edit-avatar-modal/edit-avatar-modal.component';

@Component({
  selector: 'app-avatar-manager',
  templateUrl: './avatar-manager.component.html',
  styleUrls: ['./avatar-manager.component.scss']
})
export class AvatarManagerComponent implements OnInit {
  @ViewChild('fileInput') avatarInput!: ElementRef;

  @Input() user!: User;

  private readonly logger: Logger = new Logger('Avatar Manager');

  acceptedFileExtensions!: string;

  constructor(private avatarService: ProfileAvatarService, private modalManager: ModalManager) {}

  ngOnInit() {
    this.acceptedFileExtensions = this.avatarService.getAcceptedFileExtensions();
  }

  async openEditAvatarModal() {
    const action = await this.modalManager.openMatModal(EditAvatarModalComponent, {
      data: { user: this.user },
      disableClose: true
    });

    if (action === EditAvatarActions.SELECT) {
      this.openImageSelector();
    } else if (action === EditAvatarActions.DELETE) {
      const title = 'PROFILE.EDIT_AVATAR.DELETE_PHOTO_TITLE';
      const contents = ['PROFILE.EDIT_AVATAR.DELETE_PHOTO_DESCRIPTION'];
      const buttons: Buttons[] = [
        {
          text: 'NO',
          type: 'secondary',
          enabled: true,
          onClick: () => {
            this.modalManager.dismissMatModal();
          }
        },
        {
          text: 'YES',
          type: 'primary',
          enabled: true,
          onClick: () => {
            this.modalManager.dismissMatModal(true);
          }
        }
      ];

      const confirmationAction = await this.modalManager.openMatModal(ConfirmationModalComponent, {
        data: { title, contents, buttons },
        disableClose: true,
        panelClass: ['media-content-modal', 'confirmation-two-lines-modal']
      });

      if (confirmationAction) {
        this.avatarService.deleteAvatar();
      }
    }
  }

  private openImageSelector() {
    const fileInput = this.avatarInput.nativeElement;
    fileInput.click();

    fileInput.addEventListener('change', (ev) => this.onChangeAvatar(ev.currentTarget.files[0]));
  }

  onChangeAvatar(file: File) {
    try {
      this.avatarService.triggerDesktopFlow(file);
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.clearFileInput();
    }
  }

  private clearFileInput() {
    if (this.avatarInput) {
      this.avatarInput.nativeElement.value = '';
    }
  }
}

import { Component, Input } from '@angular/core';

import { PortugueseWithholdingCertificate } from '@app/ptrab/shared/models/withholding';

@Component({
  selector: 'app-ptrab-pt-withholding-certificate-item',
  templateUrl: './pt-withholding-certificate-item.component.html',
  styleUrls: ['./pt-withholding-certificate-item.component.scss']
})
export class PtWithholdingCertificateItemComponent {
  @Input() certificate!: PortugueseWithholdingCertificate;
}

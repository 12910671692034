import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Logger, NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-title-section',
  templateUrl: 'title-section.component.html',
  styleUrls: ['title-section.component.scss']
})
export class TitleSectionComponent {
  @Input() text!: string;
  @Input() previous!: string;
  @Input() previousParams: MSafeAny;
  @Input() textLink!: string;
  @Input() linkPage!: string;
  @Input() darkMode!: boolean;
  @Input() isWebview!: boolean;

  @Output() back = new EventEmitter();

  logger = new Logger('TitleSectionComponent');
  allowGoBack!: boolean;
  isEnabledPrevious!: boolean;

  constructor(private navigationService: NavigationService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((params) => (this.isEnabledPrevious = params.enablePrevious));
    this.activatedRoute.data.subscribe((data: MSafeAny) => (this.allowGoBack = data.allowGoBack));
  }

  goBack() {
    if (this.back.observers.length > 0) {
      this.back.next({ previous: this.previous, previousParams: this.previousParams });
      return;
    }
    this.navigationService.goBack(this.previous, this.previousParams);
  }

  goToLink() {
    const navEvent = new NavigationEvent(NavigationEvents.Push, { path: this.linkPage });
    this.navigationService.navigate(navEvent);
  }
}

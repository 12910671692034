export enum ExamplesTabs {
  ALL = 'ALL',
  MY_EXAMPLES = 'MY_EXAMPLES'
}

export enum MyExamplesTabs {
  ALL = 'ALL',
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  DENIED = 'DENIED',
  DRAFT = 'DRAFT'
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IrpfDataSectionError } from '@app/ptrab/shared/interfaces/irpf-section.interface';
import { IrpfViewItem, IrpfViewItemObj } from '@app/ptrab/shared/models/irpf-view.model';

@Component({
  selector: 'app-ptrab-irpf-item',
  templateUrl: './irpf-item.component.html',
  styleUrls: ['./irpf-item.component.scss']
})
export class IrpfItemComponent implements OnInit {
  @Input() data!: IrpfViewItemObj;
  @Input() title!: string;
  @Input() sectionErrors!: IrpfDataSectionError;
  @Input() hasChanges!: boolean;

  @Output() modify = new EventEmitter();

  dataToload!: IrpfViewItem[];

  ngOnInit() {
    const irpfItems = Object.values(this.data);
    this.dataToload = irpfItems.filter((item) => Boolean(item.label));
  }

  get hasDataToLoad() {
    return this.dataToload && this.dataToload.length > 0;
  }

  get hasError() {
    return this.sectionErrors && this.hasChanges;
  }

  modifySection() {
    this.modify.emit();
  }

  trackByItems(item: IrpfViewItem) {
    return item;
  }
}

<div class="title-description-image-card" *ngIf="item">
  <div class="grid no-padding">
    <div class="row">
      <div class="col" size="2">
        <img class="title-description-image-card__img" [src]="item.img" />
      </div>
      <div class="col title-description-image-card__info" size="10">
        <span class="title-description-image-card__info__title">{{ item.title }}</span>
        <span class="title-description-image-card__info__date"> {{ item.description }} </span>
      </div>
    </div>
  </div>
</div>

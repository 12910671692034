import {
  Component,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { IrpfDataSectionError } from '@app/ptrab/shared/interfaces/irpf-section.interface';
import { BasePageDirective } from '@app/shared/bases/base.page';

@Component({
  selector: 'app-ptrab-irpf-section-edition',
  templateUrl: './irpf-section-edition.component.html',
  styleUrls: ['./irpf-section-edition.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IrpfSectionEditionComponent extends BasePageDirective implements OnInit, OnDestroy {
  @Input() title!: string;
  @Input() errors!: IrpfDataSectionError;
  @Input() continueButtonLabel = 'EMPLOYEE_PORTAL.CONTINUE';
  @Input() disabled!: boolean;
  @Output() cancel = new EventEmitter();
  @Output() continue = new EventEmitter();

  @HostBinding('class') readonly pageClassCss = 'ion-page';

  constructor(injector: Injector) {
    super(injector);
  }

  get hasError() {
    return this.errors;
  }

  ngOnInit() {
    super.ngOnInit();
    this.setAnalyticsPageFromRoute();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  continueEdition() {
    this.continue.emit();
  }

  cancelEdition() {
    this.cancel.emit();
  }
}

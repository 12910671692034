import { Component, OnInit } from '@angular/core';

import { TermsAndConditionsKey } from '@app/activo2/terms-and-conditions/terms-and-conditions.enum';
import { NavigationService } from '@app/services';
import { UserService } from '@app/services/user/user.service';
import { User } from '@app/shared/models/user/user.model';

import { TermsAndConditionsDirective } from '../terms-and-conditions';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['../terms-and-conditions.scss']
})
export class TermsAndConditionsComponent extends TermsAndConditionsDirective implements OnInit {
  termsConditionsKey!: TermsAndConditionsKey;

  constructor(protected navigationService: NavigationService, private userService: UserService) {
    super(navigationService);
  }

  ngOnInit() {
    this.userService.getStoredUser().then((userInfo: User) => {
      const legalConditionsType = userInfo.legalConditionsType;

      switch (legalConditionsType) {
        case 'EXTERNAL':
          this.termsConditionsKey = TermsAndConditionsKey.EXTERNAL;
          break;
        case 'INTERNAL_PTRAB':
          this.termsConditionsKey = TermsAndConditionsKey.PTRAB;
          break;
        case 'INTERNAL_NO_PTRAB':
          this.termsConditionsKey = TermsAndConditionsKey.NO_PTRAB;
          break;
        default:
          break;
      }
    });
  }
}

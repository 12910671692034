<div class="toggle" [class.disabled]="isDisabled" (click)="onToggleTranslation()">
  <i class="toggle__icon app-icon-translate"></i>
  <span class="toggle__text">{{ getToggleLiteral() | translate }}</span>
  <img class="toggle__img" src="assets/imgs/translate/google.svg" />
</div>

<ng-container *ngIf="isOpen" [ngSwitch]="currentState">
  <div *ngSwitchCase="STATES.LOADING" class="loading-content">
    <app-spinner [crescent]="true" [autoSize]="true" [horizontal]="true" class="loading-content__spinner"></app-spinner>
    <span class="loading-content__text">{{ 'TRANSLATION_CONTENT.TRANSLATING' | translate }}</span>
  </div>

  <div *ngSwitchCase="STATES.ERROR" class="error-content">
    <span class="error-content__text">{{ 'TRANSLATION_CONTENT.ERROR' | translate }}</span>
    <span (click)="translateContent()" class="error-content__retry">{{ 'ERROR_MESSAGES.RETRY' | translate }}</span>
  </div>

  <div *ngSwitchCase="STATES.DONE" class="translated-content" [innerHTML]="textTranslated"></div>
</ng-container>

import {
  OnBoardingResponse,
  OnBoardingRole,
  OnBoardingType
} from '@app/shared/interfaces/user/user-onboarding.interface';

export class OnBoarding {
  type: OnBoardingType;
  role: OnBoardingRole;
  companyCode: string;
  completed: boolean;

  constructor(dto: OnBoardingResponse) {
    this.type = dto.onboarding_type;
    this.role = dto.onboarding_role;
    this.companyCode = dto.company_code;
    this.completed = dto.completed;
  }
}

import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';

import { ErrorMsgEnum } from '@app/components/error-msg/error-msg-enum';
import { IrsRepository } from '@app/ptrab/services/irs/irs.repository';
import { IrsService } from '@app/ptrab/services/irs/irs.service';
import { IrsWithholdingModel } from '@app/ptrab/shared/models/irs.model';
import { Logger } from '@app/services';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { PTRAB_PAGES } from '@app/shared/enums/pages/pages.enum';

import { BaseVoluntaryWithholdingComponent } from '../base-voluntary-withholding/voluntary-withholding.component';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-ptrab-voluntary-withholding-pt',
  templateUrl: './voluntary-withholding-pt.component.html',
  styleUrls: ['./voluntary-withholding-pt.component.scss', '../../../../styles/ptrab-title-section.scss']
})
export class VoluntaryWithholdingPtComponent
  extends BaseVoluntaryWithholdingComponent
  implements OnDestroy, OnInit, OnChanges
{
  @Input() reloadData!: boolean;
  protected ptrabPage: PTRAB_PAGES = PTRAB_PAGES.WITHHOLDING_EDITION_PT;
  protected logger = new Logger('VoluntaryWithholdingPtComponent');
  subscription!: Subscription;

  constructor(protected injector: Injector, protected irsRepository: IrsRepository, protected irsService: IrsService) {
    super(injector);
  }

  ngOnInit(): void {
    this.getWithholding();
    this.initializeErrorHandling();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reloadData.currentValue) {
      this.isWithholdingLoading = true;
      this.errorOcurred = null;
      this.subscription = this.irsRepository.getWithholding().subscribe(() => (this.isWithholdingLoading = false));
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  protected async getWithholding() {
    this.irsService.onWithholdingUpdate$.pipe(takeUntil(this.destroyed)).subscribe((response: IrsWithholdingModel) => {
      this.withholding = response;
      this.isWithholdingLoading = false;
    });

    this.irsRepository.getWithholding().subscribe();
  }

  protected initializeErrorHandling() {
    this.irsService.onError$.pipe(takeUntil(this.destroyed)).subscribe((errorType) => {
      this.errorOcurred = errorType;
      this.isWithholdingLoading = false;
      this.isSectionMaintenance = this.errorOcurred === ErrorMsgEnum.SECTION_MAINTENANCE;
    });
  }

  async confirmRequest(withholding: IrsWithholdingModel) {
    this.analyticsService.sendEvent(CategoriesAnalytics.CLICK, {
      [ActionsAnalytics.CLICKACTION]: ActionsAnalytics.WITHHOLDING_VOLUNTARY_REQUESTED
    });

    this.editWithholding(withholding);
  }
}

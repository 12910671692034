import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { StorageService, STORAGE_CONSTANTS } from '@app/services/storage';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { CookieService } from 'ngx-cookie-service';

export enum ConfigUserCookies {
  MERCADONA_LINK = 'https://activo2.mercadona.com',
  WINDOWS = 'http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies',
  IE9 = 'http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9',
  FIREFOX = 'http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we',
  CHROME = 'https://support.google.com/accounts/answer/61416?hl=es',
  OPERA = 'https://help.opera.com/en/latest/web-preferences/#cookies',
  SAFARI = 'http://support.apple.com/kb/HT1677?viewlocale=es_ES'
}

export interface CookiesType {
  type: string;
  name: string;
  use: string;
  expired: string;
}

export interface UserCookies {
  essentialsCookies: boolean;
  notEssentialsCookies: boolean;
  expireTimeInMilliseconds: number;
}

export const COOKIES_TIME = {
  create: new Date().getTime(),
  expireTime: new Date(new Date().setFullYear(new Date().getFullYear() + 5)).getTime(),
  expire: new Date(new Date().setFullYear(new Date().getFullYear() + 5))
};

export enum ActionCookies {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  REMOVE = 'REMOVE',
  SAVE = 'SAVE',
  RENEW = 'RENEW'
}
@Injectable({
  providedIn: 'root'
})
export class CookiesConfig {
  userBasicCookies = new BehaviorSubject<boolean>(true);
  userBasicCookies$: Observable<boolean> = this.userBasicCookies.asObservable();

  constructor(private cookieService: CookieService, private storageService: StorageService) {}

  async checkIsExpiredCookiesLocal(): Promise<MSafeAny> {
    const cookieInternalUserId = await this.getNameCookieByUser();
    if (this.cookieService.check(cookieInternalUserId)) {
      try {
        const itemCookies = await this.getCookiesService();
        if (!itemCookies || new Date().getTime() > itemCookies.expireTimeInMilliseconds) {
          return { action: ActionCookies.REMOVE };
        }
        return null;
      } catch {
        return { action: ActionCookies.REMOVE };
      }
    } else {
      return { action: ActionCookies.RENEW };
    }
  }

  async getCookiesService(): Promise<MSafeAny | null> {
    try {
      const cookieInternalUserId = await this.getNameCookieByUser();
      return JSON.parse(this.cookieService.get(cookieInternalUserId));
    } catch {
      return null;
    }
  }

  async getNameCookieByUser(): Promise<string> {
    const userInfo = await this.storageService.get(STORAGE_CONSTANTS.USER);
    if (!userInfo) return '';
    return STORAGE_CONSTANTS.COOKIES + '-' + userInfo.internal_user_id;
  }

  async saveCookies(cookies: UserCookies, expiredTime?: Date) {
    const cookieInternalUserId = await this.getNameCookieByUser();
    if (!cookieInternalUserId) return;
    const optsSetCookie: MSafeAny[] = [expiredTime ? expiredTime : COOKIES_TIME.expire, null, null, null, null];
    this.cookieService.set(cookieInternalUserId, JSON.stringify(cookies), ...optsSetCookie);
    this.storageService.set(cookieInternalUserId, cookies);
  }

  async renewCookie(): Promise<MSafeAny> {
    const cookieInternalUserId = await this.getNameCookieByUser();
    const cookieBody = await this.storageService.get(cookieInternalUserId);
    if (!cookieBody) return null;
    const nowDate = new Date().getTime();
    if (nowDate > cookieBody.expireTimeInMilliseconds) {
      return { action: ActionCookies.REMOVE };
    }
    return {
      action: ActionCookies.SAVE,
      data: { cookies: cookieBody, expiredTime: new Date(cookieBody.expireTimeInMilliseconds) }
    };
  }

  removeCookies() {
    this.deleteCookieService();
    this.removeCookieLocal();
  }

  private async deleteCookieService() {
    const cookieInternalUserId = await this.getNameCookieByUser();
    this.cookieService.delete(cookieInternalUserId);
  }
  private async removeCookieLocal() {
    const cookieInternalUserId = await this.getNameCookieByUser();
    this.storageService.remove(cookieInternalUserId);
  }
}

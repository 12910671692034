<article class="menu is-desktop">
  <div class="menu-content">
    <a
      *ngFor="let segment of segments; trackBy: trackByItems"
      (click)="openPage(segment)"
      class="segment u-lmenu"
      [class.is-active]="segment.isActive">
      <i role="img" [ngClass]="segment.icon" class="segment-icon"></i>
      {{ segment.text | translate }}
    </a>
    <a
      class="segment u-lmenu scrapper-more-tag"
      [class.is-active]="pageMoreActive"
      (click)="checkIsActive(pageMore)"
      #mPopover="m-popover"
      popoverPosition="bottomLeft"
      [m-popover]="morePopoverTmp">
      <i role="img" class="segment-icon app-icon-more"></i>
      {{ 'MORE' | translate }}
    </a>
  </div>
</article>

<ng-template #morePopoverTmp>
  <app-more-popover [popover]="popover"></app-more-popover>
</ng-template>

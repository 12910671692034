<div [class.darkmode]="darkMode">
  <app-spinner [showText]="true" *ngIf="isLoading"></app-spinner>

  <ng-container *ngIf="!isLoading && !sectionMaintenance">
    <section class="grid-auto-padding-left grid-auto-padding-right" [class.darkmode]="darkMode">
      <div class="grid section__padding--right section__padding--left">
        <div class="row">
          <div class="col" size="12">
            <h1 class="regular-title-section">{{ 'EMPLOYEE_PORTAL.IRS_INFO.TITLE' | translate }}</h1>
            <p class="info__views">{{ video?.views }} {{ 'VIEWS' | translate | lowercase }}</p>
            <app-media-player
              *ngIf="video"
              #player
              [video]="video"
              [analyticsCategory]="analyticsCategory"
              (play)="onPlay()"
              (stalled)="onStalled()"
              (enterFullscreen)="onEnterFullscreen()"
              (exitFullscreen)="onExitFullscreen()"></app-media-player>
          </div>
        </div>
      </div>
    </section>

    <section class="grid-auto-padding-left grid-auto-padding-right section--no-margin-top" [class.darkmode]="darkMode">
      <div class="grid section__padding--right section__padding--left">
        <div class="row section__colum-container section__colum-container--no-margin-top">
          <div class="col" size="12">
            <p class="section__text">{{ 'EMPLOYEE_PORTAL.IRS_INFO.WHAT_IS_IRS' | translate }}</p>
            <ion-icon name="arrow-down"></ion-icon>
            <h4 class="section__subtitle">{{ 'EMPLOYEE_PORTAL.IRS_INFO.WHAT_IS_IRS_2' | translate }}</h4>
          </div>
        </div>
        <div class="row section__colum-container">
          <div class="col" size="12">
            <h4 class="section__subtitle">{{ 'EMPLOYEE_PORTAL.IRS_INFO.WHEN_IRS_IS_PAID_QUESTION' | translate }}</h4>
            <p class="section__text">{{ 'EMPLOYEE_PORTAL.IRS_INFO.WHEN_IRS_IS_PAID' | translate }}</p>
            <ion-icon name="arrow-down"></ion-icon>
            <h4 class="section__subtitle">{{ 'EMPLOYEE_PORTAL.IRS_INFO.WHEN_IRS_IS_PAID_2' | translate }}</h4>
          </div>
        </div>
      </div>
    </section>

    <section class="grid-auto-padding-left grid-auto-padding-right section section--green" [class.darkmode]="darkMode">
      <div class="grid section__padding--right section__padding--left">
        <h1 class="section__title">{{ 'EMPLOYEE_PORTAL.IRS_INFO.HOW_TO_CALCULATE.TITLE' | translate }}</h1>
        <div class="row">
          <div class="col">
            <p class="section__text">{{ 'EMPLOYEE_PORTAL.IRS_INFO.HOW_TO_CALCULATE.TEXT_1' | translate }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <ul class="list__section">
              <li class="list__item">
                <p class="list__title">{{ 'EMPLOYEE_PORTAL.IRS_INFO.HOW_TO_CALCULATE.TEXT_2' | translate }}</p>
              </li>
              <li class="list__item">
                <p class="list__title">{{ 'EMPLOYEE_PORTAL.IRS_INFO.HOW_TO_CALCULATE.TEXT_3' | translate }}</p>
                <p class="list__title">{{ 'EMPLOYEE_PORTAL.IRS_INFO.HOW_TO_CALCULATE.TEXT_4' | translate }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col" size="12">
            <app-quote-phrase
              white
              [quoteText]="'EMPLOYEE_PORTAL.IRS_INFO.EXAMPLE.TEXT_1' | translate"
              [darkMode]="darkMode"></app-quote-phrase>
          </div>
        </div>
      </div>
    </section>

    <section class="grid-auto-padding-left grid-auto-padding-right section section--blue" [class.darkmode]="darkMode">
      <div class="grid">
        <div class="row section__align-center">
          <div class="col" size="12">
            <div class="image__container">
              <img src="assets/imgs/ptrab/calc.svg" />
              <img class="image__content" src="assets/imgs/ptrab/public_finance.svg" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grid-auto-padding-left grid-auto-padding-right section" [class.darkmode]="darkMode">
      <div class="grid section__padding--right section__padding--left">
        <h1 class="section__title">{{ 'EMPLOYEE_PORTAL.IRS_INFO.BENEFITS.TITLE' | translate }}</h1>
        <div class="row section__gray-content">
          <div class="col">
            <p class="section__text">
              {{ 'EMPLOYEE_PORTAL.IRS_INFO.BENEFITS.TEXT_1' | translate }}
            </p>
            <p class="section__text">
              {{ 'EMPLOYEE_PORTAL.IRS_INFO.BENEFITS.TEXT_2' | translate }}
            </p>
            <p class="section__text">
              {{ 'EMPLOYEE_PORTAL.IRS_INFO.BENEFITS.TEXT_3' | translate }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      class="grid-auto-padding-left grid-auto-padding-right section section--dark-gray"
      [class.darkmode]="darkMode">
      <div class="grid section__padding--right section__padding--left">
        <h1 class="section__title">{{ 'EMPLOYEE_PORTAL.IRS_INFO.VOLUNTARY_WITHHOLDING.TITLE' | translate }}</h1>
        <div class="row section__gray-content">
          <div class="col">
            <p class="section__text">
              {{ 'EMPLOYEE_PORTAL.IRS_INFO.VOLUNTARY_WITHHOLDING.TEXT_1' | translate }}
            </p>
            <p class="section__text">
              {{ 'EMPLOYEE_PORTAL.IRS_INFO.VOLUNTARY_WITHHOLDING.TEXT_2' | translate }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="grid-auto-padding-left section" [class.darkmode]="darkMode">
      <div class="grid section__padding--left">
        <div class="row section__certificate">
          <div size="12" size-lg="7" class="col">
            <h1 class="section__title">
              {{ 'EMPLOYEE_PORTAL.IRS_INFO.WITHHOLDING_DECLARATION.TITLE' | translate }}
            </h1>
            <p class="section__gray-content section__certificate-description">
              {{ 'EMPLOYEE_PORTAL.IRS_INFO.WITHHOLDING_DECLARATION.TEXT' | translate }}
            </p>
          </div>
          <div class="col section__certificate-container" size="12" size-lg="5">
            <img src="assets/imgs/ptrab/withholding_pt.svg" />
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>

import { ModelSectionNames } from '@shared/enums/model/model-sections.enum';
import { IModelSectionRoute } from '@shared/interfaces/model';

export const MODEL_SECTIONS_ROUTES: IModelSectionRoute[] = [
  {
    sectionName: ModelSectionNames.MODEL_IMPORTANCE,
    url: 'importance-of-the-model',
    tag: 'app-importance-of-the-model'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS,
    url: 'universal-truths',
    tag: 'app-menu-universal-truths'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS_WHAT,
    url: 'universal-truths',
    section: 'what-is',
    tag: 'app-universal-truths'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS_DPE,
    url: 'universal-truths',
    section: 'give-ask-demand',
    tag: 'app-universal-truths'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS_CONTRAST,
    url: 'universal-truths',
    section: 'contrast',
    tag: 'app-universal-truths'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS_FACTS,
    url: 'universal-truths',
    section: 'facts',
    tag: 'app-universal-truths'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS_NOBODY,
    url: 'universal-truths',
    section: 'nobody-knows',
    tag: 'app-universal-truths'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS_IMPROVE,
    url: 'universal-truths',
    section: 'improve',
    tag: 'app-universal-truths'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS_WALK,
    url: 'universal-truths',
    section: 'walk',
    tag: 'app-universal-truths'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS_CAUSE,
    url: 'universal-truths',
    section: 'cause',
    tag: 'app-universal-truths'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS_TIME,
    url: 'universal-truths',
    section: 'time',
    tag: 'app-universal-truths'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS_CHAIN,
    url: 'universal-truths',
    section: 'chain',
    tag: 'app-universal-truths'
  },
  {
    sectionName: ModelSectionNames.UNIVERSAL_TRUTHS_APPLY,
    url: 'universal-truths',
    section: 'apply-all',
    tag: 'app-universal-truths'
  },
  {
    sectionName: ModelSectionNames.PARADIGM,
    url: 'paradigm',
    tag: 'app-paradigm'
  },
  {
    sectionName: ModelSectionNames.MISSION_AND_VISION,
    url: 'mission-and-vision',
    tag: 'app-mission-and-vision'
  },
  {
    sectionName: ModelSectionNames.THE_BOSS,
    url: 'the-boss',
    tag: 'app-the-boss'
  },
  {
    sectionName: ModelSectionNames.THE_WORKER,
    url: 'the-worker',
    tag: 'app-the-worker'
  },
  {
    sectionName: ModelSectionNames.THE_PROVIDER,
    url: 'the-provider',
    tag: 'app-the-provider'
  },
  {
    sectionName: ModelSectionNames.THE_SOCIETY,
    url: 'society',
    tag: 'app-the-society'
  },
  {
    sectionName: ModelSectionNames.THE_CAPITAL,
    url: 'the-capital',
    tag: 'app-the-capital'
  },
  {
    sectionName: ModelSectionNames.MODEL_APPLICATION,
    url: 'model-application',
    tag: 'app-model-application'
  },
  {
    sectionName: ModelSectionNames.PURPOSE,
    url: 'purpose',
    tag: 'app-purpose'
  }
];

<app-avatar-comment
  *ngIf="!showLoadingControl"
  [avatarUser]="avatarPhoto"
  (mouseover)="onEnterAvatar($event)"
  (mouseleave)="onLeaveAvatar()">
</app-avatar-comment>

<section class="comment-container desktop">
  <img *ngIf="comment.highlighted" src="assets/imgs/comments/chincheta.svg" alt="" class="img-chincheta" />
  <app-user-info-popover
    *ngIf="popoverOpen"
    [comment]="commentInfo"
    (closePopover)="closePopover()"
    (close)="closePopover()">
  </app-user-info-popover>

  <div *ngIf="comment && !editMode && !showLoadingControl" class="user-comment">
    <div class="user">
      <p class="user-comment__name u-h7">
        <span (mouseover)="onEnterAvatar($event)" (mouseleave)="onLeaveAvatar()">{{ fullName | nounUpperCase }}</span>
      </p>
      <div
        *ngIf="hasInfoInAvatar"
        class="user-comment__icon"
        (mouseover)="onEnterAvatar($event)"
        (mouseleave)="onLeaveAvatar()">
        <img src="assets/imgs/toast/info-{{ isIconHovered ? 'hover' : 'default' }}.svg" />
      </div>
    </div>
    <div class="user-detail">
      <div class="user-comment__division_zone u-p6">
        {{ comment.division_zone | nounUpperCase }}
      </div>
      <span class="user-comment__date u-p6"> {{ relativeDate }}</span>
    </div>
    <p class="user-comment__comment u-p4" [class.italic]="isTranslated" [innerHTML]="commentText"></p>
  </div>

  <div *ngIf="editMode && !showLoadingControl" class="edit-mode-container">
    <app-richtext-editor
      [showToolbar]="true"
      [maxLength]="MAX_COMMENT_LENGTH"
      [placeholder]="messages['COMMENTS.PLACEHOLDER']"
      [(ngModel)]="commentEdit"
      (ngModelChange)="onCommentEditChanged()">
      <button class="c-button link" (click)="disableEditMode()">{{ 'COMMENTS.CANCEL_EDIT' | translate }}</button>
      <button class="c-button link" [disabled]="isSaveCommentEditDisabled" (click)="editComment()">
        {{ 'COMMENTS.SAVE_EDIT' | translate }}
      </button>
    </app-richtext-editor>
  </div>
  <div class="spinner" *ngIf="showLoadingControl">
    <app-spinner [crescent]="true"></app-spinner>
  </div>

  <div
    *ngIf="!showLoadingControl"
    [class.three-buttons-display]="canEditMiddleware() && this.hasToShowTranslationButton"
    [class.three-buttons-display-bigger]="userCanReply"
    class="actions">
    <ng-container *ngIf="!replyMode && checkIfCanUserReply()">
      <div
        (click)="enableReply()"
        class="reply-button hover-img"
        [ngClass]="{
          'hidden-block': editMode,
          'margin-end': loadUser && canEditMiddleware()
        }">
        <img class="comment-icon" src="assets/imgs/comments/comment_filled.svg" />
        <span>{{ 'COMMENTS.REPLY' | translate }}</span>
      </div>
    </ng-container>
    <div class="actions-buttons" *ngIf="this.loadUser && canEditMiddleware()" [class.hidden-block]="replyMode">
      <div *ngIf="!isBanned" class="edit" (click)="enableEditMode()">
        <i class="app-icon-edit-pencil"></i>
        <span>{{ 'COMMENTS.EDIT' | translate }}</span>
      </div>
      <div class="delete" (click)="deleteComment()">
        <i class="app-icon-bin"></i>
        <span>{{ 'COMMENTS.DELETE' | translate }}</span>
      </div>
    </div>
    <div class="blank-content"></div>
    <ng-container *ngIf="hasToShowTranslationButton">
      <app-google-translation
        class="align-text"
        [sourceText]="comment.comment"
        [sourceLang]="comment.language"
        (toggleTranslate)="toggleTranslate($event)"></app-google-translation>
    </ng-container>
  </div>

  <div *ngIf="replyMode" class="reply-input">
    <app-add-comment
      *ngIf="!isSendingReply"
      [isReply]="true"
      [user]="user"
      [asUsers]="asUsers"
      [userIsOwner]="userIsOwner"
      [userIsPresident]="userIsPresident"
      [userCanReply]="userCanReply"
      [commentUserId]="comment.user_id"
      publishLabel="COMMENTS.REPLY"
      (userComment)="replyMiddleware($event)"
      (userSelected)="setUserSelected($event)"></app-add-comment>
    <div class="spinner" *ngIf="isSendingReply">
      <app-spinner [crescent]="true"></app-spinner>
    </div>
  </div>

  <app-comment
    *ngFor="let commentReply of comment.response_comments; odd as isOdd; let ri = index; trackBy: trackComment"
    app-scroll-to-comment
    [isHighlightComment]="highlightedCommentId === commentReply.id"
    (afterScroll)="setAnimate($event)"
    [comment]="commentReply"
    [commentIndex]="ri"
    [userIsOwner]="userIsOwner"
    [commentInfo]="commentReply"
    [commentParent]="comment"
    [contentIdPublication]="parentItemId"
    [asUsers]="asUsers"
    [userIsPresident]="userIsPresident"
    [avatarPhoto]="getAvatarPhoto(commentReply, isOdd)"
    [userCanReply]="true"
    [userPermission]="userPermission"
    [showLoadingOnComment]="showLoadingOnComment"
    (action)="action.emit($event)"
    class="less-margin"
    [class.animate-flicker]="animatedCommentId === commentReply.id"
    [mainContent]="mainContent"
    [id]="commentReply.id"></app-comment>
</section>

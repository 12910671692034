import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ITranslationRequest } from './content-translator.model';
import { ApiUrls } from '../api/api.urls.service';

interface TranslateResponse {
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContentTranslatorService {
  constructor(public http: HttpClient, private urls: ApiUrls) {}

  translate(translationRequest: ITranslationRequest): Observable<string> {
    return this.http
      .post<TranslateResponse>(this.urls.translate.translate, translationRequest)
      .pipe(map((t) => t.text));
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { ApiUrls } from '@services/api/api.urls.service';

import { Logger } from '../logger';
import { ENV } from 'src/environments/environment';
import { TokenCollection } from './token.helper.service';

export interface Token {
  token: string;
  expiration: number;
}

export enum SessionTokenTypes {
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  ACCESS_TOKEN = 'ACCESS_TOKEN'
}
export interface AccessTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  resource: string;
  refresh_token: string;
  refresh_token_expires_in: number;
  scope: string;
  id_token: string;
}

export interface AzureAccessTokenResponse {
  access_token: string;
  expires_in: number;
  ext_expires_in: number;
  refresh_token: string;
  id_token: string;
  token_type: string;
  scope: string;
}

export interface BearerToken {
  refreshToken: string;
  refreshTokenExpiration: number;
  privateToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private logger = new Logger('token provider');
  private currentRefreshPromise!: Promise<BearerToken> | null;
  private currentRenewPromise!: Promise<BearerToken> | null;
  private currentExpiredToken!: string;

  constructor(private http: HttpClient, private urls: ApiUrls) {}

  getRefreshToken(code: string, isAzureSession: boolean): Promise<BearerToken> {
    if (this.currentRefreshPromise) {
      this.logger.debug('Attempting to retrieve the same refresh token request, returning cached promise...');
      return this.currentRefreshPromise;
    }

    const body = this.#getRenewTokenParams(isAzureSession);
    body.set('code', code);
    body.set('grant_type', 'authorization_code');
    body.delete('resource');

    if (isAzureSession) {
      body.set('scope', 'openid');
      body.set('code_verifier', localStorage.getItem('code_verifier') || '');
      body.set('redirect_uri', ENV.azure.redirectUri);
    } else {
      body.set('scope', 'https://graph.microsoft.com/default');
      body.set('redirect_uri', ENV.authentication.redirectUri);
    }

    return this.#getRenewToken(isAzureSession, body);
  }

  resetRenewPromise() {
    this.currentRenewPromise = null;
  }

  renewToken(tokenCollection: TokenCollection, isAzureSession: boolean): Promise<BearerToken> {
    if (tokenCollection.token !== this.currentExpiredToken) {
      this.logger.debug('Expired token is different to current expired token, removing current request');
      this.currentRenewPromise = null;
    }

    if (this.currentRenewPromise) {
      this.logger.debug('Attempting to retrieve the same renew token request, returning cached promise...');
      return this.currentRenewPromise;
    }

    this.currentExpiredToken = tokenCollection.token;

    const body = isAzureSession ? this.#getRenewTokenAzureParams() : this.#getRenewTokenAdfsParams();

    body.set('refresh_token', tokenCollection.refreshToken);
    body.set('grant_type', SessionTokenTypes.REFRESH_TOKEN.toLowerCase());

    return this.#getRenewToken(isAzureSession, body);
  }

  #getTokenUrl(isAzureSession: boolean): string {
    return isAzureSession ? this.urls.azure.token : this.urls.adfs.token;
  }

  #getRenewTokenParams(isAzureSession: boolean) {
    return isAzureSession ? this.#getRenewTokenAzureParams() : this.#getRenewTokenAdfsParams();
  }

  #getRenewTokenAdfsParams(): URLSearchParams {
    const body = new URLSearchParams();
    body.set('client_id', ENV.authentication.clientId);
    body.set('resource', ENV.authentication.resource);

    return body;
  }

  #getRenewTokenAzureParams(): URLSearchParams {
    const body = new URLSearchParams();
    body.set('client_id', ENV.azure.clientId);
    body.set('scope', ENV.azure.scope + ' ' + ENV.azure.resource);

    return body;
  }

  #getHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  #mapBearerToken(data: AccessTokenResponse | AzureAccessTokenResponse, isAzure: boolean): BearerToken {
    const refreshTokenExpiration = isAzure
      ? (data as AzureAccessTokenResponse).ext_expires_in
      : (data as AccessTokenResponse).refresh_token_expires_in;

    return {
      refreshToken: data.refresh_token,
      refreshTokenExpiration,
      privateToken: data.access_token
    };
  }

  #getRenewToken(isAzureSession: boolean, body: URLSearchParams) {
    const renewTokenUrl = this.#getTokenUrl(isAzureSession);

    return lastValueFrom(
      this.http.post<AccessTokenResponse>(renewTokenUrl, body, this.#getHeaders()).pipe(
        finalize(() => {
          this.logger.debug('AUTH >>> getToken finalize');
          if (body.get('grant_type') === SessionTokenTypes.REFRESH_TOKEN.toLowerCase()) {
            this.currentRefreshPromise = null;
          } else {
            this.currentRenewPromise = null;
          }
        }),
        map((data) => {
          this.logger.info('AUTH >>> getToken', data);
          return this.#mapBearerToken(data, isAzureSession);
        }),
        catchError((error) => throwError(() => error))
      )
    );
  }
}

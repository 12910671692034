<div class="notification-filters" *ngIf="navTabsToLoad && notificationCategories.length > 1">
  <app-scrollable-x [scrollToElementID]="selectedTab">
    <app-nav-tabs
      *ngIf="navTabsToLoad"
      [selectedTab]="selectedTab"
      (tabSelect)="onSelectTab($event.detail.tabId)"
      class="notification-filters__tabs"
    >
      <app-nav-tab
        *ngFor="let tab of navTabsToLoad; trackBy: trackByItems; let i = index"
        [id]="tab.tabID"
        [tabId]="tab.tabID"
        [tabLabel]="tab.label"
        [tabIcon]="tab.tabIcon"
        [tabNotificationAdvice]="unreadNotifications[i]"
        class="notification-filters__tab"
        [class.is-popover]="!isMobile"
      >
      </app-nav-tab>
    </app-nav-tabs>
  </app-scrollable-x>
</div>

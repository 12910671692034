import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError, zip } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ApiUrls } from '@app/services/api/api.urls.service';
import { AppError, ErrorCodes, ErrorMessages, ErrorTypes } from '@app/services/error/error.model';
import { ErrorService } from '@app/services/error/error.service';
import { LanguageService } from '@app/services/language/language.service';
import { Logger } from '@app/services/logger';
import { ENCTYPE } from '@app/shared/constants/enctype/enctype.constants';
import { FilterModeEnum } from '@app/shared/enums/requests/filterMode.enum';
import { OrderingEnum } from '@app/shared/enums/requests/ordering.enum';
import {
  ExampleRequestStatusEnum,
  IExampleRequestParams
} from '@app/shared/interfaces/multimedia/example.request.interface';
import { IMultimediaService } from '@app/shared/interfaces/multimedia/multimedia.service.interface';
import { ExampleStatusResponse } from '@app/shared/interfaces/multimedia/my-examples.response.interface';
import { HttpOptions } from '@app/shared/models/http/http.options.model';
import { Example } from '@app/shared/models/multimedia/example.model';
import { MultimediaMetadata } from '@app/shared/models/multimedia/multimedia-metadata.model';
import { AbstractPaginatedMultimediaItemResponse } from '@app/shared/models/multimedia/multimedia.abstract.model';
import { MultimediaFactory } from '@app/shared/models/multimedia/multimedia.factory';
import { Pagination } from '@app/shared/models/multimedia/pagination.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Injectable({
  providedIn: 'root'
})
export class ExampleService implements IMultimediaService {
  private onReloadSubject = new Subject<void>();
  onReload$ = this.onReloadSubject.asObservable();
  private onError = new Subject<ErrorTypes>();
  onError$ = this.onError.asObservable();

  logger = new Logger('ExampleService');

  constructor(
    private http: HttpClient,
    private urls: ApiUrls,
    private languageService: LanguageService,
    private errorService: ErrorService
  ) {}

  create(formData: MSafeAny): Observable<MSafeAny> {
    const httpOptions = new HttpOptions(
      {},
      {
        // 'Content-Type': 'multipart/form-data',
        // XXX: There is a super strange problem due to the autogenerated boundary of the browser, force it
        // do not delete this!
        enctype: ENCTYPE.BOUNDARY
      }
    );
    return this.http
      .post(this.urls.example.route, formData, httpOptions)
      .pipe(map((item) => MultimediaFactory.createExample(item)));
  }

  edit(formData: MSafeAny, id: number): Observable<MSafeAny> {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'multipart/form-data',
        // XXX: There is a super strange problem due to the autogenerated boundary of the browser, force it
        // do not delete this!
        enctype: ENCTYPE.BOUNDARY
      })
    };

    return this.http
      .put(this.urls.example.put(id), formData, httpOptions)
      .pipe(map((item) => MultimediaFactory.createExample(item)));
  }

  // Creates a video into the Vimeo service. For more info, please check:
  // https://developer.vimeo.com/api/upload/videos
  createVimeoVideo(uploadLink: string, itemId: number, file: File | Blob, isPublication = false) {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      let headResponse;

      try {
        // 1. Patch request: upload video
        const patchResponse = await this.patchRequest(uploadLink, file).toPromise();
        this.logger.debug('PATCH request form vimeo success, checking with HEAD...', patchResponse);
      } catch (error) {
        this.logger.error('Error on PATCH request for vimeo:', error);
        reject(error);
      }

      try {
        // 2. Head request: Know if video has been successfully uploaded
        headResponse = await this.headRequest(uploadLink).toPromise();
        this.logger.debug('HEAD request success, checking headers...', headResponse);
      } catch (error) {
        this.logger.error('Error on HEAD request:', error);
        reject(error);
      }

      if (!this.isUploadSuccess(headResponse)) {
        const msgError = `Vimeo error length is different to offset`;
        this.logger.error(msgError);
        reject(msgError);
      }

      try {
        this.logger.debug(`Vimeo success length and offset is equal`);
        const response = isPublication
          ? await this.http
              .patch(this.urls.localPublication.patch(itemId), {
                video: {
                  vimeo_status: 'load'
                }
              })
              .toPromise()
          : await this.http.post(this.urls.example.videoLoaded(itemId), {}).toPromise();
        this.logger.debug('CMS video loaded success:', response);
        resolve(itemId);
      } catch (error) {
        this.logger.error('CMS video loaded error:', error);
        reject(error);
      }
    });
  }

  private patchRequest(uploadLink: string, file: File | Blob) {
    // Specific Http headers for patch request
    const patchHttpOptions = {
      headers: new HttpHeaders({
        'Tus-Resumable': '1.0.0',
        'Upload-Offset': '0',
        'Content-Type': 'application/offset+octet-stream'
      })
    };

    return this.http.patch(uploadLink, file, patchHttpOptions);
  }

  private headRequest(uploadLink: string) {
    return this.http.head(uploadLink, {
      headers: new HttpHeaders({
        'Tus-Resumable': '1.0.0'
      }),
      observe: 'response'
    });
  }

  private isUploadSuccess(headResponse: MSafeAny): boolean {
    const uploadLength = headResponse.headers.get('Upload-Length');
    const uploadOffset = headResponse.headers.get('Upload-Offset');
    return uploadLength === uploadOffset;
  }

  getExamplesWithHeaders(params: IExampleRequestParams): Observable<AbstractPaginatedMultimediaItemResponse<Example>> {
    return this.languageService.getCurrentLanguage().pipe(
      mergeMap((lang) => {
        params.language = lang;
        return this.http
          .get(this.urls.example.route, { observe: 'response', params: { ...params } } as MSafeAny)
          .pipe(catchError((err) => this.handleError(err)));
      }),
      map((response: MSafeAny) => {
        const pagination = new Pagination(response.headers);
        const lastViewedId = Number(response.headers.get('x-last-viewed'));
        const items = response.body.map((item: MSafeAny) => MultimediaFactory.createExample(item));
        return new AbstractPaginatedMultimediaItemResponse(items, pagination, lastViewedId);
      })
    );
  }

  getExamples(params: IExampleRequestParams): Observable<Example[]> {
    return this.getExamplesWithHeaders(params).pipe(
      map((response: AbstractPaginatedMultimediaItemResponse) => {
        return response.items as Example[];
      })
    );
  }

  getPages(params: IExampleRequestParams = {}) {
    return this.getExamplesWithHeaders(params).pipe(
      map((response: AbstractPaginatedMultimediaItemResponse) => {
        return response.pagination.count / response.pagination.limit;
      })
    );
  }

  deleteItem(id: number): Observable<MSafeAny> {
    return this.http.delete(this.urls.example.delete(id));
  }

  getItemWithMetadata(id: number): Observable<MultimediaMetadata<Example>> {
    return this.http.get(this.urls.example.get(id), { observe: 'response' }).pipe(
      map((response) => {
        const item = MultimediaFactory.createExample(response.body);
        return new MultimediaMetadata(item, response.headers);
      }),
      catchError((err) => {
        if (err.status === ErrorCodes.NOT_FOUND) {
          this.errorService.add(new AppError(ErrorTypes.NOT_FOUND, err));
        } else {
          this.errorService.add(new AppError(ErrorTypes.OTHER, err));
        }
        return throwError(err);
      })
    );
  }

  getItem(id: number): Observable<Example> {
    return this.getItemWithMetadata(id).pipe(map((i) => i.item));
  }

  updateItemVisualizations(id: number): Observable<number> {
    return this.http
      .post(this.urls.example.views(id), null)
      .pipe(map((result: MSafeAny) => parseInt(result.views, 10)));
  }

  sendToReview(exampleId: number): Observable<MSafeAny> {
    return this.http.post(this.urls.example.sendToReview(exampleId), null);
  }

  sendLike(exampleId: number, like: boolean): Observable<MSafeAny> {
    return this.http.post(this.urls.example.like(exampleId), { like });
  }

  // eslint-disable-next-line
  reloadExamples() {
    this.onReloadSubject.next(null as MSafeAny);
  }

  loadExamplesByStatus(status: ExampleRequestStatusEnum, amount: number, page = 0) {
    const params = this.getParams(status, amount, page);

    return this.getExamples(params);
  }

  loadExamplesByStatuses(statuses: ExampleRequestStatusEnum[], amount: number): Observable<ExampleStatusResponse[]> {
    const calls = statuses.map((status) => {
      const params = this.getParams(status, amount);
      return this.getExamplesWithHeaders(params).pipe(
        catchError((error) => of(new Error(error))),
        map((response) => {
          return {
            response,
            status
          };
        })
      );
    });

    return zip(...calls);
  }

  private getParams(status: ExampleRequestStatusEnum, amount: number, page = 0): IExampleRequestParams {
    const params: IExampleRequestParams = {
      filter_mode: FilterModeEnum.OWN,
      size: amount,
      status
    };

    const statusesOrderedByModification = [
      ExampleRequestStatusEnum.DRAFT,
      ExampleRequestStatusEnum.DENIED,
      ExampleRequestStatusEnum.PENDING
    ];
    if (statusesOrderedByModification.includes(status)) {
      params.ordering = OrderingEnum.DESC_MODIFICATION_DATE;
    }

    if (!page) {
      return params;
    }

    params.page = page;

    return params;
  }

  handleError = (error: HttpErrorResponse): Observable<MSafeAny[]> => {
    let errorType = ErrorTypes.OTHER;

    if (error.status === ErrorCodes.SECTION_MAINTENANCE && error.error.code === ErrorMessages.PERM_DEACTIVATED) {
      errorType = ErrorTypes.SECTION_MAINTENANCE;
    }

    this.onError.next(errorType);
    this.logger.error(error);

    return throwError(error);
  };
}

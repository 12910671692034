import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FilterTypes } from '@app/ptrab/shared/enums/filter-types.enum';
import { CarTransfer } from '@app/ptrab/shared/models/car-transfer';
import { Company } from '@app/ptrab/shared/models/company';
import { DropdownItem, IDropdownOption } from '@app/ptrab/shared/models/dropdown';
import { Payslip } from '@app/ptrab/shared/models/payslip';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { removeArrayDuplicates } from '@app/shared/utils/utils';

import { PayslipsFilterManager } from './payslips-filter-manager';

@Component({
  selector: 'app-app-ptrab-dropdown-filters',
  templateUrl: './dropdown-filters.component.html',
  styleUrls: ['./dropdown-filters.component.scss']
})
export class DropdownFiltersComponent implements OnInit {
  @Input() payslips: (Payslip | CarTransfer)[] = [];
  @Input() yearsInDropdown!: number;
  @Input() allowFilterByCompany!: boolean;
  @Input() defaultLiteralId!: string;
  @Output() filterChange = new EventEmitter();

  dateItems!: DropdownItem[];
  companyItems!: DropdownItem[];

  private dateFilter!: PayslipsFilterManager;
  private companyFilter!: PayslipsFilterManager;

  private filterType: IDropdownOption = {
    filter: FilterTypes.LAST,
    option: ''
  };

  private labelFilteredMonths!: string;
  private companyCode!: string;

  private customRange = {
    option: null,
    startDate: null,
    endDate: null
  };

  constructor(private datePipe: DatePipe) {}

  ngOnInit() {
    this.dateFilter = new PayslipsFilterManager(this.payslips, this.datePipe);
    this.buildCompanyDropdownItems();
    this.applyFilters();
  }

  private getUniqueCompanies(): Company[] {
    const companies = this.payslips.map((payslip: Payslip | CarTransfer) => {
      return payslip.company;
    });

    const uniqueCompaniesCodes: string[] = Array.from(new Set(companies.map((company) => company.code)));
    return uniqueCompaniesCodes.map((code) => companies.find((company: Company) => company.code === code)) as Company[];
  }

  private buildCompanyDropdownItems() {
    if (this.payslips.length === 0 || !this.allowFilterByCompany) {
      return;
    }

    const uniqueCompanies = this.getUniqueCompanies();
    this.companyItems = this.createDropdownItems(uniqueCompanies, 'name', 0);
    this.companyCode = this.companyItems[0].value.code;
  }

  private setDateDropdown(selectedOption?: MSafeAny) {
    let dropdownOptions: IDropdownOption[] = [];
    this.setDefaultOption(dropdownOptions, selectedOption);
    this.setYearsOptions(dropdownOptions);

    /* istanbul ignore next */
    if (this.labelFilteredMonths) {
      this.setCustomRangeFilter(dropdownOptions);
      return;
    }

    dropdownOptions = this.yearsInDropdown ? dropdownOptions.slice(0, this.yearsInDropdown + 1) : dropdownOptions;
    this.dateItems = this.createDropdownItems(dropdownOptions, 'option', 0);
  }

  private setDefaultOption(dropdownOptions: IDropdownOption[], selectedOption?: MSafeAny) {
    dropdownOptions.push({ option: this.defaultLiteralId, filter: FilterTypes.LAST });
    this.filterType = selectedOption ? selectedOption : dropdownOptions[0];
  }

  private setYearsOptions(dropdownOptions: IDropdownOption[]) {
    const filteredListByCompany = this.dateFilter.filterByCompany(this.companyCode);
    this.companyFilter = new PayslipsFilterManager(filteredListByCompany, this.datePipe);
    const payslipsfilter: PayslipsFilterManager = this.allowFilterByCompany ? this.companyFilter : this.dateFilter;
    const uniqueYears = removeArrayDuplicates(payslipsfilter.mapYears());

    for (const year of uniqueYears) {
      dropdownOptions.push({ option: year.toString(), filter: FilterTypes.YEARS });
    }
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  private setCustomRangeFilter(dropdownOptions: MSafeAny[]) {
    dropdownOptions.push({ option: this.customRange.option, filter: FilterTypes.CUSTOM_RANGE });
    this.dateItems = this.createDropdownItems(dropdownOptions, 'option', 8);
  }

  onSelectDropdownOption(filterType: IDropdownOption) {
    this.filterType = filterType;
    this.applyFilters();
  }

  onSelectCompany(company: Company) {
    const previousCompanyCode = this.companyCode;
    this.companyCode = company.code;

    if (previousCompanyCode !== this.companyCode) {
      this.setDateDropdown();
    }

    this.applyFilters();
  }

  private applyFilters(): void {
    let filteredList: (Payslip | CarTransfer)[] = [];
    const filteredListByCompany = this.dateFilter.filterByCompany(this.companyCode);
    this.companyFilter = new PayslipsFilterManager(filteredListByCompany, this.datePipe);
    const payslipsfilter: PayslipsFilterManager = this.allowFilterByCompany ? this.companyFilter : this.dateFilter;

    switch (this.filterType.filter) {
      case FilterTypes.LAST:
        this.labelFilteredMonths = '';
        this.setDateDropdown();
        filteredList = payslipsfilter.filterByLastMonths(6);
        return this.filterChange.emit(filteredList);

      case FilterTypes.CUSTOM_RANGE:
        if (this.customRange && this.customRange.startDate && this.customRange.endDate) {
          filteredList = payslipsfilter.filterByRange(this.customRange.startDate, this.customRange.endDate);
        }
        return this.filterChange.emit(filteredList);

      default:
        this.labelFilteredMonths = '';
        filteredList = payslipsfilter.filterByYear(parseInt(this.filterType.option as string, 10));
        this.filterChange.emit(filteredList);
    }
  }

  hasCompanies() {
    return this.companyItems && this.companyItems.length > 1;
  }

  private createDropdownItems(values: MSafeAny[], labelName: string, indexSelected: number): DropdownItem[] {
    return values.map(
      (value, index) =>
        new DropdownItem({
          value,
          label: value[labelName],
          selected: indexSelected === index
        })
    );
  }
}

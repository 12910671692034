import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @Input() placeholder: string = '';
  @Input() disabled!: boolean;
  @Input() readonly!: boolean;
  @Input() maxlength!: number;
  @Input() model!: MSafeAny;
  @Output() modelChange = new EventEmitter();
  @Output() focusInput = new EventEmitter();

  onModelChange() {
    this.modelChange.emit(this.model);
  }

  onFocus() {
    this.focusInput.emit();
  }
}

<div class="container" [class.is-webview]="isWebview">
  <section class="icons-container">
    <ion-icon class="close" name="close" (click)="closeGallery()"></ion-icon>
  </section>
  <section class="slides-container" [class.fullscreen-player]="setFullScreen">
    <app-slider
      [image]="images"
      [multimediaCarousel]="multimediaCarousel"
      [auxUpdateCarrousel]="auxUpdateCarrousel"
      [allowLoop]="false"
      [noBackground]="true"
      [allowZoom]="true"
      [isWebview]="isWebview"
      [spaceBetween]="spaceBetweenImages"
      (enterFullscreenVideo)="enterFullScreenVideo($event)"
      (slideChangeCarousel)="onSlideChange($event)"
      class="carousel-multimedia"
      #slides
    >
    </app-slider>
  </section>
  <div *ngIf="isSliderWorking" class="placeholder"></div>
</div>

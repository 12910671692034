/* eslint-disable @typescript-eslint/naming-convention */

export enum TIME_STAMPS {
  minute = 60 * 1000,
  hour = minute * 60,
  day = hour * 24,
  month = day * 30,
  year = day * 365
}

export enum TIME {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  YESTERDAY = 'YESTERDAY',
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}

export interface RelativeDate {
  timePast: number;
  timeStamp: string;
}

export class DateDifference {
  static timeDifference(creationDate: string, currentDate: Date): RelativeDate {
    const commentCreationDate = new Date(creationDate);
    const difference = Math.max(currentDate.getTime() - commentCreationDate.getTime(), 0);

    const relativeDate: RelativeDate = {
      timePast: 0,
      timeStamp: ''
    };

    switch (Boolean(creationDate)) {
      case difference < TIME_STAMPS.minute:
        relativeDate['timePast'] = Math.trunc(difference / 1000);
        relativeDate['timeStamp'] = relativeDate['timePast'] === 1 ? TIME.SECOND : TIME.SECONDS;
        break;
      case difference < TIME_STAMPS.hour:
        relativeDate['timePast'] = Math.trunc(difference / TIME_STAMPS.minute);
        relativeDate['timeStamp'] = relativeDate['timePast'] === 1 ? TIME.MINUTE : TIME.MINUTES;
        break;
      case difference < TIME_STAMPS.day:
        relativeDate['timePast'] = Math.trunc(difference / TIME_STAMPS.hour);
        relativeDate['timeStamp'] = relativeDate['timePast'] === 1 ? TIME.HOUR : TIME.HOURS;
        break;
      case difference < TIME_STAMPS.month:
        relativeDate['timePast'] = Math.trunc(difference / TIME_STAMPS.day);
        relativeDate['timeStamp'] = relativeDate['timePast'] === 1 ? TIME.YESTERDAY : TIME.DAYS;
        break;
      case difference < TIME_STAMPS.year:
        relativeDate['timePast'] = Math.trunc(difference / TIME_STAMPS.month);
        relativeDate['timeStamp'] = relativeDate['timePast'] === 1 ? TIME.MONTH : TIME.MONTHS;
        break;
      default:
        relativeDate['timePast'] = Math.trunc(difference / TIME_STAMPS.year);
        relativeDate['timeStamp'] = relativeDate['timePast'] === 1 ? TIME.YEAR : TIME.YEARS;
        break;
    }
    return relativeDate;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
import { Comment } from '@shared/models/comment/comment';

import { ApiUrls } from '../api/api.urls.service';
/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  private readonly defaultCommentsPageSize = 10;

  constructor(private apiUrls: ApiUrls, private http: HttpClient, private translate: TranslateService) {}

  getComments(
    content_id: number,
    pageRequest: number,
    last_viewed_id?: number,
    pageSize = this.defaultCommentsPageSize
  ): Observable<MSafeAny> {
    const params = {
      content_id,
      page: pageRequest,
      size: pageSize,
      last_viewed_id
    };
    Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);

    const options: MSafeAny = { observe: 'response', params: { ...params } };
    return this.http.get(this.apiUrls.comment.comments, options).pipe(
      map((response: MSafeAny) => {
        const parsedResponse = {
          comments: response.body,
          commentsCount: parseInt(response.headers.get('x-pagination-count'), 10),
          commentsByPage: parseInt(response.headers.get('x-pagination-limit'), 10),
          totalPages: this.setMaxPages(response)
        };
        return parsedResponse;
      })
    );
  }

  getCommentsHighlighted(content_id: number): Observable<MSafeAny> {
    const params = {
      content_id
    };
    Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);

    const options: MSafeAny = { observe: 'response', params: { ...params } };
    return this.http.get(this.apiUrls.comment.comments_highlighted, options).pipe(
      map((response: MSafeAny) => {
        const parsedResponse = {
          comments: response.body
        };
        return parsedResponse;
      })
    );
  }

  getCommentById = (comment_id: number) => this.http.get<Comment>(this.apiUrls.comment.get(comment_id));

  createComment(
    content_id: number,
    comment: string,
    parent_comment_id?: number,
    representedUser?: string
  ): Observable<MSafeAny> {
    const payload: MSafeAny = { content_id, comment, language: this.translate.currentLang };

    if (parent_comment_id) {
      payload.parent_id = parent_comment_id;
    }
    if (representedUser) {
      payload.as_user = representedUser;
    }

    return this.http.post(this.apiUrls.comment.comments, payload);
  }

  deleteComment(comment_id: number): Observable<MSafeAny> {
    return this.http.delete(this.apiUrls.comment.delete(comment_id));
  }

  updateComment(comment_id: number, comment: string): Observable<MSafeAny> {
    return this.http.put(this.apiUrls.comment.update(comment_id), { comment });
  }

  private setMaxPages(response: MSafeAny): number {
    const count = parseInt(response.headers.get('x-pagination-count'), 10);
    const limit = parseInt(response.headers.get('x-pagination-limit'), 10);
    const total = count / limit;
    return Math.ceil(total);
  }
}

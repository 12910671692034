import { IWizardSection } from '@shared/interfaces/model/model-wizard-section.interface';

export const WIZARD_SECTIONS: IWizardSection[] = [
  {
    title: 'MODEL_WIZARD.WELCOME_TITLE',
    image: 'assets/imgs/model-wizard/wizard-0.svg',
    image_class: 'wizard-image-0',
    text: 'MODEL_WIZARD.WELCOME_CONTENT',
    next: 'MODEL_WIZARD.BUTTON_NEXT'
  },
  {
    title: 'MODEL_WIZARD.SECTIONS_TITLE',
    image: 'assets/imgs/model-wizard/wizard-1.svg',
    image_class: 'wizard-image-1',
    text: 'MODEL_WIZARD.SECTIONS_CONTENT',
    back: 'MODEL_WIZARD.BUTTON_BACK',
    next: 'MODEL_WIZARD.BUTTON_NEXT',
    active: false
  },
  {
    title: 'MODEL_WIZARD.START_TITLE',
    image: 'assets/imgs/model-wizard/wizard-2.svg',
    image_class: 'wizard-image-2',
    text: 'MODEL_WIZARD.START_CONTENT',
    back: 'MODEL_WIZARD.BUTTON_BACK',
    next: 'MODEL_WIZARD.BUTTON_NEXT',
    active: false
  },
  {
    title: 'MODEL_WIZARD.FORWARD_TITLE',
    image: 'assets/imgs/model-wizard/wizard-3.svg',
    image_class: 'wizard-image-3',
    text: 'MODEL_WIZARD.FORWARD_CONTENT',
    back: 'MODEL_WIZARD.BUTTON_BACK',
    next: 'MODEL_WIZARD.BUTTON_FINISH',
    active: false
  }
];

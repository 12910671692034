import { HttpHeaders, HttpParams } from '@angular/common/http';

import { MSafeAny } from '../safe-any/safe-any.model';

interface ParamsInput {
  [param: string]: string | number;
}

export class HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };

  observe?: 'body';

  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };

  reportProgress?: boolean;

  responseType?: 'json';

  withCredentials?: boolean;

  constructor(params: ParamsInput, headers?: MSafeAny) {
    if (params) {
      this.params = this.buildParams(params);
    }
    if (headers) {
      this.headers = new HttpHeaders(headers);
    }
  }

  buildParams(params: ParamsInput = {}): HttpParams {
    let httpParams = new HttpParams();

    Object.keys(params)
      .filter((param) => params[param])
      .forEach((param) => {
        httpParams = httpParams.set(param, params[param].toString());
      });

    return httpParams;
  }
}

<ng-template #MultimediaDetail>
  <article *ngIf="item && !isLoadingItem" class="animated animated-fix-fullscreen fadeIn">
    <h1 *ngIf="!isPublicMultimedia">{{ item.internal_title || item.title }}</h1>
    <app-message-info
      *ngIf="!isPublicMultimedia && canShowStatusInfo"
      [title]="statusInfoTitle | translate"
      [message]="item.status_info"></app-message-info>
    <div
      *ngIf="!isPublicMultimedia && isPublicOrOfficialContent()"
      class="detail__info"
      [class.only-views]="!item?.likes_allowed"
      [ngPlural]="item.views">
      <div *ngIf="item?.likes_allowed" class="detail__info-likes">
        <span class="spacing">
          {{ item.likes | number }}
        </span>
        <span>{{ 'LIKES.COUNT' | translate | ucfirst }}</span>
      </div>
      <div class="detail__info-views">
        <span>
          {{ item.views | number }}
        </span>
        <span class="spacing">
          <ng-template ngPluralCase="=1">{{ 'VIEW' | translate | ucfirst }}</ng-template>
          <ng-template ngPluralCase="other">{{ 'VIEWS' | translate | ucfirst }}</ng-template>
        </span>
      </div>
    </div>

    <div class="container-multimedia-slider" [class.fullscreen-player]="setFullScreen">
      <app-slider
        #slider
        class="carousel-multimedia"
        [image]="item.multiple"
        [multimediaCarousel]="multimediaCarousel"
        [auxUpdateCarrousel]="auxUpdateCarrousel"
        [updateMultimedia]="updateMultimedia"
        (enterFullscreenVideo)="enterFullScreenVideo($event)"
        (setFullscreenCarousel)="emitFullScreen($event)"
        (slideChangeCarousel)="onSlideChange($event)">
      </app-slider>
    </div>

    <app-multimedia-actions
      *ngIf="!isPublicMultimedia && !isWebView"
      [item]="item"
      [analyticsCategory]="analyticsCategory"
      (commentsClickedEvent)="goToComments()"
      (sendLikeEvent)="like()"></app-multimedia-actions>

    <app-multimedia-detail-description
      #description
      *ngIf="!isPublicMultimedia && item.text !== ''"
      class="description"
      [multimediaItem]="item">
    </app-multimedia-detail-description>

    <app-multimedia-detail-author
      *ngIf="!isPublicMultimedia && !item.isOfficialContent()"
      [exampleItem]="item"
      [detailRenderMode]="detailRenderMode"
      class="author-info"
      [class.no-border-top]="item.text === '' ? true : null">
    </app-multimedia-detail-author>

    <app-comments-section
      *ngIf="!isPublicMultimedia && item.isOfficialContent()"
      [class.no-border-top]="item.text === '' ? true : null"
      (scrollToTop)="goToComments($event)"
      [publicationItem]="item"
      [userPermission]="userCommentPermission"
      [mainContent]="mainContent"
      [userIsOwner]="userIsOwner"
      [userIsPresident]="userIsPresident"
      [analyticsCategory]="analyticsCategory">
    </app-comments-section>
  </article>
  <app-modal-placeholder *ngIf="isLoadingItem"></app-modal-placeholder>
</ng-template>

<ng-container *ngIf="isDetailRenderModeModal()">
  <app-content-modal [buttons]="draftButtons" (closeModal)="close()">
    <ng-template [ngTemplateOutlet]="MultimediaDetail"></ng-template>
  </app-content-modal>
</ng-container>

<ng-container *ngIf="isDetailRenderModePage()">
  <ng-template [ngTemplateOutlet]="MultimediaDetail"></ng-template>
</ng-container>

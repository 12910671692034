import { Permissions } from '@shared/enums/permissions/permissions.enum';
import { NavTab } from '@shared/interfaces/nav-tab/nav-tab.interface';
import { DisabilitiesPt, YieldsOwnership } from '../shared/enums/irs.enum';
import { RadioItem } from '../shared/models/radio';

export enum IrpfTabs {
  IRPF_DATA = 'IRPF_DATA',
  WITHHOLDING_CERTIFICATE = 'WITHHOLDING_CERTIFICATE',
  VOLUNTARY_WITHHOLDING = 'VOLUNTARY_WITHHOLDING',
  PERCENTAGE_READJUSTMENT = 'PERCENTAGE_READJUSTMENT',
  IRPF_INFO = 'IRPF_INFO'
}

export enum IrpfSections {
  WORKER = 'EMPLOYEE_PORTAL.WORKER',
  SPOUSE = 'EMPLOYEE_PORTAL.SPOUSE',
  DEPENDANTS = 'EMPLOYEE_PORTAL.DEPENDANTS',
  YOUNG_BENEFIT = 'EMPLOYEE_PORTAL.YOUNG_BENEFIT',
  DESCENDANT = 'EMPLOYEE_PORTAL.DESCENDANTS',
  ANCESTOR = 'EMPLOYEE_PORTAL.ANCESTORS.NAME',
  PENSION_PAYMENT = 'EMPLOYEE_PORTAL.PENSION_PAYMENT.NAME',
  MORTGAGE_REDUCTION = 'EMPLOYEE_PORTAL.MORTGAGE_REDUCTION',
  CEUTA_MELILLA_RESIDENCE = 'EMPLOYEE_PORTAL.CEUTA_MELILLA_RESIDENCE',
  YOUNG_WITHHOLDING = 'EMPLOYEE_PORTAL.YOUNG_WITHHOLDING.TITLE'
}

export const IRPF_NAV_TABS: NavTab[] = [
  {
    tabID: IrpfTabs.IRPF_DATA,
    label: 'EMPLOYEE_PORTAL.PIT_DATA',
    tabIcon: 'icon-irpf-data',
    permissions: [Permissions.IRPF]
  },
  {
    tabID: IrpfTabs.WITHHOLDING_CERTIFICATE,
    label: 'EMPLOYEE_PORTAL.WITHHOLDING_CERTIFICATE',
    tabIcon: 'icon-certificates',
    permissions: [Permissions.WITHHOLDING_CERTIFICATE, Permissions.EXEMPLOYEE_WITHHOLDING]
  },
  {
    tabID: IrpfTabs.VOLUNTARY_WITHHOLDING,
    label: 'EMPLOYEE_PORTAL.VOLUNTARY_WITHHOLDING.TITLE',
    tabIcon: 'icon-voluntary-withholding',
    permissions: [Permissions.VOLUNTARY_WITHHOLDING]
  },
  {
    tabID: IrpfTabs.PERCENTAGE_READJUSTMENT,
    label: 'EMPLOYEE_PORTAL.PERCENTAGE_READJUSTMENT.NAME',
    tabIcon: 'icon-readjustment',
    permissions: [Permissions.PERCENTAGE_READJUSTMENT],
    hidden: true
  },
  {
    tabID: IrpfTabs.IRPF_INFO,
    label: 'EMPLOYEE_PORTAL.WHAT_IS_PIT.TITLE',
    tabIcon: 'icon-irpf',
    permissions: [Permissions.WHAT_IS_IRPF]
  }
];

export const IRS_NAV_TABS: NavTab[] = [
  { ...IRPF_NAV_TABS[0], label: 'EMPLOYEE_PORTAL.PIT_DATA' },
  { ...IRPF_NAV_TABS[1], label: 'EMPLOYEE_PORTAL.WITHHOLDING_CERTIFICATE' },
  { ...IRPF_NAV_TABS[2], label: 'EMPLOYEE_PORTAL.VOLUNTARY_WITHHOLDING.TITLE' },
  { ...IRPF_NAV_TABS[3], label: 'EMPLOYEE_PORTAL.PERCENTAGE_READJUSTMENT.NAME' },
  { ...IRPF_NAV_TABS[4], label: 'EMPLOYEE_PORTAL.IRS_INFO.TAB_LABEL' }
];

export const DISABILITY_OPTIONS: RadioItem<number>[] = [
  { label: 'EMPLOYEE_PORTAL.NONE', value: 0 },
  { label: 'EMPLOYEE_PORTAL.BETWEEN_33_AND_65', value: 1 },
  { label: 'EMPLOYEE_PORTAL.65_DISABILITY_OR_MORE', value: 2 }
];

export const DISABILITY_PT_OPTIONS: RadioItem<number>[] = [
  { label: 'EMPLOYEE_PORTAL.NONE', value: DisabilitiesPt.NO.getIndex() },
  { label: 'EMPLOYEE_PORTAL.UNDER_60', value: DisabilitiesPt.UNDER_60.getIndex() },
  { label: 'EMPLOYEE_PORTAL.OVER_60', value: DisabilitiesPt.OVER_60.getIndex() }
];

export const YIELDS_PT_OPTIONS: RadioItem<number>[] = [
  { label: 'EMPLOYEE_PORTAL.SINGLE_HOLDER', value: YieldsOwnership.SINGLE },
  {
    label: 'EMPLOYEE_PORTAL.MULTIPLE_HOLDERS_ONE_OVER_95',
    value: YieldsOwnership.MULTIPLE_ONE_OVER_95,
    extraInfo: true
  },
  { label: 'EMPLOYEE_PORTAL.MULTIPLE_HOLDERS', value: YieldsOwnership.MULTIPLE }
];

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { Company } from './company';
import { PayslipTypes } from '../enums/payslip-types.enum';
/* eslint-disable @typescript-eslint/naming-convention */

export class Payslip {
  id: string;
  company: Company;
  is_bonus: boolean;
  start_date: string;
  end_date: string;
  type: PayslipTypes;

  constructor(data: MSafeAny) {
    this.id = data.id;
    this.company = new Company(data.company);
    this.is_bonus = data.is_bonus;
    this.start_date = data.start_date;
    this.end_date = data.end_date;
    this.type = data.type;
  }
}

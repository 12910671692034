<app-input-wrapper [label]="label">
  <app-input
    [placeholder]="placeholder"
    [model]="searchTerm"
    [disabled]="disabled"
    (modelChange)="onModelChange($event)"
    (click)="showDropdown()">
    <img alt="arrow-down" src="assets/imgs/arrow-down/ic_caret_down.svg" *ngIf="!searchTerm" />
    <img
      class="c-pointer"
      [class.is-disabled]="disabled"
      alt="close-icon"
      src="assets/imgs/ic_close/ic_close.svg "
      *ngIf="searchTerm"
      (click)="onCloseIconClick($event)"
  /></app-input>
</app-input-wrapper>

<div #dropdown_item_list class="list dropdown animated fadeIn faster" [hidden]="!toggle">
  <app-item
    class="dropdown-item"
    id="item-{{ item.value }}"
    *ngFor="let item of itemsFiltered; trackBy: trackByItems"
    (click)="selectItem(item)">
    <div class="content">
      <h6 class="u-dark-grey" [innerHTML]="item.label"></h6>
      <img *ngIf="item.selected" item-end src="assets/imgs/employee-portal/green-check.svg" alt="Check" />
    </div>
  </app-item>

  <app-item class="dropdown-item item-disabled" *ngIf="!itemsFiltered.length">
    <h6 class="u-dark-grey">{{ 'SEARCH_NO_RESULTS' | translate }}</h6>
  </app-item>
</div>

import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';

import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { NOTIFICATION_CATEGORIES, NotificationsTabs } from '@app/shared/enums/notification/notification.enum';
import { Notification } from '@app/shared/models/notification/notification.model';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnChanges {
  @Input() notificationsList!: Notification[];
  @Input() filterBy!: string;
  @Output() removeItem = new EventEmitter();
  @Output() performAction = new EventEmitter();
  filteredNotificationsList!: Notification[];

  constructor(private analyticsService: AnalyticsService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.filterBy) {
      return;
    }

    if (changes.filterBy) {
      this.filterBy = changes.filterBy.currentValue;
    }

    if (changes.notificationsList) {
      this.notificationsList = changes.notificationsList.currentValue;
    }

    this.filterNotificationsByType(NOTIFICATION_CATEGORIES.indexOf(NotificationsTabs[this.filterBy]));
  }

  filterNotificationsByType(filter: number) {
    this.filteredNotificationsList = this.notificationsList.filter((notification) => {
      return notification.category === filter;
    });
  }

  removeNotification(id: string) {
    this.removeItem.emit(id);
  }

  notificationAction(notification: Notification) {
    this.performAction.emit(notification);

    this.analyticsService.sendEvent(CategoriesAnalytics.CLICK_NOTIFICATION, {
      [ActionsAnalytics.CLICKACTION]: notification.id,
      [ActionsAnalytics.TYPE]: CategoriesAnalytics.LIST
    });
  }

  trackByItems(item: Notification) {
    return item;
  }
}

import { Component, Input } from '@angular/core';

import { Withholding } from '@app/ptrab/shared/models/withholding';

import { BaseWithholdingInfoComponent } from '../../base-voluntary-withholding/base-withholding-info/withholding-info.component';

@Component({
  selector: 'app-ptrab-withholding-info-es',
  templateUrl: './withholding-info-es.component.html',
  styleUrls: ['./withholding-info-es.component.scss']
})
export class WithholdingInfoEsComponent extends BaseWithholdingInfoComponent {
  @Input() withholding!: Withholding;
}

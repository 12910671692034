<app-content-modal class="hide-overflow" [buttons]="buttons" [hideClose]="true">
  <h1 class="title">{{ titleKey | translate }}</h1>
  <div [class.reverse-display]="hasAction">
    <div>
      <p class="description">
        {{ infoMsgKey | translate }}
      </p>
      <p class="description">
        {{ descriptionKey | translate }}
      </p>
      <p *ngIf="emailQuestion" class="description">
        {{ emailQuestion | translate }}
        <span class="link" (click)="openPage()">{{ personalDataLink | translate }}</span>
      </p>
    </div>
    <img class="image" [src]="imageSuccess" />
  </div>
</app-content-modal>

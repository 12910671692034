import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { DEFAULT_LANGUAGE, LANGUAGES, Language } from '@shared/constants/language/language.const';

interface LanguageProp extends Language {
  label: string;
  checked: boolean;
}

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnChanges {
  @Input()
  languageCode!: string;
  @Output()
  sendChoice = new EventEmitter<Language>();

  languages: LanguageProp[] = [];
  currentLanguage!: LanguageProp;
  defaultLanguage: LanguageProp;

  constructor() {
    this.languages = this.getLanguages();
    this.defaultLanguage = this.languages.find(
      (languageProp) => languageProp.code === DEFAULT_LANGUAGE
    ) as LanguageProp;
  }

  ngOnChanges() {
    this.unselectAll();
    this.preselectUserLanguage();
  }

  selectedOption({ code, country }) {
    const languageEmit: Language = { code, country };
    this.checkedLanguage(code);
    this.sendChoice.emit(languageEmit);
  }

  trackByItems(item: LanguageProp) {
    return item;
  }

  private checkedLanguage(code) {
    this.languages.forEach((language) => (language.checked = language.code === code));
  }

  private unselectAll() {
    this.languages.forEach((language) => (language.checked = false));
  }

  private getLanguages(): LanguageProp[] {
    return Object.keys(LANGUAGES).map((language) => {
      return {
        code: LANGUAGES[language].code,
        country: LANGUAGES[language].country,
        label: `PROFILE.LANGUAGES.${LANGUAGES[language].code.toUpperCase()}`,
        checked: false
      };
    });
  }

  private preselectUserLanguage() {
    const language = this.languages.find((languageProp) => languageProp.code === this.languageCode);
    if (language) {
      language.checked = true;
      this.currentLanguage = language;
    } else {
      this.currentLanguage = this.defaultLanguage;
    }
  }
}

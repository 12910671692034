<div class="grid-fixed">
  <div class="block-close" tappable (click)="closeCookiesModal()">
    <img alt="icon_close" src="assets/imgs/ic_close/ic_close.svg" />
  </div>
  <p class="title">{{ 'COOKIE_MODAL.TITLE' | translate }}</p>

  <div class="container-cookie-info">
    <div tappable (click)="displayInfoCookie('essentials')" class="content-info">
      <p class="title medium">{{ 'COOKIE_MODAL.OWN_COOKIES' | translate }}</p>
      <img id="icon-essentials" class="arrow" alt="arrow-down" src="assets/imgs/arrow-down/ic_caret_down.svg" />
    </div>

    <div class="content-info flex">
      <span>{{ 'COOKIE_MODAL.OWN_COOKIES_TEXT' | translate }}</span>
      <span class="align-center">{{ 'COOKIE_MODAL.ALWAYS_ENABLED' | translate }}</span>
    </div>

    <div id="info-essentials" class="info">
      <table>
        <th id="cookie">{{ 'COOKIE_MODAL.TABLE.cookie' | translate }}</th>
        <th id="property">{{ 'COOKIE_MODAL.TABLE.property' | translate }}</th>
        <th id="use">{{ 'COOKIE_MODAL.TABLE.use' | translate }}</th>
        <th id="expired">{{ 'COOKIE_MODAL.TABLE.expired' | translate }}</th>
        <tr *ngFor="let cookie of cookiesEssentials; trackBy: trackByItems">
          <td>{{ cookie.name | translate }}</td>
          <td>{{ cookie.value | translate }}</td>
          <td>{{ cookie.desc | translate }}</td>
          <td>{{ cookie.timeExpired | translate }}</td>
        </tr>
      </table>
    </div>
  </div>

  <div class="container-cookie-info">
    <div tappable (click)="displayInfoCookie('notEssentials')" class="content-info">
      <p class="title medium">{{ 'COOKIE_MODAL.OWN_NOT_COOKIES' | translate }}</p>
      <img id="icon-notEssentials" class="arrow" alt="arrow-down" src="assets/imgs/arrow-down/ic_caret_down.svg" />
    </div>
    <div class="content-info flex">
      <span>{{ 'COOKIE_MODAL.OWN_NOT_COOKIES_TEXT' | translate }}</span>
      <div class="toggle">
        <p>{{ 'COOKIE_MODAL.TOGGLE_NO' | translate }}</p>
        <app-toggle [checked]="cookies.notEssentialsCookies" (toggleChange)="onChangeCookiesState($event)"></app-toggle>
        <p>{{ 'COOKIE_MODAL.TOGGLE_YES' | translate }}</p>
      </div>
    </div>

    <div id="info-notEssentials" class="info noflex">
      <table>
        <th id="cookie">{{ 'COOKIE_MODAL.TABLE.cookie' | translate }}</th>
        <th id="property">{{ 'COOKIE_MODAL.TABLE.property' | translate }}</th>
        <th id="use">{{ 'COOKIE_MODAL.TABLE.use' | translate }}</th>
        <th id="expired">{{ 'COOKIE_MODAL.TABLE.expired' | translate }}</th>
        <tr *ngFor="let cookie of cookiesNotEssentials; trackBy: trackByItems">
          <td>{{ cookie.name | translate }}</td>
          <td>{{ cookie.value | translate }}</td>
          <td>{{ cookie.desc | translate }}</td>
          <td>{{ cookie.timeExpired | translate }}</td>
        </tr>
      </table>
    </div>
  </div>

  <div class="link-buttons-container">
    <div class="buttons">
      <button (click)="goToCookiesPage()">{{ 'COOKIE_MODAL.MORE_INFO' | translate }}</button>
      <button (click)="closeCookiesModal()">{{ 'CANCEL' | translate }}</button>
      <button (click)="updateCookies()">{{ 'ACCEPT' | translate }}</button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { AppError, ErrorTypes } from './error.model';
import { Logger } from '../logger';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private readonly maxRetryTimes = 2;
  private logger = new Logger('errorProvider');
  private resetTimer!: Subscription;

  private subjectError: Subject<AppError> = new Subject();
  error$: Observable<AppError> = this.subjectError.asObservable();

  retryTimes = 0;
  lastError!: AppError | null;

  removeErrors() {
    this.lastError = null;
  }

  reset() {
    this.logger.info('Reset error provider');
    this.removeErrors();
    this.resetRetryTimes();
  }

  hasError(): boolean {
    return Boolean(this.lastError);
  }

  getLastErrorType(): ErrorTypes | null {
    return this.lastError?.type || null;
  }

  add(error: AppError, propagate = true) {
    if (this.lastError && this.lastError.isAuthError()) {
      return;
    }
    this.lastError = error;
    if (propagate) {
      this.subjectError.next(error);
    }
  }

  addRetry() {
    this.retryTimes++;
    if (this.resetTimer) {
      this.resetTimer.unsubscribe();
    }
    this.resetTimer = of(null)
      .pipe(delay(90000))
      .subscribe(() => {
        this.logger.debug('Automatic counter reset');
        this.resetRetryTimes();
      });
  }

  resetRetryTimes() {
    this.logger.debug('Do reset counter');
    if (this.resetTimer) {
      this.resetTimer.unsubscribe();
    }
    this.retryTimes = 0;
  }

  isRetryTimesExceeded() {
    return this.maxRetryTimes < this.retryTimes;
  }

  isCritical() {
    return this.lastError && this.lastError.isCritical();
  }

  isAuthError() {
    return this.lastError && this.lastError.isAuthError();
  }
}

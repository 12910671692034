export const HEADER_HEIGHT = {
  DESKTOP: 112,
  MOBILE: 85,
  ON_SCROLL: 72,
  MODEL: 280
};

export const HEADER_CORRECTIONS = {
  NOTIFICATION_CORRECTION: 10,
  NOTIFICATION_CORRECTION_IPAD: 45,
  PROFILE_CORRECTION: 15,
  MORE_CORRECTION: 15
};

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {
  @ViewChild('mainContent', { read: ElementRef }) mainContent!: ElementRef;
  @Input() infiniteScrollSelector!: string;
  @Output() scrolled = new EventEmitter<Event>();

  onScroll(event: Event) {
    this.scrolled.emit(event);
  }

  scrollTo(position: number) {
    this.mainContent.nativeElement.scrollTo({
      top: position,
      behavior: 'smooth'
    });
  }

  scrollToPoint(positionX: number, positionY: number) {
    this.mainContent.nativeElement.scrollTo({
      left: positionX,
      top: positionY,
      behavior: 'smooth'
    });
  }

  scrollToTop() {
    this.mainContent.nativeElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  scrollToBottom() {
    this.mainContent.nativeElement.scrollTo({
      bottom: 0,
      behavior: 'smooth'
    });
  }
}

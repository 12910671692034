import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

enum VideoFormats {
  MP4 = 'video/mp4',
  XMS = 'video/x-msvideo',
  MPEG = 'video/mpeg',
  OGG = 'video/ogg',
  WEBM = 'video/webm',
  GPP = 'video/3gpp',
  GPP2 = 'video/3gpp2',
  MOV = 'video/quicktime'
}

enum ImageFormats {
  GIF = 'image/gif',
  ICON = 'image/x-icon',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
  TIFF = 'image/tiff',
  WEBP = 'image/webp',
  PNG = 'image/png'
}

export class FileFormatsHelper {
  isImage(fileType: MSafeAny): boolean {
    return (
      fileType === ImageFormats.GIF ||
      fileType === ImageFormats.ICON ||
      fileType === ImageFormats.JPEG ||
      fileType === ImageFormats.SVG ||
      fileType === ImageFormats.TIFF ||
      fileType === ImageFormats.WEBP ||
      fileType === ImageFormats.PNG
    );
  }

  isVideo(fileType: MSafeAny): boolean {
    return (
      fileType === VideoFormats.GPP ||
      fileType === VideoFormats.GPP2 ||
      fileType === VideoFormats.MP4 ||
      fileType === VideoFormats.MPEG ||
      fileType === VideoFormats.OGG ||
      fileType === VideoFormats.WEBM ||
      fileType === VideoFormats.XMS ||
      fileType === VideoFormats.MOV
    );
  }
}

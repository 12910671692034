import { Attribute, Component, Input } from '@angular/core';

@Component({
  selector: 'app-quote-phrase',
  templateUrl: './quote-phrase.component.html',
  styleUrls: ['./quote-phrase.component.scss']
})
export class QuotePhraseComponent {
  @Input() quoteText!: string;
  @Input() darkMode!: boolean;
  @Input() noMaxWidth = false;

  public isWhite = false;

  constructor(@Attribute('white') public white = '') {
    this.isWhite = white !== null && white !== undefined;
  }
}

import { Directive, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { MediaPlayerComponent } from '@app/components/media-player/media-player.component';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { ErrorTypes } from '@app/services/error/error.model';
import { Logger } from '@app/services/logger/logger.service';
import { MediaPlayerService } from '@app/services/media-player/media-player.service';
import { PublicationService } from '@app/services/multimedia/publication.service';
import { NetworkService } from '@app/services/network/network.service';
import { StorageService } from '@app/services/storage';
import { Publication } from '@app/shared/models/multimedia/publication.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { get } from 'lodash';

import {
  ANY_EXCEPTION_LIST,
  DEFINITION,
  HOW_IS_IT_CALCULATED_LIST,
  WHEN_IS_IT_CALCULATED_LIST
} from '../irpf-info.const';
/* eslint-disable @typescript-eslint/naming-convention */

@Directive()
export abstract class BaseIrpfInfoDirective implements OnInit, OnDestroy {
  @ViewChild(MediaPlayerComponent) player!: MediaPlayerComponent;

  readonly definitionSection = DEFINITION;
  readonly howIsItCalculated = HOW_IS_IT_CALCULATED_LIST;
  readonly whenIsItCalculated = WHEN_IS_IT_CALCULATED_LIST;
  readonly anyException = ANY_EXCEPTION_LIST;
  readonly analyticsCategory = CategoriesAnalytics.IRPF_INFO;

  errorThumbnail = false;
  hasBeenUpdatedViews = false;
  isLoading = true;
  logger: Logger = new Logger('IrpfInfoComponent');
  video!: Publication;
  darkMode: boolean;
  sectionMaintenance!: boolean;

  protected abstract readonly VIDEO_ID;

  constructor(
    private network: NetworkService,
    private playerService: MediaPlayerService,
    private publicationService: PublicationService,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {
    const urlParams = new URLSearchParams(window.location.search);
    const darkMode = urlParams.get('darkmode');

    this.darkMode =
      StorageService.isDarkMode() ||
      get(this.router.getCurrentNavigation(), 'extras.state.darkmode') ||
      darkMode === 'true';
  }

  ngOnInit() {
    this.initializeErrorHandling();
    this.getVideo();
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.stopPlayer();
    }
  }

  onPlay() {
    this.errorThumbnail = !this.network.hasConnection();
    this.network.doIfConnection(() => this.incrementViews());

    this.sendAnalyticsPlayEvent();
  }

  onStalled() {
    this.errorThumbnail = !this.network.hasConnection();
  }

  onEnterFullscreen() {
    this.playerService.enterFullscreenDevice(this.player);
  }

  onExitFullscreen() {
    this.playerService.exitFullscreenDevice(this.player);
  }

  sendAnalyticsPlayEvent() {
    this.analyticsService.sendEvent(CategoriesAnalytics.CLICK, {
      [ActionsAnalytics.CLICKACTION]: ActionsAnalytics.PLAY_VIDEO_IRPF
    });
  }

  trackByItems(item: MSafeAny) {
    return item;
  }

  private getVideo() {
    this.publicationService
      .getItem(this.VIDEO_ID)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (itemResponse) => (this.video = itemResponse),
        (error: MSafeAny) => this.logger.error(error)
      );
  }

  private incrementViews() {
    if (!this.hasBeenUpdatedViews) {
      this.publicationService.updateItemVisualizations(this.video.id).subscribe((views) => {
        this.video.views = views;
        this.hasBeenUpdatedViews = true;
      });
    }
  }

  private initializeErrorHandling() {
    this.publicationService.onError$.subscribe(
      (errorType) => (this.sectionMaintenance = errorType === ErrorTypes.SECTION_MAINTENANCE)
    );
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import { DropdownItem } from '@app/ptrab/shared/models/dropdown';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-ptrab-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @ViewChild('dropdown_item_list', { read: ElementRef }) elementItemList!: ElementRef;

  @Input() label!: string;
  @Input() items!: DropdownItem[];
  @Input() @HostBinding('class.is-disabled') disabled!: boolean;

  // eslint-disable-next-line
  @Output() select = new EventEmitter<MSafeAny>();
  @Output() actionClick = new EventEmitter<boolean>();

  itemSelected!: DropdownItem;
  toggle = false;
  hasBeenOpened!: boolean;
  selectedItemLabel!: string | undefined;
  customRangeLabel!: string | undefined;
  startMonth!: string;
  endMonth!: string;
  startYear!: string;
  endYear!: string;

  @HostListener('document:click')
  clickout() {
    if (!this.hasBeenOpened) {
      this.toggle = false;
    }
    this.hasBeenOpened = false;

    if (this.toggle && this.items) {
      const currentSelectedItem = this.items.find((item) => item.selected === true);
      this.scrollToItemPosition(currentSelectedItem);
    }
  }

  selectItem(item: DropdownItem) {
    if (item.value?.filter === 'custom-range') {
      this.setRangeLabelForTranslate(item.label);
    }
    this.unselectItems(this.items);
    item.selected = true;
    this.itemSelected = item;
    this.toggle = false;
    this.select.emit(item.value);
    this.actionClick.emit(this.toggle);
  }

  openOptions() {
    this.hasBeenOpened = true;
    this.toggle = !this.toggle;
    this.actionClick.emit(this.toggle);
  }

  scrollToItemPosition(item: DropdownItem | undefined) {
    if (!item) {
      this.elementItemList.nativeElement.scrollTop = 0;
      return;
    }

    const list: Element = this.elementItemList.nativeElement;
    const dropDownSelected = document.getElementById(`item-${item.value}`) as HTMLElement;
    const itemPosition = Array.from(list.children).indexOf(dropDownSelected);
    const scrollPosition = itemPosition * dropDownSelected.offsetHeight;

    list.scrollTop = scrollPosition;
  }

  handleItemLabel() {
    const hasFilter = this.checkDropdownOptions(this.items);

    this.selectedItemLabel = this.items.find((item) => item.selected === true)?.label;

    if (!hasFilter) {
      return;
    }

    this.customRangeLabel = this.items.find(
      (item) => item.selected === true && item.value.filter === 'custom-range'
    )?.label;
    if (this.customRangeLabel) {
      this.setRangeLabelForTranslate(this.customRangeLabel);
      return this.customRangeLabel;
    }
  }

  setRangeLabelForTranslate(label: string) {
    const splitArray = label.split(' ');
    this.startMonth = splitArray[0];
    this.startYear = splitArray[1];
    this.endMonth = splitArray[3];
    this.endYear = splitArray[4];
  }

  unselectItems(items: DropdownItem[]) {
    items.forEach((item) => (item.selected = false));
  }

  checkDropdownOptions(items: DropdownItem[]): boolean {
    return items.some((item) => item.value?.filter);
  }

  trackByItems(index: number, item: DropdownItem) {
    return `${index}-${item}`;
  }
}

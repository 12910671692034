import { Component, Injector, Input } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Logger } from '@services/logger/logger.service';
import { ItemCardType } from '@shared/enums/cards/item-card-type.enum';
import { Text } from '@shared/models/multimedia/text.model';

import { TextDetailComponent } from '../../multimedia-details';
import { MultimediaCardDirective } from '../multimedia-card';

@Component({
  selector: 'app-text-card',
  templateUrl: './text-card.component.html',
  styleUrls: ['../multimedia-card.scss', './text-card.component.scss']
})
export class TextCardComponent extends MultimediaCardDirective {
  @Input() text!: Text;
  @Input() type!: ItemCardType;

  readonly isIos = this.platform.is('ios');

  logger: Logger = new Logger('Text Card Component');

  show = false;

  constructor(injector: Injector, private platform: Platform) {
    super(TextDetailComponent, injector);
  }

  protected onModalDetailDismiss(text: Text) {
    if (text) {
      this.text.views = text.views;
      this.text.likes = text.likes;
      this.text.liked = text.liked;
    }
  }

  protected getItem(): Text {
    return this.text;
  }
}

import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

import { domChanges } from '@app/shared/utils/utils';
import { Comment } from '@shared/models/comment/comment';

@Directive({
  selector: '[app-scroll-to-comment]'
})
export class ScrollToCommentDirective implements AfterViewInit {
  @Input() isHighlightComment = false;
  @Input() doScrollToComment = false;
  @Input() comment!: Comment;
  @Output() afterScroll: EventEmitter<Comment> = new EventEmitter();

  constructor(private el: ElementRef) {}

  async ngAfterViewInit() {
    if (this.isHighlightComment || this.doScrollToComment) {
      const content = this.el.nativeElement.closest('app-content') as HTMLElement;
      const clientRect: DOMRect = this.el.nativeElement.getBoundingClientRect();
      let newY = clientRect.y - Math.floor(content.offsetHeight / 2);
      if (this.doScrollToComment) {
        newY = this.el.nativeElement.offsetTop;
      }
      content.scrollTo(0, newY);

      await domChanges(1000);
      this.afterScroll.emit(this.comment);
    }
  }
}

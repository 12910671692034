import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, map, takeWhile } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';

import { TwoFactorService } from '@app/ptrab/services/two-factor/two-factor.service';
import {
  TwoFactorError,
  TwoFactorOperationCode,
  TwoFactorUserStatusResponse,
  TwoFactorValidationResult,
  TwoFactorValidationStatus
} from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { Logger, ModalManager, AlertService } from '@app/services';
import { ErrorCodes } from '@app/services/error/error.model';
import { LoadingService } from '@app/services/loading/loading.service';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { getMinutesFromSeconds } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ptrab-code-validation',
  templateUrl: './code-validation.component.html',
  styleUrls: ['./code-validation.component.scss']
})
export class CodeValidationComponent implements OnInit {
  phoneId!: string;
  operation!: TwoFactorOperationCode;

  buttons: Buttons[] = [
    {
      text: 'EMPLOYEE_PORTAL.CONFIRM',
      type: 'primary',
      enabled: false,
      onClick: () => this.validateCode()
    }
  ];

  form!: FormGroup;
  isCodeWrong = false;
  maxTriesExceeded = false;
  expiredCode = false;
  remainingMinutes!: number;
  timeRemaining$!: Observable<number>;
  sendAgainAvailable!: boolean;
  waitingTime!: number;

  readonly codeLength = 6;
  readonly defaultWaitingTime = 300;
  private logger = new Logger('CodeValidationFormComponent');
  private twoFactorservice: TwoFactorService;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private translate: TranslateService,
    private modalManager: ModalManager,
    injector: Injector
  ) {
    this.twoFactorservice = injector.get(TwoFactorService);
  }

  ngOnInit() {
    this.phoneId = this.data.phoneId;
    this.operation = this.data.operation;

    this.setupForm();
    this.sendCode();
  }

  validateCode() {
    this.loadingService.show();
    this.twoFactorservice
      .validateCode(this.operation, this.form.get('code')?.value, this.phoneId)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        (hash) => {
          const result: TwoFactorValidationResult = { status: TwoFactorValidationStatus.SUCCESS, hash };
          this.modalManager.dismissMatModal(result);
        },
        (error: HttpErrorResponse) => {
          if (error.status === ErrorCodes.BAD_REQUEST && error.error.code) {
            this.handleTwoFactorError(error.error.code);
            return;
          }

          this.handleGenericError(error);
        }
      );
  }

  async sendCode() {
    try {
      await this.sendValidationCode();
    } catch (error) {
      this.handleGenericError(error);
      const result: TwoFactorValidationResult = { status: TwoFactorValidationStatus.ERROR };
      this.modalManager.dismissMatModal(result);
    }
  }

  async resendCode() {
    try {
      await this.sendValidationCode();
      this.alertService.showSuccess(
        this.translate.instant('EMPLOYEE_PORTAL.CODE_SENT'),
        this.translate.instant('EMPLOYEE_PORTAL.CODE_SENT_DETAIL')
      );
    } catch (error) {
      this.handleGenericError(error);
    } finally {
      this.form.reset();
      this.isCodeWrong = false;
      this.expiredCode = false;
    }
  }

  showCodeValidationForm() {
    return this.form && !this.maxTriesExceeded && !this.expiredCode;
  }

  private async handleTwoFactorError(error: TwoFactorError) {
    switch (error) {
      case TwoFactorError.VALIDATION_CODE_INVALID:
        this.isCodeWrong = true;
        break;
      case TwoFactorError.VALIDATION_CODE_MAX_TRIES_EXCEEDED:
        // eslint-disable-next-line
        const userStatus = (await this.twoFactorservice.getUserStatus().toPromise()) as TwoFactorUserStatusResponse;
        this.remainingMinutes = getMinutesFromSeconds(userStatus.remaining_time);
        this.setMaxTriesError();
        break;
      case TwoFactorError.VALIDATION_CODE_EXPIRED:
        this.expiredCode = true;
        this.buttons[0].enabled = false;
        break;
      default:
        this.handleGenericError(error);
    }
  }

  private handleGenericError(error: MSafeAny) {
    this.alertService.showError(
      this.translate.instant('EMPLOYEE_PORTAL.TWO_FACTOR_TITLE_ERROR'),
      this.translate.instant('ERROR_MESSAGES.GENERIC_FAIL_MESSAGE')
    );
    this.logger.error(error);
  }

  private setMaxTriesError() {
    this.maxTriesExceeded = true;
    this.buttons = [
      {
        text: 'EMPLOYEE_PORTAL.BACK',
        type: 'primary',
        enabled: true,
        onClick: () => this.dismissBlockedUser()
      }
    ];
  }

  private dismissBlockedUser() {
    const result: TwoFactorValidationResult = { status: TwoFactorValidationStatus.USER_BLOCKED };
    this.modalManager.dismissMatModal(result);
  }

  private async sendValidationCode() {
    this.loadingService.show();
    try {
      const twoFactorResponse = await this.twoFactorservice
        .sendValidationCodeToPhone(this.operation, this.phoneId)
        .toPromise();
      this.waitingTime = twoFactorResponse?.blocking_time || this.defaultWaitingTime;
      this.startTimer();
    } finally {
      await this.loadingService.hide();
    }
  }

  private startTimer() {
    this.sendAgainAvailable = false;
    this.timeRemaining$ = timer(0, 1000).pipe(
      map((n) => (this.waitingTime - n) * 1000),
      takeWhile((n) => n > 0),
      finalize(() => (this.sendAgainAvailable = true))
    );
  }

  private setupForm() {
    this.form = this.formBuilder.group({
      code: [
        '',
        Validators.compose([
          Validators.minLength(this.codeLength),
          Validators.maxLength(this.codeLength),
          Validators.required
        ])
      ]
    });

    this.form.valueChanges.subscribe(() => {
      this.buttons[0].enabled = this.form.value.code?.length === this.codeLength;
    });
  }
}

<p class="universal-truths-title">
  {{ title | translate }}
</p>
<div class="grid">
  <div class="row">
    <div class="col" size="6" *ngFor="let filter of filterList; trackBy: trackByItems">
      <div class="universal-item u-ls u-medium-grey u-text-center" (click)="sendFilter(filter)">
        {{ filter.label | translate }}
      </div>
    </div>
  </div>
</div>

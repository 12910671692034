import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { DirectDebitAccount } from './direct-debit-account';
/* eslint-disable @typescript-eslint/naming-convention */

export class DirectDebit {
  code: string;
  employee_number: string;
  company_description: string;
  account: DirectDebitAccount;

  constructor(data: MSafeAny) {
    this.code = data.code;
    this.employee_number = data.employee_number;
    this.company_description = data.company_description;
    this.account = data.account;
  }
}

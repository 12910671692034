<app-message-info *ngIf="areCommentsDisabled()" [title]="'COMMENTS.DISABLED.MESSAGE' | translate"></app-message-info>

<div class="comments-toggler" *ngIf="!areCommentsDisabled()">
  <div class="u-ls check-comments" (click)="toggleComments()">
    <img class="comment-icon" src="assets/imgs/comments/comment_empty.svg" />
    <span class="message-toggle">{{ messageToggle | translate }}</span>
  </div>
  <div class="container-comments-count" [ngPlural]="commentsCount">
    <span class="u-p2 u-medium-grey comments-count">{{ commentsCount | number }}</span>
    <span class="u-p2 u-medium-grey comments-count spacing">
      <ng-template ngPluralCase="=1"> {{ 'COMMENTS.TITLE_SINGLE' | translate | ucfirst }}</ng-template>
      <ng-template ngPluralCase="other"> {{ 'COMMENTS.TITLE' | translate | ucfirst }}</ng-template>
    </span>
  </div>
</div>
<div #commentsContainer class="comments-container" *ngIf="showComment">
  <ng-container [ngSwitch]="userPermission">
    <app-message-info
      *ngSwitchCase="userCommentPermission.BANNED"
      [title]="'COMMENTS.BANNED.TITLE' | translate"
      [message]="'COMMENTS.BANNED.MESSAGE' | translate"></app-message-info>
    <app-message-info
      *ngSwitchCase="userCommentPermission.BLOCKED"
      [title]="'COMMENTS.RECEIVED' | translate"
      [message]="'COMMENTS.PROCESSING' | translate"></app-message-info>
    <app-add-comment
      *ngSwitchCase="userCommentPermission.ALLOWED"
      [user]="user"
      [userIsOwner]="userIsOwner"
      [userIsPresident]="userIsPresident"
      (userComment)="createComment($event)"></app-add-comment>
  </ng-container>
  <div
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollContainer]="'.' + infiniteScrollSelector"
    [infiniteScrollDisabled]="!canLoadMoreComments"
    [fromRoot]="true"
    (scrolled)="onShowMoreComments()">
    <app-comments-list
      [comments]="comments"
      [highlightedCommentId]="this.commentId"
      [doScrollToComment]="doScrollToComment"
      [parentItemId]="publicationItem.id"
      [isLoading]="isLoadingMoreComments"
      [user]="user"
      [userPermission]="userPermission"
      [asUsers]="asUsers"
      [userIsOwner]="userIsOwner"
      [userIsPresident]="userIsPresident"
      [showLoadingOnComment]="showLoadingOnComment"
      (action)="manageAction($event)"
      [mainContent]="mainContent"></app-comments-list>
    <span class="retry" *ngIf="showRetryComments()" (click)="getComments()">
      {{ 'ERROR_MESSAGES.RETRY' | translate }}
    </span>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

interface IConfirmationProps {
  buttons: Buttons[];
  contents: string[];
  example?: string;
  pageAnalytics?: PagesAnalytics;
  title: string;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements IConfirmationProps, OnInit {
  buttons!: Buttons[];
  contents!: string[];
  example!: string;
  title!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MSafeAny) {}

  ngOnInit() {
    this.buttons = this.data.buttons;
    this.contents = this.data.contents;
    this.example = this.data.example;
    this.title = this.data.title;
  }

  trackByItems(item: MSafeAny) {
    return item;
  }
}

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

export interface IrpfViewItem {
  label: string;
  value?: string;
}

export interface IrpfViewItemObj {
  [key: string]: IrpfViewItem;
}

export interface WorkerView extends IrpfViewItemObj {
  maritalStatus: IrpfViewItem;
  spouseNif?: MSafeAny;
  reducedMobility: IrpfViewItem;
  disability: IrpfViewItem;
}

export interface PensionPaymentView extends IrpfViewItemObj {
  childAllowance: IrpfViewItem;
  alimony: IrpfViewItem;
  courtRulingDate?: MSafeAny;
}

export class IrpfView {
  worker: WorkerView;
  ancestors: IrpfViewItem[];
  descendants: IrpfViewItem[];
  pensionPayment: PensionPaymentView;
  mortgageReduction: {
    mortgageReduction: IrpfViewItem;
  };
  ceutaMelillaResidence: {
    ceutaMelillaResidence: IrpfViewItem;
  };

  constructor(view: MSafeAny) {
    this.worker = view.worker;
    this.ancestors = view.ancestors;
    this.descendants = view.descendants;
    this.pensionPayment = view.pensionPayment;
    this.mortgageReduction = view.mortgageReduction;
    this.ceutaMelillaResidence = view.ceutaMelillaResidence;
  }

  hasDisabilities(): boolean {
    return this.worker.disability.label !== null || this.worker.reducedMobility.label !== null;
  }
}

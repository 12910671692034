export enum Disabilities {
  LESS_THAN_33 = 0,
  BETWEEN_33_AND_65,
  OVER_65
}

export enum Territories {
  ARABA_TERRITORY = 'TA',
  BIZKAIA_TERRITORY = 'TB',
  GIPUZKOA_TERRITORY = 'TG',
  NAVARRA_TERRITORY = 'TF',
  COMMON_TERRITORY = 'TC'
}

export enum MaritalStatus {
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
  DOMESTIC_PARTNERSHIP = 'DOMESTIC_PARTNERSHIP',
  LEGALLY_SEPARATED = 'LEGALLY_SEPARATED',
  UNDISCLOSED = 'UNDISCLOSED'
}

import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-data-item',
  templateUrl: './data-item.component.html',
  styleUrls: ['./data-item.component.scss']
})
export class DataItemComponent {
  @HostBinding('class.c-data-item') hostClass = true;

  @Input() label!: string;
  @Input() value!: string;
  @Input() actionText!: string;
  @Input() actionIcon!: string;
  @Input() date!: string;
  @Input() reason!: string;
  @Input() hideAction = false;

  @Output() actionClick = new EventEmitter();
}

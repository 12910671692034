import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AlertService, Logger, ModalManager } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { CommentsService } from '@app/services/comments/comments.service';
import { ErrorCodes } from '@app/services/error/error.model';
import { ItemChangeNotifier } from '@app/services/multimedia/item-change-notifier.service';
import { STORAGE_CONSTANTS, StorageService } from '@app/services/storage';
import { UserService } from '@app/services/user/user.service';
import { CommentStatus, UserCommentPermission } from '@app/shared/enums/comment/comment.enum';
import { UserInfo } from '@app/shared/models/auth/user-info.model';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { Comment } from '@app/shared/models/comment/comment';
import { Publication } from '@app/shared/models/multimedia/publication.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { RepresentedUser } from '@app/shared/models/user/represented-user.model';
import { User } from '@app/shared/models/user/user.model';
import { domChanges } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';

import { ActionComments } from './action-comments.enum';
import { ERROR_CODES } from './error-codes-comments.const';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { ContentComponent } from '../content/content.component';
import { MatDialogConfig } from '@angular/material/dialog';

/* eslint-disable @typescript-eslint/naming-convention */

export interface CommentAction {
  action: string;
  comment: Comment;
  commentIndex?: number;
}

@Component({
  selector: 'app-comments-section',
  templateUrl: './comments-section.component.html',
  styleUrls: ['./comments-section.component.scss']
})
export class CommentsSectionComponent implements OnInit {
  @ViewChild('commentsContainer') commentContainer!: ElementRef;
  @Input() publicationItem!: Publication;
  @Input() userPermission!: UserCommentPermission;
  @Input() userIsOwner!: boolean;
  @Input() userIsPresident!: boolean;
  @Input() analyticsCategory!: string;
  @Input() mainContent!: ContentComponent;

  @Output() scrollToTop = new EventEmitter<boolean>();

  readonly infiniteScrollSelector = 'item-detail-container';
  private logger = new Logger('CommentsSection');
  commentId!: number | null;
  commentTitle!: string;
  comments: Comment[] = [];
  commentsCount!: number;
  currentPage = 0;
  errorComments = false;
  isLoadingMoreComments = false;
  maxPages!: number;
  messages: MSafeAny;
  moreComments!: boolean;
  doScrollToComment!: boolean;
  showComment = false;
  user!: UserInfo;
  userCommentPermission = UserCommentPermission;
  asUsers: MSafeAny;
  commentsByPage!: number;
  lastCommentId!: number | undefined;
  highlightedCommentParentId!: number;

  commentActionHandlers = {
    [ActionComments.reply]: (comment) => {
      if (comment.status === CommentStatus.FORBIDDEN) {
        this.userPermission = UserCommentPermission.BLOCKED;
        return;
      }
      this.addReplyToComment(comment);
    },
    [ActionComments.edit]: (comment, commentIndex) => {
      this.setShowLoadingOnComment(comment);
      this.updateComment(comment, commentIndex);
    },
    [ActionComments.delete]: (comment, commentIndex?) => {
      this.confirmDeleteComment(comment, commentIndex);
    },
    [ActionComments.reset]: () => {
      this.commentId = null;
      this.getComments(true);
      this.getAsUsers();
    }
  };

  showLoadingOnComment = {
    commentId: undefined as string | undefined,
    showLoading: false
  };

  constructor(
    private userService: UserService,
    private commentsService: CommentsService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private modalManager: ModalManager,
    private analyticsService: AnalyticsService,
    private storageService: StorageService,
    private itemChangeNotifier: ItemChangeNotifier,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.commentsCount = this.publicationItem.n_comments || 0;
    this.setUserInfo();
    this.getTranslations();
    this.setCommentsDisabled();
    this.highlightComment();
  }

  async setUserInfo() {
    this.user = (await this.storageService.getItems([STORAGE_CONSTANTS.USER]))[STORAGE_CONSTANTS.USER] as User;
  }

  getTranslations() {
    this.translateService
      .get([
        'COMMENTS.ERROR_LOAD',
        'COMMENTS.NOT_FOUND',
        'COMMENTS.ERROR_SEND',
        'ERROR_MESSAGES.ERROR',
        'ERRORS_TOASTS.NON_EXISTENT_COMMENT'
      ])
      .subscribe((messages) => {
        this.messages = messages;
      });
  }

  setCommentsDisabled() {
    if (!this.publicationItem.comments_allowed) {
      this.userPermission = UserCommentPermission.DISABLED;
    }
  }

  toggleComments(doScroll = true) {
    this.showComment = !this.showComment;
    if (!this.showComment) {
      this.commentId = null;
      return;
    }
    if (this.showComment) {
      if (doScroll) {
        this.scrollToTop.emit();
      }
      this.resetComments();
      this.getComments();
      this.getAsUsers();
    }
  }

  showRetryComments() {
    return !this.isLoadingMoreComments && this.errorComments;
  }

  get messageToggle() {
    const openMsg = this.commentsCount ? 'COMMENTS.SHOW' : 'COMMENTS.ADD_COMMENT';
    return this.showComment ? 'COMMENTS.HIDE' : openMsg;
  }

  get canLoadMoreComments() {
    return !this.isLoadingMoreComments && this.maxPages > this.currentPage;
  }

  onShowMoreComments() {
    this.getComments();
  }

  async getComments(reset = false, doScroll?: boolean) {
    this.isLoadingMoreComments = true;
    this.errorComments = false;
    this.setCommentParameters(reset);
    if (this.currentPage !== 0) {
      this.doScrollToComment = false;
      this.getCommentsService()
        .then((commentsResolved) => {
          this.handleGetCommentsResponse(reset, commentsResolved);
        })
        .catch((error) => {
          this.logger.error('GET RELATED CONTENT', error);
        });
    } else {
      Promise.all([this.getCommentsHighlighted(), this.getCommentsService()])
        .then((commentsResolved) => {
          this.comments = this.handleGetCommentsHighlightedResponse(commentsResolved[0]);
          this.handleGetCommentsResponse(false, commentsResolved[1], doScroll);
        })
        .catch((error) => {
          this.logger.error('GET RELATED CONTENT', error);
        });
    }
  }

  private getCommentsService() {
    return new Promise((resolve, reject) => {
      this.commentsService.getComments(this.publicationItem.id, this.currentPage, this.lastCommentId).subscribe(
        (comments) => {
          return resolve(comments);
        },
        (error) => {
          this.logger.debug(`Can't load data:`, error);
          this.onErrorProvider(error);
          return reject(error);
        }
      );
    });
  }

  private getCommentsHighlighted() {
    return new Promise((resolve) => {
      this.commentsService.getCommentsHighlighted(this.publicationItem.id).subscribe(
        (comments) => {
          return resolve(comments);
        },
        (error) => {
          this.logger.debug(`Can't load data:`, error);
          const emptyComments = { comments: [] };
          return resolve(emptyComments);
        }
      );
    });
  }

  private setCommentParameters(reset: boolean) {
    if (reset) {
      this.lastCommentId = undefined;
      this.currentPage = 0;
    } else {
      this.lastCommentId = this.getLastCommentId(this.comments);
    }
  }

  private handleGetCommentsHighlightedResponse(response: MSafeAny) {
    return this.setHighlightedComments(response.comments);
  }

  private setHighlightedComments(comments: MSafeAny): Comment[] {
    comments?.map((comment: Comment) => {
      comment.highlighted = true;
    });

    return comments;
  }

  private handleGetCommentsResponse(reset: boolean, response: MSafeAny, doScroll?: boolean) {
    this.isLoadingMoreComments = false;
    if (reset) {
      this.resetComments();
    }
    if (response.comments) {
      this.comments = this.removeHightLightedComment(this.comments, response.comments);
    }
    if (doScroll) {
      this.doScrollToComment = true;
    }
    this.commentsCount = response.commentsCount;
    this.maxPages = response.totalPages;
    this.commentsByPage = response.commentsByPage;
    this.updateCommentsCount(this.commentsCount);
    this.currentPage++;
    if (this.commentId) {
      this.isCommentToHighlightLoaded(this.comments);
    }
  }

  private removeHightLightedComment(comments: Comment[], responseComments: Comment[]): Comment[] {
    if (
      this.commentId &&
      this.highlightedCommentParentId &&
      responseComments.some((comment) => comment.id === this.highlightedCommentParentId)
    ) {
      comments = comments.filter((comment) => comment.id !== this.highlightedCommentParentId);
    }

    return [...comments, ...responseComments];
  }

  private isCommentToHighlightLoaded(comments: Comment[]) {
    const isLoaded = comments.some((comment) => {
      if (comment.response_comments && comment.response_comments.length > 0) {
        return comment.response_comments.some((reply) => {
          if (reply.id === this.commentId) {
            this.highlightedCommentParentId = reply.parent_id as number;
            return true;
          }
        });
      }
    });

    if (!isLoaded) {
      this.addHighlightedComment();
    }
  }

  private async addHighlightedComment() {
    try {
      const commentToAdd = await this.commentsService.getCommentById(this.commentId as number).toPromise();
      this.highlightedCommentParentId = commentToAdd?.id as number;
      this.comments.push(commentToAdd as Comment);
    } catch (error) {
      this.commentId = null;
      this.logger.error(error);
      await domChanges(1000);
      this.scrollToTop.emit();
    }
  }

  getAsUsers() {
    this.errorComments = false;

    this.userService.getRepresentedUsers().subscribe(
      (response: RepresentedUser[]) => {
        this.asUsers = response;
      },
      (err) => this.onErrorProvider(err)
    );
  }

  createComment($event: string) {
    const comment = $event;
    this.userPermission = UserCommentPermission.BLOCKED;
    this.commentId = null;

    this.commentsService.createComment(this.publicationItem.id, comment).subscribe(
      (postedComment: Comment) => {
        if (postedComment.status === CommentStatus.FORBIDDEN) {
          return;
        }

        // The Blocked message is displayed for 3 seconds before the comment box is displayed again.
        setTimeout(() => {
          this.userPermission = UserCommentPermission.ALLOWED;
        }, 2000);

        this.getComments(true, true);
        this.incrementUserComment();
        this.sendCommentEvent(this.publicationItem);
      },
      (error) => {
        const isUserBanned = error instanceof HttpErrorResponse && error.status === 403;
        this.userPermission = isUserBanned ? UserCommentPermission.BANNED : UserCommentPermission.ALLOWED;
        this.doScrollToComment = false;
        this.logger.error(error);
        this.handleErrorIfBadRequest(error);
      }
    );
  }

  manageAction(event: CommentAction) {
    const { action, comment, commentIndex } = event;
    this.commentActionHandlers[action](comment, commentIndex);
  }

  addReplyToComment(comment: Comment) {
    this.comments.forEach((userComment) => {
      if (comment.id === userComment.id) {
        userComment.response_comments = comment.response_comments;
      }
    });
  }

  updateComment(comment: Comment, commentIndex: number) {
    this.commentsService.updateComment(comment.id, comment.comment).subscribe(
      (response: MSafeAny) => {
        const allowed = response.status === CommentStatus.ACCEPTED;
        const currentStatus = this.userPermission;
        this.userPermission = allowed ? currentStatus : UserCommentPermission.BLOCKED;

        if (response.status === CommentStatus.FORBIDDEN) {
          this.removeLocalCommentFromList(comment, commentIndex);
        }

        this.resetShowLoadingOnComment();
      },
      (error: MSafeAny) => {
        this.logger.error('Failed updating comment:', error);
        this.handleErrorIfBadRequest(error);
        this.resetShowLoadingOnComment();
      }
    );
  }

  confirmDeleteComment(comment: Comment, commentIndex: number) {
    const title = 'COMMENTS.REMOVE_COMMENT_TITLE';
    const contents = ['COMMENTS.REMOVE_COMMENT_BODY'];

    const buttons: Buttons[] = [
      {
        text: 'CANCEL',
        type: 'secondary',
        enabled: true,
        onClick: () => this.modalManager.dismissMatModal()
      },
      {
        text: 'COMMENTS.DELETE',
        type: 'primary',
        enabled: true,
        onClick: () => this.deleteComment(comment, commentIndex)
      }
    ];

    const modalOpts: MatDialogConfig = {
      data: { title, contents, buttons },
      disableClose: true,
      panelClass: ['media-content-modal', 'confirmation-modal']
    };

    this.modalManager.openMatModal(ConfirmationModalComponent, modalOpts);
  }

  deleteComment(comment: Comment, commentIndex: number) {
    this.setShowLoadingOnComment(comment);
    this.commentsService
      .deleteComment(comment.id)
      .pipe(finalize(() => this.modalManager.dismissMatModal()))
      .subscribe(
        async (response: MSafeAny) => {
          this.logger.info('Comment removed remotely');
          this.removeLocalCommentFromList(comment, commentIndex);

          // If is not a comment response
          if (!comment.parent_id) {
            this.updateCommentsCount(--this.commentsCount);
            this.decrementUserComments();
          }
          this.logger.info('Comment Deleted: ', response);
        },
        (error: MSafeAny) => {
          this.logger.error('Failed deleting comment:', error);
          this.handleErrorIfBadRequest(error);
        }
      );
  }

  removeLocalCommentFromList(commentToDelete: Comment, indexToDelete: number) {
    if (!commentToDelete.parent_id) {
      this.comments.splice(indexToDelete, 1);
    } else {
      for (const parentComment of this.comments) {
        if (parentComment.id === commentToDelete.parent_id) {
          parentComment.response_comments.splice(indexToDelete, parentComment.response_comments.length);
          break;
        }
      }
    }

    this.logger.info('Comment removed locally');
    this.resetShowLoadingOnComment();
  }

  handleErrorIfBadRequest(error: MSafeAny) {
    if (
      error.status === ErrorCodes.BAD_REQUEST &&
      (typeof error.error === 'string' || error.error?.code !== ERROR_CODES.ACTIVO2_0004)
    ) {
      this.alertService.showError(
        this.messages['ERROR_MESSAGES.ERROR'],
        this.messages['ERRORS_TOASTS.NON_EXISTENT_COMMENT']
      );
    } else {
      this.handlerErrorInvalidCharacters(error);
    }

    this.resetComments();
    this.getComments();
  }

  private handlerErrorInvalidCharacters(error) {
    if (error.error?.code === ERROR_CODES.ACTIVO2_0004) {
      this.alertService.showError(
        this.messages['ERROR_MESSAGES.ERROR'],
        this.translateService.instant('COMMENTS.ERROR_INVALID_CHARACTERS', { characters: error.error?.invalid_chars })
      );
      this.resetShowLoadingOnComment();
    } else {
      this.alertService.showError(this.messages['ERROR_MESSAGES.ERROR'], this.messages['COMMENTS.ERROR_SEND']);
    }
  }

  resetComments() {
    this.comments = [];
    this.currentPage = 0;
    this.doScrollToComment = false;
  }

  areCommentsDisabled() {
    return this.userPermission === UserCommentPermission.DISABLED;
  }

  findAndDeleteComment(comment: Comment, comments: Comment[]) {
    return comments.filter((commentary: Comment) => {
      if (commentary.response_comments.length > 0) {
        const filterReplies = this.findAndDeleteComment(comment, commentary.response_comments);
        commentary.response_comments = filterReplies;
      }

      return comment.id !== commentary.id;
    });
  }

  setShowLoadingOnComment(comment) {
    this.showLoadingOnComment = { commentId: comment.id, showLoading: true };
  }

  resetShowLoadingOnComment() {
    this.showLoadingOnComment = { commentId: undefined, showLoading: false };
  }

  private onErrorProvider(err: string) {
    this.logger.error(err);
    this.errorComments = true;
    this.alertService.showError(this.messages['ERROR_MESSAGES.ERROR'], this.messages['COMMENTS.ERROR_LOAD']);
  }

  private updateCommentsCount(n_comments: number) {
    this.publicationItem.n_comments = n_comments;
    this.itemChangeNotifier.emit(this.publicationItem, 'n_comments');
  }

  private incrementUserComment() {
    if (this.publicationItem && this.publicationItem.user_comments) {
      this.publicationItem.user_comments += 1;
      this.itemChangeNotifier.emit(this.publicationItem, 'user_comments');
    }
  }

  private decrementUserComments() {
    if (this.publicationItem && this.publicationItem.user_comments) {
      this.publicationItem.user_comments -= 1;
      this.itemChangeNotifier.emit(this.publicationItem, 'user_comments');
    }
  }

  private getLastCommentId(comments: Comment[]): number | undefined {
    if (comments && comments.length > 0) {
      const lastComment = comments[comments.length - 1];
      return lastComment.id;
    }
    return;
  }

  private async highlightComment() {
    this.commentId = Number(this.activatedRoute.snapshot.paramMap.get('comment_id'));
    if (this.commentId) {
      await domChanges(100);
      this.toggleComments();
    }
  }

  private sendCommentEvent(publication: Publication) {
    this.analyticsService.sendEvent(CategoriesAnalytics.SEND_COMMENT, {
      [ActionsAnalytics.CLICKACTION]: publication.title
    });
  }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { IrpfDataSectionError } from '@app/ptrab/shared/interfaces/irpf-section.interface';
import { IrpfViewItem, IrpfViewItemObj } from '@app/ptrab/shared/models/irpf-view.model';

@Component({
  selector: 'app-ptrab-irpf-item-pt',
  templateUrl: './irpf-item-pt.component.html',
  styleUrls: ['./irpf-item-pt.component.scss']
})
export class IrpfItemPtComponent implements OnChanges {
  @Input() data!: IrpfViewItemObj;
  @Input() title!: string;
  @Input() emptyMsg!: string;
  @Input() sectionErrors!: IrpfDataSectionError;
  @Input() hasChanges!: boolean;

  @Output() modify = new EventEmitter();

  dataToload: IrpfViewItem[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data) {
      const irpfItems = Object.values(this.data);
      this.dataToload = irpfItems.filter((item) => Boolean(item.label));
    }
  }

  get hasDataToLoad() {
    return this.dataToload && this.dataToload.length > 0 && this.dataToload[0].value;
  }

  get hasError() {
    return this.sectionErrors && this.hasChanges;
  }

  modifySection() {
    this.modify.emit();
  }

  trackByItems(item: IrpfViewItem) {
    return item;
  }
}

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { IdDescription } from '@app/shared/interfaces/data/iddescription.interface';
/* eslint-disable @typescript-eslint/naming-convention */

export class EmployeeLanguages {
  knowledge: Knowledge[];
  tests: Tests[];

  constructor(data: MSafeAny) {
    this.knowledge = data?.knowledge;
    this.tests = data?.tests;
  }
}

export interface Knowledge {
  language: {
    id: string;
    description: string;
  };
  language_level: {
    id: string;
    short_description: string;
    long_description: string;
  };
  certificate: {
    status: {
      id: CertificateStatus;
      description: string;
    };
    date: string;
  };
}

export enum CertificateStatus {
  CURRENT = 'C',
  YES = 'S',
  NO = 'N'
}

export interface Tests {
  language: LanguageItem;
  level_test: IdDescription;
  test_date: string;
  test_reason: string;
}

export class LanguageMastersModel implements LanguageMasters {
  languages: LanguageItem[];
  levels: LanguageLevels[];
  tests: IdDescription[];
  statuses: Statuses[];

  constructor(data: LanguageMasters) {
    this.languages = data.languages;
    this.tests = data.tests;
    this.levels = data.levels;
    this.statuses = data.statuses;
  }
}

export interface LanguageItem {
  id: string;
  code: string;
  description: string;
}

export interface LanguageLevels {
  id: string;
  short_description: string;
  long_description: string;
}

export interface Statuses {
  id: CertificateStatus;
  description: string;
}

export interface LanguageMasters {
  languages: LanguageItem[];
  levels: LanguageLevels[];
  tests: IdDescription[];
  statuses: Statuses[];
}

export class CertificateEmployeeModel implements CreateCertificateEmployee {
  language: string;
  language_level: string;
  certificate: {
    status: string;
    date: string;
  };

  constructor(data: CreateCertificateEmployee) {
    this.language = data.language;
    this.language_level = data.language_level;
    this.certificate = data.certificate;
  }
}

export interface CreateCertificateEmployee {
  language: string;
  language_level: string;
  certificate: {
    status: string;
    date: string;
  };
}

<app-content-modal [buttons]="buttons" [hideClose]="true">
  <div class="grid send-to-review">
    <div class="row">
      <h1>{{ 'CONFIRMATION_MODAL.REVIEW' | translate }}</h1>
    </div>
    <div class="row">
      <p>{{ 'CONFIRMATION_MODAL.REVIEW_CONTENT_1' | translate }}</p>
    </div>
    <div class="row">
      <p class="bold">{{ 'CONFIRMATION_MODAL.REVIEW_CONTENT_2' | translate }}</p>
    </div>
    <div class="row">
      <p>{{ 'CONFIRMATION_MODAL.REVIEW_CONTENT_3' | translate }}</p>
    </div>
    <div class="row">
      <img class="forbidden-icon" src="assets/imgs/icons/forbidden.svg" alt="Forbidden Icon" />
      <p>{{ 'CONFIRMATION_MODAL.INPUTS' | translate }}</p>
    </div>
    <div class="row">
      <img class="forbidden-icon" src="assets/imgs/icons/forbidden.svg" alt="Forbidden Icon" />
      <p>{{ 'CONFIRMATION_MODAL.IMPROVES' | translate }}</p>
    </div>
    <div class="row">
      <img class="forbidden-icon" src="assets/imgs/icons/forbidden.svg" alt="Forbidden Icon" />
      <p>{{ 'CONFIRMATION_MODAL.THEORIES' | translate }}</p>
    </div>
    <div class="row">
      <img class="forbidden-icon" src="assets/imgs/icons/forbidden.svg" alt="Forbidden Icon" />
      <p>{{ 'CONFIRMATION_MODAL.EXAMPLES' | translate }}</p>
    </div>
  </div>
</app-content-modal>

<div class="checkbox" (click)="onChange()" [class.subfilter]="isSubfilter">
  <span class="checkbox__label" *ngIf="filter">
    {{ filter.filter_name }} <img *ngIf="filter.filter_image" [src]="filter.filter_image" />
  </span>

  <span class="checkbox__label" [innerHTML]="segment.description" *ngIf="segment"> </span>

  <div class="checkbox__button" [class.selected]="filter?.selected || segment?.selected">
    <ion-icon *ngIf="filter?.selected || segment?.selected" name="checkmark-outline"></ion-icon>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';

import { Logger, ModalManager, AlertService } from '@app/services';
import { LoadingService } from '@app/services/loading/loading.service';
import { ExampleService } from '@app/services/multimedia/example/example.service';
import { ContentType } from '@app/shared/enums/multimedia/content-status-types.enum';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { Example } from '@app/shared/models/multimedia/example.model';
import { TranslateService } from '@ngx-translate/core';

import { ERROR_CODES } from '../comments-section/error-codes-comments.const';
import { ExampleFormComponent } from '../example-form/components/example-form/example-form.component';

@Component({
  selector: 'app-create-example',
  templateUrl: './create-example.component.html'
})
export class CreateExampleComponent implements OnInit {
  @ViewChild(ExampleFormComponent) formComponent!: ExampleFormComponent;
  exampleFormButtons: Buttons[] = [];

  private logger = new Logger('CreateExampleComponent');

  constructor(
    private modalManager: ModalManager,
    private exampleService: ExampleService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.exampleFormButtons = this.getExampleButtons();
  }

  submitForm() {
    this.formComponent.submitForm();
  }

  async createExample(formData: FormData) {
    try {
      this.loadingService.show();
      const example: Example = await this.exampleService.create(formData).toPromise();
      if (this.formComponent.type === ContentType.VIDEO) {
        await this.createVimeoVideo(example);
      }
      this.onSuccess(example);
    } catch (error) {
      this.onError(error);
    } finally {
      this.loadingService.hide();
    }
  }

  close(example: Example | null = null, sendToReview = false) {
    this.modalManager.dismissMatModal({ example, sendToReview });
  }

  getExampleButtons(enabled: boolean = false): Buttons[] {
    return [
      {
        text: 'CANCEL',
        type: 'secondary',
        enabled: true,
        onClick: () => this.close()
      },
      {
        text: 'CREATE',
        type: 'primary',
        enabled,
        onClick: () => this.submitForm()
      }
    ];
  }

  updateValidation() {
    this.exampleFormButtons = this.getExampleButtons(this.formComponent.valid);
  }

  private createVimeoVideo(example: Example) {
    const video = this.formComponent.multimedia?.file as Blob;
    const uploadLink = example.url_video_upload;
    const exampleId = example.id;
    return this.exampleService.createVimeoVideo(uploadLink as string, exampleId, video);
  }

  private onError(error) {
    this.logger.error(error);
    if (error.error?.code === ERROR_CODES.ACTIVO2_0004) {
      this.handlerErrorInvalidCharacters(error);
    } else {
      this.alertService.showError(
        this.getMessage('CUSTOM_FILE_CHOOSER.ERROR'),
        this.getMessage('CUSTOM_FILE_CHOOSER.CREATE_ERROR')
      );
    }
  }

  private handlerErrorInvalidCharacters(error) {
    this.alertService.showError(
      this.getMessage('ERROR_MESSAGES.ERROR'),
      this.translate.instant('COMMENTS.ERROR_INVALID_CHARACTERS', { characters: error.error?.invalid_chars })
    );
  }

  private onSuccess(example: Example) {
    this.logger.debug(example);
    this.exampleService.reloadExamples();
    this.close(example, true);
  }

  private getMessage(key: string): string {
    return this.translate.instant(key);
  }
}

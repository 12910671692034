import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  animations: [
    trigger('toggleTrigger', [
      state('off', style({ transform: 'translateX(5%)' })),
      state('on', style({ transform: 'translateX(70%)' })),
      transition('on <=> off', [animate('120ms ease-in-out')])
    ])
  ]
})
export class ToggleComponent {
  @Input() checked = false;
  @Input() disabled = false;
  @Output() toggleChange = new EventEmitter();

  toggleClick(): void {
    this.checked = this.checked ? false : true;
    this.toggleChange.emit(this.checked);
  }
}

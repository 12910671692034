import { Component, Input } from '@angular/core';

import { MultimediaDetailRenderMode } from '@app/shared/enums/multimedia/multimedia-detail.enum';
import { Example } from '@app/shared/models/multimedia/example.model';

@Component({
  selector: 'app-multimedia-detail-author',
  templateUrl: './multimedia-detail-author.component.html',
  styleUrls: ['./multimedia-detail-author.component.scss']
})
export class MultimediaDetailAuthorComponent {
  @Input() exampleItem!: Example;
  @Input() detailRenderMode!: MultimediaDetailRenderMode;

  isDetailRenderModePage(): boolean {
    return this.detailRenderMode === MultimediaDetailRenderMode.Page;
  }
}

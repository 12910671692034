import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Segment } from '@app/shared/interfaces/segments/segment.interface';
import { PublicationFilter } from '@app/shared/models/filters/filters';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() filter!: PublicationFilter | undefined;
  @Input() segment: Segment | undefined;
  @Input() selected = false;
  @Input() selectedIds: number[] = [];
  @Output() checkboxChange = new EventEmitter<MSafeAny>();
  @Output() selectSubcategories = new EventEmitter<boolean>();
  @Output() toggleSubcategory = new EventEmitter<boolean>();

  isSubfilter!: boolean;

  ngOnInit(): void {
    if (this.filter) {
      this.checkFilterIsSubfilter();
    }
  }

  onChange() {
    if (this.filter) {
      const selected = !this.filter.selected;
      this.filter.selected = selected;
      this.checkboxChange.emit();

      this.selectSubcategories.emit(Boolean(this.filter.subfilters?.length && this.filter.selected));
      if (!this.filter.subfilters) {
        this.toggleSubcategory.emit(Boolean(this.filter.selected));
      }
    } else if (this.segment) {
      const selected = !this.segment.selected;
      this.segment.selected = selected;
      this.checkboxChange.emit();
    }
  }

  checkFilterIsSubfilter() {
    this.isSubfilter = Boolean(!this.filter?.subfilters);
  }
}

<div class="row">
  <div class="col">
    <app-ptrab-dropdown [items]="dateItems" (select)="onSelectDropdownOption($event)"></app-ptrab-dropdown>
  </div>
</div>

<div class="row" *ngIf="hasCompanies()">
  <div class="col">
    <app-ptrab-dropdown class="company" [items]="companyItems" (select)="onSelectCompany($event)"></app-ptrab-dropdown>
  </div>
</div>

import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { RootPageHelper } from '@app/app.root.page.helper';
import { PtrabSessionManager } from '@app/ptrab/services/session/ptrab-session.manager.service';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppManagerService } from '@services/app/app.manager.service';
import { AUTHENTICATION_STATUS, AuthService } from '@services/auth/auth.service';
import { AppError, ErrorMessages, ErrorTypes } from '@services/error/error.model';
import { ErrorService } from '@services/error/error.service';
import { LanguageService } from '@services/language/language.service';
import { Logger } from '@services/logger/logger.service';
import { MessageService } from '@services/message/message.service';
import { ModalManager } from '@services/modal-manager/modal-manager';
import { NavigationEvent, NavigationEvents, NavigationService } from '@services/navigation/navigation.service';
import { NetworkService } from '@services/network/network.service';
import { NotificationService } from '@services/notification/notification.service';
import { StorageService } from '@services/storage';
import { AlertService } from '@services/alert/alert.service';
import { UserSyncPropsService } from '@services/user/user-sync-props.service';
import { UserService } from '@services/user/user.service';
import { PAGES } from '@shared/enums/pages/pages.enum';
import { NotificationHandler } from '@shared/helpers/notification/notification.handler';
import { RouteHelper } from '@shared/helpers/route.helper';
import { ISocketMessage } from '@shared/interfaces/message/socket-message.interface';
import { domChanges } from '@shared/utils/utils';
import { ENV } from 'src/environments/environment';

import { AutoCloseOverlaysService } from './services/auto-close-overlays/auto-close-overlays.service';
import { LoadingService } from './services/loading/loading.service';
import { NavigationHelperService } from './services/navigation/navigation.helper.service';
import { ActionCookies, COOKIES_TIME, CookiesConfig, UserCookies } from './shared/models/cookies/cookies.config';
import { WebViewService } from './shared/services/webview.service';
import { DomSanitizer } from '@angular/platform-browser';

/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly logger: Logger = new Logger('AppComponent');
  private redirectOnLoginChange = true;
  private userBasicCookies!: boolean;
  private socketHandlers: MSafeAny;
  compWindow: MSafeAny = window;
  canGoBack!: boolean;
  anchorPage = '/home';
  authPage = `/${PAGES.AUTHENTICATION}`;
  subsUserBasicCookiesSubject!: Subscription;
  subsBackButtonSubject!: Subscription;
  subscriptionBackButton!: Subscription;
  trackedTO!: boolean;

  analyticsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
    'https://www.googletagmanager.com/ns.html?id=' + ENV.googleAnalyticsConfigKey
  );

  constructor(
    private appManagerService: AppManagerService,
    private authService: AuthService,
    private errorService: ErrorService,
    private language: LanguageService,
    private loadingService: LoadingService,
    private location: Location,
    private messageService: MessageService,
    private modalManager: ModalManager,
    private navigationService: NavigationService,
    private navigationHelperService: NavigationHelperService,
    private network: NetworkService,
    private notificationService: NotificationService,
    private platform: Platform,
    private ptrabSessionManager: PtrabSessionManager,
    private router: Router,
    private storageService: StorageService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private userService: UserService,
    private userSyncPropsService: UserSyncPropsService,
    private cookieConfig: CookiesConfig,
    private autoCloseOverlaysService: AutoCloseOverlaysService,
    private webViewService: WebViewService,
    private sanitizer: DomSanitizer
  ) {
    this.initializeApp();
  }

  ngOnDestroy() {
    this.unsubscribeShowCookies();
  }

  ngOnInit() {
    this.handleDeprecatedUrls();
  }

  private initializeApp() {
    this.platform.ready().then(async () => {
      this.initLogger();
      this.setSocketHandlers();
      this.subscribeToNavigationMessages();
      this.subscribeToErrors();
      this.language.init();
      this.appManagerService.init(this.network, this.ptrabSessionManager);
      await this.determineRootPage();

      window.onfocus = async () => {
        await this.userService.checkData();
      };

      this.subscribeToUserStatus();
      this.subscriptionBackButtonSubject();
    });
  }

  // Rewrite and force reload on Ionic3 urls
  private handleDeprecatedUrls() {
    this.router.events.pipe(take(1)).subscribe((value) => {
      if (this.isDeprecatedUrl(value)) {
        window.location.href = this.rewriteDeprecatedUrl((value as NavigationStart).url);
      }
    });
  }

  private isDeprecatedUrl(value: Event) {
    return (
      value instanceof NavigationStart && value?.url?.match(/^\/#/) && !RouteHelper.isAdfsPostLoginRedirect(value?.url)
    );
  }

  private rewriteDeprecatedUrl(url: string) {
    return url
      .replace('/#', '')
      .replace(/javascript:.*/i, '') // prevent script injection on url
      .replace('/contents', '')
      .replace('menu-', '')
      .replace('mercadona-publications', 'publications')
      .replace('sniffer', 'device-info')
      .replace('ptrab/personal-income-tax', 'ptrab/irpf')
      .replace('profile/personal-data', 'personal-data');
  }

  private initLogger() {
    if (!ENV.isDebugMode) {
      Logger.enableProductionMode();
    }
  }

  private setSocketHandlers() {
    this.socketHandlers = {
      LANGUAGE_UPDATED: (actionMessage: ISocketMessage) => {
        this.handleLanguageChange(actionMessage.data.language_code === 'pt' ? 'pt' : 'es');
      },
      IMAGE_UPDATED: () => {
        this.handleImageChange();
      },
      NOTIFICATION_CREATED: () => {
        this.getNotifications();
      },
      NOTIFICATION_REMOVED: (actionMessage: ISocketMessage) => {
        this.notificationService.removeStaticNotification(actionMessage.data.id);
      },
      NOTIFICATION_READ: (actionMessage: ISocketMessage) => {
        this.notificationService.checkAsReadStaticNotification(actionMessage.data.id);
      }
    };
  }

  private subscribeShowCookies() {
    this.subsUserBasicCookiesSubject = this.cookieConfig.userBasicCookies$.subscribe((status) => {
      this.userBasicCookies = status;
    });
  }

  private unsubscribeShowCookies() {
    if (this.subsUserBasicCookiesSubject) this.subsUserBasicCookiesSubject.unsubscribe();
  }

  private async checkIsExpiredCookies() {
    const notStoredCookie = await this.cookieConfig.checkIsExpiredCookiesLocal();
    if (!notStoredCookie) {
      this.userBasicCookies = true;
    } else if (notStoredCookie?.action === ActionCookies.RENEW) {
      this.renewCookieIos();
    } else {
      this.removeCookies();
    }
  }

  manageActionCookies(event: MSafeAny) {
    switch (event.action) {
      case ActionCookies.ACCEPT:
      case ActionCookies.SAVE:
        this.saveCookies(!event.cookies ? this.acceptAllCookies : event.cookies);
        break;
      case ActionCookies.REJECT:
        this.saveCookies(this.rejectCookies);
        break;
      case ActionCookies.REMOVE:
        this.removeCookies();
        break;
    }
  }

  get acceptAllCookies() {
    const cookies: UserCookies = {
      essentialsCookies: true,
      notEssentialsCookies: true,
      expireTimeInMilliseconds: COOKIES_TIME.expireTime
    };

    return cookies;
  }

  get rejectCookies() {
    const cookies: UserCookies = {
      essentialsCookies: true,
      notEssentialsCookies: false,
      expireTimeInMilliseconds: COOKIES_TIME.expireTime
    };
    return cookies;
  }

  removeCookies() {
    this.cookieConfig.removeCookies();
    this.userBasicCookies = false;
  }

  saveCookies(cookies: UserCookies, expiredTime?: Date) {
    this.cookieConfig.saveCookies(cookies, expiredTime);
    this.userBasicCookies = true;
    const currentUrl = this.router.url;
    this.router.navigate([currentUrl]).then(() => {
      this.compWindow.location.reload();
    });
  }

  async renewCookieIos() {
    const cookieResult = await this.cookieConfig.renewCookie();
    if (cookieResult?.action === ActionCookies.SAVE) {
      const cookieData = cookieResult.data;
      this.saveCookies(cookieData.cookies, cookieData.expiredTime);
    } else {
      this.removeCookies();
    }
  }

  showLightBoxCookies(): boolean {
    const isLogged = this.authService.isLoggedIn();
    const noBasicCookiesAcceptance = !this.userBasicCookies;
    if (!this.trackedTO && isLogged && noBasicCookiesAcceptance && !this.webViewService.isWebView()) {
      this.trackedTO = true;
    }
    return isLogged && noBasicCookiesAcceptance && !this.webViewService.isWebView();
  }

  private onMessage(message: ISocketMessage) {
    const messageKeys: MSafeAny = Object.keys(this.socketHandlers);
    if (messageKeys.includes(message.action)) {
      this.socketHandlers[message.action](message);
    }
  }

  private subscribeToNavigationMessages() {
    this.navigationService.navigationEvent$.subscribe(async (navigationEvent: NavigationEvent) => {
      await this.appManagerService.handleNavigationEvent(navigationEvent);
      this.checkIsExpiredCookies();
    });
  }

  private subscribeToUserStatus() {
    this.authService.loginChanged$.subscribe(() => {
      if (this.authService.getStatus() === AUTHENTICATION_STATUS.LOGGED_ADFS) {
        this.authService.initUserInfo();
      } else if (this.authService.getStatus() === AUTHENTICATION_STATUS.LOGGED_ACTIVO2) {
        this.onLogged();
        this.subscribeShowCookies();
        this.getUserServiceOffice();
      } else if (this.authService.getStatus() === AUTHENTICATION_STATUS.LOGOUT) {
        this.onLogout();
      }
    });
  }

  private getUserServiceOffice() {
    this.userService.getUserConfiguration().subscribe((config) => {
      this.storageService.setCheckOfficeService(config.headquartersSwitch);
    });
  }

  private subscribeToErrors() {
    // eslint-disable-next-line
    this.errorService.error$.subscribe(async (error: AppError) => {
      this.logger.error('subscribeToErrors error:', error);
      const activePage = this.router.url.replace('/', '');
      this.modalManager.dismissAllMatModal();

      switch (error?.type) {
        case ErrorTypes.MAINTENANCE_MODE: // Maintenance
          return this.manageMaintenanceMode(activePage);

        case ErrorTypes.USER_BANNED: // Banned
          return this.manageUserBanned(activePage);
        // Forbidden
        case ErrorTypes.FORBIDDEN:
          await this.determineRootPage(error.params);
          return;

        case ErrorTypes.TERMS_NOT_ACCEPTED:
          return this.manageTermsAccept(activePage);

        case ErrorTypes.CLOSE_APP:
          return this.manageCloseAppError(activePage);

        case ErrorTypes.NOT_FOUND:
          return this.manageNotFoundException(activePage);

        case ErrorTypes.OTHER: // Other
          return this.manageOtherErrors(activePage);

        case ErrorTypes.ON_BOARDING_REQUIRED:
          return this.manageOnboardingRequired(activePage);
        default:
          return this.manageDefaultErrors(error, activePage);
      }
    });
  }

  private manageMaintenanceMode(activePage: string) {
    if (activePage !== PAGES.MAINTENANCE) {
      this.appManagerService.setMaintenanceMode();
    }
  }

  private manageUserBanned(activePage: string) {
    if (activePage !== PAGES.BANNED) {
      this.appManagerService.setBannedStatus();
    }
  }

  private manageTermsAccept(activePage: string) {
    if (activePage !== PAGES.TERMS_AND_CONDITIONS_INTER) {
      this.appManagerService
        .redirect(NavigationEvents.Push, { path: PAGES.TERMS_AND_CONDITIONS_INTER })
        .catch((err) => this.logger.error(err));
    }
  }

  private manageCloseAppError(activePage: string) {
    if (activePage !== PAGES.CLOSE_APP) {
      this.appManagerService.redirect(NavigationEvents.Push, { path: PAGES.CLOSE_APP });
    }
  }

  private manageNotFoundException(activePage: string) {
    if (activePage !== PAGES.ERROR_404) {
      this.appManagerService
        .redirect(NavigationEvents.Push, { path: PAGES.ERROR_404 })
        .catch((err) => this.logger.error(err));
    }
  }

  private manageOtherErrors(activePage: string) {
    if (activePage !== PAGES.ERROR) {
      this.appManagerService
        .redirect(NavigationEvents.Push, { path: PAGES.ERROR })
        .catch((err) => this.logger.error(err));
    }
  }

  private manageOnboardingRequired(activePage: string) {
    if (activePage !== PAGES.ON_BOARDING) {
      this.appManagerService
        .redirect(NavigationEvents.Push, { path: PAGES.ON_BOARDING })
        .catch((err) => this.logger.error(err));
    }
  }

  private async manageDefaultErrors(error: AppError, activePage: string) {
    if (this.checkIsOfflineError(error)) {
      return this.manageOfflineError();
    } else if (error.isCritical()) {
      this.manageCriticalError(activePage);
    } else if (error.isAuthError()) {
      await this.manageAuthError(error);
    }
  }

  private checkIsOfflineError(error: AppError): boolean {
    return (
      error?.type === ErrorTypes.OFFLINE ||
      error.hasOneOfMessages([ErrorMessages.NETWORK_ERROR, ErrorMessages.ADDRESS_UNREACHABLE])
    );
  }

  private manageOfflineError() {
    this.network.hasServerConnection().then((isOnline) => {
      if (!isOnline) {
        return window.location.reload();
      }
    });
  }

  private manageCriticalError(activePage: string) {
    if (activePage !== PAGES.ERROR) {
      this.appManagerService.setErrorStatus(true);
    }
  }

  private async manageAuthError(error: AppError) {
    // Auth
    if (error.isOfType(ErrorTypes.ERROR_LOGIN_SSO)) {
      await domChanges(500);
      return this.navigationHelperService.doRedirect(NavigationEvents.SetRoot, { path: PAGES.LOGOUT });
    }
    return this.appManagerService.redirect(NavigationEvents.SetRoot, { path: PAGES.LOGOUT });
  }

  subscriptionBackButtonSubject() {
    this.subsBackButtonSubject = this.autoCloseOverlaysService.unsubscribeBackButtonSubject$.subscribe(
      async (statusSubscribe) => {
        if (statusSubscribe === 'subscribe') {
          await this.subsBackButton();
        } else {
          this.manageCustomEventBackAction();
        }
      }
    );
  }

  manageCustomEventBackAction(fromFocusLost?: boolean) {
    if (!this.canGoBack && !this.navigationHelperService.getPreviousUrl()) {
      if (
        this.navigationHelperService.getPreviousUrl() !== this.anchorPage &&
        this.navigationHelperService.getPreviousUrl() !== this.authPage
      ) {
        this.navigationHelperService.doRedirect(NavigationEvents.SetRoot, { path: PAGES.HOME });
      } else {
        this.unsubscribeBackButtonSubject();
        this.unsubscribeBackButton();
      }
    } else if (fromFocusLost) {
      const navEvent = new NavigationEvent(NavigationEvents.Pop, undefined);
      this.navigationService.navigate(navEvent);
    }
  }

  unsubscribeBackButtonSubject() {
    if (this.subsBackButtonSubject) this.subsBackButtonSubject.unsubscribe();
  }

  async subsBackButton() {
    this.subscriptionBackButton = this.platform.backButton.subscribe(async () => {
      this.canGoBack = Boolean(this.navigationHelperService.getPreviousUrl());
      await this.autoCloseOverlaysService.trigger();
    });

    // Bug on cordova-android when the app lost focus https://github.com/apache/cordova-android/issues/1206
    this.subscribeAndroidBackButtonOnLostAPPFocus();
  }

  subscribeAndroidBackButtonOnLostAPPFocus() {
    document.addEventListener(
      'myCustomBackEvent',
      () => {
        this.manageCustomEventBackAction(true);
      },
      false
    );
  }

  unsubscribeBackButton() {
    if (this.subscriptionBackButton) this.subscriptionBackButton.unsubscribe();
  }

  private async handleImageChange() {
    this.userService.syncImage().catch((error) => {
      this.logger.error('Error retrieving image', error);
    });
  }

  private handleLanguageChange(language_code: string) {
    this.userService.storeLanguage(language_code);
    this.getNotifications(language_code);
  }

  private async onLogged() {
    const userInfo = await this.storageService.getUserData();

    if (!ENV.minimizeRealTimeRequests) {
      this.userSyncPropsService
        .syncAllProperties()
        .then((success) => this.logger.debug(success))
        .catch(() => this.network.redirectIfOffline());
    }

    this.language.setLanguage();

    this.messageService.init();
    this.messageService.messages$.subscribe((message: ISocketMessage) => {
      this.logger.info('Message Service: new message:', message);
      this.onMessage(message);
    });

    this.getNotifications();

    if (this.redirectOnLoginChange) {
      await this.appManagerService.redirectAfterSuccessUserInfoInit(userInfo).catch((err) => this.logger.error(err));
    }
    this.appManagerService.userInfoCompleted();

    NotificationHandler.init(
      this.navigationService,
      this.notificationService,
      this.alertService,
      this.translateService,
      this.modalManager,
      this.ptrabSessionManager,
      this.appManagerService,
      this.loadingService,
      this.language
    );

    this.checkIsExpiredCookies();
  }

  private onLogout() {
    this.logger.info('Logout');
    this.authService.logoutComplete();
    this.language.setLanguageFromBrowser();
    this.messageService.disconnect();
    this.errorService.reset();

    this.navigationService.navigate(new NavigationEvent(NavigationEvents.SetRoot, { path: PAGES.LOGOUT }));
  }

  private async determineRootPage(navParams = {}) {
    this.logger.debug(`determineRootPage: Location is ${JSON.stringify(location)}`);

    const path = this.getPath(navParams);
    this.logger.debug(`determineRootPage: getPath is "${path}"`);

    const isLogged = this.authService.isLoggedIn() || (await this.authService.hasAdfsSession());
    const rootPageHelper = new RootPageHelper(this.webViewService, path, ENV.guards.mobileweb, isLogged);

    const rootPage = rootPageHelper.getInitialInternalRoute(navParams);
    this.logger.debug(`determineRootPage: calculated root page is ${JSON.stringify(rootPage)}`);

    if (!rootPage.path) {
      this.logger.debug(
        `determineRootPage: Page refresh, direct link or other route not considered as rootPage: "${path}"`
      );
      rootPage.path = RouteHelper.getPageFromPath(path);
      await this.appManagerService.rootPageStablished(rootPage);
    } else {
      this.logger.debug(`determineRootPage: Redirect to "${JSON.stringify(rootPage)}"`);
      try {
        const navigationExtras: NavigationExtras = { state: rootPage.navParams };
        await this.appManagerService.rootPageStablished(rootPage);

        await this.router.navigateByUrl(rootPage.path, navigationExtras);
      } catch (error) {
        this.logger.error(error);
      }
    }

    if (isLogged && !RouteHelper.isPublic(rootPage)) {
      this.redirectOnLoginChange = false;
      this.logger.debug('determineRootPage: user was logged and is private path, init user info...');
      await this.authService.initUserInfo();
    }
  }

  private getPath(params: MSafeAny) {
    const hash = location.hash || params.hash;
    const path = this.location.path();
    return hash || path;
  }

  private async getNotifications(lang?: string) {
    this.logger.debug('getNotifications call...');
    const languageCode = lang || (await this.language.getCurrentLanguage().toPromise());
    this.notificationService
      .assignAppNotifications(languageCode as string)
      .pipe(
        catchError((err) => {
          this.logger.error('Error on getting notifications', err);
          return of(null);
        })
      )
      .subscribe();
  }
}

export interface CivilStatusResponse {
  civil_status: number;
  civil_status_name: string;
  fiscal_status: 0 | 1;
}

export class CivilStatus {
  id: number;
  name: string;
  fiscalStatus: number;

  constructor(data: CivilStatusResponse) {
    this.id = data.civil_status;
    this.name = data.civil_status_name;
    this.fiscalStatus = data.fiscal_status;
  }
}

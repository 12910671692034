<app-scrollable-x
  *ngIf="!selected"
  [class.scroll-container-tag]="filterType === filterTypeSection.PUBLICATION"
  (arrowClick)="scrollArrow.emit()"
>
  <div class="d-flex">
    <app-filter-tag
      *ngFor="let filter of list; trackBy: trackByItems"
      [class.scroll-container-tag]="filterType === filterTypeSection.PUBLICATION"
      (selectOption)="selectFilter(filter)"
      [filterTag]="filter"
    ></app-filter-tag>
  </div>
</app-scrollable-x>
<div class="filter-selected" *ngIf="selected">
  <app-filter-tag
    [class.scroll-container-tag]="filterType === filterTypeSection.PUBLICATION"
    [filterTag]="selected"
    (clear)="clearFilter()"
    [selected]="true"
  ></app-filter-tag>
</div>

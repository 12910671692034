import { Component, Output, EventEmitter, Input } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss']
})
export class SegmentComponent {
  @Input() items: MSafeAny[] = [];
  @Input() default = 0;
  @Output() segmentClicked: EventEmitter<MSafeAny> = new EventEmitter();

  segmentChanged(ev: MSafeAny) {
    this.segmentClicked.emit(ev.value);
  }

  trackByItems(item: MSafeAny) {
    return item;
  }
}

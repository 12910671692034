import { Component, EventEmitter, Output } from '@angular/core';

import { CookiesModalComponent } from '@app/components/modals/cookies-modal/cookies-modal.component';
import { ModalManager, NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { ActionCookies } from '@app/shared/models/cookies/cookies.config';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-lightbox-cookies',
  templateUrl: './lightbox-cookies.component.html',
  styleUrls: ['./lightbox-cookies.component.scss']
})
export class LightboxCookiesComponent {
  errorTitleCookie!: string;
  errorMessageCookie!: string;
  @Output() action: EventEmitter<MSafeAny> = new EventEmitter();

  constructor(private navigationservice: NavigationService, private modalManager: ModalManager) {}

  acceptCookies() {
    this.action.emit({ action: ActionCookies.ACCEPT });
  }

  rejectCookies() {
    this.action.emit({ action: ActionCookies.REJECT });
  }

  goToCookiesPage() {
    const navEvent = new NavigationEvent(NavigationEvents.Push, { path: PAGES.COOKIES });
    this.navigationservice.navigate(navEvent);
  }

  async configCookies() {
    const container = document.getElementById('lightbox-cookies');
    const cookiesModal = await this.modalManager.createMatModal(CookiesModalComponent, {
      data: { hasAcceptedCookies: false },
      panelClass: 'cookies-modal'
    });

    cookiesModal?.afterOpened().subscribe(() => {
      container?.classList.add('hidden-container');
    });
    cookiesModal?.afterClosed().subscribe((event) => {
      container?.classList.remove('hidden-container');
      if (event) {
        this.action.emit({ action: event.action, cookies: event.cookies });
      }
    });
  }
}

<div *ngIf="caption" #captionContainer class="caption is-desktop">
  <div
    class="caption__element"
    *ngFor="let captionElement of caption; trackBy: trackByItems"
    (click)="selectCaption(captionElement)"
    #mPopover="m-popover"
    popoverPosition="bottomRight"
    [m-popover]="popoverTemplate"
    [customPanelClass]="popover ? 'custom' : 'hidden'">
    <div class="caption-element d-flex" *ngIf="captionElement">
      <div class="caption-element__circle" [ngStyle]="getCaptionStyle(captionElement)"></div>
      <p class="caption-element__text u-p6 u-text-medium">{{ captionElement.name }}</p>
    </div>
  </div>
</div>

<ng-template #popoverTemplate>
  <div class="info-popover__content">
    <p>{{ selectedCaption.description }}</p>
  </div>
</ng-template>

<app-input-wrapper [label]="label">
  <app-item *ngIf="items" class="item-selected" (click)="openOptions()">
    <h6 *ngIf="handleItemLabel()" class="u-h6 u-dark-grey">
      {{ startMonth | translate }} {{ startYear }} - {{ endMonth | translate }} {{ endYear }}
    </h6>
    <h6 *ngIf="!handleItemLabel()" class="u-h6 u-dark-grey">{{ selectedItemLabel | translate }}</h6>

    <i [class.reverse-arrow]="toggle" item-end class="app-icon-arrow-down"></i>
  </app-item>
</app-input-wrapper>

<div #dropdown_item_list class="list dropdown animated fadeIn faster" [hidden]="!toggle">
  <app-item
    class="dropdown-item"
    id="item-{{ item.value }}"
    *ngFor="let item of items; trackBy: trackByItems"
    (click)="selectItem(item)">
    <div class="content">
      <h6 *ngIf="item.value?.filter === 'custom-range'" class="u-h6 u-dark-grey">
        {{ startMonth | translate }} {{ startYear }} - {{ endMonth | translate }} {{ endYear }}
      </h6>
      <h6 *ngIf="item.value?.filter !== 'custom-range'" class="u-h6 u-dark-grey">{{ item.label | translate }}</h6>
      <img *ngIf="item.selected" item-end src="assets/imgs/employee-portal/green-check.svg" alt="Check" />
    </div>
  </app-item>
</div>

<app-avatar-comment *ngIf="!canChooseUser()" [avatarUser]="userSelected.photo"></app-avatar-comment>

<div *ngIf="canChooseUser()">
  <div class="avatar-comment-selector__toggle" (click)="toggleAvatarSelector()">
    <app-avatar-comment [avatarUser]="userSelected.photo"></app-avatar-comment>
    <ion-icon [class.reverse-arrow]="showAvatarSelector" item-end name="icon-arrow-down"></ion-icon>
  </div>

  <div
    [@visibilitySelector]="getSelectorVisibility()"
    class="list avatar-comment-selector__list"
    [hidden]="!showAvatarSelector"
    [class.in]="showAvatarSelector">
    <app-item
      no-lines
      class="avatar-comment-selector__user"
      [class.selected]="isUserSelected(user)"
      *ngFor="let user of userList; trackBy: trackByItems; odd as isOdd"
      (click)="onSelectUser(user)">
      <app-avatar-comment [avatarUser]="user.photo"></app-avatar-comment>
      <span>{{ user.name | nounUpperCase }} {{ user.lastname | nounUpperCase }}</span>
      <span *ngIf="isCurrentUser(user)">&nbsp;({{ 'YOU' | translate }})</span>
    </app-item>
  </div>
</div>

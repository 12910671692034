import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ContentType } from '@app/shared/enums/multimedia/content-status-types.enum';
import { ExtensionsManager } from '@app/shared/extensions/extensions-manager';
import { TranslateService } from '@ngx-translate/core';

import { MultimediaService } from '../../services/multimedia/multimedia.service';
import { ImageItem, MultimediaItem } from '../example-form/example-form.component';

export interface MultimediaManagerEvent {
  type: ContentType;
  multimedia: MultimediaItem | ImageItem[];
}

@Component({
  selector: 'app-multimedia-manager',
  templateUrl: './multimedia-manager.component.html',
  styleUrls: ['./multimedia-manager.component.scss'],
  providers: [MultimediaService]
})
export class MultimediaManagerComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() inputMsg = 'MULTIMEDIA_FORM.ADD_ELEMENT';
  @Input() showIcon = true;
  @Input() showFooterMsg = true;
  @Output() addMultimedia = new EventEmitter<MultimediaManagerEvent>();

  extensions!: string;

  private extensionsManager = new ExtensionsManager<ContentType>();

  constructor(private translate: TranslateService, private multimediaService: MultimediaService) {}

  ngOnInit() {
    this.extensions = this.extensionsManager.getParsedExtensions();
  }

  onClickAddFile() {
    this.fileInput.nativeElement.click();
  }

  onChangeInputFile(selectedFiles: FileList) {
    const multimediaItems = this.multimediaService.selectDesktopFiles(selectedFiles) as MultimediaItem[];
    const firstFile = multimediaItems[0].file;

    const actions = {
      image: () => this.setImages(multimediaItems),
      video: () => multimediaItems[0]
    };

    const type = this.extensionsManager.getContentType(firstFile as Blob);

    if (actions[type]) {
      const multimedia = actions[type]();
      this.fileInput.nativeElement.value = '';
      this.emitMultimedia(type, multimedia);
    }
  }

  private setImages(multimedia: MultimediaItem[]): ImageItem[] {
    return multimedia.map(({ file, preview }: MultimediaItem, i: number) => ({
      file,
      preview,
      isThumbnail: i === 0
    }));
  }

  private emitMultimedia(type: ContentType, multimedia: MultimediaItem | ImageItem[]) {
    if (type && multimedia) {
      this.addMultimedia.emit({ type, multimedia });
    }
  }
}

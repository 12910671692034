export enum ShareAppIds {
  FacebookIos = 'com.apple.social.facebook',
  FacebookAndroid = 'com.facebook.katana',
  TwitterIos = 'com.apple.social.twitter',
  TwitterAndroid = 'com.twitter.android'
}

export enum ShareVias {
  EMAIL,
  FACEBOOK,
  TWITTER,
  LINK
}

export interface IShareItem {
  type: ShareVias;
  src: string;
  alt: string;
  translateKey?: string;
  text?: string;
}

export interface IShareSlide {
  shareItems: IShareItem[];
}

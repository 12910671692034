<div
  class="richtext-editor__editor"
  (click)="scrollToTopDescription()"
  [class.richtext-editor--focused]="editorContent?.length > 0 && multiLine">
  <ckeditor
    #richtext
    no-blur
    name="richtext"
    [editor]="editor"
    [config]="editorOptions"
    (ready)="onReady($event)"
    (change)="handleModelChange()"
    (focus)="handleFocus()"
    [(ngModel)]="editorContent">
  </ckeditor>
  <button *ngIf="showEmojiButton" class="richtext-editor__emoji-button" (click)="toggleEmojiPicker()">
    <i class="app-icon-happy-emoticon"></i>
  </button>
  <app-emoji-picker *ngIf="isPickerVisible" (emojiSelected)="appendEmoji($event)" (closePicker)="closePicker($event)">
  </app-emoji-picker>
</div>
<div class="richtext-editor__actions" *ngIf="showActions">
  <span class="richtext-editor__counter">{{ remainingChars }}</span>
  <div class="richtext-editor__buttons">
    <ng-content select="button.c-button"></ng-content>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TwoFactorOperationCode } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { Phone } from '@app/ptrab/shared/models/phone';
import { ModalManager } from '@app/services';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ptrab-second-fa-phone-selection',
  templateUrl: './second-fa-phone-selection.component.html',
  styleUrls: ['./second-fa-phone-selection.component.scss']
})
export class SecondFaPhoneSelectionComponent implements OnInit {
  phones!: Phone[];
  toastMessage!: string;

  selectedPhoneId!: string | null | undefined;
  description!: string;
  reminderMessage!: string;
  isSinglePhone!: boolean;
  availablePhones!: Phone[];

  buttons = [
    {
      text: 'EMPLOYEE_PORTAL.PHONE_NOT_FOUND',
      type: 'link',
      enabled: true,
      onClick: () => this.modalManager.dismissMatModal(TwoFactorOperationCode.REQUEST_OTHER_PHONE)
    },
    {
      text: 'EMPLOYEE_PORTAL.CONTINUE',
      type: 'primary',
      enabled: false,
      onClick: () => this.close(true)
    }
  ];

  private messages!: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny,
    private translateService: TranslateService,
    private modalManager: ModalManager
  ) {
    this.getTranslations();
  }

  ngOnInit() {
    this.phones = this.data.phones;
    this.toastMessage = this.data.toastMessage;

    this.availablePhones = this.phones.filter((phone) => phone.sms);
    this.isSinglePhone = this.availablePhones && this.availablePhones.length === 1;
    this.checkPhones();
  }

  get getUniquePhone(): Phone | undefined {
    return this.availablePhones.find((phone) => phone.sms);
  }

  close(withValue: boolean) {
    if (!withValue) {
      this.selectedPhoneId = null;
    }

    this.modalManager.dismissMatModal(this.selectedPhoneId);
  }

  setSelectedPhone(phoneId: string | undefined) {
    this.selectedPhoneId = phoneId;
    this.buttons[1].enabled = true;
  }

  trackByItems(item: Phone) {
    return item;
  }

  private checkPhones() {
    this.reminderMessage = this.messages['EMPLOYEE_PORTAL.ONLY_MOBILE'];

    if (this.isSinglePhone) {
      this.description = this.messages['EMPLOYEE_PORTAL.SINGLE_PHONE.MESSAGE'];
      this.setSelectedPhone(this.getUniquePhone?.id);
      return;
    }
    this.description = this.messages['EMPLOYEE_PORTAL.SELECT_NUMBER'];
  }

  private getTranslations() {
    this.translateService
      .get(['EMPLOYEE_PORTAL.SELECT_NUMBER', 'EMPLOYEE_PORTAL.ONLY_MOBILE', 'EMPLOYEE_PORTAL.SINGLE_PHONE.MESSAGE'])
      .subscribe((messages) => (this.messages = messages));
  }
}

import { Permissions } from '@app/shared/enums/permissions/permissions.enum';
import { flatten } from '@app/shared/utils/utils';

export const MOT_SECTIONS_PERMISSIONS: { [key: string]: Permissions[] } = {
  MOT_SCHEDULE: [
    Permissions.MOT_PERMISSION,
    Permissions.MOT_CALENDAR,
    Permissions.MOT_MONTH_VIEW,
    Permissions.MOT_WEEK_VIEW,
    Permissions.MOT_SIGNED_TIMETABLES
  ],
  MOT_VACATIONS: [Permissions.MOT_VACATIONS, Permissions.MOT_VACATIONS_CALENDAR, Permissions.MOT_VACATIONS_PDF],
  MOT_MANAGE_VACATIONS: [Permissions.MOT_VACATIONS_PROPOSAL_LIST, Permissions.MOT_VACATIONS_PROPOSAL_EDIT]
};

export const MOT_PERMISSIONS = flatten(Object.values(MOT_SECTIONS_PERMISSIONS));

import { Component, Inject, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { Logger, ModalManager, NetworkService, AlertService } from '@app/services';
import { LoadingService } from '@app/services/loading/loading.service';
import { ExampleService } from '@app/services/multimedia/example/example.service';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { Example } from '@app/shared/models/multimedia/example.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-example-confirmation-modal',
  templateUrl: './example-confirmation-modal.component.html',
  styleUrls: ['./example-confirmation-modal.component.scss']
})
export class ExampleConfirmationModalComponent implements OnInit {
  exampleItem!: Example;

  private logger = new Logger('ExampleConfirmationModalComponent');
  private messages: MSafeAny;

  readonly buttons: Buttons[] = [
    {
      text: 'CANCEL',
      type: 'secondary',
      enabled: true,
      onClick: () => this.close(false)
    },
    {
      text: 'CONFIRMATION_MODAL.REVIEW_BUTTON',
      type: 'primary',
      enabled: true,
      onClick: () => this.sendToReview(this.exampleItem)
    }
  ];

  constructor(
    private modalManager: ModalManager,
    private translate: TranslateService,
    private exampleService: ExampleService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private network: NetworkService,
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.exampleItem = this.data.exampleItem;
  }

  close = (success) => this.modalManager.dismissMatModal(success);

  private sendToReview(exampleItem: Example) {
    this.logger.info('Sent to review');
    this.loadingService.show();
    this.exampleService
      .sendToReview(exampleItem.id)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        () => {
          this.exampleService.reloadExamples();
          this.alertService.showSuccess(
            this.messages['CONFIRMATION_MODAL.REVIEW_SUCCESS_TITLE'],
            this.messages['CONFIRMATION_MODAL.REVIEW_SUCCESS_CONTENT'],
            3000
          );
          this.close(true);
        },
        (err) => {
          this.logger.error(err);
          if (this.network.hasConnection()) {
            this.alertService.showError(
              this.messages['ERRORS_TOASTS.GENERIC_MSG'],
              this.messages['ERRORS_TOASTS.GENERIC_MSG'],
              3000
            );
          }
        }
      );
  }

  private getTranslations() {
    this.translate
      .get([
        'CONFIRMATION_MODAL.REVIEW_SUCCESS_TITLE',
        'CONFIRMATION_MODAL.REVIEW_SUCCESS_CONTENT',
        'ERRORS_TOASTS.GENERIC_MSG',
        'ERRORS_TOASTS.CANNOT_SEND'
      ])
      .subscribe((messages) => (this.messages = messages));
  }
}

import { AfterViewInit, Component, HostBinding, HostListener, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ScrollHelper } from '@app/shared/helpers/scroll.helper';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ContentComponent } from '../content/content.component';

const DEFAULT_SCROLL_SPEED = 1000;
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent implements AfterViewInit, OnDestroy {
  @Input() mainContent!: ContentComponent;
  @Input() scrollSpeed = DEFAULT_SCROLL_SPEED;

  shouldShowScrollToTop!: boolean;
  scrollHelper = new ScrollHelper();

  private readonly TIME_TO_HIDE = 5000;
  private scrollTimeout: MSafeAny;
  private scrollSubscription!: Subscription;

  @HostBinding('class.d-none') get hideScrollToTop() {
    return !this.shouldShowScrollToTop;
  }

  @HostListener('click') scrollUp() {
    this.mainContent.scrollToTop();
    this.shouldShowScrollToTop = false;
  }

  ngAfterViewInit() {
    this.subscribeScroll();
  }

  subscribeScroll() {
    this.scrollSubscription = this.mainContent.scrolled.subscribe((scrollEvent: CustomEvent) => {
      this.scrollHelper.updateNative(scrollEvent.target as HTMLElement);
      this.shouldShowScrollToTop = this.scrollHelper.isScrollingToTop && !this.scrollHelper.isAtTop;

      window.clearTimeout(this.scrollTimeout);

      if (this.scrollHelper.isAtBottom) {
        this.shouldShowScrollToTop = true;
        return;
      }

      if (this.shouldShowScrollToTop) {
        this.scrollTimeout = window.setTimeout(() => {
          this.shouldShowScrollToTop = false;
        }, this.TIME_TO_HIDE);
      }
    });
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
}

import { NotificationStatus } from '@shared/enums/notification/notification.enum';
import { Notification } from '@shared/models/notification/notification.model';

export class NotificationHelper {
  static remove(id: string, notifications: Notification[]) {
    return notifications.filter((notification: Notification) => notification.id !== id);
  }

  static updateNotificationsWhenRead(id: string, notifications: Notification[]) {
    return notifications.map((notification: Notification) => {
      if (notification.id.includes(id)) {
        notification.status = NotificationStatus.OPENED;
      }

      return notification;
    });
  }

  static getUnread(notifications: Notification[]) {
    return notifications.filter((notification) => notification.status === NotificationStatus.NEW);
  }
}

import { Directive, ElementRef, Injector, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { CommentsSectionComponent } from '@app/components/comments-section/comments-section.component';
import { ExampleConfirmationModalComponent } from '@app/components/modals/example-confirmation-modal/example-confirmation-modal.component';
import { AlertService, Logger, ModalManager, NetworkService } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { MediaPlayerService } from '@app/services/media-player/media-player.service';
import { ExampleService } from '@app/services/multimedia/example/example.service';
import { ItemChangeNotifier } from '@app/services/multimedia/item-change-notifier.service';
import { PublicationService } from '@app/services/multimedia/publication.service';
import { PhotoViewerService } from '@app/services/photo-viewer/photo-viewer.service';
import { VimeoStatus } from '@app/shared/constants/multimedia/vimeo.constants';
import { UserCommentPermission } from '@app/shared/enums/comment/comment.enum';
import { MultimediaDetailRenderMode } from '@app/shared/enums/multimedia/multimedia-detail.enum';
import { ExampleRequestStatusEnum } from '@app/shared/interfaces/multimedia/example.request.interface';
import { IMultimediaService } from '@app/shared/interfaces/multimedia/multimedia.service.interface';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { Example } from '@app/shared/models/multimedia/example.model';
import { MultimediaMetadata } from '@app/shared/models/multimedia/multimedia-metadata.model';
import { AbstractMultimediaItem } from '@app/shared/models/multimedia/multimedia.abstract.model';
import { VideoExample } from '@app/shared/models/multimedia/video.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { WebViewService } from '@app/shared/services/webview.service';
import { domChanges, isNullOrUndefined } from '@app/shared/utils/utils';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { CategoriesAnalytics } from './../../../services/analytics/models/analytics.enum';
import { ContentComponent } from '@app/components/content/content.component';
import { MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';

@Directive()
export abstract class MultimediaDetailDirective implements OnInit, OnChanges {
  @ViewChild(CommentsSectionComponent) commentsComponent!: CommentsSectionComponent;
  @ViewChild(CommentsSectionComponent, {
    read: ElementRef
  })
  commentsComponentRef!: ElementRef<HTMLElement>;

  @Input() detailRenderMode!: MultimediaDetailRenderMode;
  @Input() inputItem!: AbstractMultimediaItem;
  @Input() isModal = false;
  @Input() publishIsDisabled!: boolean;
  @Input() multimediaMetadata!: MultimediaMetadata;
  @Input() mainContent!: ContentComponent;
  @Input() isPublicMultimedia!: boolean;

  abstract item: AbstractMultimediaItem;
  abstract logger: Logger;

  provider!: IMultimediaService;
  exampleStatus!: ExampleRequestStatusEnum | string;
  isLoadingItem = true;
  isLoadingFile = false;
  isDraft!: boolean;
  messages!: string[];
  draftButtons!: Buttons[];
  userCommentPermission!: UserCommentPermission;
  commentsAllowed!: boolean;
  userIsOwner!: boolean;
  userIsPresident!: boolean;
  statusInfoTitle!: string;
  canShowStatusInfo!: boolean;
  isOfficialContent!: boolean;
  analyticsCategory!: string;
  isSendingLike = false;

  private isShareModalClosing!: boolean;
  private hasBeenUpdatedViews!: boolean;

  private publicationService!: PublicationService;
  private exampleProvider!: ExampleService;
  private translate!: TranslateService;
  private modalCtrl!: ModalController;
  private itemChangeNotifier!: ItemChangeNotifier;
  protected analyticsService!: AnalyticsService;
  protected webViewService!: WebViewService;
  protected modalManager!: ModalManager;
  protected platform!: Platform;
  protected alertService!: AlertService;
  protected network!: NetworkService;
  protected photoViewerService!: PhotoViewerService;
  protected router!: Router;
  protected data!: MSafeAny;
  playerService!: MediaPlayerService;

  constructor(injector: Injector) {
    this.injectProviders(injector);

    this.modalCtrl.getTop().then((modal) => modal && modal.onWillDismiss().then(this.beforeDismiss));

    this.getTranslations();
  }

  ngOnChanges() {
    this.item = this.inputItem || this.multimediaMetadata?.item;
  }

  ngOnInit() {
    if (this.data) {
      this.multimediaMetadata = this.data.multimediaMetadata;
      this.exampleStatus = this.data.exampleStatus;
      this.isModal = this.data.isModal;

      if (this.data.inputItem) {
        this.inputItem = this.data.inputItem;
      }
    }

    const item = this.inputItem || this.multimediaMetadata?.item;
    this.isOfficialContent = item.isOfficialContent();
    this.provider = this.isOfficialContent ? this.publicationService : this.exampleProvider;

    const page = this.isOfficialContent ? PagesAnalytics.PUBLICATION : PagesAnalytics.EXAMPLE;
    const pageAnalytics = item.getAnalyticsDetailPage(page);

    this.analyticsCategory = item.getAnalyticsCategory(pageAnalytics);

    if (!this.isShareModalClosing) {
      this.playerService.stop();
    }
    this.isShareModalClosing = false;
  }

  beforeDismiss = () => {};

  close(publishDraft = false) {
    const event = {
      item: this.item,
      publishDraft
    };
    this.modalManager.dismissMatModal(event);
  }

  getItem(): Observable<AbstractMultimediaItem> {
    const getItemObs = this.multimediaMetadata
      ? of(this.multimediaMetadata)
      : this.provider.getItemWithMetadata(this.inputItem.id);

    return getItemObs.pipe(
      tap((itemMetadata) => {
        this.item = itemMetadata.item;
        this.userCommentPermission = itemMetadata.userCommentPermission;
        this.userIsOwner = itemMetadata.userIsOwner;
        this.userIsPresident = itemMetadata.userIsPresident;
        this.commentsAllowed = Boolean(itemMetadata.item.comments_allowed);
        this.initDetailData();
        this.setExampleStatus();
      }),
      map((i) => i.item),
      finalize(() => {
        this.isLoadingItem = false;
      })
    );
  }

  initDetailData() {
    this.exampleStatus = !this.isOfficialContent ? (this.item as Example).status_request : '';
    this.isDraft = this.exampleStatus === ExampleRequestStatusEnum.DRAFT;

    this.canShowStatusInfo = this.showStatusInfo();
    this.statusInfoTitle = this.getStatusInfoTitle();
    this.draftButtons = this.getDraftButton() as Buttons[];
  }

  setExampleStatus() {
    if (this.isOfficialContent) {
      return;
    }

    if (this.exampleStatus === undefined) {
      this.exampleStatus = (this.item as Example).status_request;
      this.isDraft = this.exampleStatus === ExampleRequestStatusEnum.DRAFT;
    }
  }

  isPublicOrOfficialContent() {
    return (this.item as Example).is_public || this.isOfficialContent;
  }

  hasCountViews() {
    return this.exampleStatus === ExampleRequestStatusEnum.PUBLISH || this.isOfficialContent;
  }

  sendLike(content: MSafeAny) {
    if (!this.isSendingLike) {
      this.item.liked = !this.item.liked;
      if (this.item.liked) {
        this.sendAnalyticsEvent(CategoriesAnalytics.LIKE);
      }
      this.isSendingLike = true;
      this.sendLikeEvent(content.liked);

      this.provider
        .sendLike(content.id, content.liked)
        .pipe(
          finalize(() => {
            this.isSendingLike = false;
          })
        )
        .subscribe(
          (isLiked: MSafeAny) => {
            content.liked = isLiked.like;
            if (this.item && !isNullOrUndefined(this.item.likes)) {
              if (content.liked) {
                this.item.likes++;
              } else {
                this.item.likes--;
              }
            }

            this.itemChangeNotifier.emit(this.item, 'likes');
          },
          () => {
            this.alertService.showError(
              this.messages['ERRORS_TOASTS.ERROR_TITLE'],
              this.messages['ERROR_MESSAGES.LIKE_ACTION_FAILED']
            );
          }
        );
    }
  }

  getDraftButton(): Buttons[] | undefined {
    if (!this.isDraft && !this.isDeniedExample()) {
      return [];
    }

    if (this.isDraft) {
      return [
        {
          text: 'CONFIRMATION_MODAL.REVIEW',
          type: 'primary',
          enabled: true,
          onClick: () => this.publishDraft()
        }
      ];
    }

    if (this.isDeniedExample()) {
      return [
        {
          text: 'CONFIRMATION_MODAL.REVIEW',
          type: 'primary',
          enabled: !this.item.isPublishDisabled(),
          onClick: () => this.sendToReviewDeniedPublish()
        }
      ];
    }
  }

  sendToReviewDeniedPublish() {
    const modalOpts: MatDialogConfig = {
      data: { exampleItem: this.item },
      disableClose: true,
      panelClass: 'base-modal'
    };
    this.close(true);
    this.modalManager.openMatModal(ExampleConfirmationModalComponent, modalOpts);
  }

  publishDraft() {
    if (this.publishIsDisabled) {
      return;
    }

    if ((this.item as VideoExample).vimeo_status === VimeoStatus.UPLOADING_ERROR) {
      this.alertService.showWarning(
        this.messages['ERRORS_TOASTS.GENERIC_MSG'],
        this.messages['ERRORS_TOASTS.CANNOT_SEND']
      );
      return;
    }

    this.close(true);
  }

  isDetailRenderModePage() {
    return this.isPublicMultimedia || this.detailRenderMode === MultimediaDetailRenderMode.Page;
  }

  async goToComments(isFromNewComment = false) {
    if (isFromNewComment) {
      return;
    }

    this.sendAnalyticsEvent(CategoriesAnalytics.COMMENT);

    const scrollOptions: ScrollIntoViewOptions = {
      block: 'start',
      behavior: 'smooth'
    };
    const el = this.commentsComponentRef.nativeElement;

    if (!this.commentsComponent.showComment) {
      this.commentsComponent.toggleComments(false);
    }

    if (this.commentsComponent.showComment) {
      await domChanges(500);
      if (this.platform.is('ios')) {
        const focusElement = document.getElementsByClassName('ck-focused');
        if (focusElement.length > 0) {
          focusElement[0].scrollIntoView();
        } else {
          this.mainContent.scrollTo(el.offsetTop);
        }
      } else {
        el.scrollIntoView(scrollOptions);
      }
    }
  }

  isDetailRenderModeModal() {
    return !this.detailRenderMode && !this.isPublicMultimedia;
  }

  isDeniedExample() {
    return !this.isOfficialContent && (this.item as Example).isDenied();
  }

  isDraftExample() {
    return !this.isOfficialContent && (this.item as Example).isDraft();
  }

  showStatusInfo() {
    return this.isDeniedExample() || (this.isDraftExample() && this.item.returned);
  }

  getStatusInfoTitle() {
    return this.isDeniedExample() ? 'INFO_MESSAGE.DENIED_REASON' : 'INFO_MESSAGE.EXAMPLE_RETURNED';
  }

  onShareModalClose() {
    this.isShareModalClosing = true;
  }

  protected incrementViews() {
    if (this.hasBeenUpdatedViews) {
      return;
    }
    if (this.hasCountViews()) {
      this.hasBeenUpdatedViews = true;
      this.provider.updateItemVisualizations(this.item.id).subscribe(
        (views) => {
          this.item.views = views;
          if (this.inputItem) {
            this.inputItem.views = views;
          }
          this.itemChangeNotifier.emit(this.item, 'views');
          this.hasBeenUpdatedViews = true;
        },
        () => (this.hasBeenUpdatedViews = false)
      );
    }
  }

  protected sendAnalyticsEvent(action: CategoriesAnalytics) {
    this.analyticsService.sendEvent(action, {
      [ActionsAnalytics.CLICKACTION]: this.item.getAnalyticsLabel()
    });
  }

  protected sendLikeEvent(like: boolean) {
    this.analyticsService.sendEvent(CategoriesAnalytics.LIKE_EVENT, {
      [ActionsAnalytics.CLICKACTION]: this.item.getAnalyticsLabel(),
      [ActionsAnalytics.LIKED]: like
    });
  }

  private getTranslations() {
    this.translate
      .stream([
        'ERRORS_TOASTS.ERROR_TITLE',
        'ERRORS_TOASTS.CONTENT_REQUEST_FAILED',
        'ERRORS_TOASTS.INVALID_CONTENT_URL',
        'ERRORS_TOASTS.INVALID_FORMAT',
        'ERRORS_TOASTS.GENERIC_MSG',
        'ERRORS_TOASTS.CANNOT_SEND',
        'INFO_TOASTS.INFO_TITLE',
        'INFO_TOASTS.TURNING_VIDEO',
        'ERRORS_TOASTS.INCOMPLETE_VIDEO',
        'ERROR_MESSAGES.LIKE_ACTION_FAILED'
      ])
      .subscribe((messages) => (this.messages = messages));
  }

  private injectProviders(injector: Injector) {
    this.webViewService = injector.get(WebViewService);
    this.publicationService = injector.get(PublicationService);
    this.exampleProvider = injector.get(ExampleService);
    this.playerService = injector.get(MediaPlayerService);
    this.translate = injector.get(TranslateService);
    this.platform = injector.get(Platform);
    this.alertService = injector.get(AlertService);
    this.network = injector.get(NetworkService);
    this.analyticsService = injector.get(AnalyticsService);
    this.modalManager = injector.get(ModalManager);
    this.modalCtrl = injector.get(ModalController);
    this.photoViewerService = injector.get(PhotoViewerService);
    this.itemChangeNotifier = injector.get(ItemChangeNotifier);
    this.router = injector.get(Router);
    this.data = injector.get(MAT_DIALOG_DATA);
  }
}

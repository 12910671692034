import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Logger, ModalManager } from '@app/services';
import { ElementType } from '@app/shared/enums/elements-type/element-type.enum';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { RepresentedUser } from '@app/shared/models/user/represented-user.model';
import { User } from '@app/shared/models/user/user.model';
import { cleanFormat, cleanHTMLPartially, domChanges } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { URL_REGEX } from '@ptrab/shared/constants/constants';
import { CommentHelper } from '@components/comments-section/comments-list/comment/helpers/comment.helper';

/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {
  @Input() user!: User;
  @Input() asUsers!: RepresentedUser[];
  @Input() userIsOwner!: boolean;
  @Input() userCanReply!: boolean;
  @Input() userIsPresident!: boolean;
  @Input() publishLabel = 'COMMENTS.PUBLISH';
  @Input() isReply = false;
  @Input() commentUserId!: string;
  @Output() userComment = new EventEmitter();
  @Output() userSelected = new EventEmitter();
  urlDeleted!: boolean;

  private logger = new Logger('Add-comment');

  readonly MAX_COMMENT_LENGTH = 500;
  readonly MIN_EDITOR_HEIGHT = 140;

  comment = '';
  elementType = ElementType;
  langReady = true;
  commentPlaceholder!: string;

  constructor(private translate: TranslateService, private modalManager: ModalManager) {
    this.subscribeToLanguage();
  }

  ngOnInit() {
    this.initAsUsers();
    this.commentPlaceholder = this.isReply ? 'COMMENTS.REPLY_PLACEHOLDER' : 'COMMENTS.PLACEHOLDER';
  }

  hasUrl(event: MSafeAny) {
    this.urlDeleted = event;
  }

  emitComment(buttonLabel: string) {
    // eslint-disable-next-line
    const resultRegExp = URL_REGEX.test(this.comment);

    if (resultRegExp && !this.userIsOwner) {
      CommentHelper.confirmDeleteComment(this.modalManager);
      this.urlDeleted = true;
    } else {
      if (!this.comment) {
        return;
      }

      if (buttonLabel === 'COMMENTS.REPLY') {
        // Clean HTML comment allowing links (href) if is a reply comment
        this.userComment.emit(cleanHTMLPartially(this.comment).normalize('NFKC'));
      } else {
        // Clean all HTML from comment if is a new comment publication
        this.userComment.emit(cleanFormat(this.comment));
      }
      this.urlDeleted = false;
      this.comment = '';
    }
  }

  emitUserSelected(user: User) {
    this.userSelected.emit(user);
  }

  private initAsUsers() {
    if (!this.isReply) {
      return;
    }

    this.logger.debug('asUsers', this.asUsers);
    this.logger.debug('userIsOwner', this.userIsOwner);

    if (this.userCanReply && this.isNotOwnerAndHasNoAsUsers()) {
      this.addUserToEmptyAsUsers();
      return;
    }

    if (this.isNotOwnerAndHasNoAsUsers()) {
      this.logger.error('Cannot reply comment: user is not owner and do not have AsUsers');
      throw new Error('Cannot reply comment');
    }

    if (this.isOwnerAndHasNoAsUsers()) {
      this.logger.debug('User can answer as owner but no as other users');
      this.addUserToEmptyAsUsers();
      return;
    }

    if (this.isOwnerAndHasAsUsers()) {
      this.logger.debug('User can answer as other users and as owner');
      this.addUserToExistentAsUsers();

      if (this.commentIsFromAsUser()) {
        this.logger.debug('User can answer as himself but not as other user');
        this.removeCommentUserIdFromAsUserArray();
        return;
      }

      return;
    }

    if (this.isNotOwnerAndHasAsUsers()) {
      this.logger.debug('User can answer as other users but not as owner');
      this.userSelected.emit(this.asUsers[0]);
    }
  }

  private isNotOwnerAndHasNoAsUsers() {
    return !this.userIsOwner && !this.hasAsUsers();
  }

  private isOwnerAndHasNoAsUsers() {
    return this.userIsOwner && !this.hasAsUsers();
  }

  private isOwnerAndHasAsUsers() {
    return this.userIsOwner && this.hasAsUsers();
  }

  private isNotOwnerAndHasAsUsers() {
    return !this.userIsOwner && this.hasAsUsers();
  }

  private hasAsUsers() {
    return this.asUsers && this.asUsers.length > 0;
  }

  private addUserToEmptyAsUsers() {
    this.asUsers = [];
    this.asUsers.push(this.assemblerAsUser(this.user));
  }

  private addUserToExistentAsUsers() {
    if (this.userInAsUsers()) {
      return;
    }

    this.asUsers.unshift(this.assemblerAsUser(this.user));
  }

  private removeCommentUserIdFromAsUserArray() {
    const auxArray = JSON.parse(JSON.stringify(this.asUsers));
    const asUserFromCommentIndex = auxArray.findIndex(
      (asUser: RepresentedUser) => asUser.userid === this.commentUserId
    );
    auxArray.splice(asUserFromCommentIndex, 1);
    this.asUsers = auxArray;
  }

  private userInAsUsers() {
    return this.asUsers.some((asUser: RepresentedUser) => {
      return asUser.userid === this.user.userid;
    });
  }

  private commentIsFromAsUser() {
    return this.asUsers.some((asUser: RepresentedUser) => asUser.userid === this.commentUserId);
  }

  private assemblerAsUser(user: User): RepresentedUser {
    const asUser = new RepresentedUser(user);
    asUser.cod_store = null;
    return asUser;
  }

  // TODO Update richtext editor placeholder workaround. Related to https://github.com/ckeditor/ckeditor5/issues/7383
  private subscribeToLanguage() {
    this.translate.onLangChange.subscribe(async () => {
      this.langReady = false;
      await domChanges();
      this.langReady = true;
    });
  }
}

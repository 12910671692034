import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ApiUrls } from '@app/services/api/api.urls.service';
import { TranslateService } from '@ngx-translate/core';
import { ModelSectionNames } from '@shared/enums/model/model-sections.enum';
import { IModelSection } from '@shared/interfaces/model/model-section.interface';
import { ModelTour } from '@shared/models/tour/tour.model';
/* eslint-disable @typescript-eslint/naming-convention */

interface ModelProgressResponse {
  model_section: ModelSectionNames;
}

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  private sectionName!: ModelSectionNames;
  private readonly tour = new ModelTour();
  private readonly TOUR_URL = this.urls.user.model_tour;

  public messages!: string[];

  constructor(
    private http: HttpClient,
    private urls: ApiUrls,
    private translate: TranslateService,
    private analyticsService: AnalyticsService
  ) {}

  getProgress(reload?: boolean): Observable<ModelSectionNames> {
    if (this.sectionName && !reload) {
      return of(this.getSectionName());
    }

    return this.http.get<ModelProgressResponse>(this.TOUR_URL).pipe(
      map((m) => m.model_section),
      tap((modelSection) => {
        this.setCurrentModelSection(modelSection);
      })
    );
  }

  updateProgress(modelSectionName: ModelSectionNames): Observable<ModelSectionNames> {
    return this.getProgress().pipe(switchMap((progress) => this.checkAndUpdateProgress(modelSectionName, progress)));
  }

  private checkAndUpdateProgress(
    modelSectionName: ModelSectionNames,
    progress: ModelSectionNames
  ): Observable<ModelSectionNames> {
    if (!this.isProgressAt(modelSectionName)) {
      return of(progress);
    }

    return this.http.post<ModelProgressResponse>(this.TOUR_URL, { model_section: modelSectionName }).pipe(
      map((m) => m.model_section),
      tap((modelSection) => {
        this.setCurrentModelSection(modelSection);
      })
    );
  }

  isAllowedToSee(sectionToCheck: ModelSectionNames) {
    return this.tour.isAllowedToSee(this.sectionName, sectionToCheck);
  }

  isPosterior(sectionToCheck: ModelSectionNames) {
    return this.tour.isPosterior(this.sectionName, sectionToCheck);
  }

  isProgressAt(sectionToCheck: ModelSectionNames) {
    return this.tour.areEquals(this.sectionName, sectionToCheck);
  }

  isInitial() {
    return this.tour.isInitial(this.sectionName);
  }

  isFinal() {
    return this.tour.isFinal(this.sectionName);
  }

  getSectionName(): ModelSectionNames {
    return this.sectionName;
  }

  activateSectionsIfAllowedToSee(sections: IModelSection[]): IModelSection[] {
    return this.tour.activateSectionsIfAllowedToSee(this.sectionName, sections);
  }

  activateAllSections(sections: IModelSection[]): IModelSection[] {
    return this.tour.activateAllSections(sections);
  }

  loadTranslations() {
    this.translate
      .get(['MODEL_INFO_TITLE', 'MODEL_INFO_CONTENT', 'ERROR_MESSAGES.ERROR', 'ERROR_MESSAGES.GENERIC_FAIL_MESSAGE'])
      .subscribe((messages) => {
        this.messages = messages;
      });
  }

  private setCurrentModelSection(modelSection: ModelSectionNames) {
    this.sectionName = modelSection;

    const blockIndex = this.tour.getBlockIndex(modelSection);
    this.analyticsService.setModelLevel(blockIndex);
  }
}

import { Component, Injector, OnInit } from '@angular/core';

import { Logger } from '@app/services';
import { Text } from '@app/shared/models/multimedia/text.model';

import { MultimediaDetailDirective } from '../multimedia-detail.component';

@Component({
  selector: 'app-text-detail',
  templateUrl: './text-detail.component.html',
  styleUrls: ['../multimedia-detail.component.scss']
})
export class TextDetailComponent extends MultimediaDetailDirective implements OnInit {
  item!: Text;
  logger = new Logger('Text Detail');
  isWebView!: boolean;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getText();
    this.isWebView = this.webViewService.isWebView();
  }

  getText() {
    this.getItem().subscribe(
      () => this.incrementViews(),
      (err) => {
        this.logger.error(err);
        this.close();
      }
    );
  }

  like() {
    this.sendLike(this.item);
  }
}

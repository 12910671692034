import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { PublicationFilter } from '@app/shared/models/filters/filters';
import { MultimediaMetadata } from '@app/shared/models/multimedia/multimedia-metadata.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ApiUrls } from '@services/api/api.urls.service';
import { AppError, ErrorCodes, ErrorMessages, ErrorTypes } from '@services/error/error.model';
import { ErrorService } from '@services/error/error.service';
import { LanguageService } from '@services/language/language.service';
import { IMultimediaService } from '@shared/interfaces/multimedia/multimedia.service.interface';
import { IPublicationRequestParams } from '@shared/interfaces/multimedia/publication.request.interface';
import { HttpOptions } from '@shared/models/http/http.options.model';
import {
  AbstractMultimediaItem,
  AbstractPaginatedMultimediaItemResponse
} from '@shared/models/multimedia/multimedia.abstract.model';
import { MultimediaFactory } from '@shared/models/multimedia/multimedia.factory';
import { Pagination } from '@shared/models/multimedia/pagination.model';
import { Publication } from '@shared/models/multimedia/publication.model';

import { Logger } from '../logger';

@Injectable({
  providedIn: 'root'
})
export class PublicationService implements IMultimediaService {
  private logger = new Logger('PublicationService');
  private onError = new Subject<ErrorTypes>();
  onError$ = this.onError.asObservable();

  constructor(
    private errorService: ErrorService,
    private http: HttpClient,
    private languageService: LanguageService,
    private urls: ApiUrls
  ) {}

  getPaginatedItems(params: IPublicationRequestParams = {}): Observable<AbstractPaginatedMultimediaItemResponse> {
    return this.languageService.getCurrentLanguage().pipe(
      mergeMap((lang) => {
        const options: MSafeAny = { observe: 'response', params: { lang, ...params } };
        return this.http.get(this.urls.multimediaItem.list, options).pipe(catchError(this.handleError));
      }),
      map((response: MSafeAny) => {
        const pagination: Pagination = new Pagination(response.headers);
        const items: Publication[] = response.body.map((item: AbstractMultimediaItem) =>
          MultimediaFactory.createPublication(item)
        );
        return new AbstractPaginatedMultimediaItemResponse(items, pagination, 0);
      })
    );
  }

  getItemWithMetadata(id: MSafeAny, params: object = {}): Observable<MultimediaMetadata<Publication>> {
    return this.languageService.getCurrentLanguage().pipe(
      mergeMap((lang) => {
        return this.http
          .get<Publication>(this.urls.multimediaItem.get(id), {
            observe: 'response',
            params: { lang, ...params }
          })
          .pipe(
            catchError((err) => {
              if (err.status === ErrorCodes.SECTION_MAINTENANCE) {
                this.onError.next(ErrorTypes.SECTION_MAINTENANCE);
              } else if (err.status === ErrorCodes.NOT_FOUND) {
                this.errorService.add(new AppError(ErrorTypes.NOT_FOUND, err));
              } else {
                this.errorService.add(new AppError(ErrorTypes.OTHER, err));
              }
              return throwError(err);
            })
          );
      }),
      map((response) => {
        const item = MultimediaFactory.createPublication(response.body);
        return new MultimediaMetadata(item, response.headers);
      })
    );
  }

  getItem(id: MSafeAny, params: object = {}): Observable<Publication> {
    return this.getItemWithMetadata(id, params).pipe(map((i) => i.item));
  }

  getPublicItem(id: MSafeAny, params: object = {}): Observable<Publication> {
    return this.languageService.getCurrentLanguage().pipe(
      mergeMap((lang) =>
        this.http.get<Publication>(this.urls.multimediaItem.getPublic(id), new HttpOptions({ lang, ...params }))
      ),
      map((item: Publication) => MultimediaFactory.createPublication(item))
    );
  }
  sendLike(id: number, like: boolean): Observable<MSafeAny> {
    return this.http.post(this.urls.multimediaItem.like(id), { like });
  }

  updateItemVisualizations(id: number): Observable<number> {
    return this.http
      .post(this.urls.multimediaItem.views(id), null)
      .pipe(map((result: MSafeAny) => parseInt(result.views, 10)));
  }

  getFilters(): Observable<PublicationFilter[]> {
    return this.languageService.getCurrentLanguage().pipe(
      mergeMap((lang) => {
        const options = new HttpOptions({ lang });
        return this.http.get<PublicationFilter[]>(this.urls.multimediaItem.filters, options);
      })
    );
  }

  handleError = (error: HttpErrorResponse): Observable<MSafeAny[]> => {
    let errorType = ErrorTypes.OTHER;

    if (error.status === ErrorCodes.SECTION_MAINTENANCE && error.error.code === ErrorMessages.PERM_DEACTIVATED) {
      errorType = ErrorTypes.SECTION_MAINTENANCE;
    }

    this.onError.next(errorType);
    this.logger.error(error);

    return throwError(error);
  };
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { ApiPtrabUrls } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class PtrabSessionService {
  constructor(private apiUrls: ApiPtrabUrls, private http: HttpClient) {}

  getSessionStatus(): Observable<HttpErrorResponse | void> {
    return this.http.get<void>(this.apiUrls.session.status).pipe(
      catchError(
        // eslint-disable-next-line
        (error = new HttpErrorResponse({})) =>
          // eslint-disable-next-line
          of(error)
      )
    );
  }

  // eslint-disable-next-line
  ptrabLogin(password: string, sms_hash?: string): Observable<MSafeAny> {
    // eslint-disable-next-line
    const body = sms_hash ? { password, sms_hash } : { password };
    return this.http.post(this.apiUrls.session.login, body);
  }

  ptrabLogout(): Observable<MSafeAny> {
    return this.http.post(this.apiUrls.session.logout, {});
  }
}

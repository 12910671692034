import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { domChanges } from '@app/shared/utils/utils';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  @Input() placeholder: string = '';
  @Input() disabled!: boolean;
  @Input() readonly!: boolean;
  @Input() integerOnly!: boolean;
  @Input() maxlength!: number;
  @Input() model!: MSafeAny;
  @Output() modelChange = new EventEmitter();
  @Output() focusInput = new EventEmitter();

  async onModelChange() {
    await domChanges();
    this.modelChange.emit(this.integerOnly ? this.input.nativeElement.value : this.model);
  }

  onFocus() {
    this.focusInput.emit();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PhonesResponseInterface } from '@app/ptrab/shared/interfaces/phones-response.interface';
import {
  TwoFactorOperationCode,
  TwoFactorResponse,
  TwoFactorUserStatusResponse
} from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';

import { ApiPtrabUrls } from '../api/api.service';

interface ValidateCodeResponse {
  validation_hash: string;
}

@Injectable({
  providedIn: 'root'
})
export class TwoFactorService {
  constructor(private apiUrls: ApiPtrabUrls, private http: HttpClient) {}

  getPhoneList(): Observable<PhonesResponseInterface> {
    return this.http.get<PhonesResponseInterface>(this.apiUrls.twoFactor.phoneList);
  }

  // eslint-disable-next-line
  sendValidationCodeToPhone(operation: TwoFactorOperationCode, phone: string): Observable<TwoFactorResponse> {
    return this.http.post<TwoFactorResponse>(this.apiUrls.twoFactor.sendValidationCode, { operation, phone });
  }

  validateCode(operation: TwoFactorOperationCode, code: string, phone?: string): Observable<string> {
    return this.http
      .post<ValidateCodeResponse>(this.apiUrls.twoFactor.validate, { operation, code, phone })
      .pipe(map((v) => v.validation_hash));
  }

  getUserStatus() {
    return this.http.get<TwoFactorUserStatusResponse>(this.apiUrls.twoFactor.checkUserStatus);
  }
}

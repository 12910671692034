import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ucfirst'
})
export class UcfirstPipe implements PipeTransform {
  transform(s: string) {
    return s === '' ? '' : s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector } from '@angular/core';
import { finalize, takeUntil } from 'rxjs';

import { ErrorMsgEnum } from '@app/components/error-msg/error-msg-enum';
import { SuccessModalComponent } from '@app/ptrab/components/modals/success-modal/success-modal.component';
import { WithholdingService } from '@app/ptrab/services';
import { VOLUNTARY_WITHHOLDING_ERRORS } from '@app/ptrab/shared/constants/irpf.const';
import {
  TwoFactorAuthorization,
  TwoFactorOperationCode
} from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { Withholding } from '@app/ptrab/shared/models/withholding';
import { Logger } from '@app/services';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { ErrorCodes } from '@app/services/error/error.model';
import { PTRAB_PAGES } from '@app/shared/enums/pages/pages.enum';

import { BaseVoluntaryWithholdingComponent } from '../base-voluntary-withholding/voluntary-withholding.component';

/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-ptrab-voluntary-withholding-es',
  templateUrl: './voluntary-withholding-es.component.html',
  styleUrls: ['./voluntary-withholding-es.component.scss', '../../../../styles/ptrab-title-section.scss']
})
export class VoluntaryWithholdingEsComponent extends BaseVoluntaryWithholdingComponent {
  protected ptrabPage: PTRAB_PAGES = PTRAB_PAGES.WITHHOLDING_EDITION;
  protected logger = new Logger('VoluntaryWithholdingEsComponent');

  constructor(protected injector: Injector, protected withholdingService: WithholdingService) {
    super(injector);
    this.getWithholding();
    this.initializeErrorHandling();
  }

  protected getWithholding() {
    this.withholdingService
      .getWithholdingList()
      .pipe(finalize(() => (this.isWithholdingLoading = false)))
      .subscribe((response: Withholding[]) => {
        this.withholding = response.filter((withholding) => withholding.hasCompany());
        this.hasSingleCompany = this.withholding && this.withholding.length === 1;
      });
  }

  async confirmRequest(withholding: Withholding) {
    this.analyticsService.sendEvent(CategoriesAnalytics.CLICK, {
      [ActionsAnalytics.CLICKACTION]: ActionsAnalytics.WITHHOLDING_VOLUNTARY_REQUESTED
    });

    const confirmed = await this.confirmationCtrl.confirmAction({
      title: this.messages['EMPLOYEE_PORTAL.SALARY_WITHHOLDING.CONFIRM_REQUEST.TITLE'],
      message: this.messages['EMPLOYEE_PORTAL.SALARY_WITHHOLDING.CONFIRM_REQUEST.DETAIL'],
      confirmButtonText: this.messages['EMPLOYEE_PORTAL.ACCEPT'],
      cancelButtonText: this.messages['EMPLOYEE_PORTAL.CANCEL']
    });

    if (!confirmed) {
      return;
    }

    this.editWithholding(withholding);
  }

  async removeVoluntaryWithholding(withholding: Withholding) {
    this.loadingService.show();
    const areAvailable = await this.payslipService.arePayslipsAvailable();
    this.loadingService.hide();

    if (!areAvailable) {
      return;
    }

    this.removeVoluntary(withholding);
  }

  private async removeValue(withholding: Withholding, validationHash: string) {
    this.loadingService.show();
    const withholdingCopy = { ...withholding };
    withholding.voluntary = this.minValue;
    const twoFactorAuthorization: TwoFactorAuthorization = {
      validation_hash: validationHash
    };

    this.withholdingService
      .editWithholding(withholding, twoFactorAuthorization)
      .pipe(
        finalize(async () => {
          await this.loadingService.hide();
        })
      )
      .subscribe(
        /* istanbul ignore next */
        // eslint-disable-next-line
        async () => {
          this.modalManager.dismissAllMatModal();
          await this.openSuccessModal();
          this.validation_hash = null;
        },
        (err: HttpErrorResponse) => {
          this.logger.error('Error response service', err.error.code);
          withholding.voluntary = withholdingCopy.voluntary;

          if (ErrorCodes.FORBIDDEN === err.status) {
            this.validation_hash = null;
            this.removeVoluntary(withholding);
            return;
          }

          this.showToastError(err.error);
        }
      );
  }

  private async removeVoluntary(withholding: Withholding) {
    const confirmed = await this.confirmationCtrl.confirmAction({
      title: this.messages['EMPLOYEE_PORTAL.SALARY_WITHHOLDING.CONFIRM_DELETE.TITLE'],
      message: this.messages['EMPLOYEE_PORTAL.SALARY_WITHHOLDING.CONFIRM_DELETE.DETAIL'],
      confirmButtonText: this.messages['EMPLOYEE_PORTAL.DELETE'],
      cancelButtonText: this.messages['EMPLOYEE_PORTAL.CANCEL']
    });

    if (!confirmed) return;

    if (!this.validation_hash) {
      try {
        this.validation_hash = await this.twoFactorController.validateOperation(
          TwoFactorOperationCode.WITHHOLDING,
          false
        );
      } catch {
        return;
      }
    }

    this.removeValue(withholding, this.validation_hash);
  }

  private openSuccessModal() {
    return this.modalManager.openMatModal(SuccessModalComponent, {
      data: {
        titleKey: 'EMPLOYEE_PORTAL.CHANGED_WITHHOLDING',
        infoMsgKey: 'EMPLOYEE_PORTAL.UPDATED_WITHHOLDING_DATA',
        descriptionKey: 'EMPLOYEE_PORTAL.EMAIL_CONFIRMATION',
        emailQuestion: 'EMPLOYEE_PORTAL.DONT_YOU_RECEIVE_QUESTION',
        personalDataLink: 'EMPLOYEE_PORTAL.PERSONAL_DATA_LINK'
      },
      disableClose: true
    });
  }

  private showToastError(error: { code: string }) {
    const toastText =
      error && VOLUNTARY_WITHHOLDING_ERRORS.includes(error.code)
        ? this.messages[`EMPLOYEE_PORTAL.${error.code}`]
        : this.messages['ERROR_MESSAGES.GENERIC_FAIL_MESSAGE'];
    this.alertService.showError(this.messages['EMPLOYEE_PORTAL.FAIL_WITHHOLDING'], toastText);
  }

  protected initializeErrorHandling() {
    this.withholdingService.onError$.pipe(takeUntil(this.destroyed)).subscribe((errorType) => {
      this.errorOcurred = errorType;
      this.isSectionMaintenance = this.errorOcurred === ErrorMsgEnum.SECTION_MAINTENANCE;
    });
  }
}

<div class="grid">
  <div class="row auto">
    <div size="12" size-lg="7" class="col text">
      <h1 class="u-h1">{{ message.title | translate }}</h1>
      <p>{{ message.description | translate }}</p>
    </div>
    <div size="12" size-lg="5" class="col image">
      <img [src]="message.url_image" alt="Activo2 Global Info Image" />
    </div>
  </div>
</div>

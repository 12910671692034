import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { VimeoStatus } from '@shared/constants/multimedia/vimeo.constants';
import { VideoProviderTypes } from '@shared/enums/media-player/media-player.enum';
import { ContentType, PublicationContentType } from '@shared/enums/multimedia/content-status-types.enum';
import { Example } from '@shared/models/multimedia/example.model';
import { AbstractMultimediaItem } from '@shared/models/multimedia/multimedia.abstract.model';
import { Publication } from '@shared/models/multimedia/publication.model';
import { getVideoQualities, VideoQualities } from '@shared/models/multimedia/video-qualities.model';
import { applyMixins } from '@shared/utils/utils';

import { LocalPublication } from '../local-publications/local-publications';
/* eslint-disable @typescript-eslint/naming-convention */

const CUSTOM_THUMBNAILS = {
  TRANSCODING: 'assets/imgs/video-card/vimeo_status/transcoding/',
  UPLOADING: 'assets/imgs/video-card/vimeo_status/uploading/',
  TRANSCODING_ERROR: 'assets/imgs/video-card/vimeo_status/transcoding-error/',
  EXCEEDED_UNAVAILABLE: 'assets/imgs/video-card/vimeo_status/exceeded-unavailable/'
};

export abstract class Video extends AbstractMultimediaItem {
  abstract content_type: PublicationContentType.VIDEO | ContentType.VIDEO;
  id_video?: string;
  status_upload!: string;
  video_provider?: VideoProviderTypes;
  vimeo_status?: VimeoStatus;
  video_image?: string;
  qualities?: VideoQualities[];
  subtitles!: string;
  thumbnail!: string;

  init(data: MSafeAny) {
    this.id_video = data.id_video || data.video_id;
    this.status_upload = data.status_upload;
    this.vimeo_status = data.vimeo_status;
    this.video_image = data.video_image;
    this.subtitles = data.subtitles;
    this.url = data.url;
    this.qualities = getVideoQualities(data.videos);
  }

  isTranscoding(): boolean {
    return this.vimeo_status === VimeoStatus.TRANSCODING || this.vimeo_status === VimeoStatus.TRANSCODE_STARTING;
  }

  isAvailable(): boolean {
    return this.vimeo_status === VimeoStatus.AVAILABLE;
  }

  hasProvider(type: VideoProviderTypes): boolean {
    return this.video_provider === type;
  }
}

export class VideoPublication extends Publication implements Video {
  content_type: PublicationContentType.VIDEO = PublicationContentType.VIDEO;
  id_video!: string;
  status_upload!: string;
  video_provider: VideoProviderTypes;
  vimeo_status!: VimeoStatus;
  video_image!: string;
  subtitles!: string;
  qualities!: VideoQualities[];
  thumbnail!: string;
  init!: (data: MSafeAny) => void;
  isTranscoding!: () => boolean;
  isAvailable!: () => boolean;
  hasProvider!: (type: VideoProviderTypes) => boolean;

  constructor(data: MSafeAny) {
    super(data);
    this.init(data);
    this.video_provider = data.video_provider || VideoProviderTypes.VIMEO;
  }
}

export class VideoLocalPublication extends LocalPublication implements Video {
  content_type: ContentType.VIDEO = ContentType.VIDEO;
  id_video!: string;
  status_upload!: string;
  video_provider: VideoProviderTypes;
  qualities!: VideoQualities[];
  subtitles!: string;
  vimeo_status!: VimeoStatus;
  video_image!: string;
  thumbnail!: string;
  url_video_upload: string;
  init!: (data: MSafeAny) => void;
  isTranscoding!: () => boolean;
  isAvailable!: () => boolean;
  hasProvider!: (type: VideoProviderTypes) => boolean;

  constructor(data: MSafeAny) {
    super(data);
    this.init(data.video);
    this.video_provider = data.video.video_provider || VideoProviderTypes.VIMEO;
    this.url_video_upload = data.video.url_video_upload;
  }

  hasEmptyUrl(): boolean {
    return this.vimeo_status !== VimeoStatus.TRANSCODING && this.url === '';
  }
}

export class VideoExample extends Example implements Video {
  content_type: ContentType.VIDEO = ContentType.VIDEO;
  id_video!: string;
  status_upload!: string;
  video_provider = VideoProviderTypes.VIMEO;
  vimeo_status!: VimeoStatus;
  video_image!: string;
  subtitles!: string;
  qualities!: VideoQualities[];
  thumbnail: string;
  init!: (data: MSafeAny) => void;
  isTranscoding!: () => boolean;
  isAvailable!: () => boolean;
  hasProvider!: (type: VideoProviderTypes) => boolean;

  constructor(data: MSafeAny) {
    super(data);
    this.init(data);
    this.thumbnail = this.getCustomThumbnail(data);
  }

  private getCustomThumbnail(item: Video): string {
    switch (item.vimeo_status) {
      case VimeoStatus.AVAILABLE:
        return item.thumbnail;
      case VimeoStatus.TRANSCODING:
      case VimeoStatus.TRANSCODE_STARTING:
        return CUSTOM_THUMBNAILS.TRANSCODING;
      case VimeoStatus.UPLOADING:
        return CUSTOM_THUMBNAILS.UPLOADING;
      case VimeoStatus.TRANSCODING_ERROR:
        return CUSTOM_THUMBNAILS.TRANSCODING_ERROR;
      default:
        return CUSTOM_THUMBNAILS.EXCEEDED_UNAVAILABLE;
    }
  }
}

export class BoardingVideo extends Publication implements Video {
  content_type: PublicationContentType.VIDEO = PublicationContentType.VIDEO;
  status_upload!: string;
  video_provider = VideoProviderTypes.VIMEO;
  subtitles!: string;
  thumbnail: string;
  video_image: string;
  init!: (data: MSafeAny) => void;
  isTranscoding!: () => boolean;
  isAvailable!: () => boolean;
  hasProvider!: (type: VideoProviderTypes) => boolean;

  constructor(data: MSafeAny) {
    super(data);
    this.url = data.url;
    this.thumbnail = data.thumbnail;
    this.video_image = data.thumbnail;
  }
}

applyMixins(VideoLocalPublication, [Video]);
applyMixins(VideoPublication, [Video]);
applyMixins(VideoExample, [Video]);
applyMixins(BoardingVideo, [Video]);

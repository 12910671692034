import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-ptrab-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss']
})
export class YearPickerComponent implements OnChanges {
  @Input() min = new Date().getFullYear() - 100;
  @Input() max = new Date().getFullYear();
  @Input() placeholder!: string;
  @Input() default!: number;
  @Input() disabled!: boolean;
  @Output() date = new EventEmitter<number>();

  isoDate!: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.default) {
      this.isoDate = this.default;
    }
  }

  sendDate(event: MSafeAny) {
    this.isoDate = event.target.value;
    this.date.emit(new Date(this.isoDate).getFullYear());
  }
}

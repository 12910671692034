import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { HEADER_HEIGHT } from '@app/shared/constants/header/header.constants';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { WebViewService } from '@app/shared/services/webview.service';
import { isNullOrEmpty, isNullOrUndefined, normalizeString } from '@app/shared/utils/utils';

import { HIGHLIGHTER } from './results-highlighter.const';
import { ContentComponent } from '../content/content.component';

const TEXT_TYPE_REGEXP = '^P$|^H[0-6]$|^SPAN$|^A$|^LABEL$|^B$';
const NODE_TYPE_TEXT = 3;

@Component({
  selector: 'app-results-highlighter',
  templateUrl: './results-highlighter.component.html',
  styleUrls: ['./results-highlighter.component.scss']
})
export class ResultsHighlighterComponent {
  private content!: ContentComponent;
  private highlighterScrollSubscription!: Subscription;

  private highlighterStyle = {
    widthIncrease: 0,
    width: 0,
    top: 0,
    left: 0,
    margin: 0,
    radius: 0
  };

  searchTerm!: string;
  display = false;
  loading = false;
  nodesToHighlight: HTMLElement[] = [];
  resultsPositions: number[] = [];
  currentPosition = 1;
  singleResults!: boolean;

  constructor(private webViewService: WebViewService) {}

  get isEmbedded(): boolean {
    return this.webViewService.isWebView();
  }

  get appHeader(): Element | null | undefined {
    return (this.content.mainContent.nativeElement as HTMLElement)?.querySelector('app-header');
  }

  get modelHeader(): Element | null | undefined {
    return (this.content.mainContent.nativeElement as HTMLElement)?.querySelector('app-model-page-header');
  }

  init(content: ContentComponent, searchTerm: string) {
    this.content = content;
    this.searchTerm = searchTerm;
    this.searchThroughContent(this.content.mainContent.nativeElement.childNodes, this.searchTerm);
    if (this.nodesToHighlight.length < 1) {
      return;
    }

    this.setHighlighterStyles();

    if (this.isEmbedded) {
      this.moveContentIfMobile();
    }

    this.highlighterScrollWatcher();
    this.replaceHighlight(this.searchTerm);
    this.loading = true;

    setTimeout(() => {
      this.resultsPositions = this.calculatePositions();
      this.singleResults = this.resultsPositions.length === 1;
      this.scrollToResult(1);
      this.loading = false;
      this.display = true;
    }, 1500);
  }

  calculatePositions() {
    return this.nodesToHighlight.map((node) => {
      const hiddenParentNodeChecker: HTMLElement | null = node.closest('.anchor-tag-visibility-checker');
      if (!hiddenParentNodeChecker || !this.checkIfNodeIsRotated(hiddenParentNodeChecker)) {
        return node.getBoundingClientRect().top;
      }

      return this.manageHighlightedHiddenContent(node);
    });
  }

  manageHighlightedHiddenContent(node: HTMLElement) {
    const anchorTag = node.closest('.anchor-tag') as Element;

    for (const child of Array.from(anchorTag.children)) {
      if (!this.checkIfNodeIsRotated(child as HTMLElement)) {
        child.classList.add('show-backface');
      }
    }

    return node.getBoundingClientRect().top;
  }

  highlighterScrollWatcher() {
    const scrollObservable = this.content.scrolled;
    this.highlighterScrollSubscription = scrollObservable.subscribe((e: CustomEvent) => {
      if (!e) {
        return;
      }

      if (!this.isEmbedded) {
        this.manageHighlighterWidth(e.target as HTMLElement);
        this.manageHighlighterTopDesktop(e.target as HTMLElement);
      } else {
        this.manageHighlighterTopMobile();
      }
    });
  }

  searchThroughContent(nodes: NodeList, termToMatch: string) {
    if (isNullOrEmpty(nodes)) {
      return;
    }

    (nodes as MSafeAny).forEach((node: HTMLElement) => {
      if (!isNullOrUndefined(node.innerHTML) && !this.isRelatedContent(node)) {
        const textInside: string = node.textContent as string;

        if (
          node.childNodes &&
          (this.checkIfNodeIsPureText(node) || this.checkIfNodeIsShadowText(node)) &&
          !isNullOrUndefined(this.textExecWithTerm(termToMatch, textInside))
        ) {
          if (this.nodesToHighlight.length > 0) {
            this.addNodeIfMatchNotDuplicated(node);
          } else {
            this.nodesToHighlight.push(node);
          }
        }
        this.searchThroughContent(node.childNodes, termToMatch);
      }
    });
  }

  replaceHighlight(term: string) {
    if (this.nodesToHighlight.length > 0) {
      this.nodesToHighlight.forEach((node: HTMLElement) => {
        this.changeNodeDisplayStyle(node);
        const normalizedMatches = this.textMatchWithTerm(term, node.textContent as string);
        normalizedMatches.forEach((uniqueMatch) => {
          const regExp = new RegExp(uniqueMatch, 'g');
          node.innerHTML = node.innerHTML.replace(regExp, (match) => `<b class="highlight">${match}</b>`);
        });
      });
    }
  }

  scrollToResult(position: number) {
    this.highlighterStyle.widthIncrease = HIGHLIGHTER.WIDTH / this.nodesToHighlight.length;
    this.content.scrollTo(this.resultsPositions[position - 1] - 250);

    this.highlighterStyle.widthIncrease = HIGHLIGHTER.DEFAULT_INCREASE;
  }

  checkIfNodeIsPureText(node: HTMLElement) {
    return (
      node.nodeName.match(new RegExp(TEXT_TYPE_REGEXP, 'g')) &&
      node.childNodes &&
      node.childNodes.length >= 1 &&
      node.childNodes[0].nodeType === NODE_TYPE_TEXT
    );
  }

  checkIfNodeIsShadowText(node: HTMLElement) {
    return (
      node.nodeName.match(new RegExp('DIV', 'g')) &&
      node.childNodes &&
      node.childNodes.length === 1 &&
      node.childNodes[0].nodeType === NODE_TYPE_TEXT
    );
  }

  textExecWithTerm(term: string, textInside: string) {
    const termMatchRegExp = new RegExp(normalizeString(term), 'gi');
    const normalizedPhrase = normalizeString(textInside);
    return termMatchRegExp.exec(normalizedPhrase);
  }

  textMatchWithTerm(term: string, textInside: string) {
    const termMatchRegExp = new RegExp(normalizeString(term), 'gi');
    const normalizedPhrase = normalizeString(textInside);
    const matches = normalizedPhrase.match(termMatchRegExp) as string[];
    const matchesArray = [];
    const uniqueMatchesArray = this.filterDuplicatedMatches(matches);
    uniqueMatchesArray.forEach(() => {
      const execResult = termMatchRegExp.exec(normalizedPhrase);
      matchesArray.push(
        textInside.slice(
          execResult?.index,
          (execResult && execResult.index ? execResult.index : 0) + term.length
        ) as never
      );
    });

    return matchesArray;
  }

  private filterDuplicatedMatches(matches: string[]) {
    return matches.filter((item, pos) => {
      return matches.indexOf(item) === pos;
    });
  }

  checkIfNodeIsVisible(node: HTMLElement) {
    return getComputedStyle(node).display !== 'none';
  }

  checkIfNodeIsRotated(node: HTMLElement) {
    return getComputedStyle(node).transform !== 'none';
  }

  isRelatedContent(node: HTMLElement) {
    return node.tagName === 'APP-RELATED-CONTENT';
  }

  addNodeIfMatchNotDuplicated(node: HTMLElement) {
    const previousNode: HTMLElement = this.nodesToHighlight[this.nodesToHighlight.length - 1];
    if (!previousNode.isEqualNode(node) && !previousNode.isEqualNode(node.parentNode)) {
      this.nodesToHighlight.push(node);
    }
  }

  nextResult() {
    this.currentPosition = this.currentPosition < this.resultsPositions.length ? this.currentPosition + 1 : 1;
    this.scrollToResult(this.currentPosition);
  }

  previousResult() {
    this.currentPosition = this.currentPosition > 1 ? this.currentPosition - 1 : this.resultsPositions.length;
    this.scrollToResult(this.currentPosition);
  }

  close() {
    this.nodesToHighlight.forEach((node) => {
      node.innerHTML = node.innerText;
      this.display = false;
    });
    (this.content.mainContent.nativeElement as HTMLElement).style.top = `0px`;
    this.highlighterScrollSubscription.unsubscribe();
  }

  highlighterStyles() {
    return {
      'top.px': this.highlighterStyle.top,
      'min-width.px': this.highlighterStyle.width,
      'left.px': this.highlighterStyle.left,
      'margin-left.px': this.highlighterStyle.margin,
      'border-radius.px': this.highlighterStyle.radius
    };
  }

  private changeNodeDisplayStyle(node: HTMLElement) {
    if (getComputedStyle(node).display !== 'block' && !node.className.includes('keep-display-highlighter')) {
      node.style.display = 'block';
    }
  }

  private async manageHighlighterWidth(eventScrollElement: HTMLElement) {
    if (!eventScrollElement) {
      return;
    }

    const scrollElement = this.content.mainContent.nativeElement;
    const highlighterWidth =
      ((this.highlighterStyle.top + eventScrollElement.scrollTop) *
        this.content.mainContent.nativeElement.offsetWidth) /
        (scrollElement.scrollHeight - scrollElement.offsetHeight - scrollElement.offsetTop) +
      HIGHLIGHTER.WIDTH;
    if (highlighterWidth > HIGHLIGHTER.WIDTH) {
      this.highlighterStyle.width = highlighterWidth;
    }

    this.highlighterStyle.radius =
      highlighterWidth > this.content.mainContent.nativeElement.offsetWidth ? 0 : HIGHLIGHTER.RADIUS;

    this.highlighterStyle.margin = -this.highlighterStyle.width / 2;
  }

  private moveContentIfMobile() {
    (this.content.mainContent.nativeElement as HTMLElement).style.top = `${HIGHLIGHTER.HEIGHT}px`;
  }

  private manageHighlighterTopDesktop(scrollElement: HTMLElement) {
    this.highlighterStyle.top = this.getHighlighterTopDesktop(this.modelHeader as Element, scrollElement);
  }

  private getHighlighterTopDesktop(modelHeader: Element, scrollElement: HTMLElement) {
    const scrollTop = scrollElement.scrollTop;
    const modelHeaderHeight = modelHeader.getBoundingClientRect().height;
    return scrollTop < modelHeaderHeight
      ? modelHeaderHeight + HEADER_HEIGHT.ON_SCROLL + 10 - scrollTop
      : HEADER_HEIGHT.ON_SCROLL;
  }

  private manageHighlighterTopMobile() {
    this.highlighterStyle.top = this.getHighlighterTopOnMobile(this.appHeader as Element);
  }

  private getHighlighterTopOnMobile(header: Element) {
    if (this.isEmbedded) return 0;
    if (header.clientHeight + header.getBoundingClientRect().top > 0) {
      return header.clientHeight + header.getBoundingClientRect().top;
    }
    return 0;
  }

  private setHighlighterStyles() {
    const clientHeightHeader = this.webViewService.isWebView() ? 0 : this.appHeader?.clientHeight;
    this.highlighterStyle.width = this.isEmbedded
      ? this.content.mainContent.nativeElement.offsetWidth
      : HIGHLIGHTER.WIDTH;
    this.highlighterStyle.radius = this.isEmbedded ? 0 : HIGHLIGHTER.RADIUS;
    this.highlighterStyle.left = this.content.mainContent.nativeElement.offsetWidth / 2;
    this.highlighterStyle.margin = -this.highlighterStyle.width / 2;
    this.highlighterStyle.top = !this.isEmbedded
      ? ((HEADER_HEIGHT.DESKTOP + HEADER_HEIGHT.MODEL + 10) as number)
      : (clientHeightHeader as number);
  }
}

<app-content-modal [buttons]="exampleFormButtons" [hideClose]="true" (closeModal)="close()">
  <app-edition-placeholder *ngIf="isLoading"></app-edition-placeholder>

  <article class="animated fadeIn" *ngIf="!isLoading">
    <div class="title-form">
      <h1>{{ 'MULTIMEDIA_FORM.TITLE_EDIT' | translate }}</h1>
      <app-message-info
        *ngIf="isEditDenied"
        [title]="'INFO_MESSAGE.DENIED_REASON' | translate"
        [message]="currentExample.status_info"
      ></app-message-info>
    </div>

    <app-example-form
      [example]="currentExample"
      (submitExample)="editExample($event)"
      (formChanges)="checkValidation()"
    >
    </app-example-form>
  </article>
</app-content-modal>

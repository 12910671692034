import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ModelCardItem } from '@app/shared/models/search/search.model';
import { MODEL_SECTIONS_ROUTES } from '@shared/constants/model/model-sections-routes.const';
import { PublicationContentType } from '@shared/enums/multimedia/content-status-types.enum';
import { AbstractMultimediaItem } from '@shared/models/multimedia/multimedia.abstract.model';
import { groupBy } from 'lodash';

export const isModelLink = (item: AbstractMultimediaItem) => {
  const isInternal = item.content_type === PublicationContentType.INTERNAL;
  const hasModelSectionRoute =
    MODEL_SECTIONS_ROUTES.find((route) => route.sectionName === item.internal_link) !== undefined;
  return isInternal && hasModelSectionRoute;
};

export const getModelUrlAndSection = (item: AbstractMultimediaItem) => {
  const modelSectionRoute = MODEL_SECTIONS_ROUTES.find((route) => route.sectionName === item.internal_link);
  const modelSection = modelSectionRoute?.section ? modelSectionRoute.section : '';
  return { modelSectionRoute, modelSection };
};

export const mapModelResults = (modelResultList: MSafeAny) => {
  const modelCardList: MSafeAny[] = [];
  modelResultList.items.forEach((item: MSafeAny) => {
    item.result.forEach((result: ModelCardItem) => {
      modelCardList.push({
        title: item.titleLiteralId,
        result,
        highlights: item.highlights,
        section: item.section,
        url: item.url
      });
    });
  });
  return groupBy(modelCardList, 'title');
};

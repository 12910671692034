<app-input-wrapper [label]="'EMPLOYEE_PORTAL.FROM'">
  <div
    class="wrapper-content"
    #mPopover="m-popover"
    [hideTriangle]="true"
    popoverPosition="bottomRight"
    [m-popover]="startMonthPickerTmp">
    <div class="c-input" data-e2e-id="from-selector">{{ startDateMonth | translate }} {{ startDateYear }}</div>
    <img class="calendar-icon" src="assets/imgs/employee-portal/today.svg" alt="Calendar" />
  </div>
</app-input-wrapper>

<app-input-wrapper [label]="'EMPLOYEE_PORTAL.UNTIL'">
  <div
    class="wrapper-content"
    #mPopover="m-popover"
    [hideTriangle]="true"
    popoverPosition="topRight"
    [m-popover]="endMonthPickerTmp">
    <div class="c-input" data-e2e-id="until-selector">{{ endDateMonth | translate }} {{ endDateYear }}</div>
    <img class="calendar-icon" src="assets/imgs/employee-portal/today.svg" alt="Calendar" />
  </div>
</app-input-wrapper>

<ng-template #startMonthPickerTmp>
  <app-ptrab-month-picker
    [minYear]="firstAvailableYear"
    [startDate]="null"
    [endDate]="endDate"
    [limitDate]="limitDate"
    [startDateYear]="startDateYear"
    [endDateYear]="endDateYear"
    (monthSelected)="onMonthSelected($event, DateRanges.START)"></app-ptrab-month-picker>
</ng-template>

<ng-template #endMonthPickerTmp>
  <app-ptrab-month-picker
    [minYear]="firstAvailableYear"
    [startDate]="startDate"
    [endDate]="null"
    [limitDate]="limitDate"
    [startDateYear]="startDateYear"
    [endDateYear]="endDateYear"
    (monthSelected)="onMonthSelected($event, DateRanges.END)"></app-ptrab-month-picker>
</ng-template>

import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
const getErrorMessage = (maxRetry: number) => `Tried to load Resource over XHR ${maxRetry} times without success`;

const DEFAULT_MAX_RETRIES = 1;

export function delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES) {
  let retries = maxRetry;

  return (src: Observable<MSafeAny>) =>
    src.pipe(
      retryWhen((errors: Observable<MSafeAny>) =>
        errors.pipe(
          delay(delayMs),
          mergeMap((error) => (retries-- > 0 ? of(error) : throwError(getErrorMessage(maxRetry))))
        )
      )
    );
}

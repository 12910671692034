<div class="title-description-card" [class.box]="!insideModal">
  <div class="grid">
    <div class="row">
      <div class="col title-description-card__info" size="12">
        <span class="title-description-card__info__title">{{ cardTitle }}</span>
        <p class="title-description-card__info__description" [innerHTML]="cardDescription"></p>
      </div>
    </div>
  </div>
</div>

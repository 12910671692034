<app-input-wrapper [label]="label">
  <app-input
    placeholder="SEARCH_DOTS"
    [model]="highlighterService.searchTerm"
    (modelChange)="onModelChange($event)"
    (focusInput)="showDropdown()">
    <ng-container *ngIf="showArrows">
      <img alt="arrow-down" src="assets/imgs/arrow-down/ic_caret_down.svg" *ngIf="!highlighterService.searchTerm" />
      <img
        class="c-pointer"
        alt="close-icon"
        src="assets/imgs/ic_close/ic_close.svg "
        *ngIf="highlighterService.searchTerm"
        (click)="onCloseIconClick($event)" />
    </ng-container>
  </app-input>
</app-input-wrapper>
<div #dropdown_item_list class="list dropdown animated fadeIn faster" [hidden]="!toggle">
  <app-item
    class="dropdown-item"
    id="item-{{ item.value }}"
    *ngFor="let item of officesFiltered; trackBy: trackByItems"
    (click)="selectItem(item)">
    <div class="content">
      <h6 class="u-dark-grey" [innerHTML]="item.label | translate"></h6>
      <img *ngIf="item.selected" item-end src="assets/imgs/employee-portal/green-check.svg" alt="Check" />
    </div>
  </app-item>
  <app-item class="dropdown-item item-disabled" *ngIf="!officesFiltered.length">
    <h6 class="u-dark-grey">{{ 'SEARCH_NO_RESULTS' | translate }}</h6>
  </app-item>
</div>

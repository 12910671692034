<div class="top-content top-content--covered wow fadeIn">
  <img [hidden]="!show" (load)="this.show = true" [src]="internalLink.thumbnail" class="cover-img" />
  <div class="inner-content">
    <div class="inline-info">
      <span class="card-title">{{ internalLink.title }}</span>
      <span class="card-date"> {{ cardDate | date: 'dd/MM/yyyy' }} </span>
    </div>
  </div>

  <app-card-tooltip *ngIf="isTypeLite"></app-card-tooltip>
  <app-share-content
    *ngIf="internalLink.isShareable() && !isWebview"
    class="share-corner"
    [item]="internalLink"
    [isDetail]="false"
    [analyticsCategory]="analyticsCategory"
  ></app-share-content>
</div>

<div class="bottom-content">
  <app-card-info *ngIf="!isTypeLite" [item]="internalLink" [cardType]="type"></app-card-info>
  <app-card-buttons
    *ngIf="hasActionButtons"
    [exampleItem]="internalLink"
    [publishIsDisabled]="publishIsDisabled"
  ></app-card-buttons>
</div>

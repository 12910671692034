import { Injectable } from '@angular/core';

import { StorageService } from '@app/services/storage';

@Injectable({
  providedIn: 'root'
})
export class WebViewService {
  isWebView(): boolean {
    const urlParams = new URLSearchParams(window.location.search);

    return StorageService.isWebView() || history.state?.iswebview || urlParams.get('iswebview') === 'true';
  }

  getLanguageCode(): string {
    const urlParams = new URLSearchParams(window.location.search);

    return StorageService.getLanguageCode() || history.state?.languageCode || urlParams.get('languageCode');
  }
}

<p class="u-p2 u-medium-grey">
  {{ 'EMPLOYEE_PORTAL.PERCENTAGE_READJUSTMENT.DESCRIPTION' | translate }}
</p>

<p *ngIf="hasMultipleCompanies" class="u-p2 u-medium-grey">
  {{ 'EMPLOYEE_PORTAL.PERCENTAGE_READJUSTMENT.SELECT_COMPANY' | translate }}
</p>

<div class="company" *ngFor="let company of allowedCompanies; trackBy: trackByItems">
  <h6 class="label u-h6 u-anthracite" translate>
    {{ hasMultipleCompanies ? company.name : 'EMPLOYEE_PORTAL.PERCENTAGE_READJUSTMENT.REQUEST' }}
  </h6>
  <app-toggle [checked]="company.is_requested" (toggleChange)="initReadjustment($event, company)"></app-toggle>
</div>

<app-media-player
  #player
  [previewVideo]="previewVideo"
  [isDeletableItem]="true"
  [isPublicationParent]="isPublicationParent"
  [class.video-loaded]="!isLoadingFile"
  (enterFullscreen)="onEnterFullscreen()"
  (exitFullscreen)="onExitFullscreen()"
  (stalled)="onStalled()"
  (multimediaError)="onMultimediaError($event)"
  (deleteItem)="onRemove()"
>
</app-media-player>

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { MOT_PAGES, PAGES, PTRAB_PAGES } from './pages.enum';

const PAGES_URLS_MAP: MSafeAny = {
  // General Urls
  [PAGES.AUTHENTICATION]: '/auth',
  [PAGES.BANNED]: '/banned',
  [PAGES.ERROR]: '/error',
  [PAGES.ERROR_404]: '/not-found',
  [PAGES.EXAMPLES]: '/examples',
  [PAGES.HOME]: '/home',
  [PAGES.ITEM]: '/publication',
  [PAGES.ITEM_EXAMPLE]: '/example',
  [PAGES.LOGOUT]: '/logout',
  [PAGES.MAINTENANCE]: '/maintenance',
  [PAGES.MOBILE_WEB_LANDING]: '/mobileweb-landing',
  [PAGES.MODEL]: '/model',
  [PAGES.MY_EXAMPLES]: '/my-examples',
  [PAGES.NOTIFICATIONS]: '/page-notifications',
  [PAGES.OFFLINE]: '/offline',
  [PAGES.PRIVACY]: '/privacy',
  [PAGES.PERSONAL_DATA]: '/personal-data',
  [PAGES.PUBLICATIONS]: '/publications',
  [PAGES.PUBLICATIONS_ORIGINAL]: '/publications',
  [PAGES.PUBLIC_HISTORY]: '/history',
  [PAGES.PUBLIC_MANAGE_MODEL]: '/manage-model',
  [PAGES.PUBLIC_SUSTAINABLE]: '/sustainable',
  [PAGES.RESULTS]: '/search-results',
  [PAGES.SEARCH_RESULTS]: '/search-results',
  [PAGES.TERMS_AND_CONDITIONS_INTER]: '/terms-and-conditions-inter',
  [PAGES.VIEWER]: '/viewer',
  [PAGES.EMAIL_EDITION]: `/${PAGES.PERSONAL_DATA}/email-edition`,
  [PAGES.ADDRESS_EDITION]: `/${PAGES.PERSONAL_DATA}/address-edition`,
  [PAGES.PHONE_EDITION]: `/${PAGES.PERSONAL_DATA}/phone-edition`,
  [PAGES.LANGUAGES]: `/${PAGES.PERSONAL_DATA}/languages`,
  [PAGES.STUDIES]: `/${PAGES.PERSONAL_DATA}/studies`,
  [PAGES.LOGISTIC_INFO]: '/logistic-anual-calendar',
  [PAGES.OFFICESERVICES]: '/office-service',
  [PAGES.MY_PUBLICATIONS]: '/my-publications',
  [PAGES.ITEM_MY_PUBLICATION]: '/my-publication',
  [PAGES.OFFICE_FLOOR_PLAN]: '/office-floor-plan',
  [PAGES.VACATIONS_REQUEST]: `/manage-vacations`,
  [PAGES.VACATIONS]: `/vacations`,
  [PAGES.VACATIONS_DETAIL]: `/vacations/detail`,

  // Model Urls
  'importance-of-the-model': '/model/importance-of-the-model',
  'universal-truths': '/model/universal-truths',
  'universal-truths-what-is': '/model/universal-truths/what-is',
  'universal-truths-give-ask-demand': '/model/universal-truths/give-ask-demand',
  'universal-truths-contrast': '/model/universal-truths/contrast',
  'universal-truths-facts': '/model/universal-truths/facts',
  'universal-truths-nobody-knows': '/model/universal-truths/nobody-knows',
  'universal-truths-improve': '/model/universal-truths/improve',
  'universal-truths-walk': '/model/universal-truths/walk',
  'universal-truths-cause': '/model/universal-truths/cause',
  'universal-truths-time': '/model/universal-truths/time',
  'universal-truths-chain': '/model/universal-truths/chain',
  'universal-truths-apply-all': '/model/universal-truths/apply-all',
  paradigm: '/model/paradigm',
  'mission-and-vision': '/model/mission-and-vision',
  'the-boss': '/model/the-boss',
  'the-worker': '/model/the-worker',
  'the-provider': '/model/the-provider',
  society: '/model/society',
  'the-capital': '/model/the-capital',
  'model-application': '/model/model-application',
  purpose: '/model/purpose'
};

const MOT_ROOT_URL = '/mot';
const MOT_PAGES_URLS_MAP = {
  [MOT_PAGES.SCHEDULE]: `${MOT_ROOT_URL}/schedule`,
  [MOT_PAGES.SCHEDULE_OBSERVATIONS]: `${MOT_ROOT_URL}/schedule/observations`
};

const PTRAB_ROOT_URL = '/ptrab';
const PTRAB_IRPF_ROOT_URL = '/ptrab/irpf';
const PTRAB_CERTIFICATES_ROOT_URL = '/ptrab/certificates';
const PTRAB_PAGES_URLS_MAP = {
  [PTRAB_PAGES.PAYSLIPS]: `${PTRAB_ROOT_URL}/payslips`,
  [PTRAB_PAGES.DIRECT_DEBIT_EDITION]: `${PTRAB_ROOT_URL}/payslips/direct-debit-edition`,
  [PTRAB_PAGES.PERSONAL_INCOME_TAX]: `${PTRAB_IRPF_ROOT_URL}`,
  [PTRAB_PAGES.PERSONAL_INCOME_TAX_INFO]: '/irpf-info',
  [PTRAB_PAGES.WITHHOLDING_EDITION]: `${PTRAB_IRPF_ROOT_URL}/withholding-edition`,
  [PTRAB_PAGES.WITHHOLDING_EDITION_PT]: `${PTRAB_IRPF_ROOT_URL}/withholding-edition-pt`,
  [PTRAB_PAGES.IRPF_WORKER_PAGE]: `${PTRAB_IRPF_ROOT_URL}/worker`,
  [PTRAB_PAGES.IRPF_WORKER_PT_PAGE]: `${PTRAB_IRPF_ROOT_URL}/worker-pt`,
  [PTRAB_PAGES.IRPF_ANCESTORS]: `${PTRAB_IRPF_ROOT_URL}/ancestors`,
  [PTRAB_PAGES.IRPF_DEPENDANTS]: `${PTRAB_IRPF_ROOT_URL}/dependants`,
  [PTRAB_PAGES.IRPF_DEPENDANTS_DETAIL]: `${PTRAB_IRPF_ROOT_URL}/dependants/detail`,
  [PTRAB_PAGES.IRPF_SPOUSE]: `${PTRAB_IRPF_ROOT_URL}/spouse`,
  [PTRAB_PAGES.IRPF_ROOM_BENEFIT]: `${PTRAB_IRPF_ROOT_URL}/room-benefit`,
  [PTRAB_PAGES.IRPF_MORTGAGE_REDUCTION]: `${PTRAB_IRPF_ROOT_URL}/mortgage-reduction`,
  [PTRAB_PAGES.IRPF_CEUTA_MELILLA_RESIDENCE]: `${PTRAB_IRPF_ROOT_URL}/ceuta-melilla-residence`,
  [PTRAB_PAGES.IRPF_DESCENDANTS]: `${PTRAB_IRPF_ROOT_URL}/descendants`,
  [PTRAB_PAGES.IRPF_YOUNG_WITHHOLDING]: `${PTRAB_IRPF_ROOT_URL}/young-withholding`,
  [PTRAB_PAGES.IRPF_PENSION_PAYMENT]: `${PTRAB_IRPF_ROOT_URL}/pensions`,
  [PTRAB_PAGES.CERTIFICATES]: `${PTRAB_CERTIFICATES_ROOT_URL}`,
  [PTRAB_PAGES.INCOME_AND_ASSETS]: `${PTRAB_CERTIFICATES_ROOT_URL}/income-and-assets`,
  [PTRAB_PAGES.RISKY_PREGNANCY]: `${PTRAB_CERTIFICATES_ROOT_URL}/risky-pregnancy`,
  [PTRAB_PAGES.PTRAB_LOGIN]: `${PTRAB_ROOT_URL}/login`
};

export function getUrlFromMap(page: string, section?: string): string {
  const sectionOk = section && section.length > 3;
  const id = sectionOk ? `${page}-${section}` : page;
  return PAGES_URLS_MAP[id];
}

export function getPtrabUrlFromMap(page: PTRAB_PAGES): string {
  return PTRAB_PAGES_URLS_MAP[page];
}

export function getMotUrlFromMap(page: MOT_PAGES): string {
  return MOT_PAGES_URLS_MAP[page];
}

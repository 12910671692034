import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { LANGUAGES } from '@app/shared/constants/language/language.const';
import { AbstractMultimediaItem } from '@app/shared/models/multimedia/multimedia.abstract.model';
import { TranslateService } from '@ngx-translate/core';
import { ENV } from 'src/environments/environment';

@Component({
  selector: 'app-multimedia-detail-description',
  templateUrl: './multimedia-detail-description.component.html',
  styleUrls: ['./multimedia-detail-description.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultimediaDetailDescriptionComponent implements OnInit, OnChanges {
  @Input() multimediaItem!: AbstractMultimediaItem;
  @Input() showTitle = true;
  @Input() showTranslate = true;
  @Input() darkMode!: boolean;

  canShowTranslationContent!: boolean;
  isOpenTranslation = false;
  innerHtml!: string;
  pdfLinksCount = 0;
  pdfLinks = [];
  domainPath =
    `${window.location.protocol}//${window.location.hostname}` +
    (window.location.port ? `:${window.location.port}` : '');
  enviromentSiteUrl = ENV.APP_UNIVERSAL_LINKS_URL;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.processItemHtml();
  }

  processItemHtml() {
    const segmentedHTML = this.multimediaItem.text.split('<a');
    const target = `target=''`;
    for (let i = 0; i < segmentedHTML.length; i++) {
      const isLink = this.checkElementHasText(segmentedHTML[i], 'href=');
      const linkTarget = this.getLinkTarget();

      if (isLink) {
        if (!this.checkElementHasTarget(segmentedHTML[i], target)) {
          segmentedHTML[i] = segmentedHTML[i].replace(`'>`, `' ${target}>`);
        }
        segmentedHTML[i] = '<a' + segmentedHTML[i].replace(target, `target='${linkTarget}'`);
      }
    }

    this.innerHtml = segmentedHTML.join('');
    this.multimediaItem.text = this.innerHtml;
  }

  checkElementHasTarget(element, target) {
    return element.includes(target);
  }

  getLinkTarget() {
    return '_blank';
  }

  checkElementHasText(element, text) {
    return element.includes(text);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.multimediaItem) {
      this.canShowTranslationContent = this.showTranslate && this.checkSpanishOrPortugueseLang();
    }
  }

  private checkSpanishOrPortugueseLang(): boolean {
    return (
      (this.multimediaItem.language === LANGUAGES.PT.code && this.translate.currentLang === LANGUAGES.ES.code) ||
      (this.multimediaItem.language === LANGUAGES.ES.code && this.translate.currentLang === LANGUAGES.PT.code)
    );
  }
}

<div *ngIf="showTitle" class="title-container" [class.darkmode]="darkMode">
  <h6 *ngIf="multimediaItem.isOfficialContent(); else exampleTitle" class="u-h6 u-anthracite">
    {{ 'DESCRIPTION' | translate }}
  </h6>
  <ng-template #exampleTitle>
    <h6 class="u-h6 u-anthracite">{{ 'EXAMPLE_DESCRIPTION' | translate }}</h6>
  </ng-template>
</div>

<div class="description-container" [class.darkmode]="darkMode">
  <p [innerHTML]="innerHtml | safe: 'html'"></p>
  <p *ngIf="canShowTranslationContent">
    <app-content-translation
      [itemToTranslate]="multimediaItem"
    ></app-content-translation>
  </p>
</div>

import { ContentType } from '@app/shared/enums/multimedia/content-status-types.enum';
/* eslint-disable @typescript-eslint/naming-convention */

export interface IExampleItemForm {
  language: string;
  content_type: ContentType;
  description: string;
  title: string;
  files: (Blob | File)[];
  isEdition: boolean;
  thumbnail?: number;
}

export class ExampleItemForm implements IExampleItemForm {
  static readonly NO_MIME_TYPE = 'no-mime-type';
  language: string;
  content_type: ContentType;
  description: string;
  title: string;
  files: (Blob | File)[] = [];
  isEdition: boolean;
  thumbnail = 0;

  constructor(config: IExampleItemForm) {
    this.language = config.language;
    this.content_type = config.content_type;
    this.description = config.description;
    this.title = config.title;
    this.files = config.files;
    this.isEdition = config.isEdition;
    this.thumbnail = config.thumbnail || 0;
  }

  buildFormData(): FormData {
    let formData = new FormData();

    formData.append('language', this.language);
    formData.append('content_type', this.content_type);

    formData.append('description', this.description);
    formData.append('title', this.title);

    formData.append('mime_type', this.getMimeType(this.isEdition, this.content_type, this.files[0]));
    formData.append('size', this.getSize(this.content_type, this.description, this.files[0]));

    if (this.content_type === ContentType.IMAGE && this.files.length) {
      formData.append('image', this.files[0]);

      if (this.files.length > 1) {
        formData = this.setRestOfFiles(formData, this.files, this.content_type);
      }

      formData.append('thumbnail', this.thumbnail.toString());
    }

    return formData;
  }

  private getSize(content_type: ContentType, description: string, file: File | Blob): string {
    if (content_type === ContentType.TEXT) {
      return description.length.toString();
    }

    return file ? file.size.toString() : '';
  }

  private getMimeType(isEdition: boolean, content_type: ContentType, file: File | Blob): string {
    let mimeType = isEdition ? '' : 'text/plain';

    if (content_type !== ContentType.TEXT && file) {
      mimeType = file.type;
    }

    if (!mimeType) {
      mimeType = ExampleItemForm.NO_MIME_TYPE;
    }

    return mimeType;
  }

  private setRestOfFiles(formData: FormData, files: (File | Blob)[], content_type: ContentType): FormData {
    files
      .filter((_file, index) => index > 0)
      .forEach((file, index) => {
        const suffix = index + 2;
        formData.append(`${content_type}${suffix}`, file);
        formData.append(`mime_type${suffix}`, this.getMimeType(this.isEdition, this.content_type, file));
        formData.append(`size${suffix}`, this.getSize(this.content_type, this.description, file));
      });

    return formData;
  }
}

<div class="list options" lines="none">
  <app-item
    class="item-container"
    [spaceBetween]="true"
    *ngFor="let language of languages; trackBy: trackByItems"
    (click)="selectedOption(language)">
    <h6 slot="start" class="u-h6" [class.selected]="language.checked">{{ language.label | translate }}</h6>
    <img slot="end" *ngIf="language.checked" src="assets/imgs/profile/language/check-green.svg" alt="Language Check" />
  </app-item>
</div>

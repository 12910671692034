<!-- Item -->
<ng-template #Item let-item="item">
  <app-multimedia-card
    *ngIf="item.isMultimedia()"
    [detailRenderMode]="detailRenderMode"
    [exampleOptions]="exampleOptions"
    [exampleStatus]="exampleStatus"
    [publishIsDisabled]="item.isPublishDisabled()"
    [type]="cardType"
    [pageAnalytics]="pageAnalytics"
    [multimedia]="item"
    [isWebview]="isWebView"></app-multimedia-card>
  <app-video-card
    *ngIf="item.isVideo()"
    [detailRenderMode]="detailRenderMode"
    [exampleOptions]="exampleOptions"
    [exampleStatus]="exampleStatus"
    [publishIsDisabled]="item.isPublishDisabled()"
    [type]="cardType"
    [pageAnalytics]="pageAnalytics"
    [video]="item"
    [isWebview]="isWebView"></app-video-card>
  <app-text-card
    *ngIf="item.isText()"
    [detailRenderMode]="detailRenderMode"
    [exampleOptions]="exampleOptions"
    [exampleStatus]="exampleStatus"
    [publishIsDisabled]="item.isPublishDisabled()"
    [text]="item"
    [type]="cardType"
    [pageAnalytics]="pageAnalytics"
    [isWebview]="isWebView"></app-text-card>
  <app-image-card
    *ngIf="item.isImage()"
    [detailRenderMode]="detailRenderMode"
    [exampleOptions]="exampleOptions"
    [exampleStatus]="exampleStatus"
    [image]="item"
    [publishIsDisabled]="item.isPublishDisabled()"
    [type]="cardType"
    [pageAnalytics]="pageAnalytics"
    [isWebview]="isWebView"></app-image-card>
  <app-news-card *ngIf="item.isNews()" [news]="item"></app-news-card>
  <app-internal-link-card
    *ngIf="item.isInternal()"
    [detailRenderMode]="detailRenderMode"
    [exampleOptions]="exampleOptions"
    [exampleStatus]="exampleStatus"
    [internalLink]="item"
    [publishIsDisabled]="item.isPublishDisabled()"
    [type]="cardType"
    [pageAnalytics]="pageAnalytics"
    [isWebview]="isWebView"></app-internal-link-card>
</ng-template>

<!-- Slider Mode -->
<div *ngIf="slideMode; else default">
  <swiper-container app-swiper [config]="viewSlidesOptions" init="false" (slideChange)="onActiveSlideChange()">
    <swiper-slide
      class="swiper-slide multimedia-slide animated fadeIn"
      [style.animation-delay]="'0.' + 3 + i * 2 + 's'"
      *ngFor="let item of multimediaItemList | slice : 0 : max; let i = index; trackBy: trackById">
      <ng-template [ngTemplateOutlet]="Item" [ngTemplateOutletContext]="{ item: item }"></ng-template>
    </swiper-slide>
  </swiper-container>
</div>

<!-- List Mode -->
<ng-template #default>
  <div class="row">
    <div class="col ls-advice__container" *ngIf="lastViewed && lastViewed !== 0" size="12">
      <div class="ls-advice__pill">
        <ion-icon class="ls-advice__icon" name="arrow-down-outline"></ion-icon>
        {{ 'LAST_VIEWED.NEW_EXAMPLES' | translate }}
      </div>
    </div>
    <ng-container *ngFor="let item of multimediaItemList | slice : 0 : max; let i = index; trackBy: trackById">
      <div class="col ls-advice__container" *ngIf="lastViewed && lastViewed === item.id" size="12">
        <hr class="ls-advice__separator" />
        <span class="ls-advice__pill">{{ 'LAST_VIEWED.ALREADY_UPDATED' | translate }}</span>
        <hr class="ls-advice__separator" />
      </div>
      <div class="col wow fadeIn" size="12" size-md="6" size-lg="4" [attr.data-wow-delay]="'0.' + i + 's'">
        <ng-template [ngTemplateOutlet]="Item" [ngTemplateOutletContext]="{ item: item }"></ng-template>
      </div>
    </ng-container>
  </div>
</ng-template>

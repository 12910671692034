import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { LoadingService } from '@app/services/loading/loading.service';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmationController } from '../confirmation/confirmation.controller';

@Injectable({
  providedIn: 'root'
})
export class ConfirmExitService {
  private messages!: { [key: string]: string };
  private form!: FormGroup;
  private formReference!: FormGroup;
  private isManuallyDirty = false;

  constructor(
    private translate: TranslateService,
    private confirmationCtrl: ConfirmationController,
    private loadingService: LoadingService
  ) {
    this.getTranslations();
  }

  getAlertText(event: MSafeAny) {
    event.returnValue = this.messages['EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.MESSAGE'];
    return this.messages['EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.MESSAGE'];
  }

  setCloseWindowListener(setAlert: (event: MSafeAny) => void) {
    window.addEventListener('beforeunload', setAlert);
  }

  removeCloseWindowListener(setAlert: (event: MSafeAny) => void) {
    window.removeEventListener('beforeunload', setAlert);
  }

  hasFormChanges(): boolean {
    this.formIsDifferent();
    return Boolean(this.form?.dirty);
  }

  setFormToCheck(formToCheck: FormGroup) {
    this.form = formToCheck;
    this.formReference = Object.assign({}, this.form);
  }

  setFormToModified() {
    this.isManuallyDirty = true;
  }

  setFormToUnModified() {
    this.isManuallyDirty = false;
  }

  cleanFormToCheck() {
    this.form?.reset();
    this.isManuallyDirty = false;
  }

  async confirmChangeDismiss(
    hasChanges: boolean,
    browserAlert?: MSafeAny,
    message = this.messages['EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.MESSAGE'],
    title = this.messages['EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.TITLE'],
    confirmButtonText = this.messages['EMPLOYEE_PORTAL.LEAVE'],
    cancelButtonText = this.messages['EMPLOYEE_PORTAL.CANCEL']
  ) {
    if (!hasChanges) {
      return true;
    }

    if (browserAlert) {
      this.removeCloseWindowListener(browserAlert);
    }

    this.loadingService.hide();

    const confirmed = await this.confirmationCtrl.confirmAction({
      title,
      message,
      confirmButtonText,
      cancelButtonText
    });

    if (!confirmed && browserAlert) {
      this.setCloseWindowListener(browserAlert);
    }

    return confirmed;
  }

  private getTranslations() {
    this.translate
      .stream([
        'EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.MESSAGE',
        'EMPLOYEE_PORTAL.EXIT_WITH_PENDING_CHANGES.TITLE',
        'EMPLOYEE_PORTAL.LEAVE',
        'EMPLOYEE_PORTAL.CANCEL'
      ])
      .subscribe((messages) => (this.messages = messages));
  }

  private formIsDifferent() {
    if (!this.form || !this.formReference) {
      return;
    }

    let isDirty = false;
    if (this.isManuallyDirty) {
      isDirty = true;
    } else {
      this.checkNullControlsValueInForm();
      isDirty = this.formPropertiesAreDifferent();
    }

    if (isDirty) {
      this.form.markAsDirty();
    } else {
      this.form.reset();
    }
  }

  private formPropertiesAreDifferent(): boolean {
    let isDirty = false;
    for (const property in this.form) {
      if (
        property !== 'touched' &&
        property !== 'pristine' &&
        property !== '_pendingDirty' &&
        ((property === 'value' &&
          JSON.stringify(this.formReference[property]) !== JSON.stringify(this.form[property])) ||
          (property !== 'value' &&
            this.formReference[property as keyof FormGroup<MSafeAny>] !==
              this.form[property as keyof FormGroup<MSafeAny>]))
      ) {
        isDirty = true;
      }
    }

    return isDirty;
  }

  private checkNullControlsValueInForm() {
    Object.keys(this.form.controls).forEach((key) => {
      if (this.form?.controls[key].value === null) {
        this.form.controls[key].setValue('');
      }
    });
    this.form.updateValueAndValidity();
  }
}

<app-content-modal class="hide-overflow" [buttons]="confirmationButtons" [hideClose]="true">
  <div class="content_text">
    <h2>{{ 'EXAMPLES_CREATION.TITLE' | translate }}</h2>
    <h5>{{ 'EXAMPLES_CREATION.SUBTITLE' | translate }}</h5>
    <p class="text">{{ 'EXAMPLES_CREATION.DESC_1' | translate }}</p>
    <p class="text">{{ 'EXAMPLES_CREATION.DESC_2' | translate }}</p>
    <p class="text">{{ 'EXAMPLES_CREATION.DESC_3' | translate }}</p>
  </div>
  <div class="image-container">
    <div class="image"></div>
  </div>
</app-content-modal>

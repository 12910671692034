import { Component, Injector, Input } from '@angular/core';

import { Logger } from '@app/services';
import { ItemCardType } from '@app/shared/enums/cards/item-card-type.enum';
import { Image } from '@app/shared/models/multimedia/image.model';
import { MultimediaCardDirective } from '@components/multimedia/multimedia-cards/multimedia-card';

import { ImageDetailComponent } from '../../multimedia-details';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['../multimedia-card.scss', './image-card.component.scss']
})
export class ImageCardComponent extends MultimediaCardDirective {
  @Input() image!: Image;
  @Input() type!: ItemCardType;

  logger: Logger = new Logger('Image Card Component');

  show = false;

  constructor(injector: Injector) {
    super(ImageDetailComponent, injector);
  }

  protected onModalDetailDismiss(image: Image) {
    if (image) {
      this.image.views = image.views;
      this.image.likes = image.likes;
      this.image.liked = image.liked;
    }
  }

  protected getItem(): Image {
    return this.image;
  }
}

import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';

import { NetworkService } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { PublicationService } from '@app/services/multimedia/publication.service';
import { ItemCardType } from '@app/shared/enums/cards/item-card-type.enum';
import { News } from '@app/shared/models/multimedia/news.model';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['../multimedia-card.scss', './news-card.component.scss']
})
export class NewsCardComponent implements OnInit {
  @Input() news!: News;

  @HostBinding('class.type-normal') readonly type = ItemCardType.NORMAL;

  analyticsCategory!: string;
  show = false;

  constructor(
    private network: NetworkService,
    private publicationService: PublicationService,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {}

  @HostListener('click')
  onClick = () => {
    this.network.doIfConnection(() => this.openNewsUrl());
  };

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.analyticsCategory = this.news.getAnalyticsCategory(data.pageAnalytics);
    });
  }

  openNewsUrl() {
    window.open(this.news.url, '_blank');
    this.incrementViews();
  }

  incrementViews() {
    this.publicationService.updateItemVisualizations(this.news.id).subscribe((views) => (this.news.views = views));
  }
}

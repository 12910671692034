import { Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss']
})
export class EmojiPickerComponent {
  @Output() closePicker = new EventEmitter<number>();
  @Output() emojiSelected = new EventEmitter<string>();

  private clicks = 0;

  emojiLabels = {};

  constructor(private element: ElementRef, private translate: TranslateService) {
    this.getTranslations();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MSafeAny) {
    if (!this.element.nativeElement.contains(event.target)) {
      this.closePicker.emit(this.clicks);
      this.clicks++;
    }
  }

  private getTranslations() {
    this.translate
      .stream([
        'EMOJI_PICKER.RECENT',
        'EMOJI_PICKER.PEOPLE',
        'EMOJI_PICKER.NATURE',
        'EMOJI_PICKER.FOODS',
        'EMOJI_PICKER.ACTIVITY',
        'EMOJI_PICKER.PLACES',
        'EMOJI_PICKER.OBJECTS',
        'EMOJI_PICKER.SYMBOLS',
        'EMOJI_PICKER.FLAGS'
      ])
      .subscribe((messages) => {
        this.emojiLabels = {
          categories: {
            recent: messages['EMOJI_PICKER.RECENT'],
            people: messages['EMOJI_PICKER.PEOPLE'],
            nature: messages['EMOJI_PICKER.NATURE'],
            foods: messages['EMOJI_PICKER.FOODS'],
            activity: messages['EMOJI_PICKER.ACTIVITY'],
            places: messages['EMOJI_PICKER.PLACES'],
            objects: messages['EMOJI_PICKER.OBJECTS'],
            symbols: messages['EMOJI_PICKER.SYMBOLS'],
            flags: messages['EMOJI_PICKER.FLAGS']
          }
        };
      });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MPopoverModule } from '@mercadona/components/popover';
import { MIconModule } from '@mercadona/icons';
import { MAlertModule } from '@mercadona/components/alert';

import { NotificationFilterComponent } from '@app/activo2/notifications/components/notification-filter/notification-filter.component';
import { NotificationItemComponent } from '@app/activo2/notifications/components/notification-item/notification-item.component';
import { NotificationListComponent } from '@app/activo2/notifications/components/notification-list/notification-list.component';
import { OfficeSearchComponent } from '@app/activo2/office-service/components/search/office-search.component';
import { StaticTextComponent } from '@app/activo2/static-text/static-text.component';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';
import * as hammer from 'hammerjs';

import { AlertComponent } from './alert/alert.component';
import { AuthPlaceholderComponent } from './auth-placeholder/auth-placeholder.component';
import { AvatarComponent } from './avatar/avatar.component';
import { AvatarManagerComponent } from './avatar-manager/avatar-manager.component';
import { BadgeInfoComponent } from './badge-info/badge-info.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { AddCommentComponent } from './comments-section/add-comment/add-comment.component';
import { AvatarCommentSelectorComponent } from './comments-section/add-comment/avatar-comment-selector/avatar-comment-selector.component';
import { AvatarCommentComponent } from './comments-section/avatar-comment/avatar-comment.component';
import { CommentComponent } from './comments-section/comments-list/comment/comment.component';
import { CommentsListComponent } from './comments-section/comments-list/comments-list.component';
import { UserInfoPopoverComponent } from './comments-section/comments-list/user-info-popover/user-info-popover.component';
import { CommentsSectionComponent } from './comments-section/comments-section.component';
import { CreateExampleComponent } from './create-example/create-example.component';
import { DataItemComponent } from './data-section/data-item/data-item.component';
import { DataSectionComponent } from './data-section/data-section.component';
import { DropdownButtonsComponent } from './dropdown-buttons/dropdown-buttons.component';
import { EditExampleComponent } from './edit-example/edit-example.component';
import { EmptyMsgComponent } from './empty-msg/empty-msg.component';
import { ErrorInfoComponent } from './error-info/error-info.component';
import { EditionPlaceholderComponent } from './example-form/components/edition-placeholder/edition-placeholder.component';
import { ExampleFormComponent } from './example-form/components/example-form/example-form.component';
import { ExampleFormImagesComponent } from './example-form/components/example-form-images/example-form-images.component';
import { ExamplePublishComponent } from './example-form/components/example-publish/example-publish.component';
import { MultimediaManagerComponent } from './example-form/components/multimedia-manager/multimedia-manager.component';
import { VideoPreviewComponent } from './example-form/components/video-preview/video-preview.component';
import { FilterPopoverComponent } from './filter-tag-list/filter-popover/filter-popover.component';
import { FilterTagComponent } from './filter-tag-list/filter-tag/filter-tag.component';
import { FilterTagListComponent } from './filter-tag-list/filter-tag-list.component';
import { GlobalInfoComponent } from './global-info/global-info.component';
import { GoogleTranslationComponent } from './google-translation/google-translation';
import { HeaderLiteComponent } from './header-lite/header-lite.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { ImageDetailSlidesComponent } from './image-detail-slides/image-detail-slides.component';
import { InputWrapperComponent } from './input-wrapper/input-wrapper.component';
import { LanguageComponent } from './language/language.component';
import { LinkCardComponent } from './link-card/link-card.component';
import { MediaPlayerComponent } from './media-player/media-player.component';
import {
  MessageErrorComponent,
  MessageFinalListComponent,
  MessageInfoComponent,
  MessageValidatedComponent,
  MessageWarningComponent
} from './messages';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { ContentModalComponent } from './modals/content-modal/content-modal.component';
import { CookiesModalComponent } from './modals/cookies-modal/cookies-modal.component';
import { EditAvatarModalComponent } from './modals/edit-avatar-modal/edit-avatar-modal.component';
import { ExampleConfirmationModalComponent } from './modals/example-confirmation-modal/example-confirmation-modal.component';
import { LanguageSelectModalComponent } from './modals/language-select-modal/language-select-modal.component';
import { ModalPlaceholderComponent } from './modals/modal-placeholder/modal-placeholder.component';
import { PhotoViewerComponent } from './modals/photo-viewer/photo-viewer.component';
import { ShareModalComponent } from './modals/share-modal/share-modal.component';
import { MultimediaActionsComponent } from './multimedia/multimedia-actions/multimedia-actions.component';
import { MultimediaCardModule } from './multimedia/multimedia-cards/multimedia-card.module';
import {
  ImageDetailComponent,
  TextDetailComponent,
  VideoDetailComponent,
  MultimediaCarouselComponent
} from './multimedia/multimedia-details';
import { MultimediaDetailAuthorComponent } from './multimedia/multimedia-details/components/multimedia-detail-author/multimedia-detail-author.component';
import { MultimediaListComponent } from './multimedia/multimedia-list/multimedia-list.component';
import { PlaceholderCardComponent } from './multimedia/placeholder-card/placeholder-card.component';
import { PlaceholderListComponent } from './multimedia/placeholder-list/placeholder-list.component';
import { ContentTranslationComponent } from './multimedia-detail-description/content-translation/content-translation.component';
import { MultimediaDetailDescriptionComponent } from './multimedia-detail-description/multimedia-detail-description.component';
import { NavTabComponent } from './nav-tabs/nav-tab/nav-tab.component';
import { NavTabsComponent } from './nav-tabs/nav-tabs.component';
import { NoResultsComponent } from './no-results/no-results.component';
import { MenuHeaderComponent } from './profile-menu/menu-header/menu-header.component';
import { MenuSectionComponent } from './profile-menu/menu-section/menu-section.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { PublicationFilterButtonComponent } from './publication-filter/publication-filter-button/publication-filter-button.component';
import { PublicationFilterComponent } from './publication-filter/publication-filter.component';
import { QuotePhraseComponent } from './quote-phrase/quote-phrase.component';
import { ResultsHighlighterComponent } from './results-highlighter/results-highlighter.component';
import { EmojiPickerComponent } from './richtext-editor/emoji-picker/emoji-picker.component';
import { RichtextEditorComponent } from './richtext-editor/richtext-editor.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { ScrollableXComponent } from './scrollable-x/scrollable-x.component';
import { SearchConfirmComponent } from './search-confirm/search-confirm.component';
import { SectionMaintenanceComponent } from './section-maintenance/section-maintenance.component';
import { SegmentComponent } from './segment/segment.component';
import { SegmentFilterButtonComponent } from './segment-filter/segment-filter-button/segment-filter-button.component';
import { SegmentFilterComponent } from './segment-filter/segment-filter.component';
import { ShareContentModule } from './share-content/share-content.component.module';
import { ShareModalTemplateComponent } from './share-modal-template/share-modal-template.component';
import { SliderComponent } from './slider/slider.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions/terms-and-conditions.component';
import { TitleDescriptionCardComponent } from './title-description-card/title-description-card.component';
import { TitleDescriptionImageCardComponent } from './title-description-image-card/title-description-image-card.component';
import { TitleDescriptionMultipleCardComponent } from './title-description-multiple-card/title-description-multiple-card.component';
import { ToggleOptionComponent } from './toggle-option/toggle-option.component';
import { RadioSelectorComponent } from './radio-selector/radio-selector.component';
import { ListCardComponent } from './list-card/list-card.component';
import { ErrorMsgComponent } from './error-msg/error-msg.component';
import { SignDocumentComponent } from './sign-document/sign-document.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ItemComponent } from './item/item.component';
import { ToggleComponent } from './toggle/toggle.component';
import { CaptionLeyendComponent } from './caption-leyend/caption-leyend.component';
import { InputComponent } from './input/input.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false }
  };
  options = { domEvents: true };
}

const components = [
  MultimediaListComponent,
  PlaceholderListComponent,
  PlaceholderCardComponent,
  NoResultsComponent,
  ErrorInfoComponent,
  AuthPlaceholderComponent,
  QuotePhraseComponent,
  ScrollTopComponent,
  TermsAndConditionsComponent,
  MessageWarningComponent,
  MessageFinalListComponent,
  MessageInfoComponent,
  MessageErrorComponent,
  MessageValidatedComponent,
  HeaderLiteComponent,
  GlobalInfoComponent,
  MediaPlayerComponent,
  ImageDetailSlidesComponent,
  MultimediaDetailDescriptionComponent,
  ContentTranslationComponent,
  EmptyMsgComponent,
  AvatarComponent,
  ToggleOptionComponent,
  LinkCardComponent,
  DataSectionComponent,
  DataItemComponent,
  AvatarManagerComponent,
  EditAvatarModalComponent,
  SectionMaintenanceComponent,
  StaticTextComponent,
  SegmentComponent,
  SearchConfirmComponent,
  DropdownButtonsComponent,
  IconButtonComponent,
  TitleDescriptionImageCardComponent,
  TitleDescriptionCardComponent,
  TitleDescriptionMultipleCardComponent,
  RadioSelectorComponent,
  ListCardComponent,
  OfficeSearchComponent,
  ErrorMsgComponent,
  SignDocumentComponent,
  SpinnerComponent,
  ItemComponent,
  ToggleComponent,
  CaptionLeyendComponent,
  InputComponent,
  SelectSearchComponent,
  // Multimedia Details
  MultimediaDetailAuthorComponent,
  MultimediaCarouselComponent,
  TextDetailComponent,
  ImageDetailComponent,
  VideoDetailComponent,
  // Multimedia Actions
  MultimediaActionsComponent,
  SliderComponent,
  // Filters
  FilterTagListComponent,
  FilterTagComponent,
  FilterPopoverComponent,
  ScrollableXComponent,
  PublicationFilterComponent,
  PublicationFilterButtonComponent,
  CheckboxComponent,
  SegmentFilterComponent,
  SegmentFilterButtonComponent,
  // Modals
  ContentModalComponent,
  ModalPlaceholderComponent,
  ConfirmationModalComponent,
  CookiesModalComponent,
  ShareModalComponent,
  ExampleConfirmationModalComponent,
  LanguageSelectModalComponent,
  LanguageComponent,
  PhotoViewerComponent,
  // Comments
  CommentsSectionComponent,
  CommentsListComponent,
  CommentComponent,
  AvatarCommentComponent,
  RichtextEditorComponent,
  AvatarCommentSelectorComponent,
  AddCommentComponent,
  EmojiPickerComponent,
  // Tabs
  NavTabsComponent,
  NavTabComponent,
  // Search
  ResultsHighlighterComponent,
  // Example form
  ExamplePublishComponent,
  ExampleFormImagesComponent,
  EditionPlaceholderComponent,
  VideoPreviewComponent,
  // Popovers
  UserInfoPopoverComponent,
  ProfileMenuComponent,
  MenuHeaderComponent,
  MenuSectionComponent,
  AlertComponent,
  // Notifications
  NotificationListComponent,
  NotificationItemComponent,
  NotificationFilterComponent,
  InputWrapperComponent,
  ShareModalTemplateComponent,
  GoogleTranslationComponent,
  ExampleFormComponent,
  MultimediaManagerComponent,
  CreateExampleComponent,
  EditExampleComponent,
  BadgeInfoComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule.forChild(),
    MultimediaCardModule,
    ShareContentModule,
    PipesModule,
    PickerModule,
    DragulaModule,
    MatRadioModule,
    CKEditorModule,
    DirectivesModule,
    InfiniteScrollModule,
    MatButtonToggleModule,
    HammerModule,
    MPopoverModule,
    MAlertModule,
    MIconModule
  ],
  declarations: components,
  entryComponents: [
    MultimediaCarouselComponent,
    TextDetailComponent,
    ImageDetailComponent,
    VideoDetailComponent,
    LanguageComponent,
    // Modals
    ConfirmationModalComponent,
    CookiesModalComponent,
    ShareModalComponent,
    ExampleConfirmationModalComponent,
    LanguageSelectModalComponent,
    PhotoViewerComponent,
    // Example form
    ExamplePublishComponent,
    // Popovers
    FilterPopoverComponent,
    ProfileMenuComponent
  ],
  exports: components,
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ]
})
export class ComponentsModule {}

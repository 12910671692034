import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textOrBlankSpace'
})
export class TextOrBlankSpace implements PipeTransform {
  transform(value: string) {
    if (value === undefined || value === null || value.trim() === '') {
      return '&nbsp;';
    }

    return value;
  }
}

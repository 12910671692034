import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { Logger } from '@app/services';
import { UserService } from '@app/services/user/user.service';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { User } from '@app/shared/models/user/user.model';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss']
})
export class MenuHeaderComponent implements OnInit, OnDestroy {
  @Input() userInfo: MSafeAny;
  @Output() closeEvent = new EventEmitter<void>();

  private readonly logger: Logger = new Logger('Menu header component');
  private userInfoSubscription!: Subscription;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userInfoSubscription = new Subscription();
    this.subscribeUserInfoChanges();
  }

  ngOnDestroy() {
    this.userInfoSubscription.unsubscribe();
  }

  onClose() {
    this.closeEvent.emit();
  }

  subscribeUserInfoChanges() {
    this.userInfoSubscription.add(
      this.userService.userInfoChanges$.subscribe((userInfo) => {
        this.handleNewUserInfo(userInfo);
      })
    );
  }

  private handleNewUserInfo(userInfo: User) {
    if (!userInfo) {
      this.logger.warn('handleNewUserInfo: User Info is empty, aborting...');
      return;
    }

    this.userInfo = userInfo;
  }
}

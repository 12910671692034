import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ITranslationRequest } from '@app/services/content-translator/content-translator.model';
import { ContentTranslatorService } from '@app/services/content-translator/content-translator.service';
import { TranslateService } from '@ngx-translate/core';

import { TRANSLATION_STATE } from './google-translation.constants';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-google-translation',
  templateUrl: 'google-translation.html',
  styleUrls: ['./google-translation.scss']
})
export class GoogleTranslationComponent {
  @Input() sourceText = '';
  @Input() sourceLang = 'es';
  @Output() toggleTranslate: EventEmitter<string> = new EventEmitter<string>();

  private translatedText = '';

  readonly STATE = TRANSLATION_STATE;
  currentState = TRANSLATION_STATE.IDLE;
  showTranslated = false;

  constructor(private contentTranslator: ContentTranslatorService, private translateService: TranslateService) {}

  translate() {
    const request: ITranslationRequest = {
      language_from: this.sourceLang,
      language_to: this.translateService.currentLang,
      text: this.sourceText
    };

    this.currentState = this.STATE.LOADING;

    this.contentTranslator.translate(request).subscribe(
      (text) => {
        this.currentState = this.STATE.DONE;
        this.translatedText = text;
        this.toggleTranslate.emit(this.translatedText);
      },
      () => (this.currentState = this.STATE.ERROR)
    );
  }

  showTranslatedText() {
    this.showTranslated = true;
    if (this.translatedText) {
      this.toggleTranslate.emit(this.translatedText);
      return;
    }

    this.translate();
  }

  showOriginalText() {
    this.showTranslated = false;
    this.toggleTranslate.emit(this.sourceText);
  }

  get isTranslated(): boolean {
    return this.currentState === this.STATE.DONE && this.showTranslated;
  }
}

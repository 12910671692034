import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

/* eslint-disable @typescript-eslint/naming-convention */
export class UserAnalytics {
  userID?: string;
  store?: string;
  department?: string;
  divisionZone?: string;
  LOGADO: 'si' | 'no';
  USERTYPE: 'registrado' | 'no_registrado';

  constructor(userInfo: MSafeAny, logged: boolean) {
    this.userID = userInfo?.internal_user_id;
    this.store = userInfo?.store.slice(-36);
    this.department = userInfo?.department.slice(-36);
    this.divisionZone = userInfo?.division_zone.slice(-36);
    this.LOGADO = logged ? 'si' : 'no';
    this.USERTYPE = logged ? 'registrado' : 'no_registrado';
  }
}

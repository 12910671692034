export enum OrderingEnum {
  ASC_MODIFICATION_DATE = '+modification_date',
  DESC_MODIFICATION_DATE = '-modification_date',
  ASC_VIEWS = '+views',
  DESC_VIEWS = '-views',
  TITLE = 'title',
  CREATION_DATE = 'creation_date',
  ASC_PUBLICATION_DATE = '+publication_date',
  DESC_PUBLICATION_DATE = '-publication_date'
}

<div id="lightbox-cookies">
  <div class="grid-fixed">
    <div>
      <h2>{{ 'COOKIE_LIGHTBOX.POLICY' | translate }}</h2>
      <p class="lightbox-text">
        {{ 'COOKIE_LIGHTBOX.MESSAGE' | translate }}
        <span class="cookies-policy" (click)="goToCookiesPage()">{{ 'COOKIE_LIGHTBOX.POLICY' | translate }}</span
        >{{ 'COOKIE_LIGHTBOX.MESSAGE_1' | translate }}
      </p>
    </div>

    <div class="config-buttons-container">
      <div class="buttons">
        <button (click)="configCookies()">{{ 'COOKIES.COOKIES_MODAL' | translate }}</button>
        <button (click)="rejectCookies()">{{ 'COOKIE_LIGHTBOX.REJECT' | translate }}</button>
        <button (click)="acceptCookies()">{{ 'COOKIE_LIGHTBOX.ACCEPT' | translate }}</button>
      </div>
    </div>
  </div>
</div>

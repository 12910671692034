import { FeedbackTypes } from '@ptrab/shared/enums/feedback.enum';

import { IFeedbackMsg } from './feedback-msg.interface';

export const feedbackMessagesData: { [key: string]: IFeedbackMsg } = {
  [FeedbackTypes.EMPTY_RESULT]: {
    image: 'assets/imgs/no-results/empty.svg',
    textLabel: ''
  },
  [FeedbackTypes.SERVER_ERROR]: {
    image: 'assets/imgs/no-results/error.svg',
    textLabel: 'EMPLOYEE_PORTAL.UNABLE_TO_LOAD_CONTENT'
  },
  [FeedbackTypes.SERVICE_LOCKED]: {
    image: 'assets/imgs/no-results/account-man.svg',
    textLabel: 'EMPLOYEE_PORTAL.PAYSLIP_PROCESS_SOON'
  }
};

<app-data-section
  [title]="title | translate"
  [actionText]="'EMPLOYEE_PORTAL.MODIFY' | translate"
  (actionClick)="modifySection()">
  <i
    *ngIf="hasError"
    class="header-left-item app-icon-alert"
    #mPopover="m-popover"
    popoverPosition="bottomRight"
    [m-popover]="popoverTemplate"
    customPanelClass="custom"></i>
  <ng-container *ngFor="let item of dataToload; trackBy: trackByItems">
    <app-data-item [label]="item.label" [value]="item.value" *ngIf="item.value"></app-data-item>
  </ng-container>
  <app-data-item
    *ngIf="!hasDataToLoad"
    [label]="
      emptyMsg
        ? (emptyMsg | translate)
        : ('EMPLOYEE_PORTAL.WITHOUT_RECORDS' | translate) + ' ' + (title | translate | lowercase)
    "></app-data-item>
</app-data-section>

<ng-template #popoverTemplate>
  <div class="info-popover__content">
    <p>{{ 'EMPLOYEE_PORTAL.THERE_IS_ANY_ERROR' | translate }}</p>
  </div>
</ng-template>

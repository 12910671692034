import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TitleSectionComponent } from './title-section.component';

@NgModule({
  declarations: [TitleSectionComponent],
  imports: [CommonModule],
  exports: [TitleSectionComponent]
})
export class TitleSectionModule {}

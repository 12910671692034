import { DatePipe } from '@angular/common';

import { IrpfView } from '@app/ptrab/shared/models/irpf-view.model';
import { IrpfModel } from '@app/ptrab/shared/models/irpf.model';
import { IrsView } from '@app/ptrab/shared/models/irs-view.model';
import { IrsModel } from '@app/ptrab/shared/models/irs.model';
import { TranslateService } from '@ngx-translate/core';

export class IrpfViewFormatter {
  constructor(private translate: TranslateService, private datePipe: DatePipe) {}

  format(irpf: IrpfModel): IrpfView {
    const maritalStatus = irpf.worker.maritalStatus;
    const { childAllowance, alimony, courtRulingDate } = irpf.pensionPayment;

    return new IrpfView({
      worker: {
        maritalStatus: irpf.isCommonTerritory() && {
          label: this.translate.instant('EMPLOYEE_PORTAL.MARITAL_STATUS'),
          value: this.translate.instant(`EMPLOYEE_PORTAL.${maritalStatus}`)
        },
        disability: {
          label: irpf.worker.disability ? this.translate.instant('EMPLOYEE_PORTAL.HANDICAP') : null,
          value: this.translate.instant('EMPLOYEE_PORTAL.KNOWN')
        },
        reducedMobility: {
          label: irpf.worker.reducedMobility ? this.translate.instant('EMPLOYEE_PORTAL.REDUCED_MOBILITY') : null,
          value: this.translate.instant('EMPLOYEE_PORTAL.SELECTED')
        }
      },
      pensionPayment: (childAllowance || alimony) && {
        childAllowance: irpf.isCommonTerritory() && {
          label: childAllowance ? this.translate.instant('EMPLOYEE_PORTAL.CHILD_ALLOWANCE') : null,
          value:
            childAllowance &&
            childAllowance.toLocaleString('es', {
              style: 'currency',
              currency: 'EUR'
            })
        },
        alimony: {
          label: alimony ? this.translate.instant('EMPLOYEE_PORTAL.ALIMONY') : null,
          value: alimony && alimony.toLocaleString('es', { style: 'currency', currency: 'EUR' })
        },
        courtRulingDate: !irpf.isCommonTerritory() &&
          !irpf.isNavarraTerritory() && {
            label: courtRulingDate ? this.translate.instant('EMPLOYEE_PORTAL.COURT_RULING_DATE') : null,
            value: courtRulingDate && this.formatDate(courtRulingDate)
          }
      },
      mortgageReduction: {
        mortgageReduction: {
          label: irpf.mortgageReduction
            ? this.translate.instant('EMPLOYEE_PORTAL.SELECTED')
            : this.translate.instant('EMPLOYEE_PORTAL.NOT_SELECTED')
        }
      },
      ceutaMelillaResidence: {
        ceutaMelillaResidence: {
          label: irpf.ceutaMelillaResidence
            ? this.translate.instant('EMPLOYEE_PORTAL.CEUTA_MELILLA_RESIDENT')
            : this.translate.instant('EMPLOYEE_PORTAL.CEUTA_MELILLA_NON_RESIDENT')
        }
      },
      ancestors: irpf.ancestors.map((ascentor, index) => {
        index++;
        return {
          label: `${this.translate.instant('EMPLOYEE_PORTAL.ANCESTOR')} ${index}`,
          value: `${this.translate.instant('EMPLOYEE_PORTAL.BORN_IN')} ${ascentor.birthDate}`
        };
      }),
      descendants: irpf.descendants.map((descendant, index) => {
        index++;
        return {
          label: `${this.translate.instant('EMPLOYEE_PORTAL.DESCENDANT')} ${index}`,
          value: `${this.translate.instant('EMPLOYEE_PORTAL.BORN_IN')} ${descendant.birthDate}`
        };
      })
    });
  }

  formatPt(irs: IrsModel): IrsView {
    return new IrsView({
      worker: {
        civilStatus: {
          label: this.translate.instant('EMPLOYEE_PORTAL.MARITAL_STATUS'),
          value: irs.worker.civilStatusName || '-'
        }
      },
      spouse: {
        spouse: {
          label: this.translate.instant('EMPLOYEE_PORTAL.SPOUSE_DISABILITY'),
          value: irs.hasDisability('spouse')
            ? this.translate.instant(`EMPLOYEE_PORTAL.${irs.spouse.disability.value}`)
            : null
        }
      },
      dependants: irs.dependants?.map((dependant, index) => {
        index++;
        return {
          label: `${this.translate.instant('EMPLOYEE_PORTAL.DEPENDANT')} ${index}`,
          value: this.formatDate(dependant.birthdate)
        };
      }),
      youngBenefit: {
        youngBenefit: {
          label: this.translate.instant('EMPLOYEE_PORTAL.COURSE_COMPLETION_DATE'),
          value: this.formatDate(irs.youngBenefit.date)
        }
      },
      roomBenefit: {
        roomBenefit: {
          label: this.translate.instant('EMPLOYEE_PORTAL.ROOM_BENEFIT'),
          value: irs.roomBenefit ? this.translate.instant('YES') : null
        }
      }
    });
  }

  formatDate(date) {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }
}

import { PageExtras } from '@app/services/analytics/models/analytics-engine.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ContentSourceType, PublicationContentType } from '@shared/enums/multimedia/content-status-types.enum';
import { isModelLink } from '@shared/helpers/model/model.helper';
import { ENV } from 'src/environments/environment';

import { AbstractMultimediaItem } from './multimedia.abstract.model';
/* eslint-disable @typescript-eslint/naming-convention */

export abstract class Publication extends AbstractMultimediaItem {
  content_type!: PublicationContentType;

  constructor(multimediaItem: MSafeAny) {
    super(multimediaItem);
    this.content_source = ContentSourceType.PUBLICATION;
    this.link = `${ENV.APP_UNIVERSAL_LINKS_URL}/p/${this.slug}`;
    this.patchLink();
  }

  getAnalyticsPageExtras(): PageExtras {
    return { pageType: 'publicaciones', tipologiaDeContenido: this.getAnalyticsPageContentType() };
  }

  /**
   * @description Patch link when is Publication of Example (internal link is the Example Id).
   */
  private patchLink() {
    if (this.isInternal() && !isModelLink(this)) {
      this.link = `${ENV.APP_UNIVERSAL_LINKS_URL}/e/${this.internal_link}`;
    }
  }
}

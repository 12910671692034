import { Video, VideoPublication } from '@app/shared/models/multimedia/video.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { VideoProviderTypes } from '@shared/enums/media-player/media-player.enum';
import { PublicationContentType } from '@shared/enums/multimedia/content-status-types.enum';
import { AbstractMultimediaItem } from '@shared/models/multimedia/multimedia.abstract.model';
import { Publication } from '@shared/models/multimedia/publication.model';
import { applyMixins } from '@shared/utils/utils';

import { VideoQualities } from './video-qualities.model';
/* eslint-disable @typescript-eslint/naming-convention */

export class ImageItem {
  alt!: string;
  caption!: string;
  thumb!: string;
  src!: string;
}

export class VideoItem {
  id_video!: string;
  video_provider!: VideoProviderTypes;
  iframe!: boolean;
  thumb!: string;
  src!: string;
  videos!: VideoQualities[];
  video_image!: string;
  detailVideo?: Video;
}

export abstract class Multimedia extends AbstractMultimediaItem {
  abstract content_type: PublicationContentType.MULTIMEDIA;
  multiple?: {
    type: string;
    image?: ImageItem;
    video?: VideoItem;
  }[];

  init(data: MSafeAny) {
    this.multiple = data.multiple || [];
  }
}

export class MultimediaPublication extends Publication implements Multimedia {
  content_type: PublicationContentType.MULTIMEDIA = PublicationContentType.MULTIMEDIA;
  multiple?: {
    type: string;
    image?: ImageItem;
    video?: VideoItem;
  }[];

  init!: (data: MSafeAny) => void;
  loadDataVideo(data: MSafeAny) {
    if (data.multiple) {
      data.multiple.forEach((item: MSafeAny) => {
        if (item.type === PublicationContentType.VIDEO) {
          const video = {
            id_video: item.id_video,
            video_provider: item.video_provider,
            video_image: item.video_image,
            videos: item.videos,
            url: item.src
          } as MSafeAny;

          item.detailVideo = new VideoPublication(video);
        }
      });
    }
  }

  constructor(data: MSafeAny) {
    super(data);
    this.loadDataVideo(data);
    this.init(data);
  }
}

applyMixins(MultimediaPublication, [Multimedia]);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from '@app/components/components.module';
import { PtrabComponentsModule } from '@app/ptrab/components/ptrab-components.module';
import { IrpfComponentsModule } from '@app/ptrab/irpf/components/irpf-components.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { AncestorModalComponent } from './ancestor-modal/ancestor-modal.component';
import { DescendantModalComponent } from './descendant-modal/descendant-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule.forChild(),
    ComponentsModule,
    PtrabComponentsModule,
    IrpfComponentsModule
  ],
  declarations: [DescendantModalComponent, AncestorModalComponent],
  entryComponents: [DescendantModalComponent, AncestorModalComponent]
})
export class RelativeModalsModule {}

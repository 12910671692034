<app-content-modal [hideClose]="false" (closeModal)="close()" class="share-modal">
  <ng-content select="[title]"></ng-content>
  <div class="share-modal__warning">
    <ng-content select="[warning]"></ng-content>
  </div>

  <div class="share-modal__networks">
    <div
      *ngFor="let item of shareItems; trackBy: trackByItems"
      class="share-modal__item"
      (click)="onShareVia(item.type)">
      <img [src]="item.src" [alt]="item.alt" />
      <p class="u-p2 u-medium-grey share-slide__text--desktop">
        {{ item.translateKey ? (item.translateKey | translate) : item.text }}
      </p>
    </div>
  </div>

  <div *ngIf="linkToShare" class="share-modal__link">
    <span class="u-p2 u-medium-grey share-modal__url">{{ linkToShare }}</span>
    <span (click)="onShareVia(shareVias.LINK)" class="share-modal__copy">{{ 'SHARE.COPY' | translate }}</span>
  </div>
</app-content-modal>

<div #backdrop class="backdrop"></div>

import { IEnjoyedYear } from '../interfaces/enjoyed-year.interface';

export class EnjoyedYear {
  id: number;
  name: string;

  constructor(data: IEnjoyedYear) {
    this.id = data.id;
    this.name = data.name;
  }
}

<ng-container *ngIf="comments?.length > 0">
  <app-comment
    *ngFor="let userComment of comments; let i = index; trackBy: trackComment"
    app-scroll-to-comment
    [doScrollToComment]="doScrollToComment"
    [comment]="userComment"
    [commentIndex]="i"
    [parentItemId]="parentItemId"
    [avatarPhoto]="getAvatarPhoto(userComment, i)"
    [userPermission]="userPermission"
    [userIsOwner]="userIsOwner"
    [userIsPresident]="userIsPresident"
    [asUsers]="asUsers"
    [showLoadingOnComment]="showLoadingOnComment"
    [commentInfo]="commentInfo"
    [highlightedCommentId]="highlightedCommentId"
    (action)="onSendAction($event)"
    (enableEdit)="closeUserInfo()"
    (enterAvatar)="openUserInfoOnEnterAvatar($event, userComment)"
    [mainContent]="mainContent"
    [id]="userComment.id"></app-comment>
</ng-container>

<div class="comments-spinner" *ngIf="isLoading">
  <app-spinner [crescent]="true"></app-spinner>
</div>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AbstractMultimediaItem } from '@app/shared/models/multimedia/multimedia.abstract.model';

interface ChangeEvent {
  itemDetail: AbstractMultimediaItem;
  prop: string;
}

@Injectable({
  providedIn: 'root'
})
export class ItemChangeNotifier {
  private updatedItem = new Subject<ChangeEvent>();
  updatedItem$ = this.updatedItem.asObservable();

  update(event: ChangeEvent, items: AbstractMultimediaItem[]) {
    const { itemDetail, prop } = event;
    const item = items.find((itemToFind) => itemToFind.id === itemDetail.id);
    if (item) {
      item[prop] = itemDetail[prop];
    }
  }

  emit(itemDetail: AbstractMultimediaItem, prop: string) {
    this.updatedItem.next({ itemDetail, prop });
  }
}

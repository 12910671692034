<div [class.darkmode]="darkMode">
  <app-spinner [showText]="true" *ngIf="isLoading"></app-spinner>

  <ng-container *ngIf="!isLoading && !sectionMaintenance">
    <section class="grid-auto-padding-left grid-auto-padding-right" [class.darkmode]="darkMode">
      <div class="grid section__padding--right section__padding--left">
        <div class="row">
          <div class="col" size="12">
            <h1 class="regular-title-section">{{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.TITLE' | translate }}</h1>
            <p class="info__views">{{ video?.views }} {{ 'VIEWS' | translate | lowercase }}</p>
            <app-media-player
              *ngIf="video"
              #player
              [video]="video"
              [analyticsCategory]="analyticsCategory"
              (play)="onPlay()"
              (stalled)="onStalled()"
              (enterFullscreen)="onEnterFullscreen()"
              (exitFullscreen)="onExitFullscreen()"></app-media-player>
          </div>
        </div>
      </div>
    </section>

    <section class="grid-auto-padding-left grid-auto-padding-right section" [class.darkmode]="darkMode">
      <div class="grid section__padding--right section__padding--left">
        <h1 class="section__title">{{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.DEFINITION.TITLE' | translate }}</h1>
        <div class="row section__colum-container">
          <div class="col" size="12" size-lg="6" *ngFor="let definition of definitionSection; trackBy: trackByItems">
            <h4 class="section__subtitle">{{ definition.question | translate }}</h4>
            <p class="section__text">{{ definition.answer | translate }}</p>
            <ion-icon name="arrow-down"></ion-icon>
            <h4 class="section__subtitle">{{ definition.note | translate }}</h4>
          </div>
        </div>
      </div>
    </section>

    <section class="grid-auto-padding-left grid-auto-padding-right section section--green" [class.darkmode]="darkMode">
      <div class="grid section__padding--right section__padding--left">
        <h1 class="section__title">{{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.HOW_IS_IT_CALCULATED.TITLE' | translate }}</h1>
        <div class="row">
          <div class="col">
            <p class="section__text">{{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.HOW_IS_IT_CALCULATED.TEXT_1' | translate }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="section__text">{{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.HOW_IS_IT_CALCULATED.TEXT_2' | translate }}</p>
            <ng-container *ngFor="let item of howIsItCalculated; trackBy: trackByItems">
              <ul class="list__section">
                <li class="list__item">
                  <p class="list__title">{{ item.title | translate }}</p>
                  <p class="list__description">{{ item.description | translate }}</p>
                  <p class="list__description">{{ 'EMPLOYEE_PORTAL.FOR_EXAMPLE' | translate }}:</p>
                </li>
              </ul>
              <app-quote-phrase white [quoteText]="item.quote | translate" [darkMode]="darkMode"></app-quote-phrase>
            </ng-container>
          </div>
        </div>
      </div>
    </section>

    <section class="grid-auto-padding-left grid-auto-padding-right section section--blue" [class.darkmode]="darkMode">
      <div class="grid">
        <div class="row section__align-center">
          <div class="col" size="12">
            <div class="image__container">
              <img src="assets/imgs/ptrab/calc.svg" />
              <img class="image__content" src="assets/imgs/ptrab/public_finance.svg" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grid-auto-padding-left grid-auto-padding-right section" [class.darkmode]="darkMode">
      <div class="grid section__padding--right section__padding--left">
        <h1 class="section__title">{{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.WHEN_IS_IT_CALCULATED.TITLE' | translate }}</h1>
        <div class="row section__gray-content section__half">
          <div class="col">
            <p class="section__text">
              {{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.WHEN_IS_IT_CALCULATED.TEXT_1' | translate
              }}<span class="section__text--bold">{{
                'EMPLOYEE_PORTAL.WHAT_IS_PIT.WHEN_IS_IT_CALCULATED.TEXT_2' | translate
              }}</span
              >{{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.WHEN_IS_IT_CALCULATED.TEXT_3' | translate }}
            </p>
            <ul class="list__section">
              <li class="list__item list__item--gray" *ngFor="let item of whenIsItCalculated; trackBy: trackByItems">
                <p class="list__title">{{ item.title | translate }}</p>
                <p class="list__description">{{ item.description | translate }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section
      class="grid-auto-padding-left grid-auto-padding-right section section--dark-gray"
      [class.darkmode]="darkMode">
      <div class="grid section__padding--right section__padding--left">
        <h1 class="section__title">{{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.VOLUNTARY_WITHHOLDING.TITLE' | translate }}</h1>
        <div class="row section__gray-content">
          <div class="col">
            <p class="section__text">
              {{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.VOLUNTARY_WITHHOLDING.TEXT_1' | translate
              }}<span class="section__text--bold">{{
                'EMPLOYEE_PORTAL.WHAT_IS_PIT.VOLUNTARY_WITHHOLDING.TEXT_2' | translate
              }}</span
              >{{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.VOLUNTARY_WITHHOLDING.TEXT_3' | translate }}
            </p>
            <p class="section__text">
              {{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.VOLUNTARY_WITHHOLDING.TEXT_4' | translate }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="grid-auto-padding-left grid-auto-padding-right section" [class.darkmode]="darkMode">
      <div class="grid section__padding--right section__padding--left">
        <h1 class="section__title">{{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.ANY_EXCEPTION.TITLE' | translate }}</h1>
        <div class="row section__gray-content section__half">
          <div class="col">
            <p class="section__text">
              {{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.ANY_EXCEPTION.TEXT' | translate }}
            </p>
            <ul class="list__section">
              <li class="list__item list__item--gray" *ngFor="let item of anyException; trackBy: trackByItems">
                <p class="list__title">{{ item.title | translate }}</p>
                <p class="list__description">{{ item.description | translate }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="grid-auto-padding-left section section--dark-gray" [class.darkmode]="darkMode">
      <div class="grid section__padding--left">
        <div class="row section__certificate">
          <div size="12" size-lg="7" class="col">
            <h1 class="section__title">
              {{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.WITHHOLDING_CERTIFICATE.TITLE' | translate }}
            </h1>
            <p class="section__gray-content section__certificate-description">
              {{ 'EMPLOYEE_PORTAL.WHAT_IS_PIT.WITHHOLDING_CERTIFICATE.TEXT' | translate }}
            </p>
          </div>
          <div class="col section__certificate-container" size="12" size-lg="5">
            <img src="assets/imgs/ptrab/withholding.svg" />
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>

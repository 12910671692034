import { Component, Input, HostBinding, OnInit } from '@angular/core';

import { ItemCardType } from '@app/shared/enums/cards/item-card-type.enum';
import { ContentSourceType } from '@app/shared/enums/multimedia/content-status-types.enum';
import { Example } from '@app/shared/models/multimedia/example.model';

@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss']
})
export class CardInfoComponent implements OnInit {
  @Input() item!: Example;
  @Input() cardType!: ItemCardType;

  @HostBinding('class.is-expanded') isExpandedCard!: boolean;

  isPublication = false;
  isSegmentedPublication = false;

  ngOnInit() {
    this.isExpandedCard = this.cardType === ItemCardType.EXPANDED;
    this.isPublication = this.item.content_source === ContentSourceType.PUBLICATION;
    this.isSegmentedPublication = this.isPublication && Boolean(this.item.label);
  }
}

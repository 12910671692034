import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { StorageService, STORAGE_CONSTANTS } from '@app/services/storage';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { Permissions } from '@app/shared/enums/permissions/permissions.enum';
import { InternalRoute } from '@app/shared/helpers/route.helper';
import { User } from '@app/shared/models/user/user.model';
import { Logger } from '@services/logger/logger.service';
import { NavigationEvent, NavigationEvents, NavigationService } from '@services/navigation/navigation.service';
import { AppManagerService } from '..';
import { AppStatus } from '../app/app.manager.service';
import { NavigationHelperService } from '../navigation/navigation.helper.service';

@Injectable({
  providedIn: 'root'
})
export class OnBoardingGuardService implements CanActivate {
  private readonly logger = new Logger('OnBoardingGuardService');

  constructor(
    private navigationService: NavigationService,
    private storage: StorageService,
    private navigationHelperService: NavigationHelperService,
    private appManager: AppManagerService
  ) {}

  async canActivate(): Promise<boolean> {
    const canAccess = await this.checkUserOnBoardingPermission();
    const eventType: NavigationEvents = NavigationEvents.SetRoot;
    const onBoardingRoute: InternalRoute = { path: PAGES.ON_BOARDING };

    if (!canAccess) {
      this.logger.error('User has on boarding permission');

      if (this.appManager.status === AppStatus.INIT) {
        this.navigationHelperService.doRedirect(eventType, onBoardingRoute);
      } else {
        const navEvent = new NavigationEvent(eventType, onBoardingRoute);
        this.navigationService.navigate(navEvent);
      }
    }

    return canAccess;
  }

  private async checkUserOnBoardingPermission(): Promise<boolean> {
    const user: User = await this.storage.get(STORAGE_CONSTANTS.USER);
    const hasOnBoardingPermission = user.permissions.some((permission) => permission === Permissions.ONBOARDING);
    return !hasOnBoardingPermission;
  }
}

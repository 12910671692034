import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

import * as introJs from 'intro.js/intro.js';

import { NewsTypes } from '../enums/news/news-types.enum';
import { TourPopoverService } from '../services/tour-popover.service';

@Directive({
  selector: '[app-tour-popover-directive]'
})
export class TourPopoverDirective {
  @Input() popoverTitle!: string;
  @Input() popoverDescription!: string;
  @Output() popoverClicked: EventEmitter<NewsTypes> = new EventEmitter();

  constructor(private el: ElementRef, private tourPopoverService: TourPopoverService) {}

  initPopover(newsKey: NewsTypes, el?: Element) {
    const popover = introJs().setOptions({
      steps: [
        {
          element: el || this.el.nativeElement,
          title: this.popoverTitle,
          intro: this.popoverDescription
        }
      ],
      showButtons: false,
      showBullets: false,
      exitOnOverlayClick: false
    });

    popover.onexit(() => {
      this.tourPopoverService.hidePopover();
      this.tourPopoverService.markNewsAsRead();

      if (this.tourPopoverService.arePendingPopovers()) {
        this.tourPopoverService.setPopover(this.tourPopoverService.getFirstPopover());
        this.tourPopoverService.setPopoverKey(this.tourPopoverService.getFirstPopoverKey());
        this.tourPopoverService.getPopover().start();
        const popoverKey = this.tourPopoverService.getPopoverKey();

        window.addEventListener('click', () => popover.exit(), { once: true });

        const popoverHtml = document.getElementsByClassName('introjs-tooltip')[0];
        const highlightedContainerHtml = document.getElementsByClassName('introjs-helperLayer')[0];

        popoverHtml.addEventListener('click', () => this.popoverClicked.emit(popoverKey));
        highlightedContainerHtml.addEventListener('click', () => this.popoverClicked.emit(popoverKey));
      }
    });

    window.addEventListener('click', () => popover.exit(), { once: true });

    if (!this.tourPopoverService.getPopoverActive()) {
      this.tourPopoverService.storePopover(popover);
      this.tourPopoverService.storeKeys(newsKey);
      this.tourPopoverService.setPopover(popover);
      this.tourPopoverService.setPopoverKey(newsKey);
      this.tourPopoverService.getPopover().start();
      const popoverKey = this.tourPopoverService.getPopoverKey();

      const popoverHtml = document.getElementsByClassName('introjs-tooltip')[0];
      const highlightedContainerHtml = document.getElementsByClassName('introjs-helperLayer')[0];

      popoverHtml.addEventListener('click', () => this.popoverClicked.emit(popoverKey));
      highlightedContainerHtml.addEventListener('click', () => this.popoverClicked.emit(popoverKey));
    }
  }

  addPopover(newsKey: NewsTypes, el?: Element) {
    const popover = introJs().setOptions({
      steps: [
        {
          element: el || this.el.nativeElement,
          title: this.popoverTitle,
          intro: this.popoverDescription
        }
      ],
      showButtons: false,
      showBullets: false,
      exitOnOverlayClick: false
    });

    this.tourPopoverService.storePopover(popover);
    this.tourPopoverService.storeKeys(newsKey);
  }

  setPopoverData(title: string, description: string) {
    this.popoverTitle = title;
    this.popoverDescription = description;
  }
}

<section>
  <div>
    <img
      class="logo"
      alt="Mercadona logo"
      src="assets/imgs/mobileweb-landing/logo.png"
      srcset="assets/imgs/mobileweb-landing/logo@2x.png 2x, assets/imgs/mobileweb-landing/logo@3x.png 3x" />
    <h1 class="header">
      {{ message | translate }}...
      <app-spinner></app-spinner>
    </h1>
  </div>
</section>

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
export class VideoQualities {
  src!: string;
  type!: string;
  size!: number;
  default!: boolean;
}

export const getVideoQualities = (videos: MSafeAny[]) => {
  if (!videos) {
    return undefined;
  }

  const videoQualities = videos.map((video: MSafeAny) => {
    return {
      src: video.link,
      type: 'video/mp4',
      size: video.height,
      default: video.default || false
    };
  });

  // eslint-disable-next-line consistent-return
  return videoQualities;
};

export enum VimeoStatus {
  AVAILABLE = 'available',
  QUOTA_EXCEEDED = 'quota_exceeded',
  TOTAL_CAP_EXCEEDED = 'total_cap_exceeded',
  TRANSCODE_STARTING = 'transcode_starting',
  TRANSCODING = 'transcoding',
  TRANSCODING_ERROR = 'transcoding_error',
  UNAVAILABLE = 'unavailable',
  // XXX: we can interpret the uploading status as a failed upload, since in the
  // web app the upload is blocking, so if any video has this status, it means
  // that the upload has failed.
  UPLOADING = 'uploading',
  UPLOADING_ERROR = 'uploading_error'
}

const THREE_MINUTES = 3 * 60 * 1000;
export { THREE_MINUTES as VIMEO_RETRY_TIMEOUT };

import { ContentType } from '../enums/multimedia/content-status-types.enum';

interface StringArray {
  [key: string]: string[];
}

const DEFAULT_ACCEPTED_EXTENSIONS: StringArray = {};
DEFAULT_ACCEPTED_EXTENSIONS[ContentType.TEXT] = [];
DEFAULT_ACCEPTED_EXTENSIONS[ContentType.IMAGE] = ['jpg', 'jpeg', 'png', 'gif'];
DEFAULT_ACCEPTED_EXTENSIONS[ContentType.VIDEO] = ['mp4', 'mpg', 'avi', 'mov', 'quicktime'];
DEFAULT_ACCEPTED_EXTENSIONS[ContentType.INTERNAL] = [];

export class ExtensionsManager<T = string> {
  private extensions: string[] = [];
  private readonly extensionRegExp = /(?:\.([^.]+))?$/;

  constructor(private readonly acceptedExtensions: StringArray = DEFAULT_ACCEPTED_EXTENSIONS) {
    this.setExtensions();
  }

  getExtensions(): string[] {
    return this.extensions;
  }

  getParsedExtensions(): string {
    return this.extensions.map((extension) => `.${extension}`).join(', ');
  }

  setExtensions(type?: T) {
    if (type && typeof type === 'string') {
      this.extensions = this.getAcceptedExtensions(type);
    } else {
      this.extensions = [];

      for (const eachType of Object.keys(this.acceptedExtensions)) {
        this.extensions = this.extensions.concat(this.acceptedExtensions[eachType]);
      }
    }
  }

  getFileExtension(filename: string): string {
    const match = this.extensionRegExp.exec(filename);
    const fileExtension = match ? match[1] || '' : '';
    return fileExtension.split('?')[0].toLowerCase();
  }

  isValidExtension(filename: string): boolean {
    const extension = this.getFileExtension(filename);
    return Boolean(this.extensions.find((ext) => ext === extension));
  }

  getContentType(file: Blob): ContentType {
    if (!file) {
      return ContentType.TEXT;
    }

    const [type, extension] = file.type.split('/');

    const acceptedExtensions = DEFAULT_ACCEPTED_EXTENSIONS[type];
    return acceptedExtensions.includes(extension) ? (type as ContentType) : ContentType.TEXT;
  }

  private getAcceptedExtensions(type: string): string[] {
    return this.acceptedExtensions[type].slice();
  }
}

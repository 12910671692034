import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ModalManager } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';

import { SegmentFilterComponent } from '../segment-filter.component';

@Component({
  selector: 'app-segment-filter-button',
  templateUrl: './segment-filter-button.component.html',
  styleUrls: ['./segment-filter-button.component.scss']
})
export class SegmentFilterButtonComponent {
  @Input() selectedFilterIds: number[] = [];
  @Output() filtersChanged = new EventEmitter<number[]>();

  constructor(private modalManager: ModalManager, private analyticsService: AnalyticsService) {}

  openSegmentFilters() {
    this.modalManager
      .openMatModal(SegmentFilterComponent, {
        data: { selectedFilterIds: this.selectedFilterIds },
        disableClose: true,
        panelClass: 'base-modal'
      })
      .then((newFilterIds: number[]) => {
        if (newFilterIds) {
          this.filtersChanged.emit(newFilterIds);
        }
      });
  }
}

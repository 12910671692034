import { Directive, Injector, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { ErrorMsgEnum } from '@app/components/error-msg/error-msg-enum';
import { PayslipsService } from '@app/ptrab/services';
import { ConfirmationController } from '@app/ptrab/services/confirmation/confirmation.controller';
import { TwoFactorController } from '@app/ptrab/services/two-factor/two-factor.controller';
import { IrsWithholdingModel } from '@app/ptrab/shared/models/irs.model';
import { Withholding } from '@app/ptrab/shared/models/withholding';
import {
  AlertService,
  Logger,
  ModalManager,
  NavigationEvent,
  NavigationEvents,
  NavigationService
} from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { LoadingService } from '@app/services/loading/loading.service';
import { PTRAB_PAGES } from '@app/shared/enums/pages/pages.enum';
import { getPtrabUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { TranslateService } from '@ngx-translate/core';

/* eslint-disable @typescript-eslint/naming-convention */

@Directive()
export abstract class BaseVoluntaryWithholdingComponent implements OnDestroy {
  withholding!: Withholding[] | IrsWithholdingModel;
  isWithholdingLoading = true;
  hasSingleCompany = false;
  errorOcurred!: ErrorMsgEnum | null;
  isSectionMaintenance = false;

  protected abstract ptrabPage: PTRAB_PAGES;
  protected abstract logger: Logger;

  protected messages!: string[];
  protected readonly minValue = 0;
  protected validation_hash!: string | null;
  protected destroyed = new Subject<boolean>();

  protected analyticsService!: AnalyticsService;
  protected navigationService!: NavigationService;
  protected twoFactorController!: TwoFactorController;
  protected payslipService!: PayslipsService;
  protected translateService!: TranslateService;
  protected loadingService!: LoadingService;
  protected modalManager!: ModalManager;
  protected confirmationCtrl!: ConfirmationController;
  protected alertService!: AlertService;

  constructor(protected injector: Injector) {
    this.provideDependencies();
    this.getTranslations();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  protected abstract getWithholding();

  protected abstract initializeErrorHandling();

  async editWithholding(withholding: Withholding | IrsWithholdingModel) {
    const navEvent = new NavigationEvent(NavigationEvents.Push, {
      path: getPtrabUrlFromMap(this.ptrabPage),
      navParams: { withholding }
    });

    this.navigationService.navigate(navEvent);
  }

  private provideDependencies() {
    this.analyticsService = this.injector.get(AnalyticsService);
    this.navigationService = this.injector.get(NavigationService);
    this.twoFactorController = this.injector.get(TwoFactorController);
    this.payslipService = this.injector.get(PayslipsService);
    this.translateService = this.injector.get(TranslateService);
    this.loadingService = this.injector.get(LoadingService);
    this.modalManager = this.injector.get(ModalManager);
    this.confirmationCtrl = this.injector.get(ConfirmationController);
    this.alertService = this.injector.get(AlertService);
  }

  trackByItems(_index: number, item: Withholding) {
    return item;
  }

  private getTranslations() {
    this.translateService
      .get([
        'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.CONFIRM_REQUEST.TITLE',
        'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.CONFIRM_REQUEST.DETAIL',
        'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.CONFIRM_DELETE.TITLE',
        'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.CONFIRM_DELETE.DETAIL',
        'EMPLOYEE_PORTAL.FAIL_WITHHOLDING',
        'EMPLOYEE_PORTAL.PTRAB-MC-RQ-OM-FIS-1015',
        'EMPLOYEE_PORTAL.ACCEPT',
        'EMPLOYEE_PORTAL.DELETE',
        'EMPLOYEE_PORTAL.CANCEL'
      ])
      .subscribe((messages) => (this.messages = messages));
  }
}

import { BLOCKS_ORDER, SECTIONS_ORDER, UT_SECTIONS_ORDER } from '@shared/constants/model/model-sections-order.const';
import { ModelSectionNames } from '@shared/enums/model/model-sections.enum';
import { IModelSection } from '@shared/interfaces/model/model-section.interface';

export class ModelTour {
  activateSectionsIfAllowedToSee(currentSection: ModelSectionNames, sections: IModelSection[]): IModelSection[] {
    return sections.map((section) => {
      section.available = this.isAllowedToSee(currentSection, section.name);
      return section;
    });
  }

  isAllowedToSee(currentSection: ModelSectionNames, sectionToCheck: ModelSectionNames): boolean {
    return !this.isInitial(currentSection) && this.isPosterior(currentSection, sectionToCheck);
  }

  isInitial(sectionName: ModelSectionNames): boolean {
    return sectionName === ModelSectionNames.INITIAL;
  }

  isPosterior(currentSection: ModelSectionNames, sectionToCheck: ModelSectionNames): boolean {
    return this.isFinal(currentSection) || this.getSectionIndex(currentSection) >= this.getSectionIndex(sectionToCheck);
  }

  getSectionIndex(section: ModelSectionNames): number {
    return SECTIONS_ORDER.indexOf(section);
  }

  getBlockIndex(section: ModelSectionNames): number {
    const isUT = UT_SECTIONS_ORDER.includes(section);
    const block = isUT ? ModelSectionNames.UNIVERSAL_TRUTHS : section;

    const fullBlockSequence = [ModelSectionNames.INITIAL, ...BLOCKS_ORDER, ModelSectionNames.FINAL];
    return fullBlockSequence.indexOf(block);
  }

  isInitialOrFinal(sectionName: ModelSectionNames): boolean {
    return this.isInitial(sectionName) || this.isFinal(sectionName);
  }

  isFinal(sectionName: ModelSectionNames): boolean {
    return sectionName === ModelSectionNames.FINAL;
  }

  activateAllSections(sections: IModelSection[]) {
    return sections.map((section) => {
      section.available = true;
      return section;
    });
  }

  areEquals(currentSection: ModelSectionNames, sectionToCheck: ModelSectionNames): boolean {
    return currentSection === sectionToCheck;
  }
}

<div class="input-container">
  <input
    [placeholder]="placeholder | translate"
    [disabled]="disabled"
    [maxlength]="maxlength"
    [(ngModel)]="model"
    (ngModelChange)="onModelChange()"
    (focus)="onFocus()"
    [readonly]="readonly" />
  <ng-content></ng-content>
</div>

import { Injectable } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { Logger } from '@services/logger/logger.service';
/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({ providedIn: 'root' })
export class HeartbeatService {
  private readonly logger = new Logger('HeartbeatService');

  readonly HEARTBEAT_MESSAGE = '--heartbeat--';
  readonly HEARTBEAT_TIME_INTERVAL = 30000;
  readonly MAX_MISSED_HEARTBEATS = 3;
  heartbeatInterval: MSafeAny = null;
  missedHearbeats = 0;

  heartbeatCheck(websocket: WebSocket) {
    if (this.heartbeatInterval !== null) {
      return;
    }

    this.missedHearbeats = 0;
    this.interval(websocket);

    this.heartbeatInterval = setInterval(() => {
      this.interval(websocket);
    }, this.HEARTBEAT_TIME_INTERVAL);
  }

  interval(websocket: WebSocket) {
    try {
      this.logger.info('sending heartbeat');
      this.missedHearbeats++;

      if (this.missedHearbeats >= this.MAX_MISSED_HEARTBEATS) {
        throw new Error('Too many missed heartbeats.');
      }

      websocket.send(JSON.stringify({ check: this.HEARTBEAT_MESSAGE }));
    } catch (e: MSafeAny) {
      this.clear();
      this.logger.warn('Closing connection. Reason: ' + e.message);
      websocket.close();
    }
  }

  checkHeartbeatResponse(data: MSafeAny) {
    this.logger.debug('checking heartbeat response');

    if (data && data.includes(this.HEARTBEAT_MESSAGE)) {
      this.logger.debug('Still Alive');
      this.missedHearbeats = 0;
      return;
    }

    this.logger.debug('not a heartbeat response');
  }

  clear() {
    clearInterval(this.heartbeatInterval);
    this.heartbeatInterval = null;
  }
}

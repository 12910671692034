<div class="personal-income-tax-page">
  <ng-container *ngIf="!hasProviderError">
    <app-error-display
      *ngIf="hasGeneralError"
      [title]="'EMPLOYEE_PORTAL.SECTION_ERROR' | translate"
      [error]="generalError"></app-error-display>
    <ng-container *ngFor="let irpfDataSection of irpfDataSections; trackBy: trackByItems">
      <app-ptrab-irpf-item-pt
        *ngIf="irpfDataSection.isVisible"
        [data]="irpfDataSection.data"
        [title]="irpfDataSection.title"
        [emptyMsg]="irpfDataSection.emptyMsg"
        [sectionErrors]="irpfDataSection.sectionError"
        [hasChanges]="hasChanges"
        (modify)="openDetail(irpfDataSection.id)"></app-ptrab-irpf-item-pt>
    </ng-container>
  </ng-container>
  <div class="row" *ngIf="hasProviderError">
    <div class="col">
      <app-ptrab-feedback-msg [type]="errorOcurred"></app-ptrab-feedback-msg>
    </div>
  </div>
</div>

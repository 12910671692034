import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalManager } from '@app/services/modal-manager/modal-manager';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-model-wizard-error',
  templateUrl: './model-wizard-error.component.html',
  styleUrls: ['./model-wizard-error.component.scss']
})
export class ModelWizardErrorComponent implements OnInit {
  darkMode!: boolean;
  buttons: Buttons[] = [
    {
      text: 'UNDER_CONSTRUCTION.BACK',
      type: 'primary',
      enabled: true,
      onClick: () => this.modalManager.dismissMatModal()
    }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: MSafeAny, private modalManager: ModalManager) {}

  ngOnInit() {
    this.darkMode = this.data.darkMode;
  }
}

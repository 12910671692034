import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ApiUrls } from '@services/api/api.urls.service';

import { Logger } from '../logger';

export interface Token {
  token: string;
  expiration: number;
}

export enum SessionTokenTypes {
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  SESSION_REFRESH = 'SESSION_REFRESH'
}

export interface SessionToken {
  token: string;
  expiration: number;
  type: SessionTokenTypes;
}

export interface ResponseRenew {
  token: Token;
  sessionRefresh?: SessionToken;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private logger = new Logger('token provider');
  private currentRefreshPromise!: Promise<SessionToken> | null;
  private currentRenewPromise!: Promise<ResponseRenew> | null;
  private currentExpiredToken!: string;

  constructor(private http: HttpClient, private urls: ApiUrls) {}

  getRefreshToken(code: string, token: string): Promise<SessionToken> {
    if (this.currentRefreshPromise) {
      this.logger.debug('Attempting to retrieve the same refresh token request, returning cached promise...');
      return this.currentRefreshPromise;
    }

    this.logger.debug('getRefreshToken before http request', code);
    this.currentRefreshPromise = this.http
      .post<MSafeAny>(this.urls.token.getRefreshToken, { code, token })
      .pipe(
        finalize(() => {
          this.logger.debug('getRefreshToken reset saved promise', code);
          this.currentRefreshPromise = null;
        }),
        map((data) => {
          this.logger.info('getRefreshToken response data', data);
          const refreshToken: SessionToken = {
            type: SessionTokenTypes.REFRESH_TOKEN,
            token: data.refresh_token,
            expiration: data.expires_in
          };

          if (data.session_refresh) {
            refreshToken.type = SessionTokenTypes.SESSION_REFRESH;
            refreshToken.token = data.session_refresh;
          }

          return refreshToken;
        }),
        catchError((error) => {
          return throwError(error);
        })
      )
      .toPromise() as MSafeAny;

    return this.currentRefreshPromise as MSafeAny;
  }

  resetRenewPromise() {
    this.currentRenewPromise = null;
  }

  renewToken(token: SessionToken, expiredToken: string): Promise<ResponseRenew> {
    if (expiredToken !== this.currentExpiredToken) {
      this.logger.debug('Expired token is different to current expired token, removing current request');
      this.currentRenewPromise = null;
    }

    if (this.currentRenewPromise) {
      this.logger.debug('Attempting to retrieve the same renew token request, returning cached promise...');
      return this.currentRenewPromise;
    }

    this.logger.debug('renewToken before http request');
    this.currentExpiredToken = expiredToken;

    const params = {
      expiredToken,
      sessionRefresh: '',
      refreshToken: ''
    };

    if (token.type === SessionTokenTypes.SESSION_REFRESH) {
      params.sessionRefresh = token.token;
    } else {
      params.refreshToken = token.token;
    }

    this.currentRenewPromise = this.http
      .post(this.urls.token.renewToken, params)
      .pipe(
        map((data) => {
          const anyData = data as MSafeAny;
          this.logger.info('request for renew token', data);
          const response: MSafeAny = {
            token: {
              token: anyData.token,
              expiration: anyData.expires_in
            },
            sessionRefresh: {}
          };

          if ('session_refresh' in anyData) {
            response.sessionRefresh = {
              token: anyData.session_refresh,
              expiration: anyData.session_refresh_expiration,
              type: SessionTokenTypes.SESSION_REFRESH
            };
          }
          return response;
        })
      )
      .toPromise();
    return this.currentRenewPromise;
  }
}

<div (click)="notificationAction(notification)" (swipe)="onSwipe($event)" [app-animate-item-sliding]="startSliding">
  <app-item #item [class.expanded]="expanded">
    <label>
      <div class="container-date">
        <p>{{ formattedDate | date : 'dd/MM/yyyy - HH:mm' }}</p>
        <div class="arrow-container">
          <ion-icon name="chevron-forward"></ion-icon>
        </div>
      </div>
      <div class="container-title">
        <div class="no-read" *ngIf="isUnread()"></div>
        <h6 class="u-h6 u-important">{{ notification.title }}</h6>
      </div>
      <ion-icon (click)="expandText($event)" *ngIf="isLongText" class="expand-icon" [name]="this.expandIcon"></ion-icon>
      <p class="u-p3 u-medium-grey" #description>{{ getFormattedDescription(notification.description) }}</p>
    </label>
  </app-item>

  <div [id]="index" class="item-options">
    <button (click)="removeNotification(notification.id, $event)">
      <i class="app-icon-bin"></i>
      {{ 'NOTIFICATIONS_PAGE.ELIMINATE' | translate }}
    </button>
  </div>
</div>

import { GARNISHMENT_STATUS } from '../enums/garnishment.enum';
/* eslint-disable @typescript-eslint/naming-convention */

export class Garnishment {
  amount: string;
  balance: string;
  garnishor: Garnishor;
  id: string;
  date: string;
  status: GARNISHMENT_STATUS;

  constructor(data: GarnishmentResponse) {
    this.id = data.seizure_id;
    this.garnishor = data.garnishor;
    this.status = data.status as GARNISHMENT_STATUS;
    this.date = data.closing_date;
    this.amount = data.total_amount;
    this.balance = data.balance_outstanding;
  }
}

export interface GarnishmentResponse {
  total_amount: string;
  balance_outstanding: string;
  garnishor: {
    entity_name: string;
    record: string;
    province: string;
    type: string;
    email: string;
    iban: string;
  };
  seizure_id: string;
  closing_date: string;
  status: string;
}

export interface Garnishor {
  entity_name: string;
  record: string;
  province: string;
  type: string;
  email: string;
  iban: string;
}

export interface GarnishAmountPayslip {
  next_garnishment: {
    amount: number;
  };
}

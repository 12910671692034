import { StorageService } from '@app/services/storage/storage.service';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ADFS_ERROR_REDIRECT_PARTS, ADFS_SUCCESS_REDIRECT_PARTS } from '@services/auth/auth.helpers';
import { Logger } from '@services/logger/logger.service';
import {
  ERROR_PAGES,
  PAGES,
  PRIVATE_PTRAB_PAGES,
  PUBLIC_PAGES,
  ROOT_PAGES,
  SHAREABLE_LINK_ROUTES
} from '@shared/enums/pages/pages.enum';

export interface InternalRoute {
  path: string;
  navParams?: MSafeAny;
  redirectAfterLogin?: {
    path: string;
    navParams?: MSafeAny;
  };
  cleanRedirect?: boolean;
}

/* eslint-disable @typescript-eslint/naming-convention */

export class RouteHelper {
  private static logger = new Logger('Route Helper');
  private static readonly NUMBER_PATH_PARTS = 3;

  static isPublic(internalRoute: InternalRoute | undefined) {
    return PUBLIC_PAGES.some((path) => internalRoute?.path?.includes(path));
  }

  static isErrorPage(path: string): boolean {
    const pageId = path && this.getUrlParts(path).pop();
    return (ERROR_PAGES as string[]).includes(pageId as string);
  }

  static isPtrabPage(path: string | undefined): boolean {
    const pageId = path && this.getUrlParts(path).pop();
    return (PRIVATE_PTRAB_PAGES as string[]).includes(pageId as string);
  }

  static getUrlParts(url: string) {
    return url.split('?')[0].split('/');
  }

  static pageIsPrivacy(url: string): boolean {
    return this.getUrlParts(url).pop() === PAGES.PRIVACY;
  }

  static getPageFromPath(path: string): string {
    return path ? (this.getUrlParts(path).pop() as string) : '';
  }

  static isShareableLink(path: string): boolean {
    return RouteHelper.parseShareablePath(path) !== undefined;
  }

  static parseShareablePath(path: string): InternalRoute | undefined {
    if (!path) {
      return;
    }
    const urlParts = this.getUrlParts(path);
    const darkmode = this.checkDarkmode(path);
    const languageCode = this.checkLanguageCode(path);

    if (path.includes('iswebview=true') && urlParts.length && urlParts[1] === PAGES.MODEL) {
      const modelPath = urlParts.slice(1).join('/');

      StorageService.setWebView();
      StorageService.setWebViewPath(modelPath);

      if (path.includes('searchTerm=')) {
        const searchTerm = path.split('?')[1];
        const urlParams = new URLSearchParams(searchTerm);
        const searchValue = urlParams.get('searchTerm');

        if (path.includes('dataTags=')) {
          const dataTags = path.split('?')[1];
          const dataTagsParams = new URLSearchParams(dataTags);
          const dataTagsValue = dataTagsParams.get('dataTagsValue');

          return {
            path: modelPath,
            navParams: { iswebview: true, searchTerm: searchValue, dataTags: dataTagsValue, darkmode, languageCode }
          };
        }

        return {
          path: modelPath,
          navParams: { iswebview: true, searchTerm: searchValue, darkmode, languageCode }
        };
      }

      return {
        path: modelPath,
        navParams: { iswebview: true, darkmode, languageCode }
      };
    }

    if (urlParts.length !== RouteHelper.NUMBER_PATH_PARTS) {
      if (urlParts[1] === ROOT_PAGES.PERSONAL_INCOME_TAX_INFO) {
        this.checkDarkmode(path);
        StorageService.setWebView();
        StorageService.setWebViewPath(ROOT_PAGES.PERSONAL_INCOME_TAX_INFO);
        return {
          path: ROOT_PAGES.PERSONAL_INCOME_TAX_INFO,
          navParams: { iswebview: true }
        };
      }
      return;
    }

    const pageFromPath = urlParts[1];
    const page =
      SHAREABLE_LINK_ROUTES[pageFromPath] ||
      Object.values(SHAREABLE_LINK_ROUTES).find((value) => value === pageFromPath);

    if (page) {
      const itemId = urlParts[2];
      RouteHelper.logger.info('getPageAndNavParamsFromPath: ', pageFromPath, '->', page, itemId);
      const rewrittenPath = page !== PAGES.EXAMPLES ? `${page}/${itemId}` : `${page}?item_id=${itemId}`;
      return {
        path: rewrittenPath,
        navParams: { item_id: itemId }
      };
    }
  }

  static getShareableInternalRoute(path: string, isMobileWeb: boolean, isLogged: boolean): InternalRoute | undefined {
    const internalRoute: InternalRoute = {
      path: ROOT_PAGES.AUTHENTICATION,
      navParams: undefined,
      redirectAfterLogin: undefined
    };

    const shareablePageAndNavParams = RouteHelper.parseShareablePath(path);

    if (!shareablePageAndNavParams) {
      return;
    }

    if (isMobileWeb && !shareablePageAndNavParams.path.includes(PAGES.ITEM) && !StorageService.isWebView()) {
      internalRoute.path = ROOT_PAGES.MOBILE_WEB_LANDING;
      return internalRoute;
    }

    if (isLogged) {
      return shareablePageAndNavParams;
    }

    if (shareablePageAndNavParams.path.includes(PAGES.ITEM)) {
      shareablePageAndNavParams.path = `${ROOT_PAGES.VIEWER}/${shareablePageAndNavParams.navParams.item_id}`;
      return shareablePageAndNavParams;
    }

    internalRoute.redirectAfterLogin = shareablePageAndNavParams;
    return internalRoute;
  }

  static isAdfsPostLoginRedirect(hash: string) {
    const isError = ADFS_ERROR_REDIRECT_PARTS.every((part) => hash.includes(part));
    const isSuccess = ADFS_SUCCESS_REDIRECT_PARTS.every((part) => hash.includes(part));
    return isError || isSuccess;
  }

  static checkDarkmode(path: string): boolean {
    let darkmode = StorageService.isDarkMode();

    if (path.includes('darkmode=')) {
      const darkmodePath = path.split('?')[1];
      const darkmodeParams = new URLSearchParams(darkmodePath);
      darkmode = darkmodeParams.get('darkmode') === 'true';
      StorageService.setDarkMode(darkmode);
    }

    return darkmode;
  }

  static checkLanguageCode(path: string): string | undefined {
    let languageCode = StorageService.getLanguageCode();

    if (path.includes('languageCode=')) {
      const languageCodePath = path.split('?')[1];
      const languageCodeParams = new URLSearchParams(languageCodePath);
      languageCode = languageCodeParams.get('languageCode') as string;
      StorageService.setLanguageCode(languageCode);
    }

    return languageCode;
  }
}

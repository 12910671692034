import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-nav-tab',
  templateUrl: './nav-tab.component.html',
  styleUrls: ['./nav-tab.component.scss']
})
export class NavTabComponent {
  @Input() tabId!: string;
  @Input() tabLabel!: string;
  @Input() tabIcon!: string;
  @Input() tabNotificationAdvice!: number;

  // eslint-disable-next-line
  @Output() select = new EventEmitter<string>();

  @HostBinding('class.active') isActive = false;

  @HostListener('click')
  emitSelectedEvent() {
    this.select.emit(this.tabId);
  }

  isIonIcon(tabIcon: string) {
    return tabIcon && !tabIcon.includes('icon-');
  }
}

export enum ICONS {
  LANGUAGE = 'language',
  DEVICE_INFO = 'device-info',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  COOKIES = 'cookies',
  DATA_TREATMENT = 'data-treatment',
  PERSONAL_DATA = 'personal-data-menu',
  MY_TIMETABLE = 'horario-menu',
  MY_HOLIDAYS = 'my-holidays',
  HOLIDAYS_REQUEST = 'holidays-request',
  PAYSLIPS = 'payslips-menu',
  INCOME_TAX = 'income-tax',
  CERTIFICATES = 'certificates-menu',
  LOGOUT = 'logout',
  BEHAVIOUR_CODE = 'multiple-neutral-2',
  LOGISTIC_INFO = 'horario-menu',
  CONFIGURATION = 'app-icon-cog',
  OFFICESERVICES = 'app-icon-build'
}

import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { domChanges } from '@app/shared/utils/utils';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-richtext-editor',
  templateUrl: './richtext-editor.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./richtext-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichtextEditorComponent),
      multi: true
    }
  ]
})
export class RichtextEditorComponent implements OnInit, ControlValueAccessor {
  @Input() maxLength = 2000;
  @Input() multiLine = true;
  @Input() showToolbar = false;
  @Input() showActions = true;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() minHeight = 1;
  @Output() urlDeleted = new EventEmitter<boolean>();
  @Input() emojiPickerEnabled = true;
  @Output() scrollToTop: EventEmitter<void> = new EventEmitter<void>();
  @HostBinding('class.richtext-editor') readonly hostClass = true;
  @ViewChild('richtext') richtext: MSafeAny;
  editor = DecoupledEditor;
  isPickerVisible = false;
  editorOptions: MSafeAny;
  isConfigLoaded = false;

  private editorInstance!: DecoupledEditor;
  private savedSelection!: Range | null;
  private blocked = false;
  private _editorContent!: string;
  private position!: number;
  private waitingPromise!: Promise<typeof setTimeout> | null;

  constructor(
    private translateService: TranslateService,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    localStorage.removeItem('wsc_lang');
    this.setEditorOptions();
  }

  set editorContent(text: string) {
    const trimText = this.trimHTMLContent(text);
    if (!trimText) {
      this._editorContent = '';
      this.position = 0;
    }

    if (trimText.length <= this.maxLength) {
      this._editorContent = trimText.length > 0 ? text : '';
      if (trimText.length >= this.maxLength) this.position = this.getCaretPosition() as number;
    }
  }

  get editorContent() {
    return this._editorContent;
  }

  writeValue(value?: string) {
    this.editorContent = value || '';
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  registerOnChange(fn: (_: string) => void) {
    this.onChange = fn;
  }

  get showEmojiButton(): boolean {
    return this.emojiPickerEnabled;
  }

  toggleEmojiPicker() {
    this.focusWhenEmojiClicked();
    this.saveSelection();
    this.isPickerVisible = !this.isPickerVisible;
  }

  onReady(event: DecoupledEditor) {
    this.editorInstance = event;
    if (this.disabled) this.editorInstance.enableReadOnlyMode('');
    if (this.showToolbar) {
      event.ui
        ?.getEditableElement()
        ?.parentElement?.insertBefore(event.ui.view.toolbar.element as Node, event.ui.getEditableElement() as Node);
    }
    if (this.editorContent) {
      this.editorInstance.setData(this.editorContent);
    }

    this.preventEnterParagraph();
    this.handleMaxLength();
  }

  appendEmoji({ emoji }) {
    if (emoji.native.length > this.remainingChars) {
      return;
    }

    this.insertEmojiOnCaretPosition(emoji.native);
  }

  get remainingChars(): number {
    if (!this.editorInstance) {
      return this.maxLength;
    }

    const trimMessage = this.trimHTMLContent(this.editorInstance.getData());

    if (!trimMessage) return this.maxLength;

    const remaining = this.maxLength - trimMessage.length;
    if (remaining < 0) {
      return 0;
    }

    return remaining;
  }

  handleFocus() {
    this.onTouch();
  }

  handleModelChange() {
    const currentContent = this.editorInstance.getData();

    if (!currentContent) {
      this.urlDeleted.emit(true);
      this.onChange('');
      return;
    }
    this.urlDeleted.emit(false);

    const trimContent = this.trimHTMLContent(currentContent);

    if (this.blocked) {
      this.blocked = false;
    } else if (trimContent && trimContent.length <= this.maxLength) {
      this.editorContent = currentContent;
      this.onChange(currentContent);
    } else if (this.editorContent !== currentContent && !this.waitingPromise) {
      this.blocked = true;
      this.editorInstance.setData(this.editorContent);
      this.onChange(this.editorContent);
      this.waitingPromise = domChanges();
      this.waitingPromise.then(() => {
        this.setCaretPosition(this.position as number, this.richtext.elementRef?.nativeElement);
        this.waitingPromise = null;
      });
    }
  }

  closePicker(clicks: number) {
    if (clicks === 0) {
      return;
    }
    this.toggleEmojiPicker();
  }

  scrollToTopDescription() {
    this.scrollToTop.emit();
  }

  private onTouch = () => {};
  private onChange = (_: string) => {};

  private setEditorOptions() {
    this.editorOptions = {
      language: this.translateService.currentLang,
      placeholder: this.placeholder,
      toolbar: ['bold', '|', 'italic', '|', 'underline'],
      wproofreader: {
        enableBadgeButton: false,
        localization: this.translateService.currentLang,
        lang: this.translateService.currentLang
      },
      link: {
        defaultProtocol: 'https://',
        decorators: {
          addTargetToExternalLinks: {
            mode: 'automatic',
            callback: (url) => {
              return url.startsWith('http') || url.startsWith('www');
            },
            attributes: {
              target: '_blank'
            }
          }
        }
      }
    };
    this.isConfigLoaded = true;
  }

  private focusWhenEmojiClicked() {
    if (this.editorInstance.getData() === '') {
      this.editorInstance.focus();
    }
  }

  private insertEmojiOnCaretPosition(emoji: string) {
    const restored = this.restoreSelection();
    if (restored) {
      this.editorInstance.model.change((writer) => {
        const insertPosition: MSafeAny = this.editorInstance.model.document.selection.getLastPosition();

        writer.insertText(emoji, insertPosition);
        this.toggleEmojiPicker();
      });
    }
  }

  private trimHTMLContent = (content: string) => {
    if (!content) return '';

    return content
      .normalize('NFKC')
      .replace(/<p>/gm, '')
      .replace(/<\/p>/gm, '')
      .replace(/(\r\n|\n|\r)/gm, '')
      .replace(/(nbsp;)/gm, ' ');
  };

  private saveSelection() {
    if (this.document.getSelection) {
      const sel = this.document.getSelection();
      if (sel && sel.getRangeAt && sel.rangeCount) {
        this.savedSelection = sel.getRangeAt(0);
      }
    } else if (this.document.createRange) {
      this.savedSelection = this.document.createRange();
    } else {
      this.savedSelection = null;
    }

    return this.savedSelection;
  }

  private restoreSelection(): boolean {
    if (this.savedSelection) {
      if (this.document.getSelection) {
        const sel = this.document.getSelection();
        sel?.removeAllRanges();
        sel?.addRange(this.savedSelection);
        return true;
      }
      return true;
    }
    return false;
  }

  private preventEnterParagraph() {
    this.editorInstance.editing.view.document.on(
      'enter',
      (evt, data) => {
        if (this.multiLine) {
          if (data.isSoft) {
            this.editorInstance.execute('enter');
          } else {
            this.editorInstance.execute('shiftEnter');
          }
        }

        data.preventDefault();
        evt.stop();
        this.editorInstance.editing.view.scrollToTheSelection();
      },
      { priority: 'high' }
    );
  }

  private handleMaxLength() {
    this.preventWhiteSpaceBug();
    this.preventMaxLengthOnPaste();
  }
  private preventWhiteSpaceBug() {
    this.elementRef.nativeElement.querySelector('ckeditor').addEventListener('input', (ev: InputEvent) => {
      if (ev.data === ' ' && this.trimHTMLContent(this.editorInstance.getData()).trim() === '') {
        this.editorInstance.setData('');
      }
    });
  }

  private preventMaxLengthOnPaste() {
    this.editorInstance.editing.view.document.on('paste', (event: MSafeAny, data: MSafeAny) => {
      data.preventDefault();
      event.stop();
      const pasteContent = data.domEvent.clipboardData.getData('text/plain');
      const parsedContent = pasteContent.replace(/\n/g, ' ');
      const currentContent = this.editorInstance.getData();
      const expectedContent = this.trimHTMLContent(currentContent + parsedContent);
      if (expectedContent.length <= this.maxLength) {
        this.editorInstance.model.change((writer) => {
          const insertPosition: MSafeAny = this.editorInstance.model.document.selection.getLastPosition();

          writer.insertText(parsedContent, insertPosition);
        });
      }
    });
  }

  private getContentUntilCaretPosition(): string | undefined {
    if (!this.richtext || !this.document.getSelection || this.document.getSelection()?.rangeCount === 0) return '';

    const range: Range = this.document.getSelection()?.getRangeAt(0) as Range;
    const preCaretRange = range?.cloneRange();
    preCaretRange?.selectNodeContents(this.richtext.elementRef.nativeElement);
    preCaretRange?.setEnd(range.endContainer, range.startOffset);
    return preCaretRange?.toString();
  }

  private getCaretPosition = () => this.getContentUntilCaretPosition()?.length;

  private setCaretPosition(pos: number, parent: MSafeAny): number {
    for (const node of parent.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.length >= pos) {
          const range = this.document.createRange();
          const sel = window.getSelection();
          range.setStart(node, pos);
          range.collapse(true);
          sel?.removeAllRanges();
          sel?.addRange(range);
          return -1;
        }
        pos = pos - node.length;
      } else {
        pos = this.setCaretPosition(pos, node);
        if (pos < 0) {
          return pos;
        }
      }
    }
    return pos;
  }
}

<app-content-modal [buttons]="buttons">
  <h1>{{ 'EMPLOYEE_PORTAL.BANK_2FA.VERIFY_ACCOUNT' | translate }}</h1>
  <p class="base-modal__description">
    <span class="u-text-bold">{{ 'EMPLOYEE_PORTAL.BANK_2FA.VERIFY_ACCOUNT_DESCRIPTION_TITLE' | translate }}</span>
    <br />
    <span>{{ 'EMPLOYEE_PORTAL.BANK_2FA.VERIFY_ACCOUNT_DESCRIPTION' | translate }}</span>
  </p>

  <form class="code-validation-form" [formGroup]="form" *ngIf="!maxTriesExceeded">
    <app-input-wrapper
      id="code"
      [label]="'EMPLOYEE_PORTAL.BANK_2FA.WRITE_ACCOUNT'"
      [errorMsg]="errorMsg"
      [errorTranslateParam]="errorHint"
      [hasError]="isCodeWrong">
      <input class="form-input" formControlName="code" maxlength="8" type="text" (keydown.enter)="validateCode()" />
    </app-input-wrapper>
  </form>

  <ng-container *ngIf="isCodeWrongTwice">
    <div class="toggle" (click)="toggleHelpMsg()">
      <span class="toggle__text">{{ 'EMPLOYEE_PORTAL.BANK_2FA.NEED_HELP_TITLE' | translate }}</span>
      <i class="toggle__arrow app-icon-arrow-down" [class.is-reverse]="isHelpMsgOpen"></i>
    </div>

    <div *ngIf="isHelpMsgOpen" class="help-msg">
      <p class="help-msg-first">{{ 'EMPLOYEE_PORTAL.BANK_2FA.NEED_HELP_TEXT1' | translate }}</p>
      <p>{{ 'EMPLOYEE_PORTAL.BANK_2FA.NEED_HELP_TEXT2' | translate }}</p>
    </div>
  </ng-container>

  <app-message-error
    *ngIf="maxTriesExceeded"
    [title]="'EMPLOYEE_PORTAL.MAX_TRIES_EXCEEDED' | translate"
    [message]="'EMPLOYEE_PORTAL.MAX_TRIES_EXCEEDED_DESCRIPTION' | translate : { minutes: remainingMinutes }"
    type="error">
  </app-message-error>

  <div class="row white-bg">
    <div class="col content center" size="12">
      <div class="video-wrapper d-flex d-justify-center">
        <video
          attr.data-tags="{{ 'IMAGES_SEARCH_TAGS.UNIVERSAL_TRUTHS_WALK.LINES' | translate }}"
          preload="auto"
          autoplay
          loop
          muted
          webkit-playsinline
          playsinline
          poster="undefined">
          <source src="assets/imgs/ptrab/2fa/bank_code_validation.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</app-content-modal>

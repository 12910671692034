<div class="c-info-card">
  <div class="c-info-card__content c-info-card__content--right-align">
    <ng-container *ngFor="let item of card.data; trackBy: trackByItems">
      <span class="c-info-card__label u-p3 u-medium-grey">{{ item.label | translate }}</span>
      <span class="c-info-card__value u-h7 u-medium-grey u-text-bold">{{ item.value }}</span>
    </ng-container>
  </div>
  <div class="c-info-card__footer">
    <span class="c-info-card__footer-description">{{ card.description }}</span>
    <div class="c-info-card__footer-actions">
      <i class="app-icon-edit-pencil" (click)="emitEditAction()"></i>
      <i class="app-icon-bin icon-trash" (click)="emitDeleteAction()"></i>
    </div>
  </div>
</div>

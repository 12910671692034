import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { Logger } from '@services/logger/logger.service';
import { NotificationStatus } from '@shared/enums/notification/notification.enum';
import { NotificationHelper as helper } from '@shared/helpers/notification/notification.helper';
import { Notification } from '@shared/models/notification/notification.model';

import { ApiUrls } from '..';
import { ErrorCodes, ErrorMessages } from '../error/error.model';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private _notifications = new BehaviorSubject<Notification[]>([]);
  private unreadNotifications: Notification[] = [];
  private readonly logger: Logger = new Logger('NotificationService');
  private sectionMaintenance = false;

  notifications = this._notifications.asObservable();
  isRequestRunning = false;

  constructor(private urls: ApiUrls, private http: HttpClient) {}

  assignAppNotifications(languageCode: string): Observable<MSafeAny> {
    return this.fetchNotifications(languageCode).pipe(
      tap((notifications: Notification[]) => {
        this.setStaticNotificationLists(notifications);
      })
    );
  }

  getStaticNotifications(): Notification[] {
    return this._notifications.value;
  }

  getUnreadNotifications(category?: number): Notification[] {
    return category !== undefined ? this.getUnreadNotificationsByCategory(category) : this.unreadNotifications;
  }

  getUnreadNotificationsByCategory(category: number): Notification[] {
    return this.unreadNotifications.filter((unread) => {
      return unread.category === category;
    });
  }

  fetchNotifications(lang: string): Observable<MSafeAny> {
    return this.http.get(this.urls.user.notification, { params: { lang } }).pipe(catchError(this.handleError));
  }

  setNotifications(notifications: Notification[]) {
    this.setStaticNotificationLists(notifications);
  }

  removeNotification(id: string): Observable<MSafeAny> {
    return this.http.delete(this.urls.user.notificationAction(id)).pipe(tap(() => this.removeStaticNotification(id)));
  }

  checkAsReadNotification(notification: Notification) {
    return new Promise((resolve, reject) => {
      if (notification.status === NotificationStatus.NEW && !this.isRequestRunning) {
        this.isRequestRunning = true;

        this.http
          .put(this.urls.user.notificationAction(notification.id), { status: NotificationStatus.OPENED })
          .toPromise()
          .then(() => {
            this.checkAsReadStaticNotification(notification.id);
            this.isRequestRunning = false;
            resolve('Promise is resolved successfully.');
          })
          .catch((err) => {
            this.logger.error('checkAsReadNotification error:', err);
            this.isRequestRunning = false;
            reject('Promise is rejected');
          });
      } else {
        resolve('Promise is resolved successfully.');
      }
    });
  }

  removeStaticNotification(id: string) {
    const notifications = helper.remove(id, this._notifications.value);
    this.setStaticNotificationLists(notifications);
  }

  checkAsReadStaticNotification(id: string) {
    const notifications = helper.updateNotificationsWhenRead(id, this._notifications.value);
    this.setStaticNotificationLists(notifications);
  }

  getNotificationsByItemId(itemId: string): Notification[] {
    return this._notifications.value.filter((notification) => notification.item_id === itemId);
  }

  getNotificationOfNewPublicationCreated(itemId: string): Notification | undefined {
    return this._notifications.value.find(
      (notification) =>
        notification.item_id === itemId && notification.status === NotificationStatus.NEW && !notification.section
    );
  }

  getSectionMaintenance() {
    return this.sectionMaintenance;
  }

  private setStaticNotificationLists(notifications: Notification[]) {
    this._notifications.next(notifications);
    this.unreadNotifications = helper.getUnread(notifications);
  }

  private setSectionMaintenance(maintenance: boolean) {
    this.sectionMaintenance = maintenance;
  }

  private handleError = (error: HttpErrorResponse): Observable<MSafeAny[]> => {
    this.setSectionMaintenance(
      error.status === ErrorCodes.SECTION_MAINTENANCE && error.error.code === ErrorMessages.PERM_DEACTIVATED
    );
    this.logger.error(error);

    return of([]);
  };
}

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ContentType, PublicationContentType } from '@shared/enums/multimedia/content-status-types.enum';
import { Example } from '@shared/models/multimedia/example.model';
import { AbstractMultimediaItem } from '@shared/models/multimedia/multimedia.abstract.model';
import { Publication } from '@shared/models/multimedia/publication.model';
import { applyMixins } from '@shared/utils/utils';

import { LocalPublication } from '../local-publications/local-publications';

/* eslint-disable @typescript-eslint/naming-convention */
export abstract class Image extends AbstractMultimediaItem {
  abstract content_type: PublicationContentType.IMAGE | ContentType.IMAGE;
  images!: { url: string; alt: string; caption?: string }[];
  thumbnail!: string;

  init(data: MSafeAny) {
    this.images = data?.images || [];
  }
}

export abstract class LocalPublicationImage extends AbstractMultimediaItem {
  abstract content_type: PublicationContentType.IMAGE | ContentType.IMAGE;
  images!: {
    caption: string;
    mime_type: string;
    url?: string;
  }[];
  thumbnail!: string;

  init(data: MSafeAny) {
    this.images =
      data?.images.map((image: MSafeAny) => {
        return {
          caption: image.caption,
          mime_type: image.mime_type,
          url: image.image
        };
      }) || [];
  }
}

export class ImageExample extends Example implements Image {
  content_type: ContentType.IMAGE = ContentType.IMAGE;
  images!: { url: string; alt: string }[];
  thumbnail!: string;
  images_thumbnail_index: number;
  init!: (data: MSafeAny) => void;

  constructor(data: MSafeAny) {
    super(data);
    this.images_thumbnail_index = data.images_thumbnail_index;
    this.init(data);
  }
}

export class ImagePublication extends Publication implements Image {
  content_type: PublicationContentType.IMAGE = PublicationContentType.IMAGE;
  images!: { url: string; alt: string; caption?: string }[];
  thumbnail!: string;
  init!: (data: MSafeAny) => void;

  constructor(data: MSafeAny) {
    super(data);
    this.init(data);
  }
}

export class ImageLocalPublication extends LocalPublication implements LocalPublicationImage {
  content_type: ContentType.IMAGE = ContentType.IMAGE;
  images!: {
    image: string;
    caption: string;
    mime_type: string;
    url?: string;
  }[];
  thumbnail!: string;
  init!: (data: MSafeAny) => void;

  constructor(data: MSafeAny) {
    super(data);
    this.init(data);
  }
}

applyMixins(ImageLocalPublication, [LocalPublicationImage]);
applyMixins(ImagePublication, [Image]);
applyMixins(ImageExample, [Image]);

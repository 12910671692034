/* eslint-disable @typescript-eslint/naming-convention */

export class DisabilitiesPt {
  static readonly NO = new DisabilitiesPt(0, 'NO');
  static readonly UNDER_60 = new DisabilitiesPt(1, 'UNDER_60');
  static readonly OVER_60 = new DisabilitiesPt(2, 'OVER_60');

  // private to disallow creating other instances of this type
  private constructor(private readonly key: number, public readonly value: string) {}

  getIndex() {
    return this.key;
  }
}

export enum YieldsOwnership {
  SINGLE,
  MULTIPLE_ONE_OVER_95,
  MULTIPLE
}

import { LocalPublicationStatus } from '@app/activo2/publications/local-publications.constants';
import { PageExtras } from '@app/services/analytics/models/analytics-engine.interface';
import { CategoriesAnalytics, PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import {
  ContentSourceType,
  ContentType,
  ExampleStatusType,
  PublicationContentType
} from '@shared/enums/multimedia/content-status-types.enum';

import { Pagination } from './pagination.model';

/* eslint-disable @typescript-eslint/naming-convention */

export interface AbstractMultimediaItemResponse {
  item: AbstractMultimediaItem;
}

export abstract class AbstractMultimediaItem {
  content_type: PublicationContentType | ContentType;
  id: number;
  publication_date: string;
  summary: string;
  text: string;
  title: string;
  internal_title: string;
  url?: string;
  liked?: boolean;
  likes?: number;
  views?: number;
  comments?: number | string;
  content_source?: ContentSourceType;
  language?: string;
  publish_available?: boolean;
  shareable?: boolean;
  thumbnail?: string;
  slug?: string;
  status?: ExampleStatusType | LocalPublicationStatus;
  comments_allowed?: boolean;
  likes_allowed?: boolean;
  link?: string;
  public?: boolean;
  n_comments?: number;
  user_comments?: number;
  returned: boolean;
  status_info: string;
  internal_link?: string;
  links_on_webview?: boolean;
  video_provider?: string;
  multiple?: MSafeAny[];
  label?: { description: string; image: string };
  audience?: { id: string; description: string };
  description?: string;
  publication_hour?: string;

  constructor(data: MSafeAny) {
    this.content_type = data.content_type;
    this.id = data.id;
    this.liked = data.liked;
    this.likes = data.likes;
    this.publication_date = data.publication_date;
    this.summary = data.summary;
    this.text = data.text;
    this.title = data.title;
    this.internal_title = data.internal_title;
    this.url = data.url;
    this.views = data.views;
    this.comments = data.comments;
    this.content_source = data.content_source;
    this.language = data.language;
    this.publish_available = data.publish_available !== false;
    this.shareable = data.shareable;
    this.thumbnail = data.thumbnail;
    this.slug = data.slug;
    this.status = data.status;
    this.comments_allowed = data.comments_allowed;
    this.likes_allowed = data.likes_allowed;
    this.public = data.public;
    this.n_comments = data.n_comments;
    this.user_comments = data.user_comments;
    this.returned = data.returned;
    this.status_info = data.status_info;
    this.internal_link = data.internal_link;
    this.links_on_webview = data.links_on_webview;
    this.multiple = data.multiple;
    this.label = data.label;

    this.audience = data.audience;
    this.description = data.description;
    this.publication_hour = data.publication_hour;
  }

  isOfficialContent(): boolean {
    return this.content_source === ContentSourceType.PUBLICATION;
  }

  isPublic() {
    return this.content_source === ContentSourceType.PUBLICATION && this.public;
  }

  isVideo(): boolean {
    return this.isOfficialContent()
      ? this.content_type === PublicationContentType.VIDEO
      : this.content_type === ContentType.VIDEO;
  }

  isMultimedia(): boolean {
    return this.isOfficialContent() ? this.content_type === PublicationContentType.MULTIMEDIA : false;
  }

  typeMultimediaCard(): string {
    return this.isMultimedia() && this.multiple && this.multiple.length > 0 ? this.multiple[0].type : null;
  }

  isMultimediaCardVideo(): boolean {
    return this.typeMultimediaCard() === PublicationContentType.VIDEO;
  }

  isMultimediaCardImage(): boolean {
    return this.typeMultimediaCard() === PublicationContentType.IMAGE;
  }

  isText(): boolean {
    return this.isOfficialContent()
      ? this.content_type === PublicationContentType.TEXT
      : this.content_type === ContentType.TEXT;
  }

  isImage(): boolean {
    return this.isOfficialContent()
      ? this.content_type === PublicationContentType.IMAGE
      : this.content_type === ContentType.IMAGE;
  }

  isNews(): boolean {
    return this.content_type === PublicationContentType.NEWS;
  }

  isPublishDisabled() {
    return this.publish_available === false;
  }

  isShareable() {
    return this.shareable || this.status === ExampleStatusType.APPROVED;
  }

  isInternal(): boolean {
    return this.content_type === PublicationContentType.INTERNAL;
  }

  canShowComments(): boolean {
    return this.comments_allowed || (this.n_comments as number) > 0;
  }

  hasComments(): boolean {
    return (this.n_comments as number) > 0;
  }

  hasCommentsAllowed(): boolean {
    return this.comments_allowed as boolean;
  }

  hasLikesAllowed(): boolean {
    return this.likes_allowed as boolean;
  }

  getAnalyticsDetailPage(
    page: PagesAnalytics.PUBLICATION | PagesAnalytics.EXAMPLE | PagesAnalytics.PUBLIC_CONTENT
  ): string {
    return `${page}: ${this.getAnalyticsLabel()}`;
  }

  getAnalyticsCategory(_pageName: string): string {
    const category = this.isOfficialContent() ? CategoriesAnalytics.PUBLICATIONS : CategoriesAnalytics.EXAMPLES;
    return category;
  }

  getAnalyticsLabel(): string {
    return this.slug || '';
  }

  getAnalyticsPageContentType() {
    const contentTypeMap: MSafeAny = {
      [ContentType.TEXT]: 'texto',
      [ContentType.IMAGE]: 'imagen',
      [ContentType.VIDEO]: 'video',
      [PublicationContentType.MULTIMEDIA]: 'multimedia'
    };
    return contentTypeMap[this.content_type];
  }

  abstract getAnalyticsPageExtras(): PageExtras;
}

export class AbstractPaginatedMultimediaItemResponse<T extends AbstractMultimediaItem = AbstractMultimediaItem> {
  items: T[];
  lastViewedId: number;
  pagination: Pagination;

  constructor(multimediaItemList: T[], pagination: Pagination, lastViewedId: number) {
    this.items = multimediaItemList;
    this.lastViewedId = lastViewedId;
    this.pagination = pagination;
  }
}

import { NgModule } from '@angular/core';

import { ScrollToCommentDirective } from '@app/components/comments-section/comments-list/comment/directives/scroll-to-comment.directive';
import { AnimateItemSlidingDirective } from './animate-item-sliding.directive';

import { NoDoubleClickDirective } from './no-double-click.directive';
import { OnlyIntegerDirective } from './only-integer.directive';
import { PhoneMaskDirective } from './phone-mask.directive';
import { PtPostalCodeMaskDirective } from './pt-postal-code.directive';
import { ScrollToPointDirective } from './scroll-to-point.directive';
import { SwiperDirective } from './swiper.directive';
import { TourPopoverDirective } from './tour-popover.directive';

@NgModule({
  declarations: [
    ScrollToCommentDirective,
    NoDoubleClickDirective,
    ScrollToPointDirective,
    TourPopoverDirective,
    PtPostalCodeMaskDirective,
    OnlyIntegerDirective,
    SwiperDirective,
    AnimateItemSlidingDirective,
    PhoneMaskDirective
  ],
  exports: [
    ScrollToCommentDirective,
    NoDoubleClickDirective,
    ScrollToPointDirective,
    TourPopoverDirective,
    PtPostalCodeMaskDirective,
    OnlyIntegerDirective,
    SwiperDirective,
    AnimateItemSlidingDirective,
    PhoneMaskDirective
  ]
})
export class DirectivesModule {}

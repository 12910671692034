import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IEnjoyedYear } from '@app/ptrab/shared/interfaces/enjoyed-year.interface';
import { IrsDataResponse, IrsWithholdingResponse } from '@app/ptrab/shared/interfaces/irs-data-response.interface';
import { TwoFactorAuthorization } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { CivilStatus, CivilStatusResponse } from '@app/ptrab/shared/models/civil-status';
import { EnjoyedYear } from '@app/ptrab/shared/models/enjoyed-year';
import { IrsModel, IrsWithholdingModel } from '@app/ptrab/shared/models/irs.model';
import { IDataResponse } from '@app/shared/interfaces/data/data.interface';

import { IrsService } from './irs.service';
import { ApiPtrabUrls } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class IrsRepository {
  constructor(private apiUrls: ApiPtrabUrls, private http: HttpClient, private irsService: IrsService) {}

  getData(): Observable<IrsModel> {
    return this.http
      .get<IDataResponse<IrsDataResponse>>(this.apiUrls.irs.personalData)
      .pipe((d) => this.irsService.mapIrsModel(d));
  }

  modifyIrsPersonalData(authorization: TwoFactorAuthorization): Observable<IrsModel> {
    return this.http
      .put<IDataResponse<IrsDataResponse>>(this.apiUrls.irs.personalData, {
        params: this.irsService.mapWorkerData(),
        authorization
      })
      .pipe((d) => this.irsService.mapIrsModel(d, true));
  }

  modifyIrsSpouse(authorization: TwoFactorAuthorization): Observable<IrsModel> {
    return this.http
      .put<IDataResponse<IrsDataResponse>>(this.apiUrls.irs.spouse, {
        params: this.irsService.mapSpouseData(),
        authorization
      })
      .pipe((d) => this.irsService.mapIrsModel(d, true));
  }

  modifyIrsDependent(dependantIndex: number, authorization: TwoFactorAuthorization): Observable<IrsModel> {
    return this.http
      .put<IDataResponse<IrsDataResponse>>(this.apiUrls.irs.dependant, {
        params: this.irsService.mapDependentData(dependantIndex),
        authorization
      })
      .pipe((d) => this.irsService.mapIrsModel(d, true));
  }

  deleteIrsDependent(id: number, authorization: TwoFactorAuthorization): Observable<IrsModel> {
    const request = {
      body: {
        authorization
      }
    };
    return this.http
      .delete<IDataResponse<IrsDataResponse>>(`${this.apiUrls.irs.dependant}/${id}`, request)
      .pipe((d) => this.irsService.mapIrsModel(d, true));
  }

  modifyIrsYoungBenefit(authorization: TwoFactorAuthorization): Observable<IrsModel> {
    return this.http
      .put<IDataResponse<IrsDataResponse>>(this.apiUrls.irs.youngBenefit, {
        params: this.irsService.mapYoungBenefitData(),
        authorization
      })
      .pipe((d) => this.irsService.mapIrsModel(d, true));
  }

  modifyIrsRoomBenefit(authorization: TwoFactorAuthorization): Observable<IrsModel> {
    return this.http
      .put<IDataResponse<IrsDataResponse>>(this.apiUrls.irs.roomBenefit, {
        params: this.irsService.mapRoomBenefitData(),
        authorization
      })
      .pipe((d) => this.irsService.mapIrsModel(d, true));
  }

  getWithholding(): Observable<IrsWithholdingModel> {
    return this.http
      .get<IDataResponse<IrsWithholdingResponse>>(this.apiUrls.irs.withholding)
      .pipe((d) => this.irsService.mapIrsWithholdingModel(d));
  }

  modifyIrsWithholding(
    voluntaryRetention: number,
    authorization: TwoFactorAuthorization
  ): Observable<IrsWithholdingModel> {
    return this.http
      .put<IDataResponse<IrsWithholdingResponse>>(this.apiUrls.irs.withholding, {
        params: this.irsService.mapIrsVoluntaryWithholding(voluntaryRetention),
        authorization
      })

      .pipe((d) => this.irsService.mapIrsWithholdingModel(d, true));
  }

  deleteIrsWithholding(): Observable<IrsWithholdingModel> {
    return this.http
      .delete<IDataResponse<IrsWithholdingResponse>>(this.apiUrls.irs.withholding)
      .pipe((d) => this.irsService.mapIrsWithholdingModel(d, true));
  }

  getCivilStatus(): Observable<CivilStatus[]> {
    return this.http
      .get<IDataResponse<CivilStatusResponse[]>>(this.apiUrls.irs.civilStatus)
      .pipe((d) => this.irsService.mapCivilStatusModel(d));
  }

  getEnjoyedYears(): Observable<EnjoyedYear[]> {
    return this.http
      .get<IDataResponse<IEnjoyedYear[]>>(this.apiUrls.irs.enjoyedYears)
      .pipe((d) => this.irsService.mapEnjoyedYearsModel(d));
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-description-card',
  templateUrl: './title-description-card.component.html',
  styleUrls: ['./title-description-card.component.scss']
})
export class TitleDescriptionCardComponent {
  @Input() cardTitle!: string;
  @Input() cardDescription!: string;
  @Input() insideModal!: boolean;
}

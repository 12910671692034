import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
  @Input() buttonText!: string;
  @Input() buttonIcon!: string;
  @Input() buttonType!: string;
  @Input() disabled!: boolean;
  @Output() buttonClicked = new EventEmitter();

  onClick(ev: Event) {
    this.buttonClicked.emit(ev);
  }
}

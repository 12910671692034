import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StorageService, STORAGE_CONSTANTS } from '@app/services/storage';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal-comment.component.html',
  styleUrls: ['./cookies-modal-comment.component.scss']
})
export class CookiesModalCommentComponent implements OnInit {
  title!: string;
  example!: string;
  advise!: string;
  checkboxLabel!: string;
  buttons!: Buttons[];
  cookieReference!: string;

  selected = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MSafeAny, private storageService: StorageService) {}

  ngOnInit() {
    this.title = this.data.title;
    this.example = this.data.example;
    this.advise = this.data.advise;
    this.checkboxLabel = this.data.checkboxLabel;
    this.buttons = this.data.buttons;
    this.cookieReference = this.data.cookieReference || STORAGE_CONSTANTS.RESPONSE_AS_OTHER_USER;
  }

  async onChange() {
    const selected = !this.selected;
    await this.storageService.set(this.cookieReference, selected);
    this.selected = selected;
  }
}

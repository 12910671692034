import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DirectivesModule } from '@app/shared/directives/directives.module';
import { MPopoverModule } from '@mercadona/components/popover';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../components.module';
import { MorePopoverComponent } from '../header/components/more-popover/more-popover.component';

import { MenuComponent } from './menu.component';

const components = [MenuComponent, MorePopoverComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, TranslateModule, FormsModule, DirectivesModule, ComponentsModule, MPopoverModule],
  exports: components
})
export class MenuModule {}

<ng-container *ngIf="!isTranslated; else showSource">
  <ng-container [ngSwitch]="currentState">
    <div class="loading-content" *ngSwitchCase="STATE.LOADING">
      <span class="loading-content__text">{{ 'GOOGLE_TRANSLATION.TRANSLATING' | translate }}</span>
      <app-spinner
        [crescent]="true"
        [autoSize]="true"
        [horizontal]="true"
        class="loading-content__spinner"></app-spinner>
    </div>

    <div class="toggle" *ngSwitchCase="STATE.ERROR">
      <span class="toggle__text">{{ 'GOOGLE_TRANSLATION.RETRY' | translate }}</span>
    </div>

    <div class="toggle" (click)="showTranslatedText()" *ngSwitchDefault>
      <i class="toggle__icon app-icon-translate"></i>
      <span class="toggle__text">{{ 'GOOGLE_TRANSLATION.SHOW' | translate }}</span>
      <div class="by-google">
        <span>{{ 'GOOGLE_TRANSLATION.BY' | translate }}</span>
        <img class="toggle__img" src="assets/imgs/translate/google.svg" />
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #showSource>
  <div class="toggle" (click)="showOriginalText()">
    <i class="toggle__icon app-icon-translate"></i>
    <span class="toggle__text">{{ 'GOOGLE_TRANSLATION.HIDE' | translate }}</span>
  </div>
</ng-template>

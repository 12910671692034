<app-content-modal class="hide-overflow" [buttons]="buttons" [hideClose]="true">
  <h1 class="title">{{ 'EMPLOYEE_PORTAL.CHANGED_DIRECT_DEBIT' + (portugueseUser ? '_PT' : '') | translate }}</h1>
  <div>
    <p class="description">
      {{ 'EMPLOYEE_PORTAL.UPDATED_BANK_DATA' | translate }}
    </p>
    <div *ngIf="portugueseUser; else spanishUser">
      <p class="description">
        {{ 'EMPLOYEE_PORTAL.EMAIL_SENT_PT' | translate }}
      </p>
    </div>
    <ng-template #spanishUser>
      <p class="description">
        {{ 'EMPLOYEE_PORTAL.EMAIL_SENT' | translate }}{{ 'EMPLOYEE_PORTAL.YOU_CAN_ALSO' | translate }}
        <a class="link" (click)="requestCertificate()">
          {{ 'EMPLOYEE_PORTAL.DOWNLOAD_CERTIFICATE' | translate }}
        </a>
      </p>
    </ng-template>
  </div>
  <img class="image" [src]="imageSuccess" />
</app-content-modal>

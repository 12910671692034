<app-content-modal class="hide-overflow" [buttons]="buttons" (closeModal)="close(false)">
  <img src="assets/imgs/ptrab/shield.svg" />
  <p class="description">
    <ng-container *ngIf="isSinglePhone; else multiplePhones">
      {{ description }}
      <span *ngIf="isSinglePhone">
        {{ 'EMPLOYEE_PORTAL.PHONE_ENDED' | translate }} {{ getUniquePhone.phone | slice : -3 }}.
      </span>
    </ng-container>
    <ng-template #multiplePhones>
      <b>{{ description }}</b>
    </ng-template>
  </p>

  <div *ngIf="!isSinglePhone" class="phone-selection__list">
    <app-item *ngFor="let phone of availablePhones; trackBy: trackByItems" class="radio-input">
      <label (click)="setSelectedPhone(phone.id)">
        <input type="radio" class="phone-selection__radio-button" name="phone" [value]="phone.id" />
        {{ phone.phone }}
      </label>
    </app-item>
  </div>

  <p class="base-modal__description">{{ reminderMessage }}</p>
</app-content-modal>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StaticText } from '@app/shared/models/static-text/static-text.model';

import { ApiUrls } from '../api/api.urls.service';

interface StaticTextResponse {
  static_text: StaticText;
}

@Injectable({
  providedIn: 'root'
})
export class StaticTextService {
  constructor(private apiUrls: ApiUrls, private http: HttpClient) {}

  getStaticText(key: string, lang?: string): Observable<StaticText> {
    let params = new HttpParams();

    params = params.append('key', key);
    if (lang) {
      params = params.append('lang', lang);
    }

    return this.http.get<StaticTextResponse>(this.apiUrls.staticText.get, { params }).pipe(map((s) => s.static_text));
  }
}

import { Pipe, PipeTransform } from '@angular/core';

const WORDS_WITH_ALL_UPPER_CASE = ['RR.EE', 'RR.HH', 'RREE', 'RRHH', 'S.A.', 'S.A', 'S.A.U.'];
const EXCEPTIONS_TO_CAPITALIZE_FIRST = ['LA', 'A', 'BO'];

@Pipe({
  name: 'nounUpperCase'
})
export class NounUpperCasePipe implements PipeTransform {
  transform(value: string) {
    if (value === undefined || value === null) {
      return undefined;
    }

    return value
      .split(',')
      .join('')
      .split(' ')
      .map((word, index) => {
        const shouldBeAllUpperCase = WORDS_WITH_ALL_UPPER_CASE.some(
          (exceptionWord) => exceptionWord.toLowerCase() === word.toLowerCase()
        );
        const isAnException = EXCEPTIONS_TO_CAPITALIZE_FIRST.some(
          (exceptionWord) => exceptionWord.toLowerCase() === word.toLowerCase()
        );

        const isNameInitial = new RegExp('^[a-zA-Z]\\.$').exec(word) !== null;
        const shouldBeCapitalizeFirst = word.length >= 3 || index < 1 || isNameInitial || isAnException;

        if (shouldBeAllUpperCase) {
          return word.toUpperCase();
        }

        return shouldBeCapitalizeFirst
          ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          : word.toLocaleLowerCase();
      })
      .join(' ');
  }
}

import { ErrorMsgEnum } from '@app/components/error-msg/error-msg-enum';
import { IErrorMsg } from '@app/components/error-msg/error-msg-interface';

export const errorMessageData: { [key: string]: IErrorMsg } = {
  [ErrorMsgEnum.EMPTY_RESULT]: {
    image: 'assets/imgs/no-results/empty.svg',
    title: 'ERROR_MESSAGES.ERROR_OCCURRED',
    description: 'ERROR_MESSAGES.GENERIC_FAIL_MESSAGE'
  },
  [ErrorMsgEnum.SERVER_ERROR]: {
    image: 'assets/imgs/no-results/error.svg',
    title: 'ERROR_MESSAGES.ERROR_OCCURRED',
    description: 'ERROR_MESSAGES.GENERIC_FAIL_MESSAGE'
  }
};

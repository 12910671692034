export const LANGUAGES = {
  ES: {
    code: 'es',
    country: 'ES'
  },
  PT: {
    code: 'pt',
    country: 'PT'
  }
};

const DEFAULT_LANGUAGE = LANGUAGES.ES.code;
export { DEFAULT_LANGUAGE };

export interface Language {
  code: string;
  country: string;
}

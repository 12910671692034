import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { MediaPlayerComponent } from '@app/components/media-player/media-player.component';
import { MediaPlayerService } from '@app/services/media-player/media-player.service';

@Directive({
  selector: '[app-scroll-to-print]'
})
export class ScrollToPointDirective implements OnChanges {
  @Input() doScrollToPoint = false;
  @Input() player!: MediaPlayerComponent;

  constructor(private el: ElementRef, private mediaPlayerService: MediaPlayerService) {}

  async ngOnChanges() {
    if (!this.doScrollToPoint) {
      return;
    }

    const playerOnFullscreen = this.mediaPlayerService.playerOnFullscreen;

    if (!playerOnFullscreen || playerOnFullscreen.getId() === this.player.getId()) {
      const content = this.el.nativeElement.closest('app-content') as HTMLElement;
      content.scrollTo(0, this.el.nativeElement.offsetTop);
    }
  }
}

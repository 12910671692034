import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics, PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { MessageService } from '@app/services/message/message.service';
import { TourPopoverDirective } from '@app/shared/directives/tour-popover.directive';
import { MessageActions } from '@app/shared/enums/message/message-actions.enum';
import { NewsTypes } from '@app/shared/enums/news/news-types.enum';
import { NewsItem } from '@app/shared/models/conditions/conditions.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TourPopoverService } from '@app/shared/services/tour-popover.service';
import { isTablet } from '@app/shared/utils/is-tablet.util';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@services/logger';
import { NavigationHelperService } from '@services/navigation/navigation.helper.service';
import { NavigationEvent, NavigationEvents, NavigationService } from '@services/navigation/navigation.service';
import { NotificationService } from '@services/notification/notification.service';
import { SearchService } from '@services/search/search.service';
import { UserService } from '@services/user/user.service';
import { PAGES } from '@shared/enums/pages/pages.enum';
import { User } from '@shared/models/user/user.model';
import { domChanges, isValidString } from '@shared/utils/utils';

import { ContentComponent } from '../content/content.component';
import { InputComponent } from '../input/input.component';

/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() scrollArea!: ContentComponent;
  @Input() shyHeaderBehaviour!: boolean;
  @Input() sticky!: string;
  @Input() text!: string;
  @Input() previous!: string;
  @Input() previousParams: MSafeAny;
  @Input() pageName!: string;
  @Input() avatar: MSafeAny;
  @Input() showTitleOnly!: boolean;

  @Output() back = new EventEmitter();

  @ViewChild('finderRef') finderRef!: ElementRef;
  @ViewChild('backdrop') backdrop!: ElementRef;
  @ViewChild(InputComponent) searchbar!: InputComponent;
  @ViewChild(InputComponent, { read: ElementRef }) searchbarRef!: ElementRef<HTMLElement>;
  @ViewChild(TourPopoverDirective) tourPopoverDirective!: TourPopoverDirective;

  searchInput: MSafeAny;
  searchResultsLoaded = false;
  cleanLastSearches = false;
  searchTerm: MSafeAny = '';
  activeSection!: string;
  allowGoBack!: boolean;
  headerElement!: Element;
  stickyElement!: Element;
  scrollElement!: Element;
  inFinderMode = false;
  isCompactDesktop = false;
  displayMobileFinder = false;
  searchHistory: string[] = [];
  userInfo!: User;
  searchDropdownStyle = {};
  backdropStyles = {};
  isTablet = isTablet();
  arrowPositionX!: number;
  newsItem: NewsItem[] = [];
  pageAnalytics!: PagesAnalytics;
  displayFooterElement: MSafeAny;
  footerElement: MSafeAny;
  active!: PAGES;

  private readonly logger = new Logger('Header Component');
  private isCreatingProfileMenuPopover = false;
  private subscriptions = new Subscription();
  private readonly INCREASE_BACKDROP = 100;
  private readonly INCREASE_BACKDROP_WIDTH = 300;

  constructor(
    private renderer: Renderer2,
    private navService: NavigationService,
    private navhelper: NavigationHelperService,
    private userService: UserService,
    private translate: TranslateService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private searchService: SearchService,
    private elementRef: ElementRef,
    private analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    private tourPopoverService: TourPopoverService
  ) {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.allowGoBack = data['allowGoBack'];

      this.pageAnalytics = data['pageAnalytics'];
    });
  }

  async ngAfterViewInit() {
    if (this.scrollArea) {
      this.headerElement = this.elementRef.nativeElement.parentElement;
      await domChanges();
      if (!this.headerElement) return;
      this.stickyElement = this.headerElement.parentElement?.getElementsByClassName(this.sticky)[0] as Element;

      const footer: MSafeAny = this.headerElement.parentElement?.getElementsByTagName('footer');
      this.footerElement = footer.length > 0 && this.hasTabMenu(footer[0]) ? footer[0] : undefined;
      this.scrollElement = this.scrollArea.mainContent.nativeElement;

      this.renderer.addClass(this.scrollElement, 'is-desktop');
    }

    this.setFinderInputStyle();
  }

  hasTabMenu(footer: Element) {
    const footerChilds = Array.from(footer.children).map((el) => el.localName);
    return footerChilds.includes('app-menu');
  }

  ngOnInit() {
    this.analyticsService.overrideAnalyticsPage$.subscribe((newPage: string) => {
      this.pageAnalytics = newPage as PagesAnalytics;
    });

    this.active = this.navhelper.getActiveRoute();
    this.setUserInfo();
    this.subscribeMessages();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get pendingNews() {
    return this.tourPopoverService.arePendingPopovers();
  }

  onLoadAvatar(element: HTMLElement) {
    if (!element.offsetLeft) {
      return;
    }

    this.subscriptions.add(
      this.userService.userInfoChanges$.subscribe((userInfo) => {
        const preventOpen = !userInfo;
        if (preventOpen) {
          return;
        }
        const newUserInfo = { ...userInfo };
        this.userInfo = newUserInfo;
        this.newsItem = newUserInfo.news?.filter(
          (item) => !item.accepted && item.type === NewsTypes.NEWS_MOMO
        ) as NewsItem[];

        if (this.newsItem.length) {
          this.newsItem.forEach((newsItem) => this.showNewsPopover(newsItem));
        }
      })
    );
  }

  showNewsPopover(newsItem: NewsItem) {
    const description = `NEWS_POPOVER.DESCRIPTION_${newsItem.type}`;
    const element = document.querySelector('app-avatar');

    this.tourPopoverDirective.setPopoverData(
      this.translate.instant('NEWS_POPOVER.TITLE_NEWS'),
      this.translate.instant(description)
    );

    if (newsItem.type === this.tourPopoverService.getPopoverKey()) {
      return;
    }

    if (this.tourPopoverService.getPopoverActive()) {
      this.tourPopoverDirective.addPopover(newsItem.type, element as Element);
    } else {
      this.tourPopoverDirective.initPopover(newsItem.type, element as Element);
    }
  }

  getUnreadNotificationNumber() {
    return this.notificationService.getUnreadNotifications().length;
  }

  goBack() {
    if (this.back.observers.length > 0) {
      this.back.next({ previous: this.previous, previousParams: this.previousParams });
      return;
    }

    this.navService.goBack(this.previous, this.previousParams);
  }

  focusOnFinder() {
    this.searchService.setSectionFromPage(this.active);

    if (this.active !== PAGES.RESULTS) {
      this.searchService.setRefererPage(this.active);
    }

    this.inFinderMode = true;

    if (!this.searchResultsLoaded) {
      this.analyticsService.setPage(
        CategoriesAnalytics.CONTENT,
        CategoriesAnalytics.finder,
        PagesAnalytics[`SEARCH_${this.active.toUpperCase()}`]
      );
      this.searchService
        .getLatestSearches()
        .pipe(
          finalize(async () => {
            this.searchResultsLoaded = true;
            this.setFinderModeStyles();
            await domChanges(300);
            this.resizeDropdown();
            this.searchbarRef.nativeElement.focus();
          })
        )
        .subscribe(
          (searchRecords: string[]) => (this.searchHistory = searchRecords),
          (err) => this.logger.error(err)
        );
    }
  }

  async onFinderBlur() {
    await domChanges(300);

    if (document.hasFocus() && !this.cleanLastSearches) {
      this.resetFinderState();
    } else if (this.cleanLastSearches) {
      this.cleanLastSearches = false;
    }
  }

  async search(term?: string) {
    // eslint-disable-next-line
    term = term?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').trim();

    let termToSearch = this.searchTerm;
    if (term) {
      termToSearch = term;
    }
    this.cleanLastSearches = false;
    if (!isValidString(termToSearch) || termToSearch.trim().length < 3) {
      return;
    }

    this.analyticsService.sendEvent(CategoriesAnalytics.finder, {
      [ActionsAnalytics.TEXT]: term,
      [ActionsAnalytics.CLICKLABEL]: this.pageAnalytics === 'home' ? 'inicio' : this.pageAnalytics
    });

    this.searchService.setSearchTerm(termToSearch);
    await this.searchService.storeSearchParameters();

    this.searchService
      .sendLastSearch(termToSearch)
      .pipe(
        finalize(async () => {
          this.navService.navigate(new NavigationEvent(NavigationEvents.Push, { path: PAGES.SEARCH_RESULTS }));
          this.searchService.searchSource.next(null);
          await this.onFinderBlur();
        })
      )
      .subscribe(
        () => {},
        (err) => this.logger.error(err)
      );
  }

  removeLastSearches() {
    this.searchService
      .removeLastestSearches()
      .pipe(finalize(() => (this.searchHistory = [])))
      .subscribe(
        () => {},
        (err) => this.logger.error(err)
      );
    this.cleanLastSearches = true;
    this.searchbarRef.nativeElement.focus();
  }

  resetFinderState() {
    this.displayMobileFinder = false;
    this.searchResultsLoaded = false;
    this.inFinderMode = false;
    this.searchTerm = '';
    this.searchbar.model = '';
  }

  showSearchbar() {
    const pagesAllowed = [
      PAGES.RESULTS,
      PAGES.EXAMPLES,
      PAGES.PUBLICATIONS,
      PAGES.HOME,
      PAGES.PERSONAL_DATA,
      PAGES.MODEL
    ];
    return pagesAllowed.indexOf(this.active) !== -1;
  }

  getSearchDropdownStyles(): MSafeAny {
    const finderElement = (this.finderRef as MSafeAny).nativeElement;

    if (finderElement) {
      return {
        'width.px': finderElement.offsetWidth,
        'left.px': finderElement.offsetLeft
      };
    }
  }

  resizeDropdown() {
    this.subscriptions.add(
      fromEvent(window, 'resize').subscribe(() => {
        this.setFinderModeStyles();
      })
    );
  }

  getBackdropStyles(): MSafeAny {
    if (this.backdrop) {
      return {
        'width.px': this.backdrop.nativeElement.offsetWidth + this.INCREASE_BACKDROP_WIDTH,
        'height.px': this.scrollElement.scrollHeight + this.INCREASE_BACKDROP
      };
    }
  }

  setFinderModeStyles() {
    this.searchDropdownStyle = this.getSearchDropdownStyles();
    this.backdropStyles = this.getBackdropStyles();
  }

  updateSearchTerm(text: string) {
    this.searchTerm = text;
  }

  showNotifications() {
    return !this.inFinderMode;
  }

  onPopoverClicked(popoverKey: NewsTypes) {
    if (popoverKey !== NewsTypes.NEWS_MOMO) {
      return;
    }

    const avatar = document.getElementsByClassName('header-avatar')[0];
    const event = new Event('click');
    avatar.dispatchEvent(event);
  }

  private setUserInfo() {
    this.userService.getStoredUser().then((userInfo) => {
      this.userInfo = userInfo;
    });
  }

  private subscribeMessages() {
    this.messageService.messages$.subscribe((response) => {
      if (response.action === MessageActions.IMAGE_UPDATED) {
        this.userService
          .syncImage()
          .then((photo: string) => {
            this.avatar = photo;
          })
          .catch((error) => {
            this.logger.error('Error getting user info', error);
          });
      } else if (response.action === MessageActions.SIGNATURE_RECEIVED) {
        if (response.data.pending_documents) {
          return;
        }

        this.tourPopoverService.setPopoverKey(NewsTypes.NEWS_DOCUMENTS);
        this.tourPopoverService.markNewsAsRead();
      }
    });
  }

  /**
   * @description Set Search Bar component styles since Shadow DOM implementation
   */
  private setFinderInputStyle() {
    if (!this.searchbar) {
      return;
    }

    this.setInputContainerStyle();
    this.setInputIconStyle();
  }

  private setInputContainerStyle() {
    const inputContainer = this.searchbarRef.nativeElement;
    if (inputContainer) {
      inputContainer.style.height = '40px';
      inputContainer.style.boxShadow = 'none';
      inputContainer.style.fontSize = '16px';
      inputContainer.style.fontWeight = '500';
      inputContainer.style.borderRadius = '4px';
      inputContainer.style.paddingLeft = '52px';
      inputContainer.style.paddingBottom = '4px';
      inputContainer.style.paddingTop = '6px';
      inputContainer.style.background = 'var(--secondary-100)';
      inputContainer.style.border = 'none';
    }
  }

  private async setInputIconStyle() {
    await domChanges();
    // eslint-disable-next-line
    const ionIcon: HTMLIonIconElement = this.searchbarRef.nativeElement.querySelector('ion-icon') as HTMLIonIconElement;
    if (ionIcon) {
      this.renderer.setStyle(ionIcon, 'left', '16px');
      this.renderer.setStyle(ionIcon, 'top', '8px');
      this.renderer.setStyle(ionIcon, 'width', '25px');
      this.renderer.setStyle(ionIcon, 'height', '25px');
    }
  }
}

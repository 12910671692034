import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { Logger } from '@app/services';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { StorageService } from '@app/services/storage';

import { PtrabSessionService } from './ptrab-session.service';
import { UserHome } from '@app/shared/interfaces/user/user-login.interface';

@Injectable({
  providedIn: 'root'
})
export class PtrabSessionManager {
  private logger = new Logger('PtrabSessionManager');

  private _pageToRedirectAfterLogin!: string;
  private _isUnauthorized = false;
  private _isBlocked = false;
  private _isSectionMaintenance = false;
  private _sessionCheckedOnce = false;

  constructor(private ptrabSessionService: PtrabSessionService, private storage: StorageService) {}

  async checkSession(): Promise<boolean> {
    const userHome = await this.storage.getUserHome();

    if (userHome === UserHome.EXEMPLOYEE) {
      this.ptrabAccessAllowed();
      return Promise.resolve(true);
    }

    return this.ptrabSessionService
      .getSessionStatus()
      .pipe(
        tap(() => this.setIsSessionCheckedOnce(true)),
        map((errorResponse: void | HttpErrorResponse) => {
          if (errorResponse) {
            this.logger.error(errorResponse, 'Error on getting ptrab status');
          } else {
            this.ptrabAccessAllowed();
          }
          return !errorResponse;
        })
      )
      .toPromise() as MSafeAny;
  }

  ptrabAccessAllowed() {
    this.setIsUnauthorized(false);
    this.setIsBlocked(false);
    this.logger.debug('Ptrab access allowed');
  }

  // eslint-disable-next-line
  getPageToRedirectAfterLogin(): Promise<string> {
    return this.storage.getPageToRedirectAfterPtrabLogin();
  }

  // eslint-disable-next-line
  getRedirectParamsAfterLogin(): Promise<MSafeAny> {
    return this.storage.getRedirectParamsAfterPtrabLogin();
  }

  // eslint-disable-next-line
  isUnauthorized(): boolean {
    return this._isUnauthorized;
  }

  // eslint-disable-next-line
  isBlocked(): boolean {
    return this._isBlocked;
  }

  // eslint-disable-next-line
  isSectionMaintenance(): boolean {
    return this._isSectionMaintenance;
  }

  // eslint-disable-next-line
  isSessionCheckedOnce(): boolean {
    return this._sessionCheckedOnce;
  }

  // eslint-disable-next-line
  setPageToRedirectAfterLogin(pageUrl: string) {
    this.storage.setPageToRedirectAfterPtrabLogin(pageUrl);
  }

  // eslint-disable-next-line
  setRedirectParamsAfterLogin(params: MSafeAny) {
    this.storage.setRedirectParamsAfterPtrabLogin(params);
  }

  // eslint-disable-next-line
  setIsUnauthorized(isUnauthorized: boolean) {
    this._isUnauthorized = isUnauthorized;
  }

  // eslint-disable-next-line
  setIsBlocked(isBlocked: boolean) {
    this._isBlocked = isBlocked;
  }

  // eslint-disable-next-line
  setIsSectionMaintenance(isSectionMaintenance: boolean) {
    this._isSectionMaintenance = isSectionMaintenance;
  }

  /* istanbul ignore next */
  setIsSessionCheckedOnce(isChecked: boolean) {
    this._sessionCheckedOnce = isChecked;
  }
}

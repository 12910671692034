export const IRPF_ERRORS = ['PTRAB-MC-RQ-OM-FIS-1008', 'PTRAB-MC-RQ-OM-FIS-1009', 'PTRAB-MC-RQ-OM-FIS-1031'];
export const ANCESTORS_ERRORS = [
  'PTRAB-MC-RQ-OM-FIS-1006',
  'PTRAB-MC-RQ-OM-FIS-1007',
  'PTRAB-MC-RQ-OM-FIS-1029',
  'PTRAB-MC-RQ-UNSUPPORTED'
];
export const PENSION_PAYMENT_ERRORS = ['PTRAB-MC-RQ-OM-FIS-1036'];
export const WORKER_ERRORS = [
  'PTRAB-MC-RQ-OM-FIS-1003',
  'PTRAB-MC-RQ-OM-FIS-1004',
  'PTRAB-MC-RQ-OM-FIS-1011',
  'PTRAB-MC-RQ-OM-FIS-1014'
];
export const DESCENDANT_ERRORS = [
  'PTRAB-MC-RQ-OM-FIS-1005',
  'PTRAB-MC-RQ-OM-FIS-1010',
  'PTRAB-MC-RQ-OM-FIS-1012',
  'PTRAB-MC-RQ-OM-FIS-1027',
  'PTRAB-MC-RQ-OM-FIS-1028'
];

export const VOLUNTARY_WITHHOLDING_ERRORS = ['PTRAB-MC-RQ-OM-FIS-1015', 'PTRAB-MC-RQ-IRS-ERR-2041'];

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { Company } from './company';

export class Withholding {
  company: Company;
  base: number;
  voluntary?: number;

  constructor(data: MSafeAny) {
    this.company = new Company(data.company);
    this.base = data.base;
    this.voluntary = data.voluntary;
  }

  hasCompany(): boolean {
    return Boolean(this.company && this.company.name && this.company.employee_number);
  }
}

export class PortugueseWithholding {
  list: PortugueseWithholdingList[];
  text: string;

  constructor(data: MSafeAny) {
    this.list = data.withholding_list;
    this.text = data.i18n_text;
  }
}

export interface PortugueseWithholdingList {
  company_code: string;
  year: string;
  certificate_type: string;
  description: string;
}

export interface PortugueseWithholdingDropdownItem {
  year: string;
  certificates: PortugueseWithholdingCertificate[];
}

export interface PortugueseWithholdingCertificate {
  company_code: string;
  description: string;
  certificate_type: string;
}

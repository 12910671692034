import { Component, Input } from '@angular/core';

import { ErrorInfoMessage } from '@shared/interfaces/error/error-info.interface';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent {
  @Input() isError!: boolean;
  @Input() hasFilters?: boolean;
  @Input() noResultsError: ErrorInfoMessage = {
    title: 'SEARCH_NO_RESULTS',
    description: 'SEARCH_NO_RESULTS_1',
    additional_description: 'SEARCH_NO_RESULTS_2',
    url_image: 'assets/imgs/no-results/empty.svg',
    icon_name: 'icon-alert',
    version: false,
    justified_style: true
  };

  readonly notAvailableError: ErrorInfoMessage = {
    title: 'ERROR_MESSAGES_COMPONENT.TITLE',
    description: 'ERROR_MESSAGES_COMPONENT.MESSAGE',
    url_image: 'assets/imgs/no-results/error.svg',
    icon_name: 'icon-alert',
    version: false,
    justified_style: true
  };
}

<div>
  <!-- Desktop -->
  <div class="grid-fixed desktop">
    <div class="row no-padding-vertical" d-align-center [class.finder__mode]="inFinderMode">
      <div size="auto" *ngIf="!inFinderMode" class="col no-padding-vertical avatar-notification-wrapper">
        <app-avatar
          *ngIf="userInfo"
          class="header-avatar"
          size="small"
          [userInfo]="userInfo"
          [hasNews]="newsItem.length > 0"
          [pendingNews]="pendingNews"
          (loadElement)="onLoadAvatar($event)"
          (popoverClicked)="onPopoverClicked($event)"
          #mPopover="m-popover"
          popoverPosition="bottomRight"
          [m-popover]="profileMenuTmp"
          app-tour-popover-directive>
        </app-avatar>
        <app-notification-advice
          *ngIf="showNotifications()"
          [unreadNotificationsNumber]="getUnreadNotificationNumber()"
          class="header-notification-advice"></app-notification-advice>
      </div>
      <div #finderRef size="4" class="col no-padding-vertical global-finder">
        <app-input-wrapper *ngIf="showSearchbar()" class="finder__input" [class.finder__mode]="inFinderMode">
          <app-input
            [placeholder]="'SEARCH' | translate"
            (focusInput)="focusOnFinder()"
            (modelChange)="updateSearchTerm($event)"
            [model]="searchTerm"
            (keydown.enter)="search($event.target.value)"></app-input>
        </app-input-wrapper>
      </div>
      <div *ngIf="!inFinderMode" size="auto" class="col no-padding-vertical header-toolbar">
        <app-menu></app-menu>
      </div>
    </div>
  </div>
</div>

<app-search-dropdown
  *ngIf="searchResultsLoaded"
  [searchInput]="searchbar"
  [active]="active"
  [records]="searchHistory"
  [searchTerm]="searchTerm"
  [pageAnalytics]="pageAnalytics"
  (search)="search($event)"
  (remove)="removeLastSearches()"
  [ngStyle]="searchDropdownStyle">
</app-search-dropdown>

<div #backdrop *ngIf="inFinderMode" class="backdrop" [ngStyle]="backdropStyles" (click)="resetFinderState()"></div>

<ng-template #profileMenuTmp>
  <app-profile-menu></app-profile-menu>
</ng-template>

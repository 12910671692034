export enum ContentSourceType {
  EXAMPLE = 'example',
  PUBLICATION = 'publication'
}

export enum PublicationContentType {
  MULTIMEDIA = 'image_and_video',
  VIDEO = 'video',
  TEXT = 'text',
  IMAGE = 'image',
  NEWS = 'news',
  INTERNAL = 'internal_link'
}

export enum ContentType {
  VIDEO = 'video',
  TEXT = 'text',
  IMAGE = 'image',
  INTERNAL = 'internal_link'
}

export enum ExampleStatusType {
  DRAFT = 'borrador',
  PENDING = 'pendiente',
  APPROVED = 'aprobado',
  PRIVATE = 'privado',
  DENIED = 'denegado'
}

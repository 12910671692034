import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';

import { LoadingService } from '@app/services/loading/loading.service';
import { StaticTextService } from '@app/services/static-text/static-text.service';
import { domChanges } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-static-text',
  templateUrl: './static-text.component.html',
  styleUrls: ['./static-text.component.scss']
})
export class StaticTextComponent implements OnInit, OnDestroy {
  @Input() key!: string;
  @Input() lang!: string;
  @Output() dataLoaded = new EventEmitter();

  htmlData = '';
  subscription: Subscription = new Subscription();

  constructor(
    private loading: LoadingService,
    private staticTextService: StaticTextService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.loadStaticText();
    this.subscribeToLangChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadStaticText() {
    this.loading.show();
    this.staticTextService
      .getStaticText(this.key, this.lang)
      .pipe(
        finalize(() => {
          this.loading.hide();
        }),
        distinctUntilChanged()
      )
      .subscribe(async (staticText) => {
        this.htmlData = staticText.value;
        await domChanges();
        this.dataLoaded.emit();
      });
  }

  private subscribeToLangChanges() {
    this.subscription = this.translateService.onLangChange.subscribe(() => {
      this.lang = this.translateService.currentLang;
      this.loadStaticText();
    });
  }
}

import { Directive, Input, OnInit } from '@angular/core';

import { NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { LEGAL } from '@app/shared/enums/terms-and-conditions/terms-and-conditions.enum';

@Directive()
export abstract class TermsAndConditionsDirective implements OnInit {
  @Input() isStatic!: boolean;

  parentPage!: string;

  constructor(protected navigationService: NavigationService) {}

  ngOnInit() {
    this.parentPage = this.isStatic ? LEGAL.TERMS_AND_CONDITIONS : LEGAL.TERMS_AND_CONDITIONS_INTER;
  }

  goToCookiesPage = () => {
    this.navigationService.navigate(
      new NavigationEvent(NavigationEvents.Push, {
        path: 'cookies',
        navParams: { isStatic: this.isStatic, parentPage: this.parentPage }
      })
    );
  };

  gotPermisions() {
    return this.parentPage === LEGAL.TERMS_AND_CONDITIONS;
  }
}

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import {
  ContentSourceType,
  ContentType,
  PublicationContentType
} from '@shared/enums/multimedia/content-status-types.enum';
import { Example } from '@shared/models/multimedia/example.model';
import { ImageExample, ImageLocalPublication, ImagePublication } from '@shared/models/multimedia/image.model';
import { InternalLink } from '@shared/models/multimedia/internal-link.model';
import { News } from '@shared/models/multimedia/news.model';
import { Publication } from '@shared/models/multimedia/publication.model';
import { TextExample, TextPublication } from '@shared/models/multimedia/text.model';
import { VideoExample, VideoLocalPublication, VideoPublication } from '@shared/models/multimedia/video.model';

import { MultimediaPublication } from './multimedia-detail.model';
import { LocalPublication } from '../local-publications/local-publications';

export class MultimediaFactory {
  static createHighlightItem(item: MSafeAny): Publication | Example | undefined {
    switch (item.content_source) {
      case ContentSourceType.PUBLICATION:
        return this.createPublication(item);
      case ContentSourceType.EXAMPLE:
        return this.createExample(item);
      default:
        return undefined;
    }
  }

  static createPublication(item: MSafeAny): Publication {
    switch (item.content_type) {
      case PublicationContentType.NEWS:
        return new News(item);
      case PublicationContentType.IMAGE:
        return new ImagePublication(item);
      case PublicationContentType.VIDEO:
        return new VideoPublication(item);
      case PublicationContentType.INTERNAL:
        return new InternalLink(item);
      case PublicationContentType.MULTIMEDIA:
        return new MultimediaPublication(item);
    }
    return new TextPublication(item);
  }

  static createExample(item: MSafeAny): Example {
    switch (item.content_type) {
      case ContentType.IMAGE:
        return new ImageExample(item);
      case ContentType.VIDEO:
        return new VideoExample(item);
    }
    return new TextExample(item);
  }

  static createLocalPublication(item: MSafeAny): LocalPublication {
    switch (item.content_type) {
      case ContentType.IMAGE:
        return new ImageLocalPublication(item);
      case ContentType.VIDEO:
        return new VideoLocalPublication(item);
    }
    return new LocalPublication(item);
  }
}

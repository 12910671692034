<section>
  <h1>{{ title | translate }}</h1>
  <div *ngIf="!hasSignedContract; else signedContract">
    <iframe [src]="document.url | safe : 'resourceUrl'"></iframe>
  </div>
  <ng-template #signedContract>
    <p>{{ 'ON_BOARDING.STEPPER.FIRST_STEP.CONTINUE_ON_BOARDING' | translate }}</p>
    <img src="assets/imgs/on-boarding/hand_mobile_email.svg" alt="Hand mobile email" />
  </ng-template>
</section>

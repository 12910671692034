import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { UserService } from '@app/services/user/user.service';
import { IntroJs } from 'intro.js';

import { NewsTypes } from '../enums/news/news-types.enum';
import { NewsItem } from '../models/conditions/conditions.model';

@Injectable({
  providedIn: 'root'
})
export class TourPopoverService {
  private popovers: IntroJs[] = [];
  private popoverKeys: NewsTypes[] = [];
  private isPopoverActive = false;
  private popoverKey!: NewsTypes;

  private popover!: IntroJs;

  constructor(private userService: UserService) {}

  storePopover(popover: IntroJs) {
    this.popovers.push(popover);
  }

  storeKeys(key: NewsTypes) {
    this.popoverKeys.push(key);
  }

  arePendingPopovers(): boolean {
    return Boolean(this.popovers.length);
  }

  getFirstPopover(): IntroJs {
    return this.popovers[0];
  }

  getFirstPopoverKey(): NewsTypes {
    return this.popoverKeys[0];
  }

  setPopover(popover: IntroJs) {
    this.popover = popover;
    this.isPopoverActive = true;
  }

  getPopover(): IntroJs {
    return this.popover;
  }

  hidePopover() {
    this.popovers.shift();
    this.popoverKeys.shift();
    this.isPopoverActive = false;
  }

  getPopoverActive(): boolean {
    return this.isPopoverActive;
  }

  setPopoverKey(key: NewsTypes) {
    this.popoverKey = key;
  }

  getPopoverKey(): NewsTypes {
    return this.popoverKey;
  }

  markNewsAsRead() {
    const newsItem: NewsItem = { type: this.getPopoverKey(), accepted: true };
    this.userService
      .setAcceptNews(newsItem)
      .pipe(finalize(() => this.hideCssElements()))
      .subscribe();
  }

  hideCssElements() {
    if (this.arePendingPopovers()) {
      return;
    }

    const highlightedContainerHtml = document.getElementsByClassName('introjs-helperLayer');

    Array.from(highlightedContainerHtml).forEach((container: Element) => {
      const htmlElement = container as HTMLElement;
      htmlElement.style.setProperty('box-shadow', 'none', 'important');
    });
  }

  hidePopoverFromDOM() {
    const tooltipLayer = document.getElementsByClassName('introjs-tooltipReferenceLayer');

    Array.from(tooltipLayer).forEach((container: Element) => {
      const htmlElement = container as HTMLElement;
      htmlElement.style.setProperty('display', 'none');
    });
  }
}

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { UserAnalytics } from '@app/shared/models/user/analytics-user.model';

import { IAnalyticsEngine } from './models/analytics-engine.interface';
import { ActionsAnalytics, CategoriesAnalytics, PagesAnalytics } from './models/analytics.enum';

export class WebAnalyticsEngine implements IAnalyticsEngine {
  // eslint-disable-next-line
  private dl: MSafeAny = window['dataLayer'] || [];

  sendEvent(category: CategoriesAnalytics, eventParameters: MSafeAny): void {
    const event: MSafeAny = {
      event: category,
      ...eventParameters
    };
    this.dl.push(event);
  }

  setPage(
    category: CategoriesAnalytics,
    subcategory: CategoriesAnalytics,
    page: PagesAnalytics,
    params?: MSafeAny,
    errorCode: string = '',
    errorDescription: string = ''
  ): void {
    if (!category || !subcategory) {
      return;
    }

    const event = {
      event: 'view_page',
      ACTIVO: 'Activo2',
      TIPO: 'normal',
      CATEGORIA: category,
      SUBCATEGORIA: subcategory,
      NOMBRE: page,
      JERARQUIA: `${category}-${subcategory}`,
      ERRORCODE: errorCode,
      ERRORDESCRIPTION: errorDescription,
      ...params
    };
    this.dl.push(event);
  }

  setUserProperties(user: UserAnalytics): void {
    this.dl.push(user);
  }

  setModelLevel(modelLevel: string) {
    this.dl.push({ modelLevel });
  }

  toggleTracking(isEnabled: boolean) {
    this.dl.push({
      event: 'OkCookieConsent',
      eventAction: ActionsAnalytics.CLICK,
      eventCategory: CategoriesAnalytics.NAVIGATION,
      eventLabel: `Consentimiento-${isEnabled ? 'ok' : 'ko'}`
    });
  }
}

export enum PayslipsTabs {
  PAYSLIPS = 'PAYSLIPS',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  WAGE_GARNISHMENT = 'WAGE_GARNISHMENT',
  CAR_TRANSFER = 'CAR_TRANSFER'
}

export enum PayslipsFilters {
  LAST = 'last-payslips',
  MONTHS = 'filter-months',
  SAME = 'same-filter'
}

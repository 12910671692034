import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { PublicationContentType } from '@shared/enums/multimedia/content-status-types.enum';
import { Publication } from '@shared/models/multimedia/publication.model';
/* eslint-disable @typescript-eslint/naming-convention */

export class News extends Publication {
  content_type = PublicationContentType.NEWS;
  source: string;

  constructor(data: MSafeAny) {
    super(data);
    this.source = data.source;
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-msg',
  templateUrl: './empty-msg.component.html',
  styleUrls: ['./empty-msg.component.scss']
})
export class EmptyMsgComponent {
  @Input() emptyTextKey!: string;
  @Input() motivationTextKey!: string;
  @Input() isInline!: boolean;
}

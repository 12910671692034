import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import { PtrabSessionService } from '@app/ptrab/services/session/ptrab-session.service';
import { AuditCodes, AuditLevels, AuditMessage } from '@app/shared/models/audit-message/audit-message';
import { Conditions } from '@app/shared/models/conditions/conditions.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { UserAnalytics } from '@app/shared/models/user/analytics-user.model';
import { User } from '@app/shared/models/user/user.model';
import { TokenHelperService } from '@services/auth/token.helper.service';
import { AppError, ErrorCodes, ErrorMessages, ErrorTypes } from '@services/error/error.model';
import { ErrorService } from '@services/error/error.service';
import { Logger } from '@services/logger/logger.service';
import { NetworkService } from '@services/network/network.service';
import { STORAGE_CONSTANTS } from '@services/storage/storage.const';
import { StorageService } from '@services/storage/storage.service';
import { UserService } from '@services/user/user.service';
import { UserInfo } from '@shared/models/auth/user-info.model';
import { ENV } from 'src/environments/environment';

import { AuthenticationHelpers } from './auth.helpers';
import { SessionToken, SessionTokenTypes, TokenService } from './auth.token.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { AuditService } from '../audit/audit.service';

/* eslint-disable @typescript-eslint/naming-convention */

export enum AUTHENTICATION_STATUS {
  ANONIMOUS = 'ANONIMOUS',
  LOGGED_ADFS = 'LOGGED_ADFS',
  LOGGED_ACTIVO2 = 'LOGGED_ACTIVO2',
  LOGOUT = 'LOGOUT',
  REFRESH = 'REFRESH'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly logger = new Logger('AuthService');

  private userInfo: UserInfo = new UserInfo();

  private loginChangedSubject = new BehaviorSubject<boolean>(false);
  loginChanged$ = this.loginChangedSubject.asObservable();

  private statusSubject = new BehaviorSubject<AUTHENTICATION_STATUS>(AUTHENTICATION_STATUS.ANONIMOUS);
  status$ = this.statusSubject.asObservable();

  private userInfoRequestResetTimer!: Subscription;

  constructor(
    private auditService: AuditService,
    private storageService: StorageService,
    private tokenHelperService: TokenHelperService,
    private tokenService: TokenService,
    private errorService: ErrorService,
    private network: NetworkService,
    private ptrabSessionService: PtrabSessionService,
    private userService: UserService,
    private analyticsService: AnalyticsService
  ) {}

  async hasAdfsSession() {
    return this.tokenHelperService.hasAdfsSession();
  }

  async hasActivo2Session() {
    const token = await this.tokenHelperService.get();
    const userInfo = await this.userService.getStoredUser();
    this.logger.debug('hasToken', token);
    this.logger.debug('hasUserInfo', userInfo);
    return token !== null && userInfo !== null;
  }

  getStatus(): AUTHENTICATION_STATUS {
    return this.statusSubject.getValue();
  }

  isLoggedIn(): boolean {
    return this.loginChangedSubject.getValue();
  }

  sendStatus(newStatus: AUTHENTICATION_STATUS) {
    this.statusSubject.next(newStatus);
    this.logger.debug('statusSubjectValue', this.getStatus());
  }

  async login(adfsRedirectHash: string = '') {
    this.errorService.reset();

    await this.handleCallback(adfsRedirectHash);

    if (!this.errorService.hasError()) {
      await this.tryWebLogin();
    }
  }

  async logout() {
    await this.ptrabSessionService
      .ptrabLogout()
      .pipe(take(1))
      .toPromise()
      .catch((err) => this.logger.error('logout error:', err));

    const userAnalytics = new UserAnalytics(null, false);
    this.analyticsService.setUserProperties(userAnalytics);

    this.resetUserInfoRequestRetry();
    this.sendStatus(AUTHENTICATION_STATUS.LOGOUT);
    const url = await this.getLogoutUrl();
    await this.cleanStorage();
    this.userService.clear();
    this.errorService.reset();
    this.storageService.forceLogin();

    setTimeout(() => this.windowLocationReplace(url), 1000);
  }

  setLogin(status: AUTHENTICATION_STATUS) {
    const loginCompleted = status === AUTHENTICATION_STATUS.LOGGED_ACTIVO2;

    this.sendStatus(status);
    this.loginChangedSubject.next(loginCompleted);
  }

  logoutComplete() {
    this.sendStatus(AUTHENTICATION_STATUS.ANONIMOUS);
  }

  cleanStorage() {
    return this.storageService.removeItems([
      STORAGE_CONSTANTS.TOKEN,
      STORAGE_CONSTANTS.USER_ID,
      STORAGE_CONSTANTS.EXPIRATION,
      STORAGE_CONSTANTS.REFRESH_TOKEN_EXPIRATION,
      STORAGE_CONSTANTS.USERNAME,
      STORAGE_CONSTANTS.USER,
      STORAGE_CONSTANTS.REFRESH_TOKEN,
      STORAGE_CONSTANTS.REDIRECT_INTERNAL_ROUTE,
      STORAGE_CONSTANTS.SESSION_REFRESH,
      STORAGE_CONSTANTS.PAYSLIPS_CURRENT_TAB,
      STORAGE_CONSTANTS.IRPF_CURRENT_TAB,
      STORAGE_CONSTANTS.CERTIFICATES_CURRENT_TAB,
      STORAGE_CONSTANTS.FORCE_LOGIN,
      STORAGE_CONSTANTS.SEARCH_PARAMETERS,
      STORAGE_CONSTANTS.MYEXAMPLES_TAB,
      STORAGE_CONSTANTS.EXAMPLES_FILTER,
      STORAGE_CONSTANTS.RESPONSE_AS_OTHER_USER,
      STORAGE_CONSTANTS.PUBLICATIONS_SEGMENTS_TAGS,
      STORAGE_CONSTANTS.PUBLICATIONS_SEGMENT_TAG_SELECT,
      STORAGE_CONSTANTS.SCHEDULE_CURRENT_TAB,
      STORAGE_CONSTANTS.PERSONAL_DATA_CURRENT_TAB,
      STORAGE_CONSTANTS.NOTIFICATIONS_CURRENT_TAB,
      STORAGE_CONSTANTS.SIGNED_TIMETABLES_FILTER,
      STORAGE_CONSTANTS.FIRST_MOT_SCHEDULE,
      STORAGE_CONSTANTS.VACATIONS_YEAR,
      STORAGE_CONSTANTS.LOGISTIC_CALENDAR_YEAR,
      STORAGE_CONSTANTS.CHECK_OFFICE_SERVICE_SELECTED,
      STORAGE_CONSTANTS.REDIRECT_PAGE_AFTER_PTRAB_LOGIN,
      STORAGE_CONSTANTS.OFFICE_FLOOR
    ]);
  }

  async renewToken(sessionRefresh: SessionToken, expiredToken: MSafeAny) {
    // Check connection before and reject if no connection
    this.logger.debug(`session refresh ${sessionRefresh}`);

    const isOnline = await this.network.isOnline();
    if (!isOnline) {
      this.logger.error('No connection available to perform renewToken request');
      return Promise.reject('No connection available');
    }

    if (!sessionRefresh.token || !expiredToken) {
      const rejectMessage = 'Bad Request: provided token info is not valid. RefreshToken';
      Promise.reject(`${rejectMessage} ${sessionRefresh.token}; expiredToken: ${expiredToken}`);
    }

    this.sendStatus(AUTHENTICATION_STATUS.REFRESH);

    try {
      const newTokenData = await this.tokenService.renewToken(sessionRefresh, expiredToken);
      this.logger.debug('newTokenData', newTokenData);

      await this.auditService.push(
        new AuditMessage({
          level: AuditLevels.Info,
          message: `renewToken: refreshToken success.`,
          status_code: AuditCodes.Success,
          app_component: 'AuthService'
        }),
        false
      );

      await this.storeUserLoginFromToken(newTokenData.token.token, newTokenData.sessionRefresh);
      await this.auditService.flush();
      this.sendStatus(AUTHENTICATION_STATUS.LOGGED_ACTIVO2);

      return Promise.resolve(newTokenData);
    } catch (error) {
      return this.handleRenewTokenError(error);
    }
  }

  async initUserInfo() {
    this.resetUserInfoRequestRetry();
    if (this.getStatus() !== AUTHENTICATION_STATUS.LOGOUT) {
      this.doInitUserInfo();
    }
  }

  windowLocationReplace(url: string) {
    return window.location.replace(url);
  }

  private getRefreshToken(code: string, token: string) {
    return this.tokenService.getRefreshToken(code, token);
  }

  private async handleRenewTokenError(error: MSafeAny) {
    const errorMessage = error && error.message ? error.message : error;

    this.auditService.push(
      new AuditMessage({
        level: AuditLevels.Critical,
        message: `handleRenewTokenError: refreshToken error: ${JSON.stringify(errorMessage)}`,
        status_code: AuditCodes.Error,
        app_component: 'AuthService'
      })
    );

    let errorType: ErrorTypes;
    if (error.status === ErrorCodes.MAINTENANCE) {
      this.logger.debug('Maintenance mode');
      errorType = ErrorTypes.MAINTENANCE_MODE;
    } else if (error.status === ErrorCodes.BAD_REQUEST || errorMessage === ErrorMessages.SESSION_REFRESH_REQUIRED) {
      this.logger.error('renewToken error. It seems refresh token is invalid', error);
      this.tokenHelperService.remove();
      errorType = ErrorTypes.REFRESH_TOKEN;
    } else {
      this.logger.error('renewToken error', error);
      this.sendStatus(AUTHENTICATION_STATUS.ANONIMOUS);
      errorType = ErrorTypes.GET_REFRESH_TOKEN;
    }
    const appError = new AppError(errorType, errorMessage);
    this.errorService.add(appError);

    this.tokenService.resetRenewPromise();
    return Promise.reject(appError);
  }

  private async getLogoutUrl() {
    const logout = 'post_logout_redirect_uri=' + encodeURIComponent(ENV.authentication.postLogoutRedirectUri);
    const token = await this.tokenHelperService.get();

    let urlNavigate = '/';

    if (token) {
      urlNavigate = `${ENV.authentication.instance}/oauth2/logout?id_token_hint=${token}&${logout}`;
    }

    return urlNavigate;
  }

  private async handleCallback(hash: string) {
    this.logger.debug('current hash', hash);

    if (hash) {
      this.auditService.push(
        new AuditMessage({
          level: AuditLevels.Info,
          message: `handleCallback: ADFS redirect hash: ${hash}`,
          status_code: AuditCodes.Success,
          app_component: 'AuthService'
        }),
        false
      );
    }

    const response = AuthenticationHelpers.deserialize(hash);
    this.logger.debug('handleCallback hash response', response);

    if (this.handleError(response)) {
      return;
    }

    if ('id_token' in response) {
      this.storageService.cleanForceLogin();
      try {
        const refreshTokenData: SessionToken = await this.getRefreshToken(response.code, response.id_token);

        if (refreshTokenData) {
          this.logger.debug('refresh token', refreshTokenData);
          await this.storeUserLoginFromToken(response.id_token, refreshTokenData);
        }
      } catch (error: MSafeAny) {
        this.logger.error('getRefreshToken', error);

        if (error.status === ErrorCodes.MAINTENANCE) {
          this.errorService.add(new AppError(ErrorTypes.MAINTENANCE_MODE, error));
          return;
        }

        const errorMessage = error && error.message ? error.message : error;
        this.auditService.push(
          new AuditMessage({
            level: AuditLevels.Critical,
            message: `handleCallback: getRefreshToken error: ${JSON.stringify(errorMessage)}`,
            status_code: AuditCodes.Error,
            app_component: 'AuthService'
          })
        );

        this.addError(ErrorTypes.GET_REFRESH_TOKEN, error.message);
      }
    }
  }

  private handleError(response: MSafeAny) {
    if (!response.error) {
      this.logger.debug('has no errors', response);
      return false;
    }

    this.logger.debug('handle error', response);
    this.addError(ErrorTypes.ERROR_LOGIN_SSO, response.error_description);
    return true;
  }

  private addError(type: ErrorTypes, message: string) {
    this.errorService.add(new AppError(type, message));
  }

  private async getCurrentSession() {
    const token = await this.tokenHelperService.get();
    if (!token) {
      this.logger.debug('token not present in getCurrentSession');
      return false;
    }

    this.userInfo = this.getUserInfoFromToken(token);
    this.logger.debug('[cached user] token', token);
    this.logger.debug('[cached userInfo] user', this.userInfo);

    this.setLogin(AUTHENTICATION_STATUS.LOGGED_ADFS);
    return token;
  }

  /**
   * Gets ADFS login URL specific to each environment and platform (Changes depends on platform and forcePrompt param)
   * @param forcePrompt this param force login process each time users access to ADFS page
   * @returns Returns ADFS login URL with specific parameters
   */
  private getLoginUrl(forcePrompt = false) {
    let urlNavigate =
      ENV.authentication.instance +
      '/oauth2/authorize' +
      AuthenticationHelpers.serialize('id_token code', ENV.authentication, null);
    if (forcePrompt) {
      urlNavigate += '&prompt=login';
    }
    urlNavigate += '&nonce=' + encodeURIComponent(AuthenticationHelpers.guid());
    this.logger.debug('Navigate url:' + urlNavigate);
    return urlNavigate;
  }

  private async tryWebLogin() {
    if (await this.getCurrentSession()) {
      return;
    }
    const forcePrompt = this.storageService.hasForceLogin();
    this.logger.debug('force prompt', forcePrompt);
    this.windowLocationReplace(this.getLoginUrl(forcePrompt));
  }

  private decodeClaims(token: string) {
    const parsed = token.split('.')[1];
    const decoded = atob(parsed);
    return JSON.parse(decoded);
  }

  private getUserInfoFromToken(token: string): UserInfo {
    const claims: MSafeAny = this.decodeClaims(token);
    return new UserInfo(claims.upn, claims.upn.split('@')[0]);
  }

  private storeUserLoginFromToken(token: string, refreshToken?: SessionToken): Promise<UserInfo> {
    return new Promise((resolve, reject) => {
      const user: UserInfo = this.getUserInfoFromToken(token);
      const tokenData = this.decodeClaims(token);
      const itemsToSave = [
        { key: STORAGE_CONSTANTS.TOKEN, value: token },
        { key: STORAGE_CONSTANTS.USER_ID, value: user.email },
        { key: STORAGE_CONSTANTS.USERNAME, value: user.name },
        { key: STORAGE_CONSTANTS.EXPIRATION, value: tokenData.exp }
      ];

      if (refreshToken) {
        const refreshTokenToSave = { key: STORAGE_CONSTANTS.REFRESH_TOKEN, value: refreshToken.token };
        if (refreshToken.type === SessionTokenTypes.SESSION_REFRESH) {
          refreshTokenToSave.key = STORAGE_CONSTANTS.SESSION_REFRESH;
        }
        itemsToSave.push(refreshTokenToSave, {
          key: STORAGE_CONSTANTS.REFRESH_TOKEN_EXPIRATION,
          value: refreshToken.expiration + tokenData.exp
        });
      }

      this.storageService
        .setItems(itemsToSave)
        .then(() => {
          this.logger.debug('storeUserLoginFromToken:setItems: ok');
          resolve(user);
        })
        .catch(() => {
          this.logger.error('storeUserLoginFromToken:setItems: ko');
          this.addError(ErrorTypes.STORAGE_USER_LOGIN, 'error saving items in storeUserLogin');
          reject();
        });
    });
  }

  private async doInitUserInfo() {
    const storedUserInfo = await this.userService.getStoredUser();
    if (storedUserInfo !== null) {
      this.logger.debug(`UserInfo found in storage ${JSON.stringify(storedUserInfo)}`);
      this.userService.updateUserConditions().subscribe(
        async (user) => {
          this.logger.info('Success UserConditions');
          this.endSuccessInitUserInfo(user as User);
        },
        (error) => {
          this.handleInitUserInfoError(error, false);
        }
      );
      return;
    }

    this.userService.requestUserInfo().then(
      async (userInfo: User) => {
        this.logger.debug(`UserInfo retrieved from user/info: ${JSON.stringify(userInfo)}`);
        this.endSuccessInitUserInfo(userInfo);
      },
      async (error) => {
        this.handleInitUserInfoError(error);
      }
    );
  }

  private async handleInitUserInfoError(error: MSafeAny, fromUserInfo = true) {
    let errorHeader = 'InitUserInfo ERROR, error syncing user conditions';
    let errorMessage = `${errorHeader}, error is: ${JSON.stringify(error.message)}`;
    let errorData = `${errorHeader}`;
    if (fromUserInfo) {
      const userData = await this.storageService.getItems([
        STORAGE_CONSTANTS.REFRESH_TOKEN,
        STORAGE_CONSTANTS.TOKEN,
        STORAGE_CONSTANTS.USER_ID
      ]);
      errorHeader = 'InitUserInfo ERROR, error calling userInfo';
      errorMessage = `${errorHeader}, error is: ${JSON.stringify(error.message)}`;
      errorData = `${errorHeader}. error DATA is: ${JSON.stringify(userData)}`;
    }

    this.logger.debug(errorMessage);
    await this.auditService.push(
      new AuditMessage({
        level: AuditLevels.Critical,
        message: `handleInitUserInfoError ${errorMessage}`,
        status_code: AuditCodes.Error,
        app_component: 'AuthService'
      })
    );

    this.logger.debug(errorData);
    await this.auditService.push(
      new AuditMessage({
        level: AuditLevels.Critical,
        message: `handleInitUserInfoError ${errorData}`,
        status_code: AuditCodes.Error,
        app_component: 'AuthService'
      })
    );

    const errorGettingInfo = new AppError(ErrorTypes.GETTING_USER_INFO, errorMessage);
    this.network.doIfConnection(() => this.errorService.add(errorGettingInfo));

    this.userInfoRequestResetTimer = of(null)
      .pipe(delay(30000))
      .subscribe(() => {
        this.logger.debug('Automatic InitInfo retry');
        this.initUserInfo();
      });
  }

  private endSuccessInitUserInfo(userInfo: User) {
    this.logger.info('Finalizing InitUserInfo');
    this.checkUserLegalConditions(userInfo);
    this.userService.mergeUser(userInfo);
    this.setLogin(AUTHENTICATION_STATUS.LOGGED_ACTIVO2);
    const userAnalytics = new UserAnalytics(userInfo, true);
    this.analyticsService.setUserProperties(userAnalytics);
  }

  private checkUserLegalConditions(conditions: Conditions) {
    if (!conditions.acceptLegal) {
      this.errorService.add(new AppError(ErrorTypes.TERMS_NOT_ACCEPTED));
    }
  }

  private resetUserInfoRequestRetry() {
    if (this.userInfoRequestResetTimer) {
      this.userInfoRequestResetTimer.unsubscribe();
    }
  }
}

import { ModelSectionNames } from '@shared/enums/model/model-sections.enum';

export const BLOCKS_ORDER: ModelSectionNames[] = [
  ModelSectionNames.MODEL_IMPORTANCE,
  ModelSectionNames.UNIVERSAL_TRUTHS,
  ModelSectionNames.PARADIGM,
  ModelSectionNames.MISSION_AND_VISION,
  ModelSectionNames.THE_BOSS,
  ModelSectionNames.THE_WORKER,
  ModelSectionNames.THE_PROVIDER,
  ModelSectionNames.THE_SOCIETY,
  ModelSectionNames.THE_CAPITAL,
  ModelSectionNames.MODEL_APPLICATION,
  ModelSectionNames.PURPOSE
];

export const UT_SECTIONS_ORDER: ModelSectionNames[] = [
  ModelSectionNames.UNIVERSAL_TRUTHS_WHAT,
  ModelSectionNames.UNIVERSAL_TRUTHS_DPE,
  ModelSectionNames.UNIVERSAL_TRUTHS_CONTRAST,
  ModelSectionNames.UNIVERSAL_TRUTHS_FACTS,
  ModelSectionNames.UNIVERSAL_TRUTHS_NOBODY,
  ModelSectionNames.UNIVERSAL_TRUTHS_IMPROVE,
  ModelSectionNames.UNIVERSAL_TRUTHS_WALK,
  ModelSectionNames.UNIVERSAL_TRUTHS_CAUSE,
  ModelSectionNames.UNIVERSAL_TRUTHS_TIME,
  ModelSectionNames.UNIVERSAL_TRUTHS_CHAIN,
  ModelSectionNames.UNIVERSAL_TRUTHS_APPLY
];

export const SECTIONS_ORDER: ModelSectionNames[] = [
  ModelSectionNames.MODEL_IMPORTANCE,
  ModelSectionNames.UNIVERSAL_TRUTHS,
  ...UT_SECTIONS_ORDER,
  ModelSectionNames.PARADIGM,
  ModelSectionNames.MISSION_AND_VISION,
  ModelSectionNames.THE_BOSS,
  ModelSectionNames.THE_WORKER,
  ModelSectionNames.THE_PROVIDER,
  ModelSectionNames.THE_SOCIETY,
  ModelSectionNames.THE_CAPITAL,
  ModelSectionNames.MODEL_APPLICATION,
  ModelSectionNames.PURPOSE
];

import { Component, OnDestroy, OnInit } from '@angular/core';

import { BaseIrpfInfoDirective } from '../irpf-info-base/irpf-info-base.component';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-ptrab-irpf-info',
  templateUrl: './irpf-info.component.html',
  styleUrls: ['./irpf-info.component.scss']
})
export class IrpfInfoComponent extends BaseIrpfInfoDirective implements OnInit, OnDestroy {
  protected readonly VIDEO_ID = 'irpf';
}

<video
  *ngIf="showPlayer"
  #multimediaElementRef
  [preload]="preloadType"
  playsinline
  webkit-playsinline
  crossorigin
  [src]="defaultSrc"
  [poster]="video.video_image"
>
  <track *ngIf="video.subtitles" src="{{ video.subtitles }}" srclang="es" label="Spanish" kind="subtitles" />
</video>
<div *ngIf="isPreviewPlayerType" class="content-preview">
  <div *ngIf="isDeletable()" class="icon-background" [class.dark]="isPublicationParent" (click)="deleteMultimedia()">
    <i class="app-icon-bin"></i>
  </div>
  <video
    #multimediaElementRef
    [preload]="preloadType"
    playsinline
    webkit-playsinline
    crossorigin
    [src]="previewVideo"
  ></video>
</div>

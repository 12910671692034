<app-content-modal class="hide-overflow" [buttons]="buttons">
  <div class="u-text-center">
    <img src="assets/imgs/ptrab/contact-coordinator.svg" />
  </div>
  <p>
    <b>{{ 'EMPLOYEE_PORTAL.CONTACT_COORDINATOR_TITLE' | translate }}</b>
  </p>
  <p>
    {{ 'EMPLOYEE_PORTAL.CONTACT_COORDINATOR' | translate }}
  </p>
</app-content-modal>

<app-content-modal [buttons]="buttons">
  <h1>{{ 'EMPLOYEE_PORTAL.CHECK_YOUR_NUMBER' | translate }}</h1>
  <p class="description">
    {{ description }}
    <span *ngIf="isSinglePhone">
      {{ 'EMPLOYEE_PORTAL.PHONE_ENDED' | translate }} {{ phones[0].phone | slice : -3 }}.
    </span>
  </p>
  <p class="base-modal__description">{{ reminderMessage }}</p>
  <div *ngIf="!isSinglePhone" class="phone-selection__list">
    <app-item *ngFor="let phone of phones; trackBy: trackByItems" class="radio-input">
      <label (click)="setSelectedPhone(phone.id)">
        <input type="radio" class="phone-selection__radio-button" name="phone" [value]="phone.id" />
        {{ phone.phone }}
      </label>
    </app-item>
  </div>
</app-content-modal>

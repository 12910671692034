import { IShareItem, ShareVias } from './share-item.model';

const email: IShareItem = {
  type: ShareVias.EMAIL,
  src: 'assets/imgs/social-share/mail-share.svg',
  alt: 'email',
  translateKey: 'SHARE.EMAIL'
};

const facebook: IShareItem = {
  type: ShareVias.FACEBOOK,
  src: 'assets/imgs/social-share/facebook-share.svg',
  alt: 'Facebook Share Logo',
  text: 'Facebook'
};

const twitter: IShareItem = {
  type: ShareVias.TWITTER,
  src: 'assets/imgs/social-share/twitter-share.svg',
  alt: 'Twitter Share Logo',
  text: 'Twitter'
};

const link: IShareItem = {
  type: ShareVias.LINK,
  src: 'assets/imgs/social-share/copy-share.svg',
  alt: 'link',
  translateKey: 'SHARE.COPY_LINK'
};

export const SHARE_ITEMS = {
  getEmail: () => ({ ...email }),
  getFacebook: () => ({ ...facebook }),
  getTwitter: () => ({ ...twitter }),
  getLink: () => ({ ...link })
};

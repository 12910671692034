import { AfterViewInit, Attribute, Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { User } from '@app/shared/models/user/user.model';
import { domChanges } from '@shared/utils/utils';

export type AvatarSize = 'small' | 'big';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements AfterViewInit, OnChanges {
  @Input() userInfo!: User;
  @Input() hasNews = false;
  @Input() pendingNews!: boolean;

  @Output() loadElement = new EventEmitter<HTMLElement>();

  avatar!: string;
  avatarInitials!: string;
  showNewsRing = true;

  constructor(@Attribute('size') public size: AvatarSize = 'big', private elementRef: ElementRef) {
    this.size = size;
  }

  async ngAfterViewInit() {
    await domChanges(1000);
    this.loadElement.emit(this.elementRef.nativeElement);
  }

  ngOnChanges() {
    this.avatar = this.userInfo.photo;
    if (!this.avatar && !this.avatarInitials) {
      this.avatarInitials = this.getInitials();
    }
  }

  getInitials() {
    const { name, lastname, userid } = this.userInfo;
    const hasNameAndLastName = Boolean(name) && Boolean(lastname);
    return hasNameAndLastName ? this.initialsByName(name, lastname) : this.initialsById(userid);
  }

  private initialsByName(name: string, lastname: string): string {
    return `${name.charAt(0)}${lastname.charAt(0)}`;
  }

  private initialsById(id: string): string {
    return id.substring(0, 2);
  }
}

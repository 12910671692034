import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { SuccessModalComponent } from '@app/ptrab/components/modals/success-modal/success-modal.component';
import { PayslipsService } from '@app/ptrab/services';
import { IrpfReadjustmentService } from '@app/ptrab/services/irpf-readjustment/irpf-readjustment.service';
import { TwoFactorController } from '@app/ptrab/services/two-factor/two-factor.controller';
import { TwoFactorOperationCode } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { ReadjustmentCompany } from '@app/ptrab/shared/models/company';
import { Logger, ModalManager, AlertService } from '@app/services';
import { LoadingService } from '@app/services/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-ptrab-percentage-readjustment',
  templateUrl: './percentage-readjustment.component.html',
  styleUrls: ['./percentage-readjustment.component.scss']
})
export class PercentageReadjustmentComponent implements OnInit {
  @Input() companies: ReadjustmentCompany[] = [];

  hasMultipleCompanies!: boolean;
  allowedCompanies!: ReadjustmentCompany[];

  private logger = new Logger('PercentageReadjustmentComponent');
  private messages!: { [key: string]: string };

  constructor(
    private loadingService: LoadingService,
    private modalManager: ModalManager,
    private irpfReadjustmentService: IrpfReadjustmentService,
    private alertService: AlertService,
    private twoFactorController: TwoFactorController,
    private translate: TranslateService,
    private payslipsService: PayslipsService
  ) {}

  ngOnInit() {
    this.setTranslations();
    this.setAllowedCompanies();
  }

  async initReadjustment(requested: boolean, company: ReadjustmentCompany) {
    company.is_requested = requested;

    this.loadingService.show();
    const areAvailable = await this.payslipsService.arePayslipsAvailable();
    await this.loadingService.hide();

    if (!areAvailable) {
      return;
    }

    try {
      const hash = await this.twoFactorController.validateOperation(
        TwoFactorOperationCode.PERCENTAGE_READJUSTMENT,
        false
      );
      this.requestReadjustment(company, hash);
    } catch (err) {
      //EMPTY CATCH
    }
  }

  trackByItems(item: ReadjustmentCompany) {
    return item;
  }

  private requestReadjustment(company: ReadjustmentCompany, hash: string) {
    this.loadingService.show();
    this.irpfReadjustmentService
      .setRegularization(
        {
          company_code: company.code,
          employee_number: company.employee_number,
          activation: !company.is_requested
        },
        { validation_hash: hash }
      )
      .pipe(
        finalize(() => {
          this.loadingService.hide();
        })
      )
      .subscribe(
        async () => {
          await this.openSuccessModal();
          company.is_requested = !company.is_requested;
        },
        (errorResponse: HttpErrorResponse) => {
          if (errorResponse.error && errorResponse.error.code) {
            return;
          }

          this.logger.error(errorResponse);
          this.showToastError();
        }
      );
  }

  private showToastError() {
    this.alertService.showError(
      this.messages['EMPLOYEE_PORTAL.PERCENTAGE_READJUSTMENT.ERROR_TITLE'],
      this.messages['ERROR_MESSAGES.GENERIC_FAIL_MESSAGE']
    );
  }

  private setAllowedCompanies() {
    this.allowedCompanies = this.companies.filter((company) => company.is_allowed);
    this.hasMultipleCompanies = this.allowedCompanies.length > 1;
  }

  private openSuccessModal() {
    const modalOptions: MatDialogConfig = {
      data: {
        titleKey: 'EMPLOYEE_PORTAL.PERCENTAGE_READJUSTMENT.MODAL_SUCCESS.TITLE',
        infoMsgKey: 'EMPLOYEE_PORTAL.PERCENTAGE_READJUSTMENT.MODAL_SUCCESS.DESCRIPTION',
        descriptionKey: 'EMPLOYEE_PORTAL.PERCENTAGE_READJUSTMENT.MODAL_SUCCESS.EMAIL',
        emailQuestion: 'EMPLOYEE_PORTAL.DONT_YOU_RECEIVE_QUESTION',
        personalDataLink: 'EMPLOYEE_PORTAL.PERSONAL_DATA_LINK'
      },
      disableClose: true
    };

    return this.modalManager.openMatModal(SuccessModalComponent, modalOptions);
  }

  private setTranslations() {
    this.translate
      .stream(['EMPLOYEE_PORTAL.PERCENTAGE_READJUSTMENT.ERROR_TITLE', 'ERROR_MESSAGES.GENERIC_FAIL_MESSAGE'])
      .subscribe((messages) => (this.messages = messages));
  }
}

import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioItem } from '@app/ptrab/shared/models/radio';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-radio-selector',
  templateUrl: './radio-selector.component.html',
  styleUrls: ['./radio-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioSelectorComponent),
      multi: true
    }
  ]
})
export class RadioSelectorComponent implements ControlValueAccessor {
  @Input() title!: string;
  @Input() popoverText!: string;
  @Input() options!: RadioItem<MSafeAny>[];
  @Output() selectionChanged = new EventEmitter<void>();

  selectedValue!: MSafeAny;

  resetSelectedValue() {
    this.selectedValue = -1;
    this.onTouch();
    this.onChange(-1);
  }

  writeValue(value?: number) {
    this.selectedValue = value || 0;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  registerOnChange(fn: (_: number) => void) {
    this.onChange = fn;
  }

  handleModelChange(value: MSafeAny) {
    this.selectedValue = value;
    this.onTouch();
    this.onChange(value);
    this.selectionChanged.emit();
  }

  trackByItems(item: MSafeAny) {
    return item;
  }

  private onTouch = () => {};
  private onChange = (_: number) => {};
}

<div *ngIf="errorInfo" class="error-info" [class.error-info--justified]="errorInfo.justified_style">
  <div class="error-info__image-wrapper">
    <img
      class="animated fadeInRight slower error-info__image"
      [src]="errorInfo.url_image"
      alt="Error Info Image"
      (click)="imageAction()"
    />
  </div>
  <div class="error-info__text-wrapper">
    <img *ngIf="errorInfo.alt_image" class="error-info__text" [src]="errorInfo.alt_image" alt="Error Info Alt Image" />
    <h1 class="error-info__title"><i class="app-{{ errorInfo.icon_name }}"></i>{{ errorInfo.title | translate }}</h1>
    <p class="error-info__description">{{ errorInfo.description | translate }}</p>
    <p class="error-info__description" *ngIf="errorInfo.additional_description && showAdditionalData">
      {{ errorInfo.additional_description | translate }}
    </p>
    <p class="error-info__version" *ngIf="errorInfo.version">
      {{ 'VERSION_APP' | translate }}&nbsp; <b>{{ version }}</b>
    </p>
    <button
      *ngFor="let button of errorInfo.action_buttons; trackBy: trackByItems"
      class="animated fadeInUp delay-0.5s {{ button.type }} error-info__button"
      (click)="button.onClick()"
    >
      {{ button.text | translate }}
    </button>
  </div>
</div>

<div class="personal-income-tax-page">
  <app-spinner [showText]="true" *ngIf="isWithholdingLoading"></app-spinner>
  <div *ngIf="!isWithholdingLoading && !errorOcurred" class="withholdings-container">
    <span class="title">{{ 'EMPLOYEE_PORTAL.VOLUNTARY_WITHHOLDING.TITLE' | translate }}</span>
    <app-ptrab-withholding-info-pt
      [withholding]="withholding"
      [singleCompany]="hasSingleCompany"
      (editClick)="editWithholding($event)"
      (requestClick)="confirmRequest(withholding)">
    </app-ptrab-withholding-info-pt>
  </div>
</div>

<div class="row" *ngIf="errorOcurred">
  <div class="col" size="12">
    <ng-container *ngIf="!isSectionMaintenance; else maintenance">
      <app-ptrab-feedback-msg [type]="errorOcurred"></app-ptrab-feedback-msg>
    </ng-container>
    <ng-template #maintenance>
      <app-section-maintenance></app-section-maintenance>
    </ng-template>
  </div>
</div>

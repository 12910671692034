import { Component, Input } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
@Component({
  selector: 'app-title-description-image-card',
  templateUrl: './title-description-image-card.component.html',
  styleUrls: ['./title-description-image-card.component.scss']
})
export class TitleDescriptionImageCardComponent {
  @Input() item: MSafeAny;
}

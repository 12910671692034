import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ItemComponent } from '@app/components/item/item.component';
import { NotificationStatus } from '@app/shared/enums/notification/notification.enum';
import { Notification } from '@app/shared/models/notification/notification.model';
import { domChanges } from '@app/shared/utils/utils';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnChanges, AfterViewInit {
  @ViewChild(ItemComponent, { read: ElementRef }) item!: ElementRef;
  @Input() notification!: Notification;
  @Input() index!: number;
  @Output() remove = new EventEmitter();
  @Output() action = new EventEmitter();

  formattedDate!: Date;
  expanded = false;
  expandIcon = 'ios-arrow-down';
  isLongText!: boolean;
  startSliding!: boolean;
  sliding!: boolean;

  ngOnChanges() {
    this.formattedDate = new Date(this.notification.created);
    this.isLongText = this.notification.description.length > 100;
  }

  isUnread() {
    return this.notification.status === NotificationStatus.NEW;
  }

  async ngAfterViewInit() {
    await domChanges();

    if (!this.itemInner) {
      await domChanges(1500);
    }

    this.setItemInnerStyles(this.itemInner);
  }

  private get itemInner(): HTMLElement {
    return (this.item.nativeElement as HTMLElement).querySelector('.item-inner') as HTMLElement;
  }

  private setItemInnerStyles(itemInner: HTMLElement) {
    itemInner.style.display = 'flex';
    itemInner.style.flexDirection = 'column';
  }

  expandText(e: Event) {
    e.stopPropagation();
    this.expanded = !this.expanded;
    this.expandIcon = this.expanded ? 'ios-arrow-up' : 'ios-arrow-down';
  }

  removeNotification(id: string, e) {
    e.stopPropagation();
    this.remove.emit(id);
  }

  getFormattedDescription(description: string) {
    const canBeEllipsed = this.isLongText && !this.expanded;

    if (canBeEllipsed) {
      return description.substring(0, 90) + '...';
    }

    return description;
  }

  notificationAction(notification: Notification) {
    if (this.sliding) {
      return;
    }

    this.action.emit(notification);
  }

  onSwipe(event) {
    this.sliding = true;
    this.startSliding = Boolean(event.deltaX < event.deltaY);
    // Timeout is mandatory in order to prevent click event to be fired is swipe event ends inside component
    setTimeout(() => (this.sliding = false), 100);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Document } from '@app/ptrab/shared/models/document';
import { MessageService } from '@app/services/message/message.service';
import { MessageActions } from '@app/shared/enums/message/message-actions.enum';

@Component({
  selector: 'app-sign-document',
  templateUrl: './sign-document.component.html',
  styleUrls: ['./sign-document.component.scss']
})
export class SignDocumentComponent implements OnInit {
  @Input() document!: Document;
  @Output() signedContract = new EventEmitter();
  hasSignedContract = false;
  title!: string;

  constructor(private messageService: MessageService) {
    this.listenWs();
  }

  ngOnInit() {
    this.updateStepTitle();
  }

  private updateStepTitle() {
    this.title = this.hasSignedContract
      ? 'ON_BOARDING.STEPPER.FIRST_STEP.SIGNED_CONTRACT_ARRIVE_EMAIL'
      : 'ON_BOARDING.STEPPER.FIRST_STEP.SIGN_YOUR_CONTRACT';
  }

  private listenWs() {
    this.messageService.messages$.subscribe((response) => {
      if (response.action === MessageActions.SIGNATURE_RECEIVED) {
        this.hasSignedContract = true;
        this.updateStepTitle();
        this.signedContract.emit();
      }
    });
  }
}

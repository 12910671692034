import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Filter } from '@app/shared/models/filters/filters';

@Component({
  selector: 'app-filter-tag',
  templateUrl: './filter-tag.component.html',
  styleUrls: ['./filter-tag.component.scss']
})
export class FilterTagComponent {
  @Input() filterTag!: Filter;
  @Input() selected!: boolean;
  @Input() universalTruth;
  @Output() clear = new EventEmitter();
  @Output() selectOption = new EventEmitter();
  @Output() dropDown = new EventEmitter();

  selectFilter() {
    this.selectOption.emit();
  }

  clearFilter() {
    if (this.isUniversalTruths()) {
      this.clear.emit('universal');
      return;
    }
    this.clear.emit();
  }

  isUniversalTruths() {
    return this.filterTag.label === 'UNIVERSAL_TRUTHS';
  }

  sendDropdown() {
    this.dropDown.emit();
  }
}

import { Component, ElementRef, HostListener, Input } from '@angular/core';

import { NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

export interface DropdownButton {
  text: string;
  icon: string;
  urlRedirect?: string;
}

@Component({
  selector: 'app-dropdown-buttons',
  templateUrl: './dropdown-buttons.component.html',
  styleUrls: ['./dropdown-buttons.component.scss']
})
export class DropdownButtonsComponent {
  @Input() buttonText!: string;
  @Input() buttonIcon!: string;
  @Input() dropdownButtons!: DropdownButton[];

  dropdownOpened = false;

  constructor(private elRef: ElementRef, private navigationService: NavigationService) {}

  @HostListener('document:click', ['$event'])
  clickout(event: MSafeAny) {
    if (!this.elRef.nativeElement.contains(event.target) && this.dropdownOpened) {
      this.toggleDropdownButtons();
    }
  }

  toggleDropdownButtons() {
    this.dropdownOpened = !this.dropdownOpened;
  }

  redirectTo(url: string) {
    const navEvent = new NavigationEvent(NavigationEvents.Push, {
      path: url
    });
    this.navigationService.navigate(navEvent);
  }

  trackByItems(item: DropdownButton) {
    return item;
  }
}

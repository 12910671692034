<app-content-modal [buttons]="buttons" (closeModal)="onCloseIconClick()">
  <h1 class="base-modal__title">{{ 'PUBLICATIONS_LIST.FILTER_BY' | translate }}</h1>
  <div class="list list-wrapper" *ngIf="publicationFilters.length">
    <div class="list-item">
      <div class="subfilters">
        <app-checkbox
          class="subfilters"
          [filter]="selectAllFilter"
          (checkboxChange)="toggleSelectAllFilter()"></app-checkbox>
      </div>
    </div>
    <div class="list-item" *ngFor="let filter of publicationFilters; trackBy: trackByItems">
      <app-checkbox
        [filter]="filter"
        (checkboxChange)="togglePublicationFilter(filter)"
        (selectSubcategories)="selectSubcategoriesFromCategory($event, filter)"></app-checkbox>
      <div class="subfilters" *ngFor="let subfilter of filter.subfilters; trackBy: trackByItems">
        <app-checkbox
          [filter]="subfilter"
          (checkboxChange)="togglePublicationFilter(subfilter)"
          (toggleSubcategory)="removeCategory($event, filter)"></app-checkbox>
      </div>
    </div>
  </div>
</app-content-modal>

export class FileFetchHelper {
  static async getBlobFromUrl(url: string): Promise<Blob> {
    const response: Response = await fetch(url);
    if (response.status !== 200) {
      return Promise.reject(`Received status ${response.status} fetching: ${url}`);
    }
    return response.blob();
  }

  static getB64FromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();

      this.getBlobFromUrl(url)
        .then((blob: Blob) => {
          reader.readAsDataURL(blob);
        })
        .catch((err) => {
          reject(err);
        });

      reader.onloadend = () => {
        resolve(reader.result as string);
      };

      reader.onerror = (err) => {
        reject(err);
      };
    });
  }

  static getB64FromBlob(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();

      reader.readAsDataURL(blob);

      reader.onloadend = () => {
        resolve(reader.result as string);
      };

      reader.onerror = (err) => {
        reject(err);
      };
    });
  }
}

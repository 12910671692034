import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalManager, AlertService, Logger, NetworkService } from '@app/services';
import { LoadingService } from '@app/services/loading/loading.service';
import { ExampleService } from '@app/services/multimedia/example/example.service';
import { ContentType, ExampleStatusType } from '@app/shared/enums/multimedia/content-status-types.enum';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { Example } from '@app/shared/models/multimedia/example.model';
import { VideoExample } from '@app/shared/models/multimedia/video.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';

import { ERROR_CODES } from '../comments-section/error-codes-comments.const';
import { ExampleFormComponent } from '../example-form/components/example-form/example-form.component';

@Component({
  selector: 'app-edit-example',
  templateUrl: './edit-example.component.html'
})
export class EditExampleComponent implements OnInit {
  @ViewChild(ExampleFormComponent) formComponent!: ExampleFormComponent;
  exampleId!: number;
  exampleFormButtons: Buttons[] = [];
  isLoading!: boolean;
  isEditDenied = false;
  currentExample!: Example;

  private logger = new Logger('EditExampleComponent');
  private oldTitle = '';
  private oldDescription = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny,
    private modalManager: ModalManager,
    private exampleService: ExampleService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private translate: TranslateService,
    private network: NetworkService
  ) {}

  async ngOnInit() {
    this.exampleId = this.data.exampleId;

    this.exampleFormButtons = this.getExampleButtons();
    this.currentExample = (await this.fetchExample()) as Example;
    this.isEditDenied = this.currentExample && this.currentExample.status === ExampleStatusType.DENIED;
    this.oldTitle = this.currentExample.title;
    this.oldDescription = this.currentExample.text.replace(/\n/g, '<br>');
  }

  submitForm() {
    this.formComponent.submitForm();
  }

  async editExample(formData: FormData) {
    this.completeFormData(formData);
    try {
      this.loadingService.show();
      const example: Example = await this.exampleService.edit(formData, this.currentExample.id).toPromise();
      if (this.formComponent.type === ContentType.VIDEO && this.formComponent.multimedia?.file) {
        await this.createVimeoVideo(example);
      }
      this.onSuccess(example);
    } catch (error) {
      this.onError(error);
    } finally {
      this.loadingService.hide();
    }
  }

  close() {
    this.modalManager.dismissMatModal();
  }

  getExampleButtons(enabled: boolean = false): Buttons[] {
    return [
      {
        text: 'CANCEL',
        type: 'secondary',
        enabled: true,
        onClick: () => this.close()
      },
      {
        text: 'MULTIMEDIA_FORM.SAVE',
        type: 'primary',
        enabled,
        onClick: () => this.submitForm()
      }
    ];
  }

  checkValidation() {
    const isFormValid = this.formComponent.valid;
    const isMultimediaModified = this.formComponent.isMultimediaModified;
    const isTitleChanged = this.formComponent.title !== this.oldTitle;
    const isDescriptionChanged = this.formComponent.description !== this.oldDescription;

    const enabled = isFormValid && (isMultimediaModified || isTitleChanged || isDescriptionChanged);
    this.exampleFormButtons = this.getExampleButtons(enabled);
  }

  private async fetchExample(): Promise<Example | undefined> {
    try {
      this.isLoading = true;
      const example = (await this.exampleService.getItem(this.exampleId).toPromise()) as Example;
      return example;
    } catch (error) {
      if (!this.network.hasConnection()) {
        this.network.redirectIfOffline();
      }
      this.logger.error(error);
      this.alertService.showError(
        this.getMessage('CUSTOM_FILE_CHOOSER.ERROR'),
        this.getMessage('ERRORS_TOASTS.CONTENT_REQUEST_FAILED')
      );
    } finally {
      this.isLoading = false;
    }
  }

  private completeFormData(formData: FormData) {
    const { multimedia, type } = this.formComponent;
    const isVideo = type === ContentType.VIDEO;
    const isNewVideo = isVideo && multimedia && multimedia.file;
    if (isNewVideo) {
      const fileName = (this.formComponent?.multimedia?.file as File).name;
      formData.append('status', 'pending');
      formData.append('id_video', (this.currentExample as VideoExample).id_video);
      formData.append('filename', fileName);
    } else {
      if (this.currentExample.content_type === ContentType.VIDEO) {
        const appendVideo = this.currentExample as VideoExample;
        const deleteVideo = this.formComponent.isMultimediaModified ? 'true' : 'false';
        formData.append('delete_video', deleteVideo);
        formData.append('id_video', appendVideo.id_video);
      }
    }
  }

  private createVimeoVideo(example: Example) {
    const video = this.formComponent?.multimedia?.file as Blob;
    const uploadLink = example.url_video_upload as string;
    const exampleId = example.id;
    return this.exampleService.createVimeoVideo(uploadLink, exampleId, video);
  }

  private onError(error) {
    this.logger.error(error);
    if (error.error?.code === ERROR_CODES.ACTIVO2_0004) {
      this.handlerErrorInvalidCharacters(error);
    } else {
      this.alertService.showError(
        this.getMessage('CUSTOM_FILE_CHOOSER.ERROR'),
        this.getMessage('CUSTOM_FILE_CHOOSER.CREATE_ERROR')
      );
    }
  }

  private handlerErrorInvalidCharacters(error) {
    this.alertService.showError(
      this.getMessage('ERROR_MESSAGES.ERROR'),
      this.translate.instant('COMMENTS.ERROR_INVALID_CHARACTERS', { characters: error.error?.invalid_chars })
    );
  }

  private onSuccess(example: Example) {
    this.logger.debug(example);
    this.exampleService.reloadExamples();
    this.alertService.showSuccess(
      this.getMessage('CUSTOM_FILE_CHOOSER.SUCCESS'),
      this.getMessage('CUSTOM_FILE_CHOOSER.CREATE_SUCCESS')
    );
    this.close();
  }

  private getMessage(key: string): string {
    return this.translate.instant(key);
  }
}

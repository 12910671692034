import { Injectable } from '@angular/core';

import { ImageItem } from '@app/components/example-form/components/example-form/example-form.component';
import { Logger } from '@app/services/logger';
import { ImageCompressionService } from '@services/files/image-compression/image-compression.service';

@Injectable({
  providedIn: 'root'
})
export class FormImageService {
  private readonly logger = new Logger('FormImageService');

  constructor(private imageCompressionService: ImageCompressionService) {}

  async optimizeImages(images: ImageItem[]): Promise<File[]> {
    const files = images.map((img) => img.file as File);
    const optimizedImages = await this.reduceImages(files);
    this.logger.debug('Files after resize: ', optimizedImages);
    return optimizedImages;
  }

  async reduceImages(files: File[]): Promise<File[]> {
    this.logger.debug('reduceImages, images to reduce: ', files.length);
    return Promise.all(
      files.map(async (file: File) => {
        try {
          if (file.type === 'image/gif') {
            return file;
          }
          const resizedImage = await this.imageCompressionService.compress(file, {
            maxWidthOrHeight: 1200 // px
          });
          this.logger.debug('Resized image:', file.name);
          return resizedImage;
        } catch (error) {
          this.logger.warn('Resize error:', error);
          return file;
        }
      })
    );
  }
}

<div class="top-content top-content--covered wow fadeIn">
  <img class="cover-img" [hidden]="!show" (load)="show = true" [src]="multimedia.thumbnail" />
  <div class="inner-content">
    <img *ngIf="multimedia.isMultimediaCardVideo()" class="media-icon" src="assets/imgs/video-card/play.svg" />
    <div class="inline-info">
      <span class="card-title">{{ multimedia.title }}</span>
      <span class="card-date"> {{ cardDate | date: 'dd/MM/yyyy' }} </span>
    </div>
  </div>

  <app-card-tooltip *ngIf="isTypeLite"></app-card-tooltip>
  <app-share-content
    *ngIf="multimedia.isShareable() && !isWebview"
    class="share-corner"
    [item]="multimedia"
    [isDetail]="false"
    [analyticsCategory]="analyticsCategory"
  ></app-share-content>
</div>

<div class="bottom-content">
  <app-card-info *ngIf="!isTypeLite" [item]="multimedia" [cardType]="type"></app-card-info>
  <app-card-buttons
    *ngIf="hasActionButtons"
    [exampleItem]="multimedia"
    [publishIsDisabled]="publishIsDisabled"
  ></app-card-buttons>
</div>

/* eslint-disable @typescript-eslint/naming-convention */

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { IrpfDataResponse } from './irpf-data-response.interface';

export interface IrpfDataSection {
  isVisible: boolean;
  data: MSafeAny;
  title: string;
  emptyMsg?: string;
  sectionError: IrpfDataSectionError | null | undefined;
  id: string;
}

export enum SECTIONS_IRPF {
  WORKER = 'WORKER',
  SPOUSE = 'SPOUSE',
  DEPENDANTS = 'DEPENDANTS',
  YOUNG_BENEFIT = 'YOUNG_BENEFIT',
  DESCENDANT = 'DESCENDANT',
  ROOM_BENEFIT = 'ROOM_BENEFIT',
  ANCESTOR = 'ANCESTOR',
  MORTGAGE_REDUCTION = 'MORTGAGE_REDUCTION',
  CEUTA_MELILLA_RESIDENCE = 'CEUTA_MELILLA_RESIDENCE',
  PENSION_PAYMENT = 'PENSION_PAYMENT',
  YOUNG_WITHHOLDING = 'YOUNG_WITHHOLDING'
}

export interface IrpfDataSectionError {
  code: string;
  description?: string;
}

export interface IrpfDataPostResponse {
  data: IrpfDataResponse;
  info_messages: IrpfDataSectionError[];
}

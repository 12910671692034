<div class="row">
  <div class="col content" size="12">
    <div class="content-text animated fadeInLeft">
      <p class="text-empty">{{ emptyTextKey | translate }}</p>
      <p>{{ motivationTextKey | translate }}</p>
    </div>
    <div class="content-img animated fadeInRight">
      <img class="banner-img" src="assets/imgs/no-results/empty.svg" [alt]="'PRIVATE_EXAMPLES.TITLE' | translate" />
    </div>
  </div>
</div>

import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList
} from '@angular/core';

import { NavTabComponent } from '@app/components/nav-tabs/nav-tab/nav-tab.component';
import { domChanges } from '@app/shared/utils/utils';

export type TabSelectEvent = CustomEvent<{ tabId: string }>;

@Component({
  selector: 'app-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements AfterContentInit, OnChanges {
  @ContentChildren(NavTabComponent) tabs!: QueryList<NavTabComponent>;
  @Input() selectedTab!: string;
  @Output() tabSelect = new EventEmitter<TabSelectEvent>();

  async ngAfterContentInit() {
    await domChanges();

    this.setActiveTab(this.selectedTab);

    this.tabs.forEach((tab) => {
      tab.select.subscribe((tabId: string) => {
        const tabEvent = new CustomEvent('tabSelect', { cancelable: true, detail: { tabId } });
        this.tabSelect.emit(tabEvent);

        if (tabEvent.defaultPrevented) {
          return;
        }

        this.setActiveTab(tabId);
      });
    });
  }

  ngOnChanges() {
    this.setActiveTab(this.selectedTab);
  }

  setActiveTab(tabId: string) {
    if (!this.tabs) {
      return;
    }

    this.tabs.forEach((tab) => {
      tab.isActive = tab.tabId === tabId;
    });
  }
}

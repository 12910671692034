<div class="personal-income-tax-page">
  <app-spinner [showText]="true" *ngIf="isWithholdingLoading"></app-spinner>
  <div *ngIf="!isWithholdingLoading && !errorOcurred" class="withholdings-container">
    <span class="title">{{ 'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.TITLE' | translate }}</span>
    <app-ptrab-withholding-info-es
      *ngFor="let item of withholding; trackBy: trackByItems"
      [withholding]="item"
      [singleCompany]="hasSingleCompany"
      (editClick)="editWithholding($event)"
      (requestClick)="confirmRequest(item)"
      (removeClick)="removeVoluntaryWithholding(item)">
    </app-ptrab-withholding-info-es>
  </div>

  <div class="row" *ngIf="errorOcurred">
    <div class="col" size="12">
      <ng-container *ngIf="!isSectionMaintenance; else maintenance">
        <app-ptrab-feedback-msg [type]="errorOcurred"></app-ptrab-feedback-msg>
      </ng-container>
      <ng-template #maintenance>
        <app-section-maintenance></app-section-maintenance>
      </ng-template>
    </div>
  </div>
</div>

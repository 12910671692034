import { Permissions } from '@app/shared/enums/permissions/permissions.enum';
import { NavTab } from '@app/shared/interfaces/nav-tab/nav-tab.interface';
import { flatten } from '@app/shared/utils/utils';
import { MOT_SECTIONS_PERMISSIONS } from '@app/mot/mot-permissions.const';
import { MOMO_SECTIONS_PERMISSIONS } from '@app/momo/momo-permissions.const';

export enum VacationsTabs {
  CALENDAR = 'CALENDAR',
  SIGNED = 'SIGNED'
}

export const VACATIONS_NAV_TABS: NavTab[] = [
  {
    tabID: VacationsTabs.CALENDAR,
    label: 'MOT.VACATIONS.TABS.CALENDAR',
    tabIcon: '',
    permissions: [Permissions.MOT_VACATIONS]
  },
  {
    tabID: VacationsTabs.SIGNED,
    label: 'MOT.VACATIONS.TABS.SIGNED',
    tabIcon: '',
    permissions: [Permissions.MOT_SIGNED_VACATIONS]
  }
];

const vacationsPermissions: { [key: string]: Permissions[] } = {
  VACATIONS: [...MOT_SECTIONS_PERMISSIONS.MOT_VACATIONS, ...MOMO_SECTIONS_PERMISSIONS.MOMO_VACATIONS]
};

export const VACATIONS_PERMISSIONS = flatten(Object.values(vacationsPermissions));

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { FeedbackTypes } from '@app/ptrab/shared/enums/feedback.enum';

import { feedbackMessagesData } from './feedback-msg.constants';
import { IFeedbackMsg } from './feedback-msg.interface';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-ptrab-feedback-msg',
  templateUrl: './feedback-msg.component.html',
  styleUrls: ['./feedback-msg.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeedbackMsgComponent implements OnInit {
  @Input() type!: FeedbackTypes;
  @Input() reverseContent = false;
  @Input() imageHiddenOnMobile = false;

  readonly SECTION_MAINTENANCE = FeedbackTypes.SECTION_MAINTENANCE;

  messageData!: IFeedbackMsg;

  ngOnInit() {
    this.messageData = feedbackMessagesData[this.type];
  }
}

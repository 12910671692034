import { ModelSectionNames } from '@shared/enums/model/model-sections.enum';
import { FilterModeEnum } from '@shared/enums/requests/filterMode.enum';
import { OrderingEnum } from '@shared/enums/requests/ordering.enum';

export enum ExampleRequestStatusEnum {
  DRAFT = 'draft',
  PENDING = 'pending',
  PUBLISH = 'publish',
  EXPIRED = 'expired',
  DENIED = 'denied'
}

export interface IExampleRequestParams {
  filter_mode?: FilterModeEnum;
  filter_text?: string;
  model_section?: ModelSectionNames;
  ordering?: OrderingEnum | string;
  page?: number;
  size?: number;
  status?: ExampleRequestStatusEnum;
  language?: string;
}

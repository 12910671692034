import { Directive, HostBinding, HostListener, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { ModelService } from '@app/activo2/model/services/model/model.service';
import { ExampleConfirmationModalComponent } from '@app/components/modals/example-confirmation-modal/example-confirmation-modal.component';
import { NetworkService } from '@app/services';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { PublicationService } from '@app/services/multimedia/publication.service';
import { SemaphoreService } from '@app/services/multimedia/semaphore.service';
import { getUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { ExampleRequestStatusEnum } from '@app/shared/interfaces/multimedia/example.request.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { ModalManager } from '@services/modal-manager/modal-manager';
import { NavigationEvent, NavigationEvents, NavigationService } from '@services/navigation/navigation.service';
import { ItemCardType } from '@shared/enums/cards/item-card-type.enum';
import { ContentType, PublicationContentType } from '@shared/enums/multimedia/content-status-types.enum';
import { MultimediaDetailRenderMode } from '@shared/enums/multimedia/multimedia-detail.enum';
import { PAGES } from '@shared/enums/pages/pages.enum';
import { getModelUrlAndSection, isModelLink } from '@shared/helpers/model/model.helper';
import { Example } from '@shared/models/multimedia/example.model';
import { AbstractMultimediaItem } from '@shared/models/multimedia/multimedia.abstract.model';

import { MultimediaDetailDirective } from '../multimedia-details/multimedia-detail.component';
/* eslint-disable @typescript-eslint/naming-convention */

@Directive()
export abstract class MultimediaCardDirective implements OnInit, OnDestroy {
  @Input() exampleStatus!: ExampleRequestStatusEnum;
  @Input() type!: ItemCardType;
  @Input() exampleOptions!: MSafeAny[];
  @Input() publishIsDisabled!: boolean;
  @Input() detailRenderMode!: MultimediaDetailRenderMode;
  @Input() pageAnalytics!: string;
  @Input() isWebview!: boolean;

  protected ItemCardType = ItemCardType;
  protected network: NetworkService;
  private modalManager: ModalManager;
  private semaphoreService: SemaphoreService;
  private navigationService: NavigationService;
  private publicationService: PublicationService;
  private modelService: ModelService;
  private analyticsService: AnalyticsService;

  cardDate!: string;
  isTypeLite!: boolean;
  isDraft!: boolean;
  isDenied!: boolean;
  hasActionButtons!: boolean;
  analyticsCategory!: string;

  @HostBinding('class') cssClass!: string;

  constructor(private multimediaDetailComponent: typeof MultimediaDetailDirective, injector: Injector) {
    this.modalManager = injector.get(ModalManager);
    this.network = injector.get(NetworkService);
    this.semaphoreService = injector.get(SemaphoreService);
    this.navigationService = injector.get(NavigationService);
    this.publicationService = injector.get(PublicationService);
    this.modelService = injector.get(ModelService);
    this.analyticsService = injector.get(AnalyticsService);
  }

  @HostListener('click')
  onClick() {
    this.network.doIfConnection(() => this.showCardDetail());
  }

  ngOnInit() {
    this.isTypeLite = this.type === ItemCardType.LITE;
    this.isDraft = this.exampleStatus === ExampleRequestStatusEnum.DRAFT;
    this.isDenied = this.exampleStatus === ExampleRequestStatusEnum.DENIED;
    this.hasActionButtons = this.isDraft || this.isDenied;
    this.cssClass = this.getCardCssClass();
    this.cardDate = this.getCardDate();

    this.analyticsCategory = this.getItem().getAnalyticsCategory(this.pageAnalytics);
  }

  ngOnDestroy() {
    this.modalManager.dismissAllModalIfNoSession();
  }

  protected abstract onModalDetailDismiss(item: AbstractMultimediaItem): void;

  protected abstract getItem(): AbstractMultimediaItem;

  private async showCardDetail() {
    this.sendAnalyticsEvent();

    const params = this.getParams();

    if (params.item.isInternal()) {
      this.incrementViews(params.item);
    }

    if (isModelLink(params.item)) {
      return this.navigateToModel(params);
    }

    if (this.isPage(params.item.content_type)) {
      let path: string = this.getNavigationPath(params.item);
      path += `/${params.item_id}`;
      const navEvent = new NavigationEvent(NavigationEvents.Push, { path, navParams: params });
      this.navigationService.navigate(navEvent);
      return;
    }

    if (!this.semaphoreService.canPass() || !this.multimediaDetailComponent) {
      return;
    }

    this.semaphoreService.on();

    const modalOptions: MatDialogConfig = {
      data: { exampleStatus: this.exampleStatus, inputItem: this.getItem(), isModal: true },
      panelClass: 'base-modal'
    };

    this.modalManager.openMatModal(this.multimediaDetailComponent as MSafeAny, modalOptions).then((event) => {
      if (!event) {
        return;
      }

      if (this.isDraft && event.publishDraft) {
        return this.openConfirmationModal(event.item);
      }

      this.onModalDetailDismiss(event.item);
    });

    this.semaphoreService.off();
  }

  private openConfirmationModal(exampleItem: Example): Promise<MSafeAny> {
    const modalOpts: MatDialogConfig = {
      data: { exampleItem },
      disableClose: true,
      panelClass: 'base-modal'
    };

    return this.modalManager.openMatModal(ExampleConfirmationModalComponent, modalOpts);
  }

  private getCardCssClass(): string {
    const cssIsDraft = this.isDraft || this.hasActionButtons ? 'is-draft ' : '';

    switch (this.type) {
      case ItemCardType.EXPANDED:
        return `${cssIsDraft}type-expanded`;
      case ItemCardType.LITE:
        return `${cssIsDraft}type-lite`;
    }

    return `${cssIsDraft}type-normal`;
  }

  private getCardDate(): string {
    const item = this.getItem();
    return item.isOfficialContent() ? item.publication_date : (item as Example).getLastDate();
  }

  private getParams() {
    return {
      exampleStatus: this.exampleStatus,
      item: this.getItem(),
      publishIsDisabled: this.publishIsDisabled,
      detailRenderMode: this.detailRenderMode,
      item_id: this.getItemPageId()
    };
  }

  private getItemPageId() {
    const item: AbstractMultimediaItem = this.getItem();
    let itemId = item.slug || item.id;

    if (item.isInternal()) {
      itemId = item.internal_link as string;
    }

    return itemId;
  }

  private isPage(contentType: PublicationContentType | ContentType) {
    return this.detailRenderMode === MultimediaDetailRenderMode.Page || contentType === PublicationContentType.INTERNAL;
  }

  private getNavigationPath(item: AbstractMultimediaItem) {
    const isExample = !item.isOfficialContent() || item.content_type === PublicationContentType.INTERNAL;
    if (isExample) {
      return PAGES.ITEM_EXAMPLE;
    }

    return PAGES.ITEM;
  }

  private navigateToModel(params: { item: AbstractMultimediaItem }) {
    const item: MSafeAny = params.item;
    const { modelSectionRoute, modelSection } = getModelUrlAndSection(item);
    const navEvent = new NavigationEvent(NavigationEvents.Push, {
      path: getUrlFromMap(modelSectionRoute?.url as string, modelSection),
      navParams: {
        section: modelSection,
        enablePrevious: true
      }
    });
    this.modelService.getProgress(true).subscribe(() => this.navigationService.navigate(navEvent));
  }

  private incrementViews(item: AbstractMultimediaItem) {
    this.publicationService.updateItemVisualizations(item.id).subscribe((views) => (item.views = views));
  }

  private sendAnalyticsEvent() {
    const item = this.getItem();
    const action = item.isOfficialContent() ? CategoriesAnalytics.SEE_PUBLICATION : CategoriesAnalytics.SEE_EXAMPLE;

    this.analyticsService.sendEvent(action, { [ActionsAnalytics.CLICKACTION]: item.getAnalyticsLabel() });
  }
}

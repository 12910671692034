<ion-datetime
  displayFormat="YYYY"
  [(ngModel)]="isoDate"
  [disabled]="disabled"
  [doneText]="'EMPLOYEE_PORTAL.CONFIRM' | translate"
  [cancelText]="'EMPLOYEE_PORTAL.CANCEL' | translate"
  (ionChange)="sendDate($event)"
  [min]="min"
  [max]="max"
  class="c-input"
>
</ion-datetime>
<img class="icon" src="assets/imgs/employee-portal/today.svg" alt="Calendar" />

import { ContentType, PublicationContentType } from '@app/shared/enums/multimedia/content-status-types.enum';
import { Example } from '@app/shared/models/multimedia/example.model';
import { AbstractMultimediaItem } from '@app/shared/models/multimedia/multimedia.abstract.model';
import { Publication } from '@app/shared/models/multimedia/publication.model';
/* eslint-disable @typescript-eslint/naming-convention */

export abstract class Text extends AbstractMultimediaItem {
  abstract content_type: PublicationContentType.TEXT | ContentType.TEXT;
}

export class TextPublication extends Publication implements Text {
  content_type: PublicationContentType.TEXT = PublicationContentType.TEXT;
}

export class TextExample extends Example implements Text {
  content_type: ContentType.TEXT = ContentType.TEXT;
}

import { Component, Injector, Input } from '@angular/core';

import { ItemCardType } from '@app/shared/enums/cards/item-card-type.enum';
import { InternalLink } from '@shared/models/multimedia/internal-link.model';

import { ImageDetailComponent } from '../../multimedia-details';
import { MultimediaCardDirective } from '../multimedia-card';

@Component({
  selector: 'app-internal-link-card',
  templateUrl: './internal-link-card.component.html',
  styleUrls: ['../multimedia-card.scss']
})
export class InternalLinkCardComponent extends MultimediaCardDirective {
  @Input() internalLink!: InternalLink;
  @Input() type!: ItemCardType;

  show = false;

  constructor(injector: Injector) {
    super(ImageDetailComponent, injector);
  }

  protected onModalDetailDismiss(internalLink: InternalLink) {
    if (!internalLink) {
      return;
    }

    this.internalLink.content_type = internalLink.content_type;
    this.internalLink.source = internalLink.source;
    this.internalLink.thumbnail = internalLink.thumbnail;
  }

  protected getItem(): InternalLink {
    return this.internalLink;
  }
}

import { Component, OnDestroy, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '@app/services/auth/auth.service';

const MESSAGES = {
  ANONIMOUS: 'AUTHENTICATION.TRYING',
  LOGGED_ADFS: 'AUTHENTICATION.INITIALIZING',
  LOGGED_ACTIVO2: 'AUTHENTICATION.INITIALIZING',
  LOGOUT: 'AUTHENTICATION.CLOSING',
  REFRESH: 'AUTHENTICATION.INITIALIZING'
};

@Component({
  selector: 'app-auth-placeholder',
  templateUrl: './auth-placeholder.component.html',
  styleUrls: ['./auth-placeholder.component.scss']
})
export class AuthPlaceholderComponent implements OnInit, OnDestroy {
  @Input() message = MESSAGES.ANONIMOUS;

  private statusSubscription!: Subscription;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    if (this.message === MESSAGES.ANONIMOUS) {
      this.statusSubscription = this.authService.status$.subscribe((status) => (this.message = MESSAGES[status]));
    }
  }

  ngOnDestroy() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }
}

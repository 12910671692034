/* eslint-disable */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { Logger } from '@app/services';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { MediaPlayerService } from '@app/services/media-player/media-player.service';
import { AbstractMultimediaItem } from '@app/shared/models/multimedia/multimedia.abstract.model';
import { Video } from '@app/shared/models/multimedia/video.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { domChanges } from '@app/shared/utils/utils';
import { Platform } from '@ionic/angular';
import Plyr from 'plyr';
import { ENV } from 'src/environments/environment';

import { generateId } from './media-player.utils';
import { AnalyticsService } from '@app/services/analytics/analytics.service';

const PERCENTAGES = [25, 50, 75, 100];

@Component({
  selector: 'app-media-player',
  templateUrl: './media-player.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./media-player.component.scss']
})
export class MediaPlayerComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {
  private static currentPlayerIdOnFullscreen: string | null;
  private static currentPlayerIdPlayingVideo: string | null;

  private readonly logger = new Logger('MediaPlayerComponent');
  private events = new Map();
  private playerChange = new BehaviorSubject<Plyr | null>(null);
  private hasBeenPlayed = false;

  @ViewChild('multimediaElementRef') multimediaElementRef!: ElementRef;

  @Input() video!: Video;
  @Input() previewVideo: MSafeAny;
  @Input() autoplay!: boolean;
  @Input() isDeletableItem: MSafeAny;
  @Input() analyticsCategory!: string;
  @Input() isActivePage = true;
  @Input() preloadType = 'auto';
  @Input() noBackgroundCarousel = false;
  @Input() isPublicationParent = false;

  // ngx-plyr events
  @Output() plyrInit = this.playerChange.pipe(filter((player) => Boolean(player))) as EventEmitter<Plyr>;

  // standard media events
  @Output() play = this.createLazyEvent('play', () => this.onPlayPlayer());
  @Output() playing = this.createLazyEvent('playing');
  @Output() pause = this.createLazyEvent('pause', () => this.allowSleepIfIsPlaying());
  @Output() enterFullscreen = this.createLazyEvent('enterfullscreen', () => this.onEnterFullscreen());
  @Output() exitFullscreen = this.createLazyEvent('exitfullscreen', () => this.onExitFullscreen());
  @Output() ready = this.createLazyEvent('ready');
  @Output() timeupdate = this.createLazyEvent('timeupdate', () => this.onTimeUpdate());

  // HTML5 events
  @Output() loadStart = this.createLazyEvent('loadstart');
  @Output() loadedData = this.createLazyEvent('loadeddata', () => this.soundHandler());
  @Output() loadedMetadata = this.createLazyEvent('loadedmetadata');
  @Output() canPlay = this.createLazyEvent('canplay');
  @Output() stalled = this.createLazyEvent('stalled');
  @Output() multimediaError = this.createLazyEvent('error');
  @Output() ended = this.createLazyEvent('ended', () => this.onEnded());
  @Output() deleteItem = new EventEmitter();
  @HostBinding('class.hide-volume') volumeHide = false;
  @HostBinding('class.center-image-fullscreen') centerImageFullscreen = false;

  isPreviewPlayerType!: boolean;
  isYoutubePlayer!: boolean;
  showPlayer!: boolean;
  videoControls!: Plyr.Options;

  defaultSrc!: string;

  private readonly id: string = generateId();
  private player!: Plyr;
  private percentagesPlayed = [];
  private eventsSubscribed: string[] = [];
  private subscriptions = new Subscription();

  constructor(
    private playerService: MediaPlayerService,
    private platform: Platform,
    private ngZone: NgZone,
    private analyticsService: AnalyticsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setPoster(changes);
  }

  async ngOnInit() {
    this.plyrInit.subscribe(() => {
      this.checkOutputBinding(this.play, 'play');
      this.checkOutputBinding(this.pause, 'pause');
      this.checkOutputBinding(this.enterFullscreen, 'enterfullscreen');
      this.checkOutputBinding(this.exitFullscreen, 'exitfullscreen');
      this.checkOutputBinding(this.ended, 'ended');
      this.checkOutputBinding(this.timeupdate, 'timeupdate');
      this.checkOutputBinding(this.loadedData, 'loadeddata');
    });

    if (this.video && this.video.url) {
      this.defaultSrc = this.video.url;
    }

    this.setPlayerType();
    this.setPoster();
  }

  ngAfterViewInit() {
    this.buildPlayer(this.multimediaElementRef.nativeElement);
    this.subscriptions.add(
      this.playerService.playVideo$.subscribe((mediaplayer) => this.pauseIfOtherPlayer(mediaplayer))
    );
    this.subscriptions.add(this.playerService.stopVideo$.subscribe(() => this.stopPlayer()));
    this.subscriptions.add(this.playerService.pauseVideo$.subscribe(() => this.pauseIfNotInFullscreen()));
    this.setPoster();
  }

  ngOnDestroy() {
    this.playerService.doScrollToContent(false);
    this.subscriptions.unsubscribe();
    this.destroyPlayer();
  }

  getId() {
    return this.id;
  }

  isVideoPlayer() {
    return Boolean(this.video);
  }

  isVideoPreviewPlayer() {
    return Boolean(this.previewVideo);
  }

  playPlayer() {
    this.player.play();
  }

  pausePlayer() {
    this.player.pause();
  }

  forwardPlayer(time: number) {
    this.player.forward(time);
  }

  rewindPlayer(time: number) {
    this.player.rewind(time);
  }

  isHasAudio() {
    return this.player.hasAudio;
  }

  mutedPlayer() {
    return this.player.muted;
  }

  setMutedPlayer(muted: boolean) {
    this.player.muted = muted;
  }

  currentTimePlayer() {
    return this.player.currentTime;
  }

  isPlayingVideo() {
    return this.player.playing;
  }

  stopPlayer() {
    this.player.stop();
    this.allowSleepIfIsPlaying();
  }

  deleteMultimedia() {
    this.deleteItem.emit();
  }

  isDeletable() {
    return Boolean(this.isVideoPreviewPlayer) && this.isDeletableItem;
  }

  openFullScreen() {
    this.player.fullscreen.enter();
  }

  exitFullScreen() {
    this.player.fullscreen.exit();
  }

  fullScreenState(): boolean {
    return this.player.fullscreen.active;
  }

  private isInFullscreen(): boolean {
    return this.id === MediaPlayerComponent.currentPlayerIdOnFullscreen;
  }

  private unsetCurrentPlayerIdPlayingVideo() {
    MediaPlayerComponent.currentPlayerIdPlayingVideo = null;
  }

  private setCurrentPlayerIdPlayingVideo(str: string) {
    MediaPlayerComponent.currentPlayerIdPlayingVideo = str;
  }

  private hasChangedVideoImage(changes: SimpleChanges): boolean {
    const playerAndVideo = this.player && this.video && this.video.video_image;

    return Boolean(changes ? changes.video && playerAndVideo : playerAndVideo);
  }

  /**
   * @description
   * Prevent player to omit set up poster, possible bug from Plyr v3.5.6
   */
  private setPoster(changes: SimpleChanges | null = null) {
    if (this.hasChangedVideoImage(changes as SimpleChanges)) {
      this.player.poster = this.video.video_image as string;
    }
  }

  private setPlayerType(): void {
    this.isPreviewPlayerType = this.isVideoPreviewPlayer();
    this.showPlayer = this.isVideoPlayer();
  }

  private allowSleepIfIsPlaying() {
    if (this.id === MediaPlayerComponent.currentPlayerIdPlayingVideo) {
      this.unsetCurrentPlayerIdPlayingVideo();
    }
  }

  private handlePlayOnCarousel() {
    if (!this.noBackgroundCarousel) {
      return;
    }

    if (!this.fullScreenState()) {
      this.openFullScreen();
    }
  }

  private handleVideoEndedOnCarousel() {
    if (this.fullScreenState()) {
      this.exitFullScreen();
    }
  }

  private pauseIfOtherPlayer(mediaplayer: MediaPlayerComponent) {
    if (this.id !== mediaplayer.id) {
      this.pausePlayer();
    }
  }

  private pauseIfNotInFullscreen() {
    if (!this.isInFullscreen()) {
      this.pausePlayer();
    }
  }

  private onPlayPlayer() {
    this.setCurrentPlayerIdPlayingVideo(this.id);
    this.playerService.play(this);
    this.handlePlayOnCarousel();
    this.sendAnalyticsEvent(CategoriesAnalytics.WATCH_VIDEO);
    if (!this.hasBeenPlayed) {
      this.hasBeenPlayed = true;
    }
  }

  private hiddenControlsByIdVideo(isHidden: boolean) {
    this.player['config'].hideControls = isHidden;
    if (!isHidden) {
      this.player.elements?.container?.classList.remove('plyr--hide-controls');
    }
  }

  private onEnterFullscreen() {
    this.playerService.doScrollToContent(false);
    this.hiddenControlsByIdVideo(true);
    this.setCurrentPlayerIdPlayingVideo(this.id);
  }

  private onExitFullscreen() {
    this.playerService.doScrollToContent(true);

    this.hiddenControlsByIdVideo(false);
    if (!this.isInFullscreen()) {
      return;
    }
    MediaPlayerComponent.currentPlayerIdOnFullscreen = null;
    this.player.toggleControls(true);
  }

  private onTimeUpdate() {
    const percentage = Math.ceil((this.player.currentTime / this.player.duration) * 100);

    const percentageToSend = PERCENTAGES.find((per, index) => {
      const nextIndex = index + 1;
      const nextPercentage = PERCENTAGES.length > nextIndex && PERCENTAGES[nextIndex];
      return nextPercentage && percentage >= per && percentage < nextPercentage;
    });

    if (percentageToSend && !this.percentagesPlayed.includes(percentageToSend as never)) {
      this.sendAnalyticsEvent(ActionsAnalytics.INTERACTION_VIDEO_PERCENT_VIEW, percentageToSend);
    }
  }

  private onEnded() {
    const percentageToSend = 100;
    this.handleVideoEndedOnCarousel();
    if (!this.percentagesPlayed.includes(percentageToSend as never)) {
      this.sendAnalyticsEvent(ActionsAnalytics.INTERACTION_VIDEO_PERCENT_VIEW, percentageToSend);
    }
    this.allowSleepIfIsPlaying();
  }

  private getControls() {
    let controls = ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'fullscreen'];

    if (this.isPublicationParent) {
      controls = ['play-large'];
    }

    return controls;
  }

  private buildPlayer(element: HTMLElement): void {
    this.ngZone.runOutsideAngular(() => {
      this.player = new Plyr(element, {
        clickToPlay: true,
        controls: this.getControls(),
        debug: ENV.isDebugMode,
        hideControls: false,
        iconUrl: 'assets/imgs/plyr-custom.svg',
        youtube: { noCookie: true },
        ratio: '16:9'
      });

      this.playerChange.next(this.player);
    });
  }

  private destroyPlayer() {
    if (this.player) {
      Array.from(this.events.keys()).forEach((name) => this.removePlayerEvent(name));

      this.player.destroy();
    }
  }

  private setPlayerEvent(name: string, handler: (event: Plyr.PlyrEvent) => void) {
    this.events.set(name, handler);
    this.player.on(name as MSafeAny, handler);
  }

  private removePlayerEvent(name: string) {
    this.player.off(name as MSafeAny, this.events.get(name));
    this.events.delete(name);
  }

  // See https://stackoverflow.com/a/53704102/1990451
  private createLazyEvent<T extends Plyr.PlyrEvent>(
    name: Plyr.StandardEvent | Plyr.Html5Event | Plyr.YoutubeEvent,
    callback: (() => void) | null = null
  ): EventEmitter<T> {
    return this.plyrInit.pipe(
      switchMap(
        () =>
          new Observable((observer) => {
            this.eventsSubscribed.push(name);
            this.setPlayerEvent(name, (event) => {
              event.stopPropagation();
              this.ngZone.run(() => {
                if (callback) {
                  callback();
                }
                observer.next(event);
              });
            });
          })
      )
    ) as EventEmitter<T>;
  }

  // Checks if the subscribe is done to listen the events and do the actions attached properly
  private checkOutputBinding(output: EventEmitter<MSafeAny>, eventName: string) {
    if (!this.eventsSubscribed.includes(eventName)) {
      output.subscribe();
    }
  }

  private sendAnalyticsEvent(event: ActionsAnalytics | CategoriesAnalytics, percentView?: number) {
    const item: AbstractMultimediaItem = this.video;

    let label = item.getAnalyticsLabel();
    if (percentView) {
      this.percentagesPlayed.push(percentView as never);
    } else {
      this.analyticsService.sendEvent(event as CategoriesAnalytics, {
        [ActionsAnalytics.CLICKACTION]: label,
        [ActionsAnalytics.TYPE]: item.label ? CategoriesAnalytics.LOCAL : CategoriesAnalytics.GLOBAL
      });
    }
  }

  private async soundHandler(): Promise<void> {
    this.player.currentTime = this.currentTimePlayer();
    await domChanges(250);

    this.volumeHide = !this.isHasAudio();
  }
}

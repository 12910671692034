import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { shareReplay } from 'rxjs/operators';

import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { delayedRetry } from '@app/shared/utils/delayedRetry';
import { ApiUrls } from '@services/api/api.urls.service';
import { Logger } from '@services/logger/logger.service';
import { NetworkService } from '@services/network/network.service';
import { STORAGE_CONSTANTS, StorageService } from '@services/storage';
import { AuditMessage } from '@shared/models/audit-message/audit-message';
import { appVersion } from '@shared/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  version: string = appVersion;
  private logger: Logger = new Logger('AuditService');
  private sendingMessages = false;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private urls: ApiUrls,
    private router: Router,
    private network: NetworkService
  ) {}

  get activePage() {
    return this.router.url.replace('/', '');
  }

  async flush() {
    if (this.activePage === PAGES.MAINTENANCE || this.sendingMessages) return;
    try {
      const auditMessages: AuditMessage[] = (await this.storage.get(STORAGE_CONSTANTS.AUDIT_MESSAGES)) || [];
      if (auditMessages.length > 0) {
        this.sendingMessages = true;
        await this.http
          .post(this.urls.audit.route, auditMessages)
          .pipe(delayedRetry(3000), shareReplay())
          .toPromise()
          .then(() => {
            this.setAuditMessages([]);
          })
          .finally(() => {
            this.sendingMessages = false;
          });
      }
    } catch (err) {
      this.logger.error('Error on Http request:', err);
      this.sendingMessages = false;
      return;
    }
  }

  async push(auditMessage: AuditMessage, realTime = true) {
    auditMessage = this.setVersion(auditMessage);
    if (this.activePage === PAGES.MAINTENANCE) return;
    try {
      const auditMessages: AuditMessage[] = (await this.storage.get(STORAGE_CONSTANTS.AUDIT_MESSAGES)) || [];
      auditMessages.push(auditMessage);
      this.logger.debug('Storage audit messages:');
      await this.setAuditMessages(auditMessages);
      this.logger.debug('Stored messages for next flush:', auditMessages);
      if ((auditMessages.length >= 50 || this.network.hasConnection()) && realTime && !this.sendingMessages) {
        await this.flush();
      }
    } catch (err) {
      this.logger.error('Cannot push audit message in storage:', err);
    }
  }

  private setAuditMessages(auditMessages: AuditMessage[]) {
    return this.storage.set(STORAGE_CONSTANTS.AUDIT_MESSAGES, auditMessages);
  }

  private setVersion(auditMessage: AuditMessage) {
    auditMessage.app_version = this.version;
    return auditMessage;
  }
}

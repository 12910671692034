import { ModelSectionNames } from '@app/shared/enums/model/model-sections.enum';
import { Filter } from '@app/shared/models/filters/filters';

export const EXAMPLE_FILTERS: Filter[] = [
  {
    value: ModelSectionNames.MODEL_IMPORTANCE,
    label: 'MODEL_MENU.MODEL_IMPORTANCE',
    icon: 'app-icon-treasure'
  },
  {
    value: ModelSectionNames.UNIVERSAL_TRUTHS,
    label: 'UNIVERSAL_TRUTHS',
    icon: 'app-icon-apple',
    subSections: [
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.ALL',
        icon: 'app-icon-apple'
      },
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS_WHAT,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.WHAT'
      },
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS_DPE,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.DPE'
      },
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS_CONTRAST,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.CONTRAST'
      },
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS_FACTS,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.FACTS'
      },
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS_NOBODY,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.NOBODY'
      },
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS_IMPROVE,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.IMPROVE'
      },
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS_WALK,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.WALK'
      },
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS_CAUSE,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.CAUSE'
      },
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS_TIME,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.TIME'
      },
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS_CHAIN,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.CHAIN'
      },
      {
        value: ModelSectionNames.UNIVERSAL_TRUTHS_APPLY,
        label: 'MODEL_PAGES.UNIVERSAL_TRUTHS.MENU.APPLY'
      }
    ]
  },
  {
    value: ModelSectionNames.PARADIGM,
    label: 'MODEL_MENU.PARADIGM',
    icon: 'app-icon-glasses'
  },
  {
    value: ModelSectionNames.MISSION_AND_VISION,
    label: 'MODEL_MENU.MISSION_AND_VISION',
    icon: 'app-icon-lettuce'
  },
  {
    value: ModelSectionNames.THE_BOSS,
    label: 'MODEL_MENU.THE_BOSS',
    icon: 'app-icon-lighthouse'
  },
  {
    value: ModelSectionNames.THE_WORKER,
    label: 'MODEL_MENU.THE_WORKER',
    icon: 'app-icon-worker'
  },
  {
    value: ModelSectionNames.THE_PROVIDER,
    label: 'MODEL_MENU.THE_PROVIDER',
    icon: 'app-icon-person'
  },
  {
    value: ModelSectionNames.THE_SOCIETY,
    label: 'MODEL_MENU.THE_SOCIETY',
    icon: 'app-icon-society'
  },
  {
    value: ModelSectionNames.THE_CAPITAL,
    label: 'MODEL_MENU.THE_CAPITAL',
    icon: 'app-icon-euro'
  },
  {
    value: ModelSectionNames.MODEL_APPLICATION,
    label: 'MODEL_MENU.MODEL_APPLICATION',
    icon: 'app-icon-plane'
  },
  {
    value: ModelSectionNames.PURPOSE,
    label: 'MODEL_MENU.PURPOSE',
    icon: 'app-icon-sprout'
  }
];

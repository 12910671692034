<app-content-modal [buttons]="buttons" [hideClose]="true">
  <div class="grid">
    <div class="row">
      <h1 class="title">{{ title | translate }}</h1>
    </div>

    <div class="row">
      <p class="advise">{{ advise | translate }}</p>
    </div>

    <div class="row">
      <div class="remember" (click)="onChange()">
        <div class="checkbox">
          <ion-icon *ngIf="selected" name="checkmark-outline"></ion-icon>
        </div>
        <span class="label">{{ checkboxLabel | translate }}</span>
      </div>
    </div>
  </div>
</app-content-modal>

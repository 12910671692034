import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from '@app/components/components.module';
import { HeaderModule } from '@app/components/header/header.module';
import { MenuModule } from '@app/components/menu/menu.module';
import { TitleSectionModule } from '@app/components/title-section/title-section.module';
import { PtrabComponentsModule } from '@app/ptrab/components/ptrab-components.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';

import { IrpfInfoComponent } from './irpf-info/irpf-info/irpf-info.component';
import { IrsInfoComponent } from './irpf-info/irs-info/irs-info.component';
import { IrpfSectionEditionComponent } from './irpf-section-edition/irpf-section-edition.component';
import { IrpfItemComponent } from './my-data/irpf-item/irpf-item.component';
import { MyDataComponent } from './my-data/my-data.component';
import { IrpfItemPtComponent } from './my-data-pt/irpf-item-pt/irpf-item-pt.component';
import { MyDataPtComponent } from './my-data-pt/my-data-pt.component';
import { PercentageReadjustmentComponent } from './percentage-readjustment/percentage-readjustment.component';
import { VoluntaryWithholdingEsComponent } from './voluntary-withholding/voluntary-withholding-es/voluntary-withholding-es.component';
import { WithholdingInfoEsComponent } from './voluntary-withholding/voluntary-withholding-es/withholding-info-es/withholding-info-es.component';
import { VoluntaryWithholdingPtComponent } from './voluntary-withholding/voluntary-withholding-pt/voluntary-withholding-pt.component';
import { WithholdingInfoPtComponent } from './voluntary-withholding/voluntary-withholding-pt/withholding-info-pt/withholding-info-pt.component';
import { PtWithholdingCertificateItemComponent } from './withholding-certificate/pt-withholding-certificate-list/pt-withholding-certificate-item/pt-withholding-certificate-item.component';
import { PtWithholdingCertificateListComponent } from './withholding-certificate/pt-withholding-certificate-list/pt-withholding-certificate-list.component';
import { WithholdingCertificateComponent } from './withholding-certificate/withholding-certificate.component';
import { ContentModule } from '@app/components/content/content.module';
import { MPopoverModule } from '@mercadona/components/popover';
import { DirectivesModule } from '@app/shared/directives/directives.module';

const components = [
  IrpfSectionEditionComponent,
  WithholdingCertificateComponent,
  MyDataComponent,
  MyDataPtComponent,
  IrpfItemComponent,
  IrpfItemPtComponent,
  VoluntaryWithholdingEsComponent,
  VoluntaryWithholdingPtComponent,
  WithholdingInfoEsComponent,
  WithholdingInfoPtComponent,
  PercentageReadjustmentComponent,
  IrpfInfoComponent,
  IrsInfoComponent,
  PtWithholdingCertificateListComponent,
  PtWithholdingCertificateItemComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    HeaderModule,
    TranslateModule,
    TitleSectionModule,
    ContentModule,
    MenuModule,
    PtrabComponentsModule,
    ComponentsModule,
    PipesModule,
    MPopoverModule,
    DirectivesModule
  ],
  declarations: [components],
  exports: [components]
})
export class IrpfComponentsModule {}

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
/* eslint-disable @typescript-eslint/naming-convention */

export class Company {
  code: string;
  name: string;
  employee_number: string;

  constructor(data: MSafeAny = {}) {
    this.code = data.code;
    this.name = data.name;
    this.employee_number = data.employee_number;
  }
}

export interface ReadjustmentCompany extends Company {
  is_allowed: boolean;
  is_requested: boolean;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ITranslationRequest } from '@app/services/content-translator/content-translator.model';
import { ContentTranslatorService } from '@app/services/content-translator/content-translator.service';
import { AbstractMultimediaItem } from '@app/shared/models/multimedia/multimedia.abstract.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
/* eslint-disable @typescript-eslint/naming-convention */

enum TRANSLATION_STATE {
  IDLE,
  LOADING,
  ERROR,
  DONE
}

@Component({
  selector: 'app-content-translation',
  templateUrl: './content-translation.component.html',
  styleUrls: ['./content-translation.component.scss']
})
export class ContentTranslationComponent {
  @Input() itemToTranslate!: AbstractMultimediaItem;
  @Output() toggle = new EventEmitter<boolean>();
  @Output() textTranslatedEmitter = new EventEmitter<void>();

  readonly STATES = TRANSLATION_STATE;

  textTranslated = '';
  isOpen = false;
  isDisabled = false;
  currentState = TRANSLATION_STATE.IDLE;
  regexTagsImg = /<\s*img[^>]*src=["|'](.*?)["|'][^>]*\/*>/g;
  regexImg = /(<img(\/?[^>]+)>)/g;
  regexSpaceValues = /( \$ 2)/g;
  regexReplaceImgTags = /\$2/g;
  regexLimitChar = /.{1,4900}/g;

  constructor(private contentTranslator: ContentTranslatorService, private translateService: TranslateService) {}

  getToggleLiteral(): string {
    return this.isOpen ? 'TRANSLATION_CONTENT.HIDE_TRANSLATION' : 'TRANSLATION_CONTENT.SHOW_TRANSLATION';
  }

  translateContent() {
    const textWithoutImgs = this.itemToTranslate.text
      .replace(this.regexTagsImg, '$2')
      .match(this.regexLimitChar) as MSafeAny;
    const arrayPromises = [];

    textWithoutImgs.map((textPlain, index) => {
      const request: ITranslationRequest = {
        language_from: this.itemToTranslate.language as string,
        language_to: this.translateService.currentLang,
        text: this.getTextToTranslate(textPlain, index)
      };

      this.currentState = this.STATES.LOADING;
      this.isDisabled = true;
      arrayPromises.push(this.contentTranslator.translate(request).toPromise() as never);
    });

    Promise.all(arrayPromises)
      .then((arrayTranslated) => {
        arrayTranslated.forEach((textTranslated, index) => {
          this.textTranslated += textTranslated;
          if (index === textWithoutImgs.length - 1) {
            this.textTranslated = this.replaceValues(this.textTranslated);
            this.currentState = this.STATES.DONE;
            this.isDisabled = false;
          }
        });
        this.textTranslatedEmitter.emit();
      })
      .catch(() => {
        this.currentState = this.STATES.ERROR;
      });
  }

  replaceValues(textTranslated) {
    let textTranslatedCopy = textTranslated.replace(this.regexSpaceValues, '$2');
    const imgsToReplace = this.itemToTranslate.text.split(this.regexImg).filter((n) => {
      if (this.regexImg.test(n)) return true;
    });
    const auxPosition = imgsToReplace.length;
    let auxPositionCopy = -1;

    textTranslatedCopy = textTranslatedCopy.replace(this.regexReplaceImgTags, () => {
      auxPositionCopy++;
      if (auxPositionCopy <= auxPosition) {
        return imgsToReplace[auxPositionCopy];
      }
    });
    return textTranslatedCopy;
  }

  onToggleTranslation() {
    if (this.isDisabled) {
      return;
    }

    this.isOpen = !this.isOpen;

    if (!this.textTranslated.length) {
      this.translateContent();
    }
  }

  private getTextToTranslate(textPlain, index): string {
    return this.itemToTranslate ? (index === 0 ? `<h1>${this.itemToTranslate.title}</h1>${textPlain}` : textPlain) : '';
  }
}
